import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Mobx
import { useObserver } from 'mobx-react';
import { checkOutStore } from '../../../../stores/MainStore';
// Library
import get from 'lodash/get';
import first from 'lodash/first';
import isArrayLikeObject from 'lodash/isArrayLikeObject';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Divider from 'antd/es/divider';
import Typography from 'antd/es/typography';
// Helpers
import { useWhen } from '../../../utils/Hooks';
import { SectionsDeclaration } from '../../../configs/Sections';
import { dictionary } from '@GlobalHelpers';
import { throatBoolean } from '../../../configs/ConfigsHeader';
import { isNothing } from '../../../utils/Utils';
import { storeOrderDetails } from '../Store/StoreOrderDetails';
import Mobi from '@Mobi'
const { Title } = Typography
const { Option } = Select

// const defaultTime = '08:00:00/12:00:00'
const defaultTime = 'none'

let shipTime = [
    { value: 'none', dictionary: 'u_all_renew_none'},
    { value: '08:00:00/12:00:00', dictionary: '08:00 - 12:00' },
    { value: '14:00:00/16:00:00', dictionary: '14:00 - 16:00' },
    { value: '16:00:00/18:00:00', dictionary: '16:00 - 18:00' },
    { value: '18:00:00/20:00:00', dictionary: '18:00 - 20:00' },
    { value: '19:00:00/21:00:00', dictionary: '19:00 - 21:00' }]

const colResponsiveConfig = {
    xs: 24,
    sm: 24,
    md: 20,
    lg: 12,
    xl: 12,
    xxl: 12
}

/**
 * @param {object} props
 * @param {boolean} props.loading 
 */
const SectionTime = props => {
    const when = useWhen()
    const [time, setTime] = useState(() => get(checkOutStore, 'shipToTime', defaultTime))

    

    useEffect(() => {
        if (isNothing(checkOutStore.shipToTime)) {
            setTime(defaultTime)
            checkOutStore.shipToTime = defaultTime
            localStorage.setItem('shipToTime', JSON.stringify(defaultTime))
        }
    }, [])

    // always update mobx shipping time whenever state changed
    useEffect(() => {
        if (throatBoolean(SectionsDeclaration.OrderDetails().time)) {
            checkOutStore.shipToTime = time
        }
    }, [time])

    const customBtn = () => {
        let customBtn = [
            {
                text: dictionary('u_all_renew_cancel'),
                handler: 'cancel'
            },
            {
                text: dictionary('u_all_renew_ok'),
                handler: 'set'
            }

        ];

        return customBtn;
    }

    const optionsFactory = () => {
        const result = []
        let list = shipTime
       

        list.map((item, index) => {
            let key = '', value = ''
           
            
            value = get(item, 'value')
            if(value == 'none'){
                key = dictionary(item.dictionary)
            }else{
                key = item.dictionary
            }
            

            result.push({
     
                value: value,
                text: key
          
              })
        })


        return result;
    }

    return useObserver(() =>
        when(throatBoolean(SectionsDeclaration.OrderDetails().time),
            <Row type="flex" justify="center" className="text-center shipping-method">
                <Col span={24}>
                    <Title level={4}>{dictionary('delivery_time')}</Title>
                    {/* <Divider/> */}
                    <Row id="delivery-time" type="flex" justify="center">
                        <Col {...colResponsiveConfig} >
                            <Mobi.Select 
                                display="center"
                                touchUi={true}
                                value={time} 
                                data={optionsFactory()} 
                                buttons={customBtn()}
                                filter={false}
                                onSet={(value,inst) => 
                                    {
                                        var selected= inst.getVal();
                                        setTime(selected)
                                        checkOutStore.shipToTime = selected
                                        localStorage.setItem('shipToTime', JSON.stringify(selected))
                                    }
                                }
                                rows={7}
                               
                                // onSelected={(e) => this.onSelected(e)}
                            />
                            {/* <Select
                                value={time}
                                onChange={(value) => 
                                    {
                                        setTime(value)
                                        checkOutStore.shipToTime = value
                                        localStorage.setItem('shipToTime', JSON.stringify(value))
                                    }
                                }
                                disabled={storeOrderDetails.isLoadingAPI}>
                                {optionsFactory()}
                            </Select> */}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    )
}

SectionTime.propTypes = {
    loading: PropTypes.bool
}

SectionTime.defaultProps = {
    loading: false
}

SectionTime.displayName = 'SectionTime'
export default SectionTime