import React, { useEffect, useRef, useState, useImperativeHandle } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { language } from '@Language'
import { storeEnroll } from '@Stores/StoreEnroll'
import { Case, Default, Switch, When } from 'react-if'
import classnames from 'classnames'
import '../Layouts/2020/Components/enroll.scss'
import './enrollment.scss'
import { inArray } from '@Components/config'
import { dictionary, formatNumber, isCountryPhilippines, isNilOrEmpty, plainDictionary } from '@Components/GlobalHelpers'
import { storeProduct } from '@Stores/StoreProduct'
import { reaction, toJS } from 'mobx'
import { Button, Card, Checkbox, Tag } from 'antd'
import styled from 'styled-components'
import { store } from '@Stores/MainStore'
import { getPrice } from '@Components/shopping/ProductPrice'
import Lightbox from 'react-image-lightbox'
import { CountryConfigs } from '@Components/configs/Configs'
import { delay, isEqual, isString, uniqWith } from 'lodash'
import { isNothing, toBool } from '@Components/utils/Utils'
import Mobi from '@Components/mobiscroll/Mobi'
import { storeElement } from '@Stores/StoreElement'
import { Country } from '@Stores/StoreConfigCountry'
import { storeBowser } from '@Stores/StoreBowser'
import { getParameterByName, getQueryString, hasQueryString } from '@Components/configs/ConfigsHeader'
import EnrollFactory from './lib/EnrollFactory'
import { getCartProfile, getEnroll } from '@Services/Network'

const Products = observer(props => {
    const refSelectProduct = useRef()

    const [totalItems, setTotalItems] = useState({
        items: 0,
        itemsPerRow: 0
    })

    const localStore = useLocalObservable(() => ({
        optionsItem: []
    }))

    useEffect(() => {
        if (Country.isPhilippines()) {
            if (hasQueryString('profile_id')) {
                if (storeEnroll.isFirstTimeProfileID) {
                    storeEnroll.isLoadingProfileID = true

                    const profileID = getQueryString().profile_id

                    getCartProfile(response => {
                        if (toBool(response.success)) {
                            const data = response.data
                            const enrollerID = data.enroller_id
                            const sponsorID = data.sponsor_id
                            const products = JSON.parse(data.product_list)
                            products.map(item => {
                                const prod = storeProduct.getProduct(item.item_code)
                                if (storeProduct.hasProduct(item.item_code) && item.qty <= prod.qty_sellable) {
                                    storeEnroll.productSource.push(item)
                                }
                            })

                            EnrollFactory.EnrollData.enroller = enrollerID
                            EnrollFactory.EnrollData.sponsor = sponsorID

                            initItems()

                            delay(() => {
                                storeEnroll.isLoadingProfileID = false
                            }, 500)
                        }
                    }, atob(profileID))
                }
            }

            let ref_id = getParameterByName('ref_id')
            if (ref_id) {
                getEnroll(response => {
                    // console.log('response==>', response)
                    if (response.statusText !== 'error') {
                        let product = JSON.parse(response.data.step_select_products)
                        let startKitItems = storeEnroll.starterKitItemsPH

                        // this one check for starter kit selected
                        if (startKitItems.length > product.length) {
                            let findStarterKit = startKitItems.reduce((result, data) => {
                                let findItem = product.find(e => e.item_code === data.item_code)
                                if (findItem) {
                                    result.push(findItem)
                                }
                                return result
                            }, [])
                            storeEnroll.starterKitSelected = findStarterKit[0].item_code
                        } else {
                            let findStarterKit = product.reduce((result, data) => {
                                let findItem = startKitItems.find(e => e.item_code === data.item_code)
                                if (findItem) {
                                    result.push(findItem)
                                }
                                return result
                            }, [])
                            storeEnroll.starterKitSelected = findStarterKit[0].item_code
                        }
                        // combine between startkit and product
                        let combineWithRecentProduct = product.concat(startKitItems)
                        // deleted dubplicate object and add to productSource
                        let finalProduct = uniqWith(combineWithRecentProduct, isEqual)
                        storeEnroll.productSource = finalProduct
                        storeEnroll.checkoutCart = finalProduct
                        setSelectedItemPHForCheck()
                    }
                }, ref_id)
            } else {
                console.log('not init for ref id')
            }
        } else if (Country.isMatchedCountry(['TH', 'SG', 'HK', 'JP', 'TW', 'MY', 'ID', 'LA', 'KH'])) {
            if (hasQueryString('profile_id')) {
                if (storeEnroll.isFirstTimeProfileID) {
                    storeEnroll.isLoadingProfileID = true

                    const profileID = getQueryString().profile_id

                    getCartProfile(response => {
                        if (toBool(response.success)) {
                            const data = response.data
                            const enrollerID = data.enroller_id
                            const sponsorID = data.sponsor_id
                            const products = JSON.parse(data.product_list)
                            products.map(item => {
                                const prod = storeProduct.getProduct(item.item_code)
                                if (storeProduct.hasProduct(item.item_code) && item.qty <= prod.qty_sellable) {
                                    storeEnroll.productSource.push(item)
                                }
                            })

                            EnrollFactory.EnrollData.enroller = enrollerID
                            EnrollFactory.EnrollData.sponsor = sponsorID

                            initItems()

                            delay(() => {
                                storeEnroll.isLoadingProfileID = false
                            }, 500)
                        }
                    }, atob(profileID))
                }
            }
        }
    }, [])

    useEffect(() => {
        const disposerDeviceType = reaction(
            () => storeBowser.type,
            () => {
                initLayout()
            }
        )

        if (props.isStarterKit === false) {
            initItems()
            initLayout()

            const disposerLanguage = reaction(
                () => language.current,
                () => {
                    initItems()
                }
            )

            const disposerProduct = reaction(
                () => storeEnroll.productSource.map(item => item),
                () => {
                    initItems()
                    initLayout()
                }
            )

            const starterKitSelectedChange = reaction(
                () => storeEnroll.starterKitSelected,
                () => {
                    initItems()
                    initLayout()
                    setSelectedItemPHForCheck()
                }
            )

            return () => {
                disposerDeviceType()
                disposerLanguage()
                disposerProduct()
                starterKitSelectedChange()
            }
        } else {
            getStarterKit()
        }
    }, [])

    const setSelectedItemPHForCheck = () => {
        const removed = storeEnroll.productSource.filter((item, key) => item.item_code !== '')
        const removed2 = removed.filter((item, key) => !inArray(item.item_code, storeEnroll.starterKitItems) || item.item_code === storeEnroll.starterKitSelected)
        let dataForCheck = []
        removed2.map((v, k) => {
            dataForCheck.push(v.item_code)
        })
        storeEnroll.selectedItemPHForCheck = dataForCheck
    }

    const getStarterKit = () => {
        const starterKits = []
        storeEnroll.productSource.map(item => {
            if (inArray(item.item_code, storeEnroll.starterKitItems)) {
                starterKits.push(item)
            }
        })

        storeEnroll.checkoutCart = starterKits
    }

    const initItems = () => {
        const temp = []

        storeProduct.products.forEach((itemValue, itemKey) => {
            if (inArray(itemValue.item_code, storeEnroll.selectedItems)) {
                return false
            }

            if (inArray(itemValue.item_code, storeEnroll.starterKitItems)) {
                return false
            }

            if (/\-/.test(itemValue.item_code)) {
                return false
            }

            if (Country.isPhilippines()) {
                if ((itemValue.price == 0 || itemValue.price == '') && itemValue.item_code !== '31325') {
                    return false
                }
            }

            let price = itemValue.price
            if (price == 0) {
                return false
            }
            if (itemValue.qty_sellable == 0) {
                return false
            }

            let title = itemValue.item_name.english
            if (language.isNative()) {
                if (itemValue.item_name.native !== '') {
                    title = itemValue.item_name.native
                }
            }

            let textSearch = itemValue.item_code + itemValue.item_name.native + itemValue.item_name.english

            title = padZerosToLength(itemValue.item_code, 5, 0) + ' ' + title
            temp.push({
                value: itemValue.item_code,
                text: textSearch,
                html: '' + `${title}`
            })
        })

        temp.sort((a, b) => {
            const aName = a['text'].toLowerCase()
            const bName = b['text'].toLowerCase()
            return aName.localeCompare(bName)
        })

        const tempSelectedItems = []
        storeEnroll.productSource.map((v, k) => {
            tempSelectedItems.push(v.item_code)
        })

        storeEnroll.selectedItemsPH = tempSelectedItems
        storeEnroll.selectedItems = tempSelectedItems

        localStore.optionsItem = temp
    }

    const padZerosToLength = (value, minLength, padChar) => {
        var iValLength = value.toString().length
        return new Array(minLength + 1 - iValLength).join(padChar) + value
    }

    const initLayout = () => {
        const itemWidth = 320
        const windowWidth = window.innerWidth - 40
        const itemsPerRow = Math.floor(windowWidth / itemWidth)
        setTotalItems({
            items: storeEnroll.productSource.length - 1 > itemsPerRow ? itemsPerRow : storeEnroll.productSource.length - 1,
            itemsPerRow: itemsPerRow
        })
    }

    return (
        <>
            {(() => {
                const productListWithNoStarterKit = storeEnroll.productSource.filter(o => o.item_code !== storeEnroll.starterKitSelected)

                if (storeEnroll.isLoadingProfileID || productListWithNoStarterKit.length === 0 || isNothing(storeEnroll.starterKitSelected)) {
                    return <></>
                } else {
                    return (
                        <div
                            className={`enrollment--product-list-item ${storeBowser.isMobile() ? 'list-item-mobile' : 'list-item-desktop'}`}
                            style={{
                                gridTemplateColumns: `repeat(${totalItems.items}, calc(100%/${totalItems.itemsPerRow}))`,
                                justifyContent: totalItems.items < totalItems.itemsPerRow ? 'center' : 'flex-start'
                            }}>
                            {storeEnroll.productSource.map(item => {
                                if (inArray(item.item_code, storeEnroll.starterKitItems) && props.isStarterKit === false) {
                                    return false
                                }

                                return <ProductItemLayout item={item} key={item.item_code} isStarterKit={props.isStarterKitItems || false} setSelectedItemPHForCheck={setSelectedItemPHForCheck} initItems={initItems} />
                            })}

                            {/* <div className="clearfix"></div> */}
                        </div>
                    )
                }
            })()}
            <SelectingProductLayout ref={refSelectProduct} selectOptions={localStore.optionsItem} />
        </>
    )
})

export const StarterKitSetBox = observer(props => {
    const isProductEmpty = storeEnroll.productSource.filter(o => o.item_code !== storeEnroll.starterKitSelected).length === 0

    return (
        <div className={classnames('enrollment--starterkit-block')} style={isProductEmpty === false || isNothing(storeEnroll.starterKitSelected) ? { paddingBottom: 40 } : {}}>
            <When condition={storeElement.mobileMode === true}>
                <div className="mobile-title">
                    <div>{language.listen('enroll_title_select_starter_kit')}</div>
                </div>
            </When>
            <Switch>
                <Case condition={storeEnroll.starterKitItems.length > 1}>
                    {(() => {
                        let starterKitItems = Country.isPhilippines() ? storeEnroll.starterKitItemsPH : storeEnroll.StarterKitItems
                        if (storeEnroll.starterKitSelected !== '') {
                            let item = starterKitItems.find(e => e.item_code === storeEnroll.starterKitSelected)
                            return (
                                <div className="block-grid-shopping-cart-starter-kit block-grid-shopping-cart-1">
                                    <ProductItemLayout item={item} key={item.item_code} isStarterKit={true} />
                                </div>
                            )
                        } else {
                            return (
                                <div
                                    className={`block-grid-shopping-cart-starter-kit ${(() => {
                                        if (storeBowser.isDesktop()) {
                                            return 'block-grid-shopping-cart-4'
                                        } else if (storeBowser.isTablet()) {
                                            return 'block-grid-shopping-cart-3'
                                        } else if (storeBowser.isMobile()) {
                                            return 'block-grid-shopping-cart-1'
                                        } else {
                                            return 'block-grid-shopping-cart-3'
                                        }
                                    })()}`}>
                                    {starterKitItems.map((item, k) => {
                                        return <ProductItemLayout item={item} key={item.item_code} isStarterKit={true} />
                                    })}
                                </div>
                            )
                        }
                    })()}
                </Case>
                <Default>
                    <div
                        className={` ${
                            storeEnroll.starterKitSelected !== '' ? 'block-grid-shopping-cart-1' : storeEnroll.starterKitItems.length === 2 ? 'block-grid-shopping-cart-2' : storeEnroll.starterKitItems.length === 3 ? 'block-grid-shopping-cart-3' : ''
                        }`}>
                        {storeEnroll.StarterKitItems.map((item, k) => {
                            return <ProductItemLayout item={item} key={item.item_code} isStarterKit={true} />
                        })}
                    </div>
                </Default>
            </Switch>
        </div>
    )
})

const SelectingProductLayout = observer(
    ({ selectOptions, index }, ref) => {
        const refSelect = useRef()

        useEffect(() => {
            const disposerSelectProduct = reaction(
                () => storeEnroll.isOpenSelectProductModal,
                (open, pOpen) => {
                    if (open) {
                        storeEnroll.resetSelectProductModal()
                        refSelect.current.instance.show()
                    }
                }
            )

            return () => {
                disposerSelectProduct()
            }
        }, [])

        useImperativeHandle(ref, () => ({
            open: () => {
                refSelect.current.instance.show()
            }
        }))

        const onSelected = (value, index) => {
            let product = storeProduct.getProduct(value)
            /**** check out of stock || back order */
            const productSource = [...storeEnroll.productSource]
            let price = product.price

            if (price == 0) {
                return false
            }

            let stock = product.stock
            stock = stock === '' || stock === null || stock === undefined || isNaN(parseInt(stock)) ? 0 : parseInt(stock)

            let disableInput = false

            if (product.status == 'out_of_stock' || product.qty_sellable == 0) {
                disableInput = true
            }

            if (product) {
                product.qty = 1

                if (disableInput) {
                    product.qty = 0
                }

                if (productSource[index]) {
                    productSource[index] = product
                    productSource[index].qty = 1
                    if (disableInput) {
                        productSource[index].qty = 0
                    }
                } else {
                    productSource.push(product)
                }

                let tempSelectedItems = []
                let tempSelectedItems2 = []
                // setCartItems(productSource)

                productSource.map((v, k) => {
                    //if (v.qty > 0) {

                    tempSelectedItems.push(v.item_code)
                    // }
                })

                tempSelectedItems.map((v, k) => {
                    tempSelectedItems2.push(v)
                })

                storeEnroll.selectedItemsPH = tempSelectedItems2
                storeEnroll.selectedItems = tempSelectedItems2
                storeEnroll.productSource = productSource
                setTimeout(() => {
                    refSelect.current.instance.clear()
                }, 500)
            }
        }

        return (
            <Mobi.Select
                filter
                ref={refSelect}
                height={35}
                maxWidth={450}
                data={selectOptions}
                buttons={[]}
                onSet={(_, inst) => {
                    const selected = inst.getVal()
                    if (selected) {
                        onSelected(selected, index)
                    }
                }}
                hideSelection
                wrapperStyle={{ display: 'none' }}
                filterPlaceholderText={dictionary('type_to_search')}
            />
        )
    },
    { forwardRef: true }
)

const ProductInfo = observer(({ item, isStarterKit = false, setShowDrawer, noRemove }) => {
    let price = item.price
    let price_retail = item.retail_price
    var subTotalPrice = parseFloat(price * (item.qty === '0' ? 0 : item.qty))
    var subTotalPV = parseInt(item.pv * item.qty)
    let discount = formatNumber(parseInt(price_retail) - parseInt(price))
    let isActiveProduct = storeProduct.getProduct(item.item_code)

    const PRODUCT_TITLE = language.isNative() ? (isNilOrEmpty(item.item_name.native) ? item.item_name.english : item.item_name.native) : item.item_name.english

    let priceLvl = getPrice(item.item_code)

    let disableInput = false

    if (item.status == 'out_of_stock' || item.qty_sellable == 0) {
        disableInput = true
    }

    let showPV = item.pv === 0 ? false : true

    return (
        <div className="enrollment--product-info">
            <h4 className="product-title">
                {PRODUCT_TITLE}
                {storeBowser.isMobile() && (
                    <>
                        <br />
                        <small style={{ marginLeft: 0 }}>{language.listen('shop_product_id').replace('{{id}}', item.item_code)}</small>
                    </>
                )}
            </h4>

            <div className="block-price-pv">
                {storeBowser.isMobile() && (
                    <div className="product-price" style={isStarterKit && Country.isPhilippines() ? { fontSize: 14 } : {}}>
                        {showPV && (
                            <>
                                <span className="number">{item.pv}</span>&nbsp;
                                <span className="text-mute currency" style={{ paddingRight: '5px' }}>
                                    {dictionary('pv')}
                                </span>
                                <span className="divider">|</span>
                            </>
                        )}
                        <span className="number">{formatNumber(price)}</span> <span className="text-mute currency">{store.currency}</span>
                    </div>
                )}
                <div className="product-price mb-3">
                    {!storeBowser.isMobile() && (
                        <>
                            <br />
                            <MoreInfo item={item} />
                            <br />
                            <small style={{ marginLeft: 0 }}>{language.listen('shop_product_id').replace('{{id}}', item.item_code)}</small>
                            <div>
                                {showPV && (
                                    <>
                                        <span className="number">{item.pv}</span>&nbsp;
                                        <span className="text-mute currency" style={{ paddingRight: '5px' }}>
                                            {dictionary('pv')}
                                        </span>
                                        <span className="divider">|</span>
                                    </>
                                )}
                                <span className="number">{formatNumber(price)}</span> <span className="text-mute currency">{store.currency}</span>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {storeBowser.isMobile() && (
                <div className="text-middle">
                    <MoreInfo item={item} />
                    <div className="text-middle shopping-cart-tag">{generateTags(item)}</div>
                </div>
            )}
            {!isStarterKit && <ProductActions item={item} setShowDrawer={setShowDrawer} noRemove={noRemove} />}
        </div>
    )
})

const ProductImage = observer(({ item }) => {
    return (
        <div className="enrollment--product-image">
            <img src={item.image_url || require('../../assets/unicitylogo.jpg').default} alt={item.item_name.native} />
        </div>
    )
})

const ProductInfoWrapper = observer(props => {
    return <div className="enrollment--product-info-wrapper">{props.children}</div>
})

export const ProductItemLayout = observer(({ item, isStarterKit = false, setSelectedItemPHForCheck = () => {}, initItems = () => {} }) => {
    const [showDrawer, setShowDrawer] = useState(false)
    const [noRemove, setNoRemove] = useState(true)

    const onCheckTC = e => {
        if (storeEnroll.starterKitItems.length > 1) {
            if (storeEnroll.starterKitSelected != e.target.id) {
                storeEnroll.starterKitSelected = e.target.id
                window.scrollTo(0, 0)
            } else {
                storeEnroll.starterKitSelected = ''
            }
        }

        storeEnroll.errorMessage = null
        storeEnroll.orderError = false
    }

    const RemoveItem = () => {
        let selectedItems = Country.isPhilippines() ? storeEnroll.selectedItemsPH : storeEnroll.selectedItems
        const tempProductSource = []
        storeEnroll.productSource.map((v, k) => {
            if (v.id !== item.id) {
                tempProductSource.push(v)
            }
        })

        const index = selectedItems.indexOf(item.item_code)
        if (index > -1) {
            selectedItems.splice(index, 1)
        }

        storeEnroll.productSource = tempProductSource
        storeEnroll.checkoutCart = storeEnroll.productSource

        if (selectedItems.length === 1) {
            window.scrollTo(0, 0)
        }
        if (Country.isPhilippines()) {
            setSelectedItemPHForCheck()
        } else {
            initItems()
        }
    }

    return (
        <>
            <div className="enrollment--item-wrapper" style={!storeBowser.isMobile() ? { width: '100%', padding: storeEnroll.starterKitItems.length > 1 ? 10 : '' } : { paddingBottom: storeEnroll.starterKitItems.length > 1 ? 20 : '' }}>
                <Card
                    className={classnames('', {
                        'drawer-delete': showDrawer
                    })}
                    style={storeEnroll.starterKitItems.length > 1 ? { height: '100%' } : {}}>
                    <When condition={isStarterKit === true}>
                        <StyledCheckbox onChange={onCheckTC} id={item.item_code} checked={storeEnroll.starterKitSelected === item.item_code ? true : false}></StyledCheckbox>
                    </When>
                    <div>
                        <ProductInfoWrapper>
                            {!storeBowser.isMobile() && <div className="text-middle shopping-cart-tag">{generateTags(item)}</div>}
                            <ProductImage item={item} />
                            <ProductInfo item={item} isStarterKit={isStarterKit} setShowDrawer={setShowDrawer} noRemove={noRemove} />
                        </ProductInfoWrapper>
                    </div>
                    {showDrawer && (
                        <div className="drawer-actions">
                            {dictionary('u_all_cart_modal_remove_description')}
                            <div className="buttons">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setShowDrawer(false)
                                        RemoveItem()
                                    }}>
                                    {dictionary('yes')}
                                </Button>
                                <Button
                                    type="ghost"
                                    onClick={() => {
                                        setShowDrawer(false)
                                        setNoRemove(true)
                                    }}>
                                    {dictionary('no')}
                                </Button>
                            </div>
                        </div>
                    )}
                </Card>
            </div>
        </>
    )
})

const ProductActions = observer(({ item, isStarterKit, setShowDrawer, noRemove }) => {
    let isActiveProduct = storeProduct.getProduct(item.item_code)
    let disableInput = false
    if (item.status == 'out_of_stock' || item.qty_sellable == 0) {
        disableInput = true
    }

    const [qtyCurrent, setQtyCurrent] = useState(0)

    const plusMinusCart = (type, qty, event, id, code) => {
        if (type === 'input') {
            qty = parseInt(qty)

            if (isNaN(qty)) {
                qty = 0
            }
            if (qty < 1) {
                qty = 0
                if (id == '895' && isCountryPhilippines()) {
                    qty = 1
                }
            } else if (qty >= 999) {
                qty = 999
            }

            let a = []

            if (qty === 0) {
                if (!inArray(code, storeEnroll.starterKitItems)) {
                    setQtyCurrent(qty)
                    setShowDrawer(true)
                } else {
                    qty = 1
                    if (storeEnroll.starterKitItems.length > 1) {
                        storeEnroll.starterKitSelected = ''
                    }
                }
            }

            const item = storeEnroll.productSource.find(el => el.id === id)
            if (item) {
                item.qty = qty
                item.proQTY = qty
            }

            // storeEnroll.productSource = storeEnroll.productSource.map(el => (el.id === id ? { ...el, proQTY: qty, qty: qty } : el))
            storeEnroll.checkoutCart = storeEnroll.productSource
        } else {
            qty = parseInt(qty)
            if (type === 'minus') {
                if (qty < 1) {
                    qty = 0
                    if (id == '895' && isCountryPhilippines()) {
                        qty = 1
                    }
                } else {
                    qty = parseInt(qty) - parseInt(1)

                    if (qty === 0 && id == '895' && isCountryPhilippines()) {
                        qty = 1
                    }
                }
            } else {
                if (qty > 998) {
                    qty = 999
                } else {
                    qty = parseInt(qty) + parseInt(1)
                }
            }

            let confirmRemove = false
            if (qty === 0) {
                if (!inArray(code, storeEnroll.starterKitItems)) {
                    confirmRemove = true
                    setShowDrawer(true)
                } else {
                    qty = 1
                    if (storeEnroll.starterKitItems.length > 1) {
                        storeEnroll.starterKitSelected = ''
                    }
                }
            }

            if (confirmRemove === false) {
                const item = storeEnroll.productSource.find(el => el.id === id)
                if (item) {
                    item.qty = qty
                    item.proQTY = qty
                }
                // storeEnroll.productSource = storeEnroll.productSource.map(el => (el.id === id ? { ...el, proQTY: qty, qty: qty } : el))
                storeEnroll.checkoutCart = storeEnroll.productSource
            }
        }
    }

    return (
        <div className="product-actions">
            <div className="block-qty">
                <div className={isStarterKit || item.qty === '0' || !isActiveProduct || disableInput ? 'qtyInput mb-1 qtyInputDisable' : 'qtyInput mb-1'}>
                    <span>
                        <button className={isStarterKit ? 'hide' : 'qtyplusminusbtn borderRight'} onClick={event => plusMinusCart('minus', item.qty, event, item.id, item.item_code)}>
                            <IconMinus />
                        </button>
                    </span>
                    <input
                        type="text"
                        min={item.qty === '0' ? '0' : '1'}
                        max="99"
                        className="form-control-number-new"
                        value={item.qty === '0' ? '0' : item.qty}
                        style={{ maxWidth: '60px', textAlign: 'center' }}
                        onFocus={e => {
                            e.target.select()
                        }}
                        onChange={event => plusMinusCart('input', event.target.value, event, item.id, item.item_code)}
                        disabled={isStarterKit || item.qty === '0' || !isActiveProduct || disableInput}
                    />
                    <span>
                        <button
                            className={isStarterKit ? 'hide' : 'qtyplusminusbtn borderLeft'}
                            onClick={event => plusMinusCart('plus', item.qty, event, item.id, item.item_code)}
                            disabled={isStarterKit || item.qty === '0' || !isActiveProduct || disableInput}>
                            <IconPlus />
                        </button>
                    </span>
                </div>
            </div>
        </div>
    )
})

const MoreInfo = ({ item }) => {
    const [toggle, setToggle] = useState(false)
    const [enabled, setEnabled] = useState(false)
    const [images, setImages] = useState([])
    const [photoIndex, setPhotoIndex] = useState(0)

    useEffect(() => {
        let info_list = 'english'

        if (language.footer == 'native') {
            info_list = 'native'
        }
        const rawInfoList = toJS(item.item_info_list[info_list])

        if (rawInfoList) {
            if (rawInfoList.length > 0) {
                setImages(rawInfoList)
                setEnabled(true)
            } else {
                setImages([])
                setEnabled(false)
            }
        } else {
            setImages([])
            setEnabled(false)
        }
    }, [])

    return (
        <>
            {enabled ? (
                <>
                    <span>
                        <a title={dictionary('more_info')} className="more-info text-primary" onClick={() => setToggle(true)}>
                            {dictionary('more_info')}
                        </a>
                    </span>

                    {toggle && images.length > 0 && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images.length > 1 ? images[(photoIndex + 1) % images.length] : null}
                            prevSrc={images.length > 1 ? images[(photoIndex + images.length - 1) % images.length] : null}
                            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                            onCloseRequest={() => setToggle(false)}
                        />
                    )}
                </>
            ) : (
                <span></span>
            )}
        </>
    )
}

const generateTags = item => {
    const tagBody = feature => {
        let color, value, key

        if (isString(feature)) {
            key = feature
        }

        const c = CountryConfigs.CountryCode(true)
        const globalPrefix = `u_all_tag_`
        const nativePrefix = `u_${c}_tag_`

        const keyTemplate = {
            outOfStock: {
                color: {
                    native: `${nativePrefix}color_out_of_stock`,
                    global: `${globalPrefix}color_out_of_stock`
                },
                value: {
                    native: `${nativePrefix}value_out_of_stock`,
                    global: `${globalPrefix}value_out_of_stock`
                }
            },
            backOrder: {
                color: {
                    native: `${nativePrefix}color_back_order`,
                    global: `${globalPrefix}color_back_order`
                },
                value: {
                    native: `${nativePrefix}value_back_order`,
                    global: `${globalPrefix}value_back_order`
                }
            }
        }

        const keyOutOfStockColor = plainDictionary(keyTemplate.outOfStock.color.native) ? keyTemplate.outOfStock.color.native : keyTemplate.outOfStock.color.global
        const keyOutOfStockValue = plainDictionary(keyTemplate.outOfStock.value.native) ? keyTemplate.outOfStock.value.native : keyTemplate.outOfStock.value.global
        const keyBackOrderColor = plainDictionary(keyTemplate.backOrder.color.native) ? keyTemplate.backOrder.color.native : keyTemplate.backOrder.color.global
        const keyBackOrderValue = plainDictionary(keyTemplate.backOrder.value.native) ? keyTemplate.backOrder.value.native : keyTemplate.backOrder.value.global

        switch (key) {
            case 'outofstock':
                color = dictionary(keyOutOfStockColor)
                value = dictionary(keyOutOfStockValue)
                break
            case 'backorder':
                color = dictionary(keyBackOrderColor)
                value = dictionary(keyBackOrderValue)
                break
            default:
                const keyt = {
                    native: feature.native,
                    global: feature.english
                }

                color = feature.color

                if (isNothing(color)) {
                    color = '#B4B4B4'
                }

                value = language.isEnglish() ? feature.english : feature.native ? feature.native : feature.english

                break
        }

        return (
            <Tag color={color} style={{ marginBottom: '5px' }} key={key}>
                {value}
            </Tag>
        )
    }

    const tags = []

    item.system_tags.map((v, k) => {
        if (v) {
            const featureEnglish = v.text.english
            const featureNative = v.text.native
            if (featureEnglish || featureNative) {
                tags.push(tagBody({ english: featureEnglish, native: featureNative, color: v.style.background_color }))
            }
        }
    })

    item.tags.map((v, k) => {
        if (v) {
            const featureEnglish = v.text.english
            const featureNative = v.text.native
            if (featureEnglish || featureNative) {
                tags.push(tagBody({ english: featureEnglish, native: featureNative, color: v.style.background_color }))
            }
        }
    })

    return tags
}

const StyledCheckbox = styled(Checkbox)`
    display: inline;
    margin-left: 15px;
    white-space: pre;
    ​ > span {
        display: inline-table;
        margin-top: 5px;
    }
    ​ .ant-checkbox {
        height: min-content;
    }

    .ant-checkbox + span {
        padding-right: 0;
    }
`

const IconMinus = () => {
    return (
        <svg width="20" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5714 0.857142C12.5714 0.383928 12.1875 0 11.7143 0H0.857143C0.383929 0 0 0.383928 0 0.857142V2.57143C0 3.04464 0.383929 3.42857 0.857143 3.42857H11.7143C12.1875 3.42857 12.5714 3.04464 12.5714 2.57143V0.857142Z"
                fill="black"
            />
        </svg>
    )
}

const IconPlus = () => {
    return (
        <svg width="14" height="24" viewBox="0 0 15 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5714 5.85704C12.5714 5.38382 12.1875 4.9999 11.7143 4.9999H8V1.28561C8 0.812395 7.61607 0.428467 7.14286 0.428467H5.42857C4.95536 0.428467 4.57143 0.812395 4.57143 1.28561V4.9999H0.857143C0.383929 4.9999 0 5.38382 0 5.85704V7.57132C0 8.04454 0.383929 8.42847 0.857143 8.42847H4.57143V12.1428C4.57143 12.616 4.95536 12.9999 5.42857 12.9999H7.14286C7.61607 12.9999 8 12.616 8 12.1428V8.42847H11.7143C12.1875 8.42847 12.5714 8.04454 12.5714 7.57132V5.85704Z"
                fill="black"
            />
        </svg>
    )
}

export default Products
