import React, { useState, useEffect, useMemo, useImperativeHandle, forwardRef } from 'react';
import { useObserver, useLocalStore } from 'mobx-react-lite';
import { CountryConfigs } from '../../../configs/Configs';
import { FormUsage, AddressArrangement } from '../../../configs/Address';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import * as K from '../../../configs/Keywords';

/** The automatic forms generator for **OrderDetails**. 
 * @param {object} props
 * @param {'delivery'|'pickUp'|'aramex'|'motorDelivery'} props.type
 * @param {object} props.formItems
*/
const FormItemGenerator = forwardRef((props, ref) => {
    FormItemGenerator.displayName = 'FormItemGenerator'
    FormItemGenerator.propTypes = {
        type: PropTypes.string.isRequired,
        formItems: PropTypes.object.isRequired
    }

    const country = CountryConfigs.Country()

    const mState = useLocalStore(() => ({ 
        count: 0,
        increaseCount() { 
            console.log('increaseCount', this.count)
            this.count += 1 
        }
    }))

    // * Get forms usage config
    // ? NOTED: if you want to change forms setting, please use `configs/Address`.
    const [usage] = useState(() => {
        switch (props.type) {
            case K.Delivery: return FormUsage.Delivery
            case K.PickUp: return FormUsage.PickUp
            case K.Aramex: return FormUsage.Aramex
            case K.MotorDelivery: return FormUsage.MotorDelivery
            default: return FormUsage.Delivery
        }
    })

    useImperativeHandle(ref, () => ({
        forcedUpdate: () => {
            mState.increaseCount()
        }
    }))

    // ? NOTED: if you want to change forms setting, please use `configs/Address`.
    const draw = () => {
        return (
            AddressArrangement(CountryConfigs.Country(), props.type).map((item, index) => {
                const input = (item.split('_')[0]).split('#')[0]
                if (usage(input, country)) {
                    return (
                        <Row key={`row-${index + 1}`}>
                            <Col span={24}>
                                {props.formItems[item]}
                            </Col>
                        </Row>
                    )
                }
            })
        )
    }

    const generate = useMemo(() => draw(), [props.formItems, mState.count])

    return useObserver(() => generate)
})

export default FormItemGenerator