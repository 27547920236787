import React from 'react';
import PropTypes from 'prop-types';
// Mobx
import { useObserver } from 'mobx-react';
import { checkOutStore,store } from '../../../../stores/MainStore';
// Library
import get from 'lodash/get';
import first from 'lodash/first';
import isArrayLikeObject from 'lodash/isArrayLikeObject';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Typography from 'antd/es/typography';
// Helpers
import { SectionsDeclaration } from '../../../configs/Sections';
import { ShippingMethods } from '../../../configs/ShippingMethods';
import { dictionary, isCountryPhilippines , isCountrySingapore,getCountryCode } from '@GlobalHelpers';
import { throatBoolean } from '../../../configs/ConfigsHeader';
import { isNothing } from '../../../utils/Utils';
import { When } from 'react-if';
import SuperPills from '../../../fragment/dropdown/SuperPills';
import { storeOrderDetails } from '../Store/StoreOrderDetails';
import { shopprofileStatusConfig,inArray } from "../../../config";

const { Title } = Typography

const colResponsiveConfig = {
    xs: 24,
    sm: 24,
    md: 20,
    lg: 12,
    xl: 12,
    xxl: 12
}

/**
 * @param {object} props
 */
const SectionShipping = props => {

    let spConfig = shopprofileStatusConfig(store.shareCart.sharedStatus)
    let shippingMethodConfig = spConfig.shippingMethod;

    const optionsFactory = () => {
        const result = []

        let list = ShippingMethods.getShippingAcceptance()

        // if no array like = it is warehouse
        if (isArrayLikeObject(list) === false) {
            if (isNothing(checkOutStore.warehouse)) {
                // handle empty value on warehouse
                checkOutStore.warehouse = first(ShippingMethods.getWarehouseAcceptance())
            }
            list = list[checkOutStore.warehouse]
        }

       
        list.map((method, index) => {
            let key = '', value = ''

            

            if (method.indexOf('.') === -1) {
                key = get(ShippingMethods.ShippingDeclaration, `${method}.default.dictionary`)
                value = get(ShippingMethods.ShippingDeclaration, `${method}.default.value`)

                
                if (isCountryPhilippines() && method === 'pickUp') {
                    key = 'pickuptaguig';
                }
            } else {
                key = get(ShippingMethods.ShippingDeclaration, `${method}.dictionary`)
                value = get(ShippingMethods.ShippingDeclaration, `${method}.value`)
                if (isCountryPhilippines() && method === 'pickUp') {
                    key = 'pickuptaguig';
                }
            }

            // if(!inArray(key, shippingMethodConfig)){
            //     return false;
            // }


            const overridedShippingID = get(
                ShippingMethods.WarehouseDeclaration, 
                `${checkOutStore.warehouse}.shippingValue.${ShippingMethods.getShippingName(value)}`, value
            )
            // console.log('option >', method, '| k:', key, '- v:', value, '- override:', overridedShippingID)

            if(key !== 'aramex'){
                result.push({ value: overridedShippingID, text: dictionary(key) })
            }

            /* result.push((
                <Option value={overridedShippingID} key={`${index}`}>
                    {dictionary(key)}
                </Option>
            )) */
        })

        return result
    }

    return useObserver(() => 
        <When condition={throatBoolean(SectionsDeclaration.OrderDetails().shippingMethods)}>
            <Row type="flex" justify="center" className="text-center shipping_method">
                <Col span={24}>
                    <Title level={4}>{dictionary('shipping_method')}</Title>
                    <Row id="delivery" type="flex" justify="center">
                        <Col {...colResponsiveConfig}>
                            <SuperPills 
                                defaultValue={checkOutStore.shipping}
                                value={checkOutStore.shipping}
                                onSelected={
                                    (value) => {
                                        checkOutStore.shipping = value 
                                        props.store.shareCart.shipping = value
                                    }
                                    
                                }
                                disabled={storeOrderDetails.isLoadingAPI}
                                options={optionsFactory()}
                                placeholder={`${dictionary('please_select')}`}
                                notFoundContent={<div className="d-block text-center py-5">{dictionary('not_found_content')}</div>}
                            />
                        </Col>
                    </Row>
                    <When condition={(checkOutStore.shipping === '3' || checkOutStore.shipping === '32')}>
                        <Row id="" style={{marginTop:'25px',justifyContent:'center',padding: '0 25px'}}>
                            {dictionary(`u_${getCountryCode().toLowerCase()}_od_pickup_address`)}
                          
                        </Row>
                    </When>
                    
                </Col>
            </Row>
        </When>
    )
}

SectionShipping.displayName = 'SectionShipping'
export default SectionShipping