import React, { useState, useEffect } from 'react'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Skeleton from 'antd/es/skeleton'
import styled from 'styled-components'
import { useResponsive } from 'ahooks'
import { getBreakpoint, someOf } from '@Utils/Utils'

const productProps = {
    avatar: {
        shape: 'square',
        size: 320
    },
    paragraph: {
        rows: 0
    },
    title: false,
    active: true,
    round: true
}

const colResponsive = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 8,
    xl: 8,
    xxl: 8
}

const SkOH = styled.div`
    .ant-skeleton-header {
        width: 100% !important;
    }
    
    .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
        width: 100% !important;
        height: 200px !important;
        border-radius: 7px;
    }
`

const SkOHD = styled.div`

    .one-col .ant-skeleton-header {
        padding: 0;
    }

    .ant-row .ant-col:first-child .two-col .ant-skeleton-header {
        padding-right: 10px;
    }

    .ant-row .ant-col:last-child .ant-skeleton-header {
        padding: 0 !important;
    }

    .ant-skeleton-header {
        width: 100% !important;
    }

    .ant-skeleton-title {
        border-radius: 7px;
    }
    
    .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
        width: 100% !important;
        border-radius: 7px;
    }

    .ant-skeleton-paragraph li {
        border-radius: 7px;
    }
`

export const SectionSkeletonRegister = () => {

    const screens = useResponsive()
    const [breakpoint, setBreakpoint] = useState()
    const [dynamicCols, setDynamicCols] = useState('')

    useEffect(() => {
        const newbp = getBreakpoint(screens)
        setBreakpoint(newbp)
        // if (someOf(newbp, ['xs', 'sm'])) {
        //     setDynamicCols('one-col')
        // } else if (someOf(newbp, ['md', 'lg'])) {
        //     setDynamicCols('two-col')
        // } else {
        //     setDynamicCols('')
        // }
        setDynamicCols('one-col')
    }, [screens])

    return (
        <SkOH className="mb-5">
            {/* <Row justify="space-between">
                <Col {...colResponsive}>
                    <Skeleton {...productProps} className={dynamicCols} />
                </Col>
                {someOf(breakpoint, ['md', 'lg', 'xl', 'xxl']) &&
                    <Col {...colResponsive}>
                        <Skeleton {...productProps} className={dynamicCols} />
                    </Col>
                }
                {someOf(breakpoint, ['lg', 'xl', 'xxl']) &&
                    <Col {...colResponsive}>
                        <Skeleton {...productProps} />
                    </Col>
                }
            </Row> */}
            <Row justify="center">
                <Col span={24}>
                    <Skeleton loading active paragraph={{ rows: 5 }} />
                </Col>
            </Row>
            <Row justify="space-between">
                <Col span={24}>
                    <Skeleton {...productProps} className={dynamicCols} />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={24}>
                    <Skeleton loading active paragraph={{ rows: 5 }} />
                </Col>
            </Row>
            <Row justify="space-between">
                <Col span={24}>
                    <Skeleton {...productProps} className={dynamicCols} />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={24}>
                    <Skeleton loading active paragraph={{ rows: 5 }} />
                </Col>
            </Row>
        </SkOH>
    )
}