import * as K from './Keywords'

/** Country code for get products API. */
export const Products = {
    // others always return country code
}

/** Login portal configs. */
export const Login = {
    [K.All]: true,
    [K.Thailand]: false,
    [K.Singapore]: false,
    [K.Australia]: false
}

/** Easy shop configs. */
export const EasyShop = {
    [K.All]: false,
    [K.Thailand]: true,
    [K.Singapore]: true,
    [K.Australia]: true
}

/** Country code for static footer. */
export const FooterStatic = {
    // others always return country code
}

/** Country code for special footer. */
export const FooterSpecial = {
    // others always return country code
}

export const FacebookMessenger = {
    [K.Thailand]: 'https://www.messenger.com/t/UnicityThailand',
    [K.Singapore]: 'https://www.messenger.com/t/UnicitySIN',
}

export const NewPassword = {
    [K.All]: 'https://office.unicity.com/pwupdate'
}