import { language } from '@Language'
import { checkOutStore } from '@Stores/MainStore'
import { isArray, isNothing, someOf } from '@Utils/Utils'
import { get, has, keys, first, omit, isArrayLikeObject, startsWith } from 'lodash'
import { language as l } from '@Language'
import { isObservableArray, makeAutoObservable } from 'mobx'
import { throatArray, throatBoolean, throatObject } from './ConfigsHeader'
import * as K from './Keywords'

class classShippingMethods {

    WarehouseEnabled = {
        [K.Indonesia]: true
    }

    WarehouseAcceptance = {
        [K.Indonesia]: ['jakarta', 'surabaya']
    }

    ShippingAcceptance = {
        [K.Thailand]: ['delivery', 'pickUp'],
        [K.Singapore]: ['delivery', 'pickUp'],
        [K.Philippines]: ['delivery', 'pickUp.ph_taguig'],
        [K.Indonesia]: {
            jakarta: ['delivery', 'pickUp', 'motorDelivery'],
            surabaya: ['delivery', 'pickUp', 'motorDelivery']
        },
        [K.Australia]: ['delivery'],
        [K.Newzealand]: ['delivery'],
        [K.Japan]: ['delivery', 'pickUp'], // ???
        [K.Vietnam]: ['delivery', 'pickUp.vn_hanoi', 'pickUp.vn_hochimin'],
        [K.Hongkong]: ['delivery', 'pickUp'],
        [K.Myanmar]: ['delivery'],
        [K.Cambodia]: ['delivery'],
        [K.Laos]: ['delivery'],
        [K.Taiwan]: ['delivery', 'pickUp'],
        [K.Malaysia]: ['delivery', 'pickUp']
    }

    ShippingDeclaration = {
        [K.Delivery]: {
            [K.Default]: {
                value: '2',
                dictionary: 'od_shipping_delivery'
            }
        },
        [K.PickUp]: {
            [K.Default]: {
                value: '3',
                dictionary: 'od_shipping_pickup'
            },
            // * VN - Pick Up
            vn_hanoi: {
                value: '3',
                dictionary: 'pickup_hanoi'
            },
            vn_hochimin: {
                value: '5',
                dictionary: 'pickup_hochiminh'
            },
            ph_taguig: {
                value: '3',
                dictionary: 'od_shipping_pickup_taguig'
            }
        },
        [K.Aramex]: {
            [K.Default]: {
                value: '4',
                dictionary: 'aramex'
            }
        },
        [K.MotorDelivery]: {
            [K.Default]: {
                value: '6',
                dictionary: 'motordelivery'
            }
        },
    }

    WarehouseDeclaration = {
        jakarta: {
            shippingValue: {
                [K.Delivery]: '2',
                [K.PickUp]: '3',
                [K.MotorDelivery]: '6'
            },
            value: 'jakarta',
            dictionary: 'warehouse_jakarta',
        },
        surabaya: {
            shippingValue: {
                [K.Delivery]: '7',
                [K.PickUp]: '8',
                [K.MotorDelivery]: '9'
            },
            value: 'surabaya',
            dictionary: 'warehouse_surabaya'
        }
    }

    ID = {
        /** ID = 2 */
        delivery: '2',
        /** ID = 3 */
        pickUp: '3',
        /** ID = 4 */
        aramex: '4',
        /** ID = 5 */
        pickUpHochiminh: '5',
        /** ID = 6 */
        motorDelivery: '6',
        /** ID = 7 */
        deliverySBY: '7',
        /** ID = 8 */
        pickUpSBY: '8',
        /** ID = 9 */
        motorDeliverySBY: '9',
        /** ID = 10 */
        pickUpTaguig: '32',
        /** ID = 501 */
        samedayDelivery: '501'
    }

    name = {
        delivery: 'delivery',
        pickUp: 'pickUp',
        aramex: 'aramex',
        pickUpHochiminh: 'pickUpHochiminh',
        motorDelivery: 'motorDelivery',
        deliverySBY: 'deliverySBY',
        pickUpSBY: 'pickUpSBY',
        motorDeliverySBY: 'motorDeliverySBY',
        pickUpTaguig: 'pickUpTaguig',
        samedayDelivery: 'samedayDelivery'
    }

    type = {
        delivery: 'delivery',
        pickUp: 'pickUp',
        aramex: 'aramex',
        motorDelivery: 'motorDelivery',
        samedayDelivery: 'samedayDelivery'
    }

    dictionary = {
        delivery: 'delivery',
        pickUp: 'pickup',
        aramex: 'aramex',
        pickUpHochiminh: 'pickuphochiminh',
        motorDelivery: 'motordelivery',
        deliverySBY: 'delivery',
        pickUpSBY: 'pickup',
        motorDeliverySBY: 'motordelivery',
        pickUpTaguig: 'pickup-taguig',
        samedayDelivery: 'samedayDelivery'
    }

    IDToName = {
        '2': 'delivery',
        '3': 'pickUp',
        '4': 'aramex',
        '5': 'pickUp', // Pick Up Hochiminh
        '6': 'motorDelivery',
        '7': 'deliverySBY', // Delivery for IDN - Surabaya Warehouse
        '8': 'pickUpSBY', // PickUp for IDN - Surabaya Warehouse
        '9': 'motorDeliverySBY', // Motor Delivery for IDN - Surabaya Warehouse
        '32': 'pickUpTaguig', // PickUp for PHL - Taguig
        '501': 'samedayDelivery' // sameday delivery
    }

    IDToType = {
        '2': 'delivery',
        '3': 'pickUp',
        '4': 'aramex',
        '5': 'pickUp', // Pick Up Hochiminh
        '6': 'motorDelivery',
        '7': 'delivery', // Delivery for IDN - Surabaya Warehouse
        '8': 'pickUp', // PickUp for IDN - Surabaya Warehouse
        '9': 'motorDelivery', // Motor Delivery for IDN - Surabaya Warehouse
        '32': 'pickUp', // PickUp for PHL - Taguig
        '501': 'samedayDelivery' // sameday delivery
    }

    constructor() {
        makeAutoObservable(this)
    }

    getShippingName(shippingID = '') {
        if (isNothing(shippingID)) shippingID = checkOutStore.shipping
        return this.IDToName[shippingID]
    }

    getShippingType(shippingID = '') {
        if (isNothing(shippingID)) shippingID = checkOutStore.shipping
        return this.IDToType[shippingID]
    }

    isAramex(shippingID = '') {
        return this.getShippingType(shippingID) === this.type.aramex
    }

    isDelivery(shippingID = '') {
        return this.getShippingType(shippingID) === this.type.delivery
    }

    isPickup(shippingID = '') {
        return this.getShippingType(shippingID) === this.type.pickUp
    }

    isMotorDelivery(shippingID = '') {
        return this.getShippingType(shippingID) === this.type.motorDelivery
    }

    isSamedayDelivery(shippingID = '') {
        return this.getShippingType(shippingID) === this.type.samedayDelivery
    }

    getWarehouseEnabled() {
        return throatBoolean(this.WarehouseEnabled)
    }

    getWarehouseAcceptance() { 
        return throatArray(this.WarehouseAcceptance) 
    }

    getShippingAcceptance() { 
        return throatArray(this.ShippingAcceptance) 
    }

    isAcceptedMethod(shippingID = '') {
        if (isNothing(shippingID)) shippingID = checkOutStore.shipping
        let acceptance = this.getShippingAcceptance()
        if (isArray(acceptance) || isObservableArray(acceptance)) {
            acceptance = acceptance.map((item) => first(item.split('.')))
        } else {
            acceptance = get(acceptance, `[${checkOutStore.warehouse}]`, []).map((item) => first(item.split('.')))
        }
        return someOf(this.getShippingType(shippingID), acceptance)
    }

    getPickupAddress() {
        const addressData = throatObject(this.PickupDeclaration.address)
        const omitedData = omit(addressData, ['dictionary', 'value'])
        let dictionary, value
        if (keys(omitedData).length > 0) {
            dictionary = get(omitedData, `${checkOutStore.warehouse}.dictionary`)
            value = get(omitedData, `${checkOutStore.warehouse}.value`)
        } else {
            dictionary = get(addressData, 'dictionary')
            value = get(addressData, 'value')
        }
        if (has(value, 'EN') || has(value, 'en')) {
            value = language.listen(value)
        }

        //console.log(checkOutStore.warehouse,"MMM")
        return {
            dictionary,
            value
        }
    }

    getShippingDeclarationDictionary() {
        // get shipping methods list of current country
        let list = this.getShippingAcceptance()
        // if it not array like, assume as warehouse support type
        if (isArrayLikeObject(list) === false) {
            let legitWarehouse = checkOutStore.warehouse
            if (isNothing(legitWarehouse)) {
                // handle empty value on warehouse by set default to first one
                legitWarehouse = first(this.getWarehouseAcceptance())
                checkOutStore.warehouse = legitWarehouse
            }
            // convert list to legit warehouse data
            list = list[legitWarehouse]
        }

        const method = list.find(item => item.split('.')[0] === this.getShippingType())
        if (method) {
            let key = ''
            if (method.indexOf('.') === -1) {
                key = get(this.ShippingDeclaration, `${method}.default.dictionary`)
            } else {
                key = get(this.ShippingDeclaration, `${method}.dictionary`)
            }
            return key
        } else {
            return ''
        }
    }
}

const core = new classShippingMethods()

export { core as default }
export { core as ShippingMethods }