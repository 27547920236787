import React, { useEffect, useState } from 'react'
import ShoppingClass from '../shopping/ShoppingClass'
import { shopStore, enrollStore, checkOutStore, defaultShopStore, store, storeManager, devTools } from '../../stores/MainStore'
import { getWarehouse, isCountrySingapore } from '@GlobalHelpers'
import { logn, loge, logsys } from '../utils/PikaLog'
import { isEqualText, isSomething } from '../utils/Utils'
import { appConfig } from '../config'
import { isCustomerValid, getCustomerObject, getCartStorage, isCustomerLogin, getCustomerID, clearCartStorage } from '../utils/Customer'
import get from 'lodash/get'
import { reaction } from 'mobx'
import { storeProduct } from '../../stores/StoreProduct'
import Raven from '../../services/Raven'
import { observer } from 'mobx-react-lite'
import { Country } from '@Stores/StoreConfigCountry'

const ProductSourceEnroll = observer(props => {
    const warehouse = (!enrollStore.isMenaCountry) ? getWarehouse() : ''
    const [userPriceLevel, setUserPriceLevel] = useState('retail_price')

    useEffect(() => {
        initUserPrice()
        
        if (!enrollStore.isMenaCountry) {
            startProductAPI() // get products
            //pingHydraAPI() // ping hydra
        }

        reaction(
            () => storeProduct.isInitialized,
            (init, prev, reaction) => {
                if (init) {
                    if (isCustomerLogin()) {
                        loadCartFromServer()
                    } else {
                        loadCartFromLocal()
                    }
                    reaction.dispose()
                }
            }
        )
    }, [])

    const initUserPrice = () => {
        let user = localStorage.getItem('customerData')
        let price_level = userPriceLevel
        if (user !== null) {
            user = JSON.parse(user)
            if (user.acl !== undefined) {
                price_level = user.acl.api.priceLevel
            }
        }

        const shoppClass = new ShoppingClass()
        if (shoppClass.isPreferredCustomer()) {
            price_level = 'preferred_price'
        }

        if (shoppClass.isFamilyCustomer()) {
            price_level = 'employee_price'
        }

        setUserPriceLevel(price_level)
    }

    const pingHydraAPI = () => {
        Raven.getProductSource(true, userPriceLevel, warehouse)
            .then(response => logn('✅ 🐙 Successfully ping Hydra getproducts API.'))
            .catch(response => loge('❌ 🐙 Failed to ping Hydra getproducts API.'))
    }

    const startProductAPI = () => {
        Raven.getProductSource2('enroll', 'A')
            .then(response => {
                processProductSource(response)
                logn('✅ 🐉 Successfully load product source from database.')
            })
            .catch(response => {
                loge('❌ 🐉 Failed to connect our product source, please check getProducts API.')
                const emergencySource = localStorage.getItem('product-source')
                if (emergencySource) {
                    processProductSource(JSON.parse(emergencySource))
                    logn('🚨 🐉 Current product source was restored from localStorage in case of incident problem.')
                } else {
                    loge('❌ 🐉 Failed to restored product source from localStorage, our website will crash.')
                }
            })
    }

    const processProductSource = response => {
        // let cates = response.categories
        // if (response.hot !== undefined) {
        //     shopStore.hotProduct = response.hot
        // }

        const productData = response.items

        if (enrollStore !== undefined) {
            enrollStore.enrollProductSource = productData
        }

        // shopStore.top10 = response.top10

        // sessionStorage.setItem('allProducts', JSON.stringify(response.items))
        // localStorage.setItem('product-source', JSON.stringify(response))

        // start -> new store
        productData.map(item => {
            storeProduct.setProduct(item.item_code, item)
        })
        // end <- new store

        setShoppingProductSource(response.items)
        setEnrollProductSource(response.items, true)
        if (Country.isThailand()) {
            setStarterKit(response.marketingItems)
        } else {
            setStarterKit(response.starter_kit)
        }
    }
    const setStarterKit = data => {
        data.map(item => {
            storeProduct.starterKits.push(item)
        })
    }

    const setShoppingProductSource = data => {
        if (shopStore.productDataSource.length <= 0) {
            restoreShoppingProductSourceFromLocal()
        }

        let dataSource = []
        let infoProducts = {}
        let sourceData = JSON.parse(JSON.stringify(data))
        data.map((item, key) => {
            sourceData.map((v, k) => {
                if (item.item_code === v.product_id) {
                    v.stock = item.qty
                    v.allow_backorder = item.allow_backorder
                    v.pv = item.pv
                    v.status = item.status
                    v.remarks = item.remarks
                    v.image = item.image_url
                    v.sorted = item.sorted
                    v.video = item.video_url
                    v.member_price = item.retail_price
                    v.qty = 0

                    if (v.image === '') {
                        v.image = 'https://member-calls.unicity.com/api/unishop/assets/false/JPN/none.jpg'
                    }

                    // Hard code for Status L, SG
                    if (isCountrySingapore()) {
                        if (isCustomerValid()) {
                            if (isEqualText(get(getCustomerObject(), 'employmentDetails.employerName', ''), 'Performance Labs')) {
                                v.member_price = item.wholesale_price
                                return v
                            }
                        }
                    }

                    // Regular cases
                    if (appConfig.preferredPrice === true) {
                        if (checkOutStore.referralValue !== '') {
                            v.member_price = item.preferred_price // Preferred price
                        }
                    } else {
                        if (checkOutStore.referralValue !== '') {
                            v.member_price = item.retail_price // Retail price
                        }
                    }

                    if (store.authPass) {
                        v.member_price = item[userPriceLevel]
                    }

                    if (checkOutStore.dToken !== null) {
                        v.member_price = item[userPriceLevel]
                    }

                    return v
                }

                // draw products table for our testers
                if (devTools.isShowInfoLog) {
                    infoProducts[item.item_code] = {
                        item_name_1: item.item_name_1,
                        category_name_1: item.category_name_1,
                        qty: item.qty,
                        buffer_qty: item.buffer_qty,
                        status: item.status,
                        warehouse: item.warehouse,
                        allow_backorder: item.allow_backorder,
                        remark: item.remarks,
                        pv: item.pv,
                        retail_price: item.retail_price,
                        preferred_price: item.preferred_price,
                        wholesale_price: item.wholesale_price,
                        member_price: item.member_price
                    }
                    logsys('Products', infoProducts) // log new get products
                }
            })

            return item
        })

        defaultShopStore.productDataSource = JSON.parse(JSON.stringify(shopStore.productDataSource || []))
        shopStore.productDataSource = sourceData
        shopStore.productDataNew = sourceData

        // * new - mark as done
        storeProduct.isInitialized = true
    }

    const loadCartFromLocal = () => {
        const localCart = getCartStorage()
        if (localCart) {
            storeProduct.clearCart()
            Object.entries(localCart).map(([key, value]) => {
                storeProduct.assignToCart(key, value)
            })
        }
        storeManager.isCartRestoredDone = true
        logn('✅ 🛒 🗄️ Restored cart from localStorage.')
    }

    const loadCartFromServer = () => {
        Raven.getCartInfo(getCustomerID())
            .then(response => {
                if (response) {
                    storeProduct.clearCart()
                    response.map(item => {
                        storeProduct.assignToCart(item.item_code, item.quantity)
                    })
                    if (storeProduct.isCartEmpty()) {
                        loadCartFromLocal()
                    } else {
                        logn('✅ 🛒 🌎 Restored cart from server.')
                        clearCartStorage()
                        storeManager.isCartRestoredDone = true
                    }
                }
            })
            .catch(error => {
                loge('❌ 🛒 Failed to get cart from server.', error)
            })
    }

    const setEnrollProductSource = data => {
        let dataSource = []
        let sourceData = JSON.parse(JSON.stringify(enrollStore.enrollProductSource))
        data.map(item => {
            sourceData.map(dataItem => {
                if (item.item_code === dataItem.product_id) {
                    dataItem.stock = item.qty
                    dataItem.allow_backorder = item.allow_backorder
                    dataItem.pv = item.pv
                    dataItem.status = item.status
                    dataItem.remarks = item.remarks
                    dataItem.member_price = item.wholesale_price
                    dataItem.price = item.wholesale_price
                    dataItem.image = item.image_url
                    dataItem.sorted = item.sorted
                    dataItem.qty = 0
                    return dataItem
                }
            })

            return item
        })

        // ???
        dataSource = sourceData
        dataSource.map(b => {
            if (b.remarks !== '|enroll|') {
                b.qty = 0
            } else {
                if (b.post_title === 'Starter Kit') {
                    b.qty = 1
                }
            }
            return false
        })

        // ???
        enrollStore.enrollProductSource = JSON.parse(JSON.stringify(dataSource)) || []
        shopStore.gotStock = true

        // map enrollProductSource to enrollCart
        // if (isSomething(enrollStore.enrollCart)) {
        dataSource.map((v, k) => {
            enrollStore.enrollCart[k] = dataSource[k]
        })
        // }

        let items = localStorage.getItem('storageEnrollCart')
        if (items !== null) {
            items = JSON.parse(items)
            if (items.length > 0) {
                items.map((v, k) => {
                    enrollStore.enrollCart.map((b, i) => {
                        if (v.id === b.product_id) {
                            b.qty = v.qty
                            return b
                        } else {
                            // b.qty = 0
                            return b
                        }
                    })
                })
            } else {
                dataSource.map((v, k) => {
                    enrollStore.enrollCart[k] = dataSource[k]
                })
            }
        }

        swapStarterKitToFirst()

        // localStorage.setItem('hydraEnrollProductSource', JSON.stringify(enrollStore.enrollProductSource))
        // localStorage.setItem('hydraEnrollCart', JSON.stringify(enrollStore.enrollCart))

        logn('✅ Enroll product source has been initialized.')
    }

    const swapStarterKitToFirst = () => {
        let index = 0
        let items = localStorage.getItem('storageEnrollCart')
        if (items !== null) {
            items = JSON.parse(items)
            if (items.length > 0) {
                enrollStore.enrollCart.filter((v, k) => {
                    if (v.remarks === '|enroll|') {
                        index = k
                    }
                })
                arrayMove(index, 0)
            }
        }
    }

    const arrayMove = (old_index, new_index) => {
        if (new_index >= enrollStore.enrollCart.length) {
            var k = new_index - enrollStore.enrollCart.length + 1
            while (k--) {
                enrollStore.enrollCart.push(undefined)
            }
        }
        enrollStore.enrollCart.splice(new_index, 0, enrollStore.enrollCart.splice(old_index, 1)[0])
    }

    const restoreEnrollProductSourceFromLocal = () => {
        // after leave enroll, .enrollStore will be clear, when come back to enroll have to load .enrollProductSource and .enrollCart again
        // below is use for restore .enrollProductSource and .enrollCart from localStorage instead of calling hydra
        let hydraEnrollProductSource = localStorage.getItem('hydraEnrollProductSource')
        hydraEnrollProductSource = JSON.parse(hydraEnrollProductSource)
        enrollStore.enrollProductSource = hydraEnrollProductSource

        let hydraEnrollCart = localStorage.getItem('hydraEnrollCart')
        hydraEnrollCart = JSON.parse(hydraEnrollCart)
        enrollStore.enrollCart = hydraEnrollCart || []

        // after waiting for hydra response, .enrollCart will be set value from localStorage.storageEnrollCart
        // and after restore .enrollCart from localStorage.hydraEnrollCart need to check if current localStorage.storageEnrollCart is empty then set .enrollCart to empty
        let storageEnrollCart = localStorage.getItem('storageEnrollCart')
        storageEnrollCart = JSON.parse(storageEnrollCart) || []
        if (!storageEnrollCart || storageEnrollCart.length == 0) {
            enrollStore.enrollCart.map((b, i) => {
                if (isSomething(b)) {
                    b.qty = 0
                }
                return b
            })
        } else {
            storageEnrollCart.map((v, k) => {
                enrollStore.enrollCart.map((b, i) => {
                    if (isSomething(b)) {
                        if (v.id === b.product_id) {
                            b.qty = v.qty
                            return b
                        }
                    }
                    return b
                })
            })
        }
    }

    const restoreShoppingProductSourceFromLocal = () => {
        let hydraShoppingProductSource = localStorage.getItem('hydraShoppingProductSource')

        if (hydraShoppingProductSource) {
            hydraShoppingProductSource = JSON.parse(hydraShoppingProductSource)
            shopStore.productDataSource = hydraShoppingProductSource
        }
    }

    return <div></div>
})

export default ProductSourceEnroll
