import { makeAutoObservable } from 'mobx';
import { isIPad } from '../../../utils/Bowser';

class StoreResponsive {
    orderDetails = {
        rowResponsive: {
            justify: 'center'
        },
        colResponsive: {
            wrapperLayout: {
                xs: 22,
                sm: 22,
                md: 14,
                lg: isIPad() ? 16 : 12,
                xl: 12,
                xxl: 12
            },
            wrapperBody: {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24,
                xl: 24,
                xxl: 24
            },
            wrapperFooter: {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24,
                xl: 24,
                xxl: 24
            }
        }
    }
    form = {
        rowResponsive: {
            justify: 'center'
        },
        colResponsive: {
            xs: 24,
            sm: 24,
            md: 20,
            lg: 12,
            xl: 12,
            xxl: 12
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export const storeResponsive = new StoreResponsive()