import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './CSS/Style.css';
// Mobx
import { reaction } from 'mobx';
import { useObserver } from 'mobx-react';
import { checkOutStore,store } from '../../../stores/MainStore';
import { storeCart } from '../../../stores/StoreCart';
// Helpers
import { clearEasyShop } from '@Components/ShoppingHelper';
import { dictionary, isCountryIndonesia } from '@GlobalHelpers';
import { trim, isSomething, isNothing, someOf } from '@Utils/Utils';
import { ShippingMethods } from '../../configs/ShippingMethods';
import { CountryConfigs as C } from '../../configs/Configs';
import * as S from '../../configs/StorageKeys';
// Library
import Layout from 'antd/es/layout';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import PropTypes from 'prop-types';
import { Element, scroller } from 'react-scroll';
import { Switch, Case } from 'react-if';
// Components
import FormDelivery from './Form/FormDelivery';
import FormPickUp from './Form/FormPickUp';
import FormAramex from './Form/FormAramex';
import FormMotorDelivery from './Form/FormMotorDelivery';
import SectionCommission from './Section/SectionCommission';
import SectionWarehouse from './Section/SectionWarehouse';
import SectionShipping from './Section/SectionShipping';
import SectionTime from './Section/SectionTime';
import ODFooter from './Common/ODFooter';
import { shopprofileStatusConfig} from "../../config";
import { storeOrderDetails } from './Store/StoreOrderDetails';
import { storeResponsive } from './Store/StoreResponsive';
import { storeEasyShop } from '../../../stores/StoreEasyShop'


// Destructuring for antd components 
const { Header, Content } = Layout

// * Core Component
/** A component of form for checkout process. */
const OrderDetailsPH = props => {
    const history = useHistory()
    const [shiptoTab, setShiptoTab] = useState('1')
    const [commissionMonths, setCommissionMonth] = useState(props.commissionMonth)
    const [commission, setCommission] = useState(props.commission)

    checkOutStore.shipping = ''+props.store.shareCart.shipping
    
    useEffect(() => {
        const disposerCart = reaction(
            () => storeCart.current,
            (cart) => {
                if (cart.length === 0) {
                    // history.replace(`/${C.Country()}/empty-cart`)
                }
            }
        )


        const disposerShipping = reaction(
            () => checkOutStore.shipping,
            () => {
                const localShipping = {
                    shipping: checkOutStore.shipping,
                    warehouse: ShippingMethods.getWarehouseEnabled() ?  checkOutStore.warehouse : '',
                    country: C.CountryCode()
                }

                localStorage.setItem(S.OrderDetailsShipping, JSON.stringify(localShipping))

                props.processWarehouse()
                storeOrderDetails.errorMessage = []
            }
        )

        const disposerWarehouse = reaction(
            () => checkOutStore.warehouse,
            (warehouse) => {


                // switch (get(checkOutStore, 'shipping', '').toString()) {
                //     case '7': return  'deliverySBY'
                //     case '3': return  'pickup'
                //     case '32': return 'pickUpTaguig'
                //     default: return 'delivery'
                // }       

               
                if (checkOutStore.shipping === '7') {
                    checkOutStore.shipping = '2'
                } else if (checkOutStore.shipping === '32') {
                    checkOutStore.shipping = '3'
                } else if (checkOutStore.shipping === '9') {
                    checkOutStore.shipping = '6'
                }
                else  {
                    checkOutStore.shipping = '3'
                }
                
                const localShipping = {
                    shipping: checkOutStore.shipping,
                    warehouse: ShippingMethods.getWarehouseEnabled() ?  checkOutStore.warehouse : ''
                }

                localStorage.setItem(S.OrderDetailsShipping, JSON.stringify(localShipping))
            }
        )

        const disposerTime = reaction(
            () => checkOutStore.shipToTime,
            () => {
                storeOrderDetails.errorMessage = []
            }
        )
        
        // * Restoration Process
        const localShipping = JSON.parse(localStorage.getItem(S.OrderDetailsShipping))
        
        if (isSomething(localShipping)) {
            if (localShipping.country === C.CountryCode()) {
                // restored warehouse
                if (ShippingMethods.getWarehouseEnabled()) {
                    checkOutStore.warehouse = localShipping.warehouse
                } else {
                    checkOutStore.warehouse = ''
                }
                // restored shipping
                checkOutStore.shipping = localShipping.shipping
            }
        }

        // * Unmount & Dispose
        return () => {
            disposerCart()
            disposerShipping()
            disposerWarehouse()
            disposerTime()
        }
        console.log("bernard shipping", checkOutStore.shipping);
    }, [])

    const onChangeShiptoTab = (e) => {
        setShiptoTab(e)
    }

    const onClickBack = () => {
        clearEasyShop()

        props.store.shareCart.proceedCheckout = false
        
        props.store.shareCart.OrderDetailForm = false

        props.store.shareCart.loading = false
          
        window.scrollTo(0, 0)
        //history.push('./catproduct/all')
    }

    const onClickCheckout = () => {
       
        if(!storeOrderDetails.isCheckTc){
            storeOrderDetails.errorMessage = [dictionary('u_all_please_agree_terms_and_conditions')]
            storeOrderDetails.isLoadingAPI = false
        }else{
            props.onConfirmedShipping()
        }
   
    }

    const scrollToTabShipTo = () => {
        scroller.scrollTo('shipto-tabs', {
            duration: 500,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -80
        })
    }

    console.log(checkOutStore.shipping,'checkOutStore.shipping')

    let spConfig = shopprofileStatusConfig(store.shareCart.sharedStatus)
    let shippingMethodConfig = spConfig.shippingMethod;
    console.log("spConfig",spConfig)

    console.log("shippingMethodConfig",shippingMethodConfig);

    return useObserver(() => 
        <Layout className="order-details">
            <Header className="order-details-header">
                <Row {...storeResponsive.orderDetails.rowResponsive}>
                    <Col {...storeResponsive.orderDetails.colResponsive.wrapperLayout}>
                        <div className="order-details-header-div mobile">
                            <div>{dictionary('u_all_od_title')}</div>
                        </div>
                    </Col>
                </Row>
            </Header>
            <Content className={`order-details-content ${store.shareCart.sharedStatus !== 'A' && 'notBA'}`}>
                <Row {...storeResponsive.orderDetails.rowResponsive}>
                    <Col {...storeResponsive.orderDetails.colResponsive.wrapperLayout}>

                        
                        <Card className="card-section-top" style={shippingMethodConfig.length === 1 ?{display:'none'}:{}}>
                        
                            <SectionCommission 
                                {...props}
                                commissionMonth={commissionMonths} 
                                commission={commission}
                                style={store.shareCart.sharedStatus !== 'A'?{display:'none'}:{}}
                            />
                            
                            <SectionWarehouse/>
                            <SectionShipping {...props}/>
                            <SectionTime/>
                        </Card>
                        

                        <Row {...storeResponsive.orderDetails.rowResponsive}>
                            <Col {...storeResponsive.orderDetails.colResponsive.wrapperBody}>
                                <Card className="card-section-bottom">
                                    <Switch>
                                        {/* -- Delivery Method -- */}
                                        <Case condition={someOf(checkOutStore.shipping, [ShippingMethods.ID.delivery, ShippingMethods.ID.deliverySBY])}>
                                            <Layout className="section-shipto">
                                                <Content className="section-shipto-content-top">
                                                    <Card>
                                                        <FormDelivery {...props}/>
                                                    </Card> 
                                                </Content>
                                                <ODFooter {...props} onClickBack={onClickBack} onClickCheckout={onClickCheckout}/>
                                            </Layout>
                                        </Case>
                                        {/* --------------------- */}
                                        {/* --- PickUp Method --- */}
                                        <Case condition={someOf(checkOutStore.shipping, [ShippingMethods.ID.pickUp, ShippingMethods.ID.pickUpSBY,ShippingMethods.ID.pickUpTaguig])}>
                                            <Layout className="section-shipto">
                                                <Content className="section-shipto-content-top">
                                                    <Card>
                                                        <FormPickUp/>
                                                    </Card>
                                                </Content>
                                                <ODFooter {...props} onClickBack={onClickBack} onClickCheckout={onClickCheckout}/>
                                            </Layout>
                                        </Case>
                                    </Switch>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

OrderDetailsPH.propTypes = {
    // commissionMonth: PropTypes.any,
    onConfirmedShipping: PropTypes.func,
    processWarehouse: PropTypes.func
}

OrderDetailsPH.displayName = 'OrderDetailsPH'
export default OrderDetailsPH