import React from 'react';
import { toJS } from 'mobx';
import { appConfig } from '../config';
import { getCountryCode } from '@GlobalHelpers';
import PriceWithCurrency from './PriceWithCurrency';
import ShoppingClass from '../shopping/ShoppingClass'
import { store, shareThisCart, checkOutStore, shopStore } from '../../stores/MainStore';
import { isNothing, isUndefined, defaults, isNullOrUndefined, isEmpty, isSomething, isString } from '../utils/Utils';
import { storeEasyShop } from '../../stores/StoreEasyShop';
import { PriceLevelToPriceKey } from '../configs/ShopProfile';
import { isCustomerLogin, getCustomerPriceLevel } from '../utils/Customer';
import { storeProduct } from '../../stores/StoreProduct';
import get from 'lodash/get';

const isPriceEmployee = () => getCustomerPriceLevel() === 'employee_price'
const isPricePreferred = () => getCustomerPriceLevel() === 'preferred_price'

export const getProductPrice = () => {
    
    const formatPrice = (price, currencyPosition) => {
        let currency = store.currency
        if (isNothing(currencyPosition) || currencyPosition === 'none') {
            currency = ''
        }
        return <PriceWithCurrency currencyPosition={currencyPosition} price={price} currency={currency} />
    }
        
    return {
        formatPrice: (price, currencyPosition) => formatPrice(price, currencyPosition)
    }
}

/** New get product price method. */
export const useTotalPrice = () => {
}

/** New get product price method. */
export const getPrice = (productID) => {

    let dToken = new ShoppingClass()
    let price = 0

    if (isString(productID)) {
        const item = storeProduct.getProduct(productID)
        if (isSomething(item)) {
            // ** Item existed
            if (isCustomerLogin()) {
                // * Login Users
                price = get(item, getCustomerPriceLevel(), null)

                if (isNothing(price)) {
                    if (isPriceEmployee() || isPricePreferred()) {
                        price = item.employee_price || 0
                    } else {
                        price = 0
                    }
                }

                price = parseFloat(price)
            } else {
                // * Guest Users
                if (appConfig.preferredPrice === true) {
                    if (checkOutStore.referralValue !== '') {
                        price = item.preferred_price || 0
                    }
                } else {
                    price = item.retail_price
                    if (checkOutStore.referenceDetail !== '') {
                        if (checkOutStore.referralValue === '' && checkOutStore.referenceDetail.type !== undefined) {
                            if (checkOutStore.referenceDetail.type === 'Associate' || checkOutStore.referenceDetail.type === 'Pending') {
                                price = item.wholesale_price || 0
                            } 
                        }
                    }
                }
            }
            
            if (dToken.isPreferredCustomer() === true || dToken.isFamilyCustomer() === true) {
                price = item.employee_price || 0
            }
        } 

        if (shareThisCart.editMode) {
            if (!isNullOrUndefined(shareThisCart.editData.price_level) && !isEmpty(shareThisCart.editData.price_level)) {
                price = item[PriceLevelToPriceKey[shareThisCart.editData.price_level]]
            }
        }
    }
    
    return parseFloat(price)
}

/**
 * @param {*} props 
 * @param {object} props.product
 * @param {boolean} props.formatted
 * @param {'none'|'left'|'right'} props.currencyPosition
 */
const ProductPrice = props => {

    const { product, formatted, currencyPosition } = props

    const productPrice = getPrice(product.item_code)

    if (formatted) {
        return getProductPrice().formatPrice(productPrice, currencyPosition)
    } else {
        return productPrice
    }
}

export default ProductPrice