export const IconPlus = () => {
    return (
        <svg width="19" height="17" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5714 5.85704C12.5714 5.38382 12.1875 4.9999 11.7143 4.9999H8V1.28561C8 0.812395 7.61607 0.428467 7.14286 0.428467H5.42857C4.95536 0.428467 4.57143 0.812395 4.57143 1.28561V4.9999H0.857143C0.383929 4.9999 0 5.38382 0 5.85704V7.57132C0 8.04454 0.383929 8.42847 0.857143 8.42847H4.57143V12.1428C4.57143 12.616 4.95536 12.9999 5.42857 12.9999H7.14286C7.61607 12.9999 8 12.616 8 12.1428V8.42847H11.7143C12.1875 8.42847 12.5714 8.04454 12.5714 7.57132V5.85704Z" fill="#333" />
        </svg>
    )
}

export const IconPlusMobile = () => {
    return (
        <svg width="22" height="25" viewBox="0 0 20 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5714 5.85704C12.5714 5.38382 12.1875 4.9999 11.7143 4.9999H8V1.28561C8 0.812395 7.61607 0.428467 7.14286 0.428467H5.42857C4.95536 0.428467 4.57143 0.812395 4.57143 1.28561V4.9999H0.857143C0.383929 4.9999 0 5.38382 0 5.85704V7.57132C0 8.04454 0.383929 8.42847 0.857143 8.42847H4.57143V12.1428C4.57143 12.616 4.95536 12.9999 5.42857 12.9999H7.14286C7.61607 12.9999 8 12.616 8 12.1428V8.42847H11.7143C12.1875 8.42847 12.5714 8.04454 12.5714 7.57132V5.85704Z" fill="#333" />
        </svg>
    )
}

export const IconMinus = () => {
    return (
        <svg
            width="20"
            height="6"
            viewBox="0 0 13 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.5714 0.857142C12.5714 0.383928 12.1875 0 11.7143 0H0.857143C0.383929 0 0 0.383928 0 0.857142V2.57143C0 3.04464 0.383929 3.42857 0.857143 3.42857H11.7143C12.1875 3.42857 12.5714 3.04464 12.5714 2.57143V0.857142Z"
                fill="black"
            />
        </svg>
    )
}