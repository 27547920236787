import Resizer from 'react-image-file-resizer'

// Resizer.imageFileResizer(
//     file, // Is the file of the image which will resized.
//     maxWidth, // Is the maxWidth of the resized new image.
//     maxHeight, // Is the maxHeight of the resized new image.
//     compressFormat, // Is the compressFormat of the resized new image.
//     quality, // Is the quality of the resized new image.
//     rotation, // Is the degree of clockwise rotation to apply to uploaded image.
//     responseUriFunc, // Is the callBack function of the resized new image URI.
//     outputType, // Is the output type of the resized new image.
//     minWidth, // Is the minWidth of the resized new image.
//     minHeight // Is the minHeight of the resized new image.
//   );

const useResizeImage = async (file, maxWidth = 1920, maxHeight = 1080) => {
    // read image width
    const reader = new FileReader()
    const fileWidth = await new Promise(resolve => {
        try {
            reader.onload = data => {
                const image = new Image()
                image.onload = () => resolve(image.width)
                image.src = data.target.result
                image.crossOrigin = 'anonymous'
            }
            reader.error = (error) => {
                console.log('reader error', error)
            }
            reader.readAsDataURL(file)
        } catch (e) {
            const image = document.createElement('img')
            image.onload = () => resolve(image.width)
            image.src = URL.createObjectURL(file)
        }
    })

    const minWidth = fileWidth >= maxWidth ? maxWidth : 300

    // check image width and image size
    const MAX_IMAGE_SIZE = 5000000
    if (file.size < MAX_IMAGE_SIZE && fileWidth < maxWidth) return file
    const resizePercent = file.size > MAX_IMAGE_SIZE ? Math.floor(((file.size - MAX_IMAGE_SIZE) / file.size) * 100) : 80

    // resize image
    const imageResized = await new Promise(resolve => {
        Resizer.imageFileResizer(
            file,
            maxWidth,
            maxHeight,
            'JPEG',
            resizePercent,
            0,
            uri => {
                resolve(uri)
            },
            'blob',
            minWidth,
            300
        )
    })
    return imageResized
}
export default useResizeImage
