import { makeAutoObservable } from "mobx"
import { CountryConfigs } from "@Components/configs/Configs"
import { hasQueryString } from "@Components/configs/ConfigsHeader"
import { isArray, isSomething, isString } from "@Components/utils/Utils"
import { first } from "lodash"
import { translateHydraErrors } from "@Components/utils/Errors"


class EnrollSubmitPayment {


    ProceedToPayment() {
        return new Promise((resolve, reject) => {
            const country = CountryConfigs.CountryFullPascal()
            import(`./EnrollPaymentData/${country}PaymentData`).then(Payment => {
                Payment.default.doSubmitPayment().then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            }).catch(err => {
                reject(err)
            })
        })
    }

    ProceedToPaymentResume(Ref_resume) {
        return new Promise((resolve, reject) => {
            const country = CountryConfigs.CountryFullPascal()
            import(`./EnrollPaymentData/${country}PaymentData`).then(Payment => {
                Payment.default.doSubmitPaymentResume(Ref_resume).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            }).catch(err => {
                reject(err)
            })
        })
    }

    prepareErrorMessages(response) {
        console.log('<<response>>', response)
        // * Simulate errors result
        // response = errorHydraPaymentRequired // Payment Required

        if (hasQueryString('fail')) {
            response = undefined
        }

        // get error messages
        const em = response ? response.error_messages : undefined
        // find out if this is hydra errors
        let resultErrorMessages = []
        if (isSomething(em)) {
            if (isArray(em)) {
                const em0 = first(em)
                if (isSomething(em0)) {
                    // Checked if this is backend error messages
                    if (first(em0).indexOf('db_') !== -1) {
                        resultErrorMessages = em
                    } else {
                        // hydra error messages
                        resultErrorMessages = translateHydraErrors(response)
                    }
                } else {
                    response.error_messages = ['u_all_internal_server_error']
                    resultErrorMessages = translateHydraErrors(response)
                }
            } else {
                if (isString(em)) {
                    resultErrorMessages = [em]
                } else {
                    // undefined error_messages
                    resultErrorMessages = []
                }
            }
        } else {
            resultErrorMessages = translateHydraErrors(response)
        }

        return resultErrorMessages
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export default new EnrollSubmitPayment()