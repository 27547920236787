import React, { Component, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { observer, useObserver } from 'mobx-react';
import { shopStore, checkOutStore, staticFooter, store } from '../../stores/MainStore';
import { restoreCheckOutFromLocal, resetReferralStore, resetCheckOutStore, resetShopStore, resetCheckOutEnrollStore } from '../EnrollHelper'
import { detectIE, getParameterByName, dictionary, isCountryThailand } from '@GlobalHelpers'
import success from '../../assets/customlink/payment_success.svg'
import error from '../../assets/customlink/payment_failed.svg'
import Spinner from '../Spinner';
import { appConfig } from '../config';
import { apiURL, clearCartInfo, getShowPV, getPickupMessage, clearEasyShop, getCurrentDomain } from '../ShoppingHelper'
import { Button } from 'antd';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import $ from 'jquery';
import '@Layouts/2020/Components/shoppingList.scss'


const PostBack = props => {
    const [ajaxLoading, setAjaxLoading] = useState(true)
    const [status, setStatus] = useState(props.match.params.paymentstatus)
    const [checkOrderIdError, setCheckOrderIdError] = useState('')
    const [message, setMessage] = useState('')
    const [message2, setMessage2] = useState('')
    const [title, setTitle] = useState('')
    const [messageOrderId, setMessageOrderId] = useState('')
    const [new_id, setNew_id] = useState('')

    const checkOrderId = (reference_id) => {

        window.maxCountRequestCheckOrder = 5
        window.countRequestCheckOrder++


        if (window.countRequestCheckOrder <= window.maxCountRequestCheckOrder) {
            setAjaxLoading(true)
            setCheckOrderIdError(false)


            let postData = { 'reference_id': reference_id }

            $.post(apiURL.shopping.checkOrderId, JSON.stringify(postData))
                .done(function (result) {

                    if (result.order_id) {
                        setMessageOrderId(result.order_id)
                        setNew_id(result.new_id)
                        setAjaxLoading(false)

                        window.countRequestCheckOrder = window.maxCountRequestCheckOrder

                    } else {
                        window.setTimeout(() => {
                            checkOrderId(reference_id)
                        }, 5000
                        );
                    }
                })
                .fail(function (error) {
                    setAjaxLoading(false)

                });
        } else {
            checkOrderIdWithInquery(reference_id)
        }
    }

    const checkOrderIdWithInquery = (reference_id) => {

        let postData = { 'reference_id': reference_id }


        $.post(apiURL.shopping.checkOrderIdWithInquery, JSON.stringify(postData))
            .done(successHandler)
            .fail(failHandler)

        function successHandler(result) {
            if (result.order_id) {

                setMessageOrderId(result.order_id)
                setNew_id(result.new_id)
                setAjaxLoading(false)

            } else {
                failHandler()
            }
        }
        function failHandler(err) {
            setAjaxLoading(false)
            setMessageOrderId(getParameterByName('Ref'))

        }
    }



    useEffect(() => {
        setLanguage()

        if (status === 'success') {

            let reference_id = getParameterByName('Ref')
            let order_id = getParameterByName('order_id')

            if (reference_id) {

                if (isCountryThailand()) {
                    checkOrderId(reference_id)
                } else {
                    setMessageOrderId('')
                }

            } else {
                if (order_id) {
                    let new_order_id = order_id.replace(/65-/gi, '')
                    new_order_id = new_order_id.replace(/63-/gi, '')
                    new_order_id = new_order_id.replace(/85-/gi, '')
                    new_order_id = new_order_id.replace(/66-/gi, '')
                    setMessageOrderId(new_order_id)

                }
            }
        }

        setTimeout(() => {
            setAjaxLoading(false)
        }, 2000)

    }, [])

    let alert = status === 'failed' ? `payment-fail` : 'payment-success'

    const openDialPad = (number) => {
        if (props.store.isWebview) {
            if (props.store.latestWebview) {
                window.ReactNativeWebView.postMessage(JSON.stringify({ data: `tel: + ${number}`, targetFunc: "redirect" }));
            } else {
                window.WebViewBridge.send(JSON.stringify({ data: `tel: + ${number}`, targetFunc: "redirect" }));
            }
        } else {
            window.location.href = `tel: + ${number}`;
        }
    }

    const setLanguage = () => {
        if (status === 'success') {
            setTitle(dictionary('payment_thank_you'))
            setMessage(dictionary('payment_order_placed'))
            setMessage2(dictionary(''))

        } else {
            setTitle(dictionary('payment_something_wrong'))
            setMessage(dictionary('payment_try_again_th'))
            setMessage2(dictionary('payment_if_error_persists'))

        }


    }

    const backToShop = () => {
        let sharedCardID = props.match.params.id
        let sharedStatus = props.match.params.status

        let url = getCurrentDomain() + '/' + appConfig.country + '/social/' + getParameterByName('reference_id_3') + '?try_again=true';

        window.location = url
    }

    return useObserver(() =>
        <div className={`offcanvas-wrapper padding-bottom-3x customlink-result ${alert}`}>
            {ajaxLoading &&
                <div className="d-flex justify-content-center w-100">
                    <Spinner />
                </div>
            }

            {!ajaxLoading &&
                <div className="container">
                    <div className="card text-center padding-top-1x">

                        {status === 'success' &&
                            <img className="postbackenroll-img" src={success} />
                        }
                        {status === 'failed' &&
                            <img className="postbackenroll-img" src={error} />
                        }


                        <div className="card-body">
                            <h3 className={`card-title`}>

                                {status === 'success' && dictionary('payment_thank_you')}
                                {status === 'failed' && dictionary('u_link_all_payment_failed')}
                            </h3>

                            <div className="text">
                                {status === 'success' && dictionary('payment_order_placed')}

                                {status === 'failed' && dictionary('payment_something_wrong')}

                            </div>
                            {status === 'success' &&
                                <div className="messageOrderId">
                                    <span>{dictionary('u_all_link_order_number')} : </span> {messageOrderId.replace(/66-/gi, '')}
                                </div>}
                            {status === 'failed' &&
                                <div className="block-button" >
                                    <Button
                                        block={true}
                                        onClick={() => {
                                            backToShop()
                                        }}
                                    >

                                        <Row type="flex" justify="center" className="ml-1">
                                            <Col span={24}>
                                                <span>
                                                    {dictionary('u_link_all_try_again')}
                                                    {/* {status === 'success' &&  dictionary('u_link_all_back_to_shop')} */}
                                                </span>
                                            </Col>
                                        </Row>
                                    </Button>
                                </div>
                            }


                        </div>

                    </div>


                </div>}
        </div>
    )
}

export default PostBack;
