import React, { useState, useEffect } from 'react';
// Mobx
import { useObserver } from 'mobx-react';
import { checkOutStore, store } from '../../../../stores/MainStore';
// Library
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';
// Helpers
import { SectionsDeclaration } from '../../../configs/Sections';
import { CountryConfigs as CC } from '../../../configs/Configs';
import { dictionary, isLanguageNative } from '@GlobalHelpers';
import { throatBoolean } from '../../../configs/ConfigsHeader';
import { isSomething, isNothing, toBool } from '../../../utils/Utils';
import { checkOutPeriod } from '@Services/Network';
import { CountryCode } from '../../../configs/Country';
import { isCustomerLogin, getCustomerObject } from '../../../utils/Customer';
import Button from 'antd/es/button';
import last from 'lodash/last';
import { When, If, Then, Else } from 'react-if';
import { Radio } from 'antd';
import Redirect from '../../../../components/Redirect';
import SuperPills from '../../../fragment/dropdown/SuperPills';
const { Title } = Typography

/**
 * 
 */
const SectionCommission = props => {

    const [commissionMonths, setCommissionMonth] = useState(props.commissionMonth)
    const [commission, setCommission] = useState(props.commission)
    const [loading, setLoading] = useState(true)

    const getPeriodCountryCode = () => {
        switch (CC.CountryCode()) {
            case CountryCode.australia: return 'SG'
            case CountryCode.newzealand: return 'SG'
            default: return CC.CountryCode()
        }
    }

    const requestCommissionMonth = () => {
        checkOutPeriod((response, success) => {
            if (toBool(success)) {
                if (toBool(response.success)) {
                    checkOutStore.commissionMonth = response.entryPeriod
                    if (isCustomerLogin() === false) {
                        setCommissionMonth(response.message)
                        setCommission(last(response.message).period)
                        store.shareCart.period = last(response.message).period
                        store.shareCart.periodData = last(response.message)

                    } else {
                        const customerData = getCustomerObject()
                        const cusJoinDate = customerData.joinDate.split('-')
                        const checkEnrollTopup = `${cusJoinDate[0]}-${cusJoinDate[1]}`

                        let data, result = []
                        if (checkEnrollTopup === response.message[0].period && toBool(response.topup)) {
                            data = [response.message[0]]
                        } else {
                            data = response.message
                        }
                        data.map((item) => {
                            result.push({
                                value: item.period,
                                name: item.name,
                                native: item.native
                            })
                        })

                        setCommissionMonth(result)
                        setCommission(last(result).value)

                        store.shareCart.period = last(result).value
                        store.shareCart.periodData = last(result)

                    }
                }
            }
        }, { country: getPeriodCountryCode(), system: 'AO' })
    }

    useEffect(() => {
        requestCommissionMonth()

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])


    // always update mobx commission whenever state changed



    useEffect(() => {
        checkOutStore.commissionMonth = props.commission
    }, [props.commission])



    const onChangRadio = e => {

        setCommission(e.target.value)

    };

    const optionsCommissionMonths = () => {
        const result = []

        commissionMonths.map((item, index) => {

            result.push({ value: item.period, text: isLanguageNative() ? item.native : item.name })

        })

        return result
    }
    const colResponsiveConfig = {
        xs: 24,
        sm: 24,
        md: 20,
        lg: 12,
        xl: 12,
        xxl: 12
    }

    return useObserver(() =>
        // <When condition={throatBoolean(SectionsDeclaration.OrderDetails().commision)}>
        <Row type="flex" justify="center" className="text-center">
            <Col span={24}>
                <Title level={4}>{dictionary('commission_month')}</Title>

                <When condition={loading}>
                    <Redirect />
                </When>
                <When condition={!loading}>
                    <Row className={`select-commision select-commision-radio ${commissionMonths.length === 1 ? 'select-commision-radio-one' : 'select-commision-superpill'}`} type="flex" justify="center" gutter={[20, 10]}>
                        <When condition={isSomething(commissionMonths)}>
                            {/* {() => commissionMonths.map((item, index) => {

                                        console.log('commissionMonths item===>' , item)
                                        const classNameStatus = item.period === commission ? 'actived' : 'inactived'
                                        return (
                                            <Col>
                                                <Button key={`cb-${index}`} 
                                                    className={classNameStatus}
                                                    value={item.period}
                                                    onClick={() => setCommission(item.period)}>
                                                    <span>{isLanguageNative() ? item.native : item.name}</span>
                                                </Button>
                                            </Col>
                                        )
                                    })} */}
                            <If condition={commissionMonths.length === 1}>
                                <Then>
                                    {() => commissionMonths.map((item, index) => {
                                        const checked = item.period === commission ? 'checked' : ''
                                        return (
                                            <label className="ant-radio-wrapper" key={`radio-item-${index}`}>
                                                <span>{isLanguageNative() ? item.native : item.name}</span>
                                            </label>

                                        )
                                    })}

                                </Then>
                                <Else>
                                    <Row id="delivery" type="flex" justify="center">
                                        <Col {...colResponsiveConfig}>
                                            <SuperPills
                                                defaultValue={commission}
                                                value={commission}
                                                onSelected={
                                                    (value) => {

                                                        setCommission(value)
                                                        store.shareCart.period = value
                                                        let item = commissionMonths.filter(each => each.period === value)
                                                        store.shareCart.periodData = item
                                                    }

                                                }
                                                //disabled={storeOrderDetails.isLoadingAPI}
                                                options={optionsCommissionMonths()}
                                                placeholder={`${dictionary('please_select')}`}
                                                notFoundContent={<div className="d-block text-center py-5">{dictionary('not_found_content')}</div>}
                                            />
                                        </Col>
                                    </Row>


                                </Else>
                            </If>

                            {/* {() => commissionMonths.map((item, index) => {
                                        const checked = item.period === commission ? 'checked' : ''
                                    return (
                                        
                                            <Radio  
                                                value={item.period} 
                                                onClick={() => {
                                                    setCommission(item.period)
                                                    store.shareCart.period = item.period
                                                    store.shareCart.periodData = item
                                                }} 
                                                checked={checked}>
                                            {isLanguageNative() ? item.native : item.name}
                                            </Radio>
                                    )
                                    })} */}

                        </When>
                    </Row>
                </When>

            </Col>
        </Row>
        // </When>
    )
}

SectionCommission.displayName = 'SectionCommission'
export default SectionCommission