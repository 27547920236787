import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useObserver, useLocalStore } from 'mobx-react-lite';
import { autorun, entries, toJS, reaction } from 'mobx';
import { dictionary, randomNumber, randomString, getCountryCode } from '@GlobalHelpers';
import { CountryConfigs as CC } from '../../../configs/Configs';
import { isDesktop } from '../../../utils/Bowser';
import { checkOutStore } from '../../../../stores/MainStore';
import { storeAddress } from '../../../../stores/AddressStore';
import { getCustomerHumanName, getCustomerMobilePhone, getCustomerEmail } from '../../../utils/Customer';
import { trim, isSomething, isNothing, sortString } from '../../../utils/Utils';
import FormItemGenerator from './FormItemGenerator';
import { hasQueryString } from '../../../configs/ConfigsHeader';
import { CountryCode } from '../../../configs/Country';
import * as K from '../../../configs/Keywords';
import * as S from '../../../configs/StorageKeys';
import SuperInput from '../../../fragment/SuperInput';
import SuperPills from '../../../fragment/dropdown/SuperPills';
import { MotorDelivery, ShippingAddress } from '../../../configs/Address';
import { ShippingMethods } from '../../../configs/ShippingMethods'

import { When } from 'react-if';
import get from 'lodash/get';
import set from 'lodash/set';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Layout, Form } from 'antd';

const FormMotorDelivery = props => {
    const mState = useLocalStore(() => ({
        selectedAddress2: undefined,
        selectedCity: undefined,
        selectedDistrict: undefined,
        selectedState: undefined,
        fullName: getCustomerHumanName().fullName,
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        mobilePhone: getCustomerMobilePhone(),
        email: getCustomerEmail(),
        comment: ''
    }))

    const mOptions = useLocalStore(() => ({
        address2: [],
        city: [],
        district: [],
        state: []
    }))

    const refGenerator = useRef()

    

    const drawForm = () => {
        // label keys
        const c = getCountryCode(true)
        const a = '' // additionalAddressLabel
        const keyFullname = `full_name`
        const keyCountry = `country`
        const keyProvince = `province`
        const keyAddressL1 = `address_line1_${c}`
        const keyAddressL2 = `address_line2_${c}${a}`
        const keyCity = `city`
        const keyDistrict = `district`
        const keyState = `state`
        const keyVillage = `village`
        const keyZip = `zip`
        const keyMobilePhone = `mobilePhone`
        const keyEmail = `email`
        const keyComment = 'comment'
        // placeholder keys
        const keyPlaceholderFullname = `placeholder_full_name_${c}`
        const keyPlaceholderAddressL1 = `placeholder_address_line1_${c}`
        const keyPlaceholderAddressL2 = `placeholder_address_line2_${c}`
        const keyPlaceholderCity = `placeholder_city_${c}`
        const keyPlaceholderDistrict = `placeholder_district_${c}`
        const keyPlaceholderState = `placeholder_state_${c}`
        const keyPlaceholderZip = `placeholder_zip_${c}`
        const keyPlaceholderMobile = `placeholder_mobile_phone_${c}`
        const keyPlaceholderEmail = `placeholder_email_${c}`

        const formTemplate = {}

        set(formTemplate, 
            'name', (
            <Form.Item {...formItemConfig} label={dictionary(keyFullname)}>
                <SuperInput 
                    defaultValue={mState.fullName}
                    value={mState.fullName}
                    onChange={(input) => mState.fullName = input.target.value}
                    placeholder={dictionary(keyPlaceholderFullname)}
                    />
            </Form.Item>
        ))

        set(formTemplate, 
            'address1', (
            <Form.Item {...formItemConfig} label={dictionary(keyAddressL1)}>
                <SuperInput 
                    defaultValue={mState.address1}
                    value={mState.address1}
                    onChange={(input) => mState.address1 = input.target.value}
                    placeholder={dictionary(keyPlaceholderAddressL1)}
                    />
            </Form.Item>
        ))

        set(formTemplate, 
            'city_id', (
            <Form.Item {...formItemConfig} label={dictionary(keyCity)}>
                <SuperPills 
                    showSearch
                    options={toJS(mOptions.city)} 
                    defaultValue={mState.selectedCity}
                    value={mState.selectedCity}
                    onSelected={(value) => mState.selectedCity = value }
                    placeholder={`${dictionary('please_select')}`}
                    notFoundContent={<div className="d-block text-center py-5">{dictionary('not_found_content')}</div>}
                />
            </Form.Item>
        ))

        set(formTemplate, 
             // ID: Village (state_id) | only Jakarta has village
            'state_id', (
            <Fragment>
                <When condition={checkOutStore.warehouse === ShippingMethods.WarehouseDeclaration.jakarta.value}>
                    <Form.Item {...formItemConfig} label={dictionary(keyVillage)}>
                        <SuperPills 
                            showSearch
                            disabled={isNothing(mOptions.state)}
                            options={toJS(mOptions.state)} 
                            value={mState.selectedState}
                            onSelected={(value) => mState.selectedState = value }
                            placeholder={`${dictionary('please_select')}`}
                            notFoundContent={<div className="d-block text-center py-5">{dictionary('not_found_content')}</div>}
                        />
                    </Form.Item> 
                </When>
            </Fragment>
        ))

        set(formTemplate, 
            // ID: ID: District should append after City (address2_id)
            'district_id', (
            <Form.Item {...formItemConfig} label={dictionary(keyDistrict)}>
                <SuperPills 
                    showSearch
                    disabled={isNothing(mOptions.district)}
                    options={toJS(mOptions.district)} 
                    value={mState.selectedDistrict}
                    onSelected={(value) => mState.selectedDistrict = value }
                    placeholder={`${dictionary('please_select')}`}
                    notFoundContent={<div className="d-block text-center py-5">{dictionary('not_found_content')}</div>}
                />
            </Form.Item>
        ))

        set(formTemplate, 
            'province_id', (
            <Form.Item {...formItemConfig} label={dictionary(keyProvince)}>
                <SuperInput 
                    disabled
                    value={get(ShippingAddress.motorDelivery.indonesia, `${checkOutStore.warehouse}.province`, '')}
                    placeholder={CC.CountryFullDisplay(true)}
                    />
            </Form.Item>
        ))

        set(formTemplate, 
            'zip', (
            <Form.Item {...formItemConfig} label={dictionary(keyZip)}>
                <SuperInput 
                    defaultValue={mState.zip}
                    value={mState.zip}
                    onChange={(input) => mState.zip = input.target.value}
                    placeholder={dictionary(keyPlaceholderZip)}
                    />
            </Form.Item>
        ))

        set(formTemplate, 
            'country', (
            <Form.Item {...formItemConfig} label={dictionary(keyCountry)}>
                <SuperInput 
                    disabled
                    value={CC.CountryFullDisplay(true)}
                    placeholder={CC.CountryFullDisplay(true)}
                    />
            </Form.Item>
        ))

        set(formTemplate, 
            'mobile', (
            <Form.Item {...formItemConfig} label={dictionary(keyMobilePhone)} style={{marginBottom: '0.2rem'}}>
                <SuperInput 
                    defaultValue={mState.mobilePhone}
                    value={mState.mobilePhone}
                    onChange={(input) => mState.mobilePhone = input.target.value}
                    placeholder={dictionary(keyPlaceholderMobile)}
                    type={isDesktop() ? 'text' : 'tel'}
                    />
            </Form.Item>
        ))

        set(formTemplate, 
            'email', (
            <Form.Item {...formItemConfig} label={dictionary(keyEmail)} style={{marginBottom: '0.2rem'}}>
                <SuperInput 
                    defaultValue={mState.email}
                    value={mState.email}
                    onChange={(input) => mState.email = input.target.value}
                    placeholder={dictionary(keyPlaceholderEmail)}
                    />
            </Form.Item>
        ))

        return formTemplate
    }

    const [formItems, setFormsItems] = useState(drawForm())

    const formItemConfig = {
        colon: false
    }

    const restoreAddress = () => {
        const localOrderDetails = localStorage.getItem(S.OrderDetailsMotorDelivery)
        if (isSomething(localOrderDetails) && localOrderDetails !== 'null' && localOrderDetails !== 'undefined') {
            const restore = JSON.parse(localOrderDetails)
            storeAddress.orderDetailsAddressDelivery = {
                selectedAddress2: restore.selectedAddress2,
                selectedCity: restore.selectedCity,
                selectedDistrict: restore.selectedDistrict,
                selectedState: restore.selectedState,
                fullName: restore.fullName,
                address1: restore.address1,
                address2: restore.address2,
                city: restore.city,
                state: restore.state,
                zip: restore.zip,
                mobilePhone: restore.mobilePhone,
                email: restore.email
            }
        }

        localStorage.removeItem(S.OrderDetailsMotorDelivery)

        if (isSomething(storeAddress.orderDetailsAddressDelivery)) {
            entries(storeAddress.orderDetailsAddressDelivery).map((kv) => {
                const [ key, value ] = kv
                mState[key] = value
            })
        }
    }

    const generatePills = () => {
        // get list for all selection
        const listMotorDelivery = get(MotorDelivery, `${CC.Country()}.${checkOutStore.warehouse}`, {})
        // each country have diffecrent configs
        switch (CC.Country()) {
            case K.Indonesia: 
                const listCity = []
                Object.keys(listMotorDelivery).map(key => {
                    listCity.push(key)
                })
                listCity.sort(sortString)
                mOptions.city = listCity

                mState.selectedAddress2 = undefined
                mState.selectedCity = undefined
                mState.selectedDistrict = undefined
                mState.selectedState = undefined

                mOptions.district = []
                mOptions.state = []
                break
        }
    }

    useEffect(
        () => {
            // restore address if possible
            restoreAddress()
            // track every times that address changed
            const autorunDisposer = autorun(
                () => {
                    // declared motor delivery key
                    const keyMotorDelivery = ShippingMethods.getShippingName(checkOutStore.shipping)

                    checkOutStore.billingAddress[keyMotorDelivery].fullName = trim(`${mState.fullName}`)
                    checkOutStore.billingAddress[keyMotorDelivery].firstNameEn = trim(`${mState.fullName}`)
                    checkOutStore.billingAddress[keyMotorDelivery].lastNameEn = ''
                    checkOutStore.billingAddress[keyMotorDelivery].address1 = trim(`${mState.address1}`)
                    checkOutStore.billingAddress[keyMotorDelivery].address2 = trim(`${mState.address2}`)
                    checkOutStore.billingAddress[keyMotorDelivery].city = trim(`${mState.city}`)
                    checkOutStore.billingAddress[keyMotorDelivery].state = trim(`${mState.state}`)
                    checkOutStore.billingAddress[keyMotorDelivery].zip = trim(`${mState.zip}`)
                    checkOutStore.billingAddress[keyMotorDelivery].email = trim(`${mState.email}`)
                    checkOutStore.billingAddress[keyMotorDelivery].mobilePhone = trim(`${mState.mobilePhone}`)
                    checkOutStore.billingAddress[keyMotorDelivery].comment = trim(`${mState.comment}`)

                    // * Any special case for each country
                    switch (CC.Country()) {
                        case K.Indonesia: 
                            // `address2` = district, `state` = village
                            checkOutStore.billingAddress[keyMotorDelivery].address2 = trim(mState.address2)
                            // only Jakarta warehouse has village
                            if (mState.address2 && checkOutStore.warehouse === ShippingMethods.WarehouseDeclaration.jakarta.value) {
                                // if no village should added a space to prevent validate pass
                                checkOutStore.billingAddress[keyMotorDelivery].address2 += mState.state ? `, ${trim(mState.state)}` : ' '
                            }
                            // clear state
                            checkOutStore.billingAddress[keyMotorDelivery].state = ''
                            break
                    }

                    // copy changed to later restored it
                    storeAddress.orderDetailsAddressDelivery = mState
                    props.clearErrors()

                    setFormsItems(drawForm())
                }
            )

            const disposerReactionWarehouse = reaction(
                () => checkOutStore.warehouse,
                () => {
                    generatePills()
                }
            )

            const disposerReactionCity = reaction(
                () => mState.selectedCity,
                (newValue) => {
                    switch (CC.Country()) {
                        case K.Indonesia:
                            // get list for all selection
                            const listMotorDelivery = get(MotorDelivery, `${CC.Country()}.${checkOutStore.warehouse}`, {})
                            const resultListDistrict = get(listMotorDelivery, `${newValue}`)
                            if (isSomething(resultListDistrict)) {
                                const createdList = []
                                if (checkOutStore.warehouse === ShippingMethods.WarehouseDeclaration.surabaya.value) {
                                    // surabaya wasn't have village, so district container only values
                                    Object.values(resultListDistrict).map(value => {
                                        createdList.push(value)
                                    })
                                } else {
                                    Object.keys(resultListDistrict).map(key => {
                                        createdList.push(key)
                                    })
                                }
                                
                                createdList.sort(sortString)
                                mOptions.district = createdList

                                mState.city = newValue
                            } else {
                                mState.city = ''
                                mState.selectedCity = undefined
                            }

                            mState.selectedDistrict = undefined
                            mState.selectedState = undefined
                            mOptions.state = []
                            break
                    }

                    refGenerator.current.forcedUpdate()
                }
            )

            const disposerReactionDistrict = reaction(
                () => mState.selectedDistrict,
                (newValue) => {
                    switch (CC.Country()) {
                        case K.Indonesia:
                            if (checkOutStore.warehouse === ShippingMethods.WarehouseDeclaration.surabaya.value) {
                                mState.address2 = newValue
                            } else {
                                // get list for all selection
                                const listMotorDelivery = get(MotorDelivery, `${CC.Country()}.${checkOutStore.warehouse}`, {})
                                const resultListVillage = get(listMotorDelivery, `${mState.selectedCity}.${newValue}`)
                                if (isSomething(resultListVillage)) {
                                    const createdList = []
                                    Object.values(resultListVillage).map(value => {
                                        createdList.push(value)
                                    })
                                    createdList.sort(sortString)
                                    mOptions.state = createdList

                                    mState.address2 = newValue
                                } else {
                                    mState.address2 = ''
                                    mState.selectedDistrict = undefined
                                }
                                mState.selectedState = undefined
                            }
                            break
                    }

                    refGenerator.current.forcedUpdate()
                }
            )

            const disposerReactionState = reaction(
                () => mState.selectedState,
                (newValue) => {
                    mState.state = newValue
                    refGenerator.current.forcedUpdate()
                }
            )

            generatePills()

            // mockup filled forms
            if (hasQueryString('sleepy')) {
                switch (CC.CountryCode()) {
                    case CountryCode.indonesia:
                        mState.fullName = 'Carter Wilson'
                        mState.address1 = '445 Mount Eden Road'
                        mState.address2 = ''
                        mState.city = 'Depok'
                        mState.state = ''
                        mState.zip = randomNumber(5, false)
                        mState.mobilePhone = `${randomNumber(10)}`
                        mState.email = `${randomString(8, true, true, true)}@unicity.com`
                        break
                    default: break
                }
            }

            return () => {
                // dispose watcher
                autorunDisposer()
                disposerReactionWarehouse()
                disposerReactionCity()
                disposerReactionDistrict()
                disposerReactionState()
            }
        }, []
    )
    
    return useObserver(() =>
        <Layout>
            <Row>
                <Col span={24}>
                    <When condition={isSomething(formItems)}>
                        <Form layout="vertical">
                            <FormItemGenerator 
                                ref={refGenerator}
                                type={K.MotorDelivery} 
                                formItems={isSomething(formItems) ? formItems : null}
                            />
                        </Form>
                    </When>
                </Col>
            </Row>
        </Layout>
    )
}

export default FormMotorDelivery