import React from 'react';
import { useObserver } from 'mobx-react';
import Loadable from 'react-loadable';
import { Skeleton } from 'antd';

const TemplatePortal = ({ type, country }) => {
    
    if(country == 'Hong Kong'){
        country = 'HK';
    }

    const DynamicForm = Loadable({
        loader: () => import(`./${country}/${type}`),
        loading: () => <div style={{ display: 'block', height: 500 }}></div>
        /* <Skeleton title={true} paragraph={{ rows: 10 }} active></Skeleton> */
    })

    return useObserver(() => 
        <div>
            <DynamicForm/>
        </div>
    )
}

export default TemplatePortal