/* eslint-disable no-useless-catch */

import { apiURL } from '../ShoppingHelper'
import Raven from '../../services/Raven'
import { getCountryCode3, getProductCountryCode3 } from '@GlobalHelpers'
import axios from 'axios'
import { loge, logn } from '../utils/PikaLog'
import { isSomething } from '../utils/Utils'
import { getCartStorage } from '../utils/Customer'
import { Country } from '@Stores/StoreConfigCountry'

export const getProductData = (callback, price_level, warehouse, hydra) => {
    let url = 'https://member-calls.unicity.com/api/unishop/v1/global/getproducts'

    const params = {
        country_code: getProductCountryCode3()
    }

    if (warehouse) {
        params.warehouse = warehouse
    }

    if (hydra === false) {
        params.secret = 'yes'
        params.price_level = price_level
    }

    const headers = {
        'Content-Type': 'application/json'
    }

    axios(url, { params: params, headers: headers })
        .then(data => {
            callback(true, data.data)
        })
        .catch(error => {
            if (isSomething(error)) {
                callback(false, error)
            }
        })
}

export const getCart = (callback, user_id) => {
    // Storage Cart
    let fireCart = {}
    let localCart = getCartStorage()
    if (isSomething(localCart)) {
        logn('HAS LOCAL CART AND LOAD CART FROM API')
        localCart.map((b, i) => {
            fireCart[b.id] = {}
            fireCart[b.id].id = b.id
            fireCart[b.id].qty = b.qty
        })
        updateCart(user_id, fireCart, (success, result) => {
            callback(true, result)
        })
    } else {
        logn('LOAD CART FROM API')
        axios
            .get(`${apiURL.cart.get}/${user_id}`)
            .then(data => {
                callback(true, data.data)
            })
            .catch(error => {
                if (isSomething(error)) {
                    callback(false, error)
                }
            })
    }
}

const updateCart = (user_id, cart, callback) => {
    let data = {
        ba_id: user_id,
        cart: cart
    }

    axios
        .post(apiURL.cart.add, JSON.stringify(data))
        .then(data => {
            logn('UPDATED CART', data.data)
            callback(true, data.data)
        })
        .catch(error => {
            if (isSomething(error)) {
                callback(false, error)
            }
        })
}

export const validateFormatEnroll = (data, country = null) => {
    let countryCode3 = ''
    if (country !== null) {
        countryCode3 = country
    } else {
        countryCode3 = Country.getCode3()
    }

    try {
        const result = axios({
            method: 'POST',
            url: `https://member-calls2.unicity.com/format/${countryCode3}/enroll/v2/address`,
            data: data
        })

        return result
    } catch (err) {
        throw err
    }
}

export const updateFormatEnroll = (data, ref) => {
    const countryCode3 = Country.getCode3()
    try {
        const result = axios({
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            url: `https://member-calls2.unicity.com/format/${countryCode3}/enroll/update/${ref}`,
            data: data
        })

        return result
    } catch (err) {
        throw err
    }
}

export const getFormatEnroll = (uuid, country = null) => {
    let countryCode3 = ''
    if (country !== null) {
        countryCode3 = country
    } else {
        countryCode3 = Country.getCode3()
    }

    let url = `https://member-calls2.unicity.com/format/${countryCode3}/enroll/detail-v2/${uuid}`

    if (countryCode3 === 'TWN') url = `https://member-calls2.unicity.com/format/${countryCode3}/enroll/detail-test/${uuid}`

    try {
        const result = axios({
            method: 'get',
            url: url
        })

        return result
    } catch (err) {
        throw err
    }
}

export const getFormatEnrollSummaryForTWOnly = (uuid, ordercal) => {
    let url = `https://member-calls2.unicity.com/format/TWN/enroll/detail-test/${uuid}?ordercalC=${ordercal ? 1 : 0}`

    try {
        const result = axios({
            method: 'get',
            url: url
        })

        return result
    } catch (err) {
        throw err
    }
}

export const getFormatEnrollSummary = (uuid, ordercal) => {
    const countryCode3 = Country.getCode3()

    let url = `https://member-calls2.unicity.com/format/${countryCode3}/enroll/detail-v2/${uuid}?ordercalC=${ordercal ? 1 : 0}`

    try {
        const result = axios({
            method: 'get',
            // url: `https://member-calls2.unicity.com/etl/TWN/enroll/detail/${uuid}?ordercalC=${ordercal ? 1 : 0}`,
            url: url
        })

        return result
    } catch (err) {
        throw err
    }
}

export const calculateOrderV2 = data => {
    try {
        const result = axios({
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            url: 'https://member-calls2.unicity.com/ordercalc',
            data: data
        })
        return result
    } catch (err) {
        throw err
    }
}

export const validateFormatAutoship = (data, token) => {
    const countryCode3 = Country.getCode3()
    try {
        const result = axios({
            method: 'post',
            headers: { Authorization: `Bearer ${token}` },
            url: `https://member-calls2.unicity.com/format/${countryCode3}/autoship/v2/address`,
            data: data
        })
        return result
    } catch (err) {
        throw err
    }
}

export const getFormatAutoshipSummary = (uuid, ordercal) => {
    const countryCode3 = Country.getCode3()
    try {
        const result = axios({
            method: 'get',
            url: `https://member-calls2.unicity.com/format/${countryCode3}/autoship/detail-v2/${uuid}?ordercalC=${ordercal ? 1 : 0}`
        })
        return result
    } catch (err) {
        throw err
    }
}

export const updateCacheAutoship = token => {
    try {
        const result = axios({
            method: 'get',
            headers: { 'Content-Type': 'application/json', 'authorization-hydra': `Bearer ${token}` },
            url: 'https://member-calls2.unicity.com/etlV2/autoship?ushopCountryCode=JPN&collapse=hydra&byPassCache=1'
        })
        return result
    } catch (err) {
        throw err
    }
}

export const createAutoshipTracking = (data, token) => {
    try {
        const result = axios({
            method: 'post',
            headers: { Authorization: `Bearer ${token}` },
            url: 'https://member-calls2.unicity.com/proxy/autoship/create',
            data: data
        })
        return result
    } catch (err) {
        throw err
    }
}

export const createAutoshipTrackingV2 = (data, token) => {
    try {
        const result = axios({
            method: 'post',
            headers: { Authorization: `Bearer ${token}` },
            url: 'https://member-calls2.unicity.com/proxy/autoship/createV2',
            data: data
        })
        return result
    } catch (err) {
        throw err
    }
}

export const createMenaAccount = (data) => {
    try {
        const result = axios({
            method: 'POST',
            url: 'https://member-calls.unicity.com/api/unishop/v1/MENA/validate_and_payment/enroll',
            data: data
        })
        return result
    } catch (err) {
        throw err
    }
}
