import * as K from './Keywords';
import { throatField } from './ConfigsHeader';
import { isNullOrUndefined, isNothing } from '../utils/Utils';
import get from 'lodash/get';

/** Control the visibility of all **AddressBook** functional both local and global. */
export const AddressBook = {
    /** Control the visibility of **AddressBook** in these country. */
    Local() {
        return {
            [K.All]: true,
            [K.Vietnam]: false
        }
    },
    /** Customers from these country ***won't*** able to use an **AddressBook**, wherever they go. */
    Global() {
        return {
            [K.All]: true,
            [K.Vietnam]: false
        }
    }
}

/** Control the visibility of all **EmailEdit** functional both local and global. */
export const EmailEdit = {
    /** Control the visibility of **EmailEdit** on profile page of these country. */
    Local() {
        return {
            [K.All]: true,
            [K.Vietnam]: false
        }
    },
    /** Customers from these country ***won't*** able to use an **EmailEdit**, wherever they go. */
    Global() {
        return {
            [K.All]: true,
            [K.Vietnam]: false
        }
    }
}

/** How to use ShippingAddress.
 * @example
 * /// with warehouse
 * pickUp: {
 *      country: {
 *          warehouse1: { ... },
 *          warehouse2: { ... }
 *      }
 * }
 * /// without warehouse
 * pickUp: {
 *      country: {
 *          { ... }
 *      }
 * }
 */
export const ShippingAddress = {
    aramex: {
        [K.Singapore]: {
            address1: 'Aramax (AIC Dept.) 3 Changi South Street 1, #01-01',
            address2: 'Santa United International  Bldg.',
            city: 'Singapore',
            state: '',
            zip: '486795',
            country: 'SG',
            mobilePhone: '6565430300'
        }
    },
    pickUp: {
        [K.Thailand]: {
            TH: {
                fullName: 'Bangkok, Main Office',
                address1: '44/1 ชั้น 1 และชั้น 15 อาคารรุ่งโรจน์ธนกุล',
                address2: 'ถนนรัชดาภิเษก เขตห้วยขวาง',
                city: 'กรุงเทพมหานคร',
                country: 'TH',
                zip: '10310'
            },
            EN: {
                fullName: 'Bangkok, Main Office',
                address1: '44/1 Ground floor and 15 floor',
                address2: 'Rungrojthanakul Building Ratchadapisek Road, Huaykwang',
                city: 'Bangkok',
                country: 'TH',
                zip: '10310'
            }
        },
        [K.Singapore]: {
            fullName: 'Singapore Main Office',
            address1: '3 Temasek Boulevard',
            address2: '#01-351, Suntec City Mall (West Wing)',
            city: 'Singapore',
            state: '',
            zip: '038983',
            country: 'SG'
        },
        [K.Indonesia]: {
            jakarta: {
                fullName: 'Jakarta Office',
                address1: 'Gedung Perkantoran Bidakara 2 Lt.15 Unit 2',
                address2: 'Jl. Jend. Gatot Subroto Kav 71-7',
                city: 'Jakarta Selatan',
                state: '',
                zip: '12870',
                country: 'ID',
            },
            surabaya: {
                fullName: 'Surabaya Office',
                address1: 'Intiland Tower Surabaya 1st floor Suite 1',
                address2: 'Jl. Panglima Sudirman No.101-103, Embong Kaliasin,',
                city: 'KEC. Genteng - Kota Surabaya - Jawa Timur',
                state: '',
                zip: '60271',
                country: 'ID'
            }
        },
        [K.Hongkong]: {
            HK: {
                fullName: 'Main Office',
                address1: 'Room 1118, 11/F Leighton Centre',
                address2: '77 Leighton Road',
                city: 'Causeway Bay, ',
                country: 'HK',
                zip: ''
            },
            EN: {
                fullName: 'Main Office',
                address1: 'Room 1118, 11/F Leighton Centre',
                address2: '77 Leighton Road',
                city: 'Causeway Bay',
                country: 'HK',
                zip: ''
            }
        },
        [K.Philippines]: {
            EN: {
                fullName: 'Taguig City, Main Office',
                address1: 'Ground Floor 8/10 Upper McKinley Building',
                address2: 'Upper McKinley Road, McKinley Town Center',
                city: 'Taguig City',
                country: 'PH',
                zip: '1201',
                comment: 'Pick-Up Taguig'
            }
        },
        [K.Taiwan]: {
            TW: {
                fullName: 'Taipei, Main Office',
                address1: 'No. 141, Section 1',
                address2: '',
                city: '台北',
                country: 'TW',
                zip: '110',
                comment: ''
            },
            EN: {
                fullName: 'Taipei, Main Office',
                address1: 'No. 141, Section 1',
                address2: '',
                city: '台北',
                country: 'TW',
                zip: '110',
                comment: ''
            }
        }
    },
    motorDelivery: {
        [K.Indonesia]: {
            jakarta: {
                province: 'Jadetabek'
            },
            surabaya: {
                province: 'Java Timur'
            }
        }
    }
}

// Address order form config
export const FormUsage = {
    AddressBook: (form, country) => {
        const list = {
            name: { [K.All]: true },
            country: {
                [K.All]: true,
                [K.MENA]: false
            },
            address1: { [K.All]: true },
            address2: { [K.All]: true },
            district: {
                [K.Indonesia]: true,
                [K.Hongkong]: true
            },
            city: {
                [K.Australia]: true,
                [K.Newzealand]: true
            },
            state: {
                [K.Australia]: true,
                [K.Newzealand]: true
            },
            province: {
                [K.Cambodia]: true,
                [K.Indonesia]: true,
                [K.Philippines]: true,
                [K.Thailand]: true,
                [K.Vietnam]: true
            },
            landmark: {},
            comment: {},
            zip: {
                [K.All]: true,
                [K.Hongkong]: false
            }
        }

        return throatField(list[form], country)
    },
    Delivery: (form, country) => {
        const list = {
            country: {
                [K.All]: true,
                [K.MENA]: false
            },
            address1: { [K.All]: true },
            address2: { [K.All]: true },
            district: {
                [K.Indonesia]: true,
                [K.Hongkong]: true
            },
            city: {
                [K.Malaysia]: true,
                [K.Australia]: true,
                [K.Newzealand]: true
            },
            state: {
                [K.Newzealand]: true
            },
            province: {
                [K.Thailand]: true,
                [K.Indonesia]: true,
                [K.Philippines]: true,
            },
            comment: {},
            zip: {
                [K.All]: true,
                [K.Hongkong]: false
            },
            mobile: { [K.All]: true },
            email: { [K.All]: true },
            // other html
            hr: { [K.All]: true }
        }

        return throatField(list[form], country)
    },
    PickUp: (form, country) => {
        const list = {
            comment:    { [K.All]: false },
            name:      { [K.All]: true },
            zip:        { [K.All]: true },
            mobile:     { [K.All]: true },
            email:      { [K.All]: true },
            referralId:   { [K.All]: true },
        }

        return throatField(list[form], country)
    },
    Aramex: (form, country) => {
        const list = {
            country: { [K.All]: true },
            address1: { [K.All]: true },
            address2: { [K.All]: true },
            district: { [K.All]: false },
            city: { [K.All]: false },
            state: { [K.All]: false },
            province: { [K.All]: false },
            comment: { [K.All]: false },
            zip: { [K.All]: true },
            mobile: { [K.All]: true },
            email: { [K.All]: true },
            sns1: { [K.All]: true },
            sns2: { [K.All]: true },
        }

        return throatField(list[form], country)
    },
    MotorDelivery: (form, country) => {
        const list = {
            name: { [K.All]: true },
            country: { [K.All]: true },
            address1: { [K.All]: true },
            address2: {
                [K.All]: true,
                [K.Indonesia]: false
            },
            district: {
                [K.All]: false,
                [K.Indonesia]: true
            },
            city: {
                [K.All]: false,
                [K.Indonesia]: true
            },
            state: {
                [K.All]: false,
                [K.Indonesia]: true
            },
            province: {
                [K.All]: false,
                [K.Indonesia]: true
            },
            comment: { [K.All]: false },
            zip: { [K.All]: true },
            mobile: { [K.All]: true },
            email: { [K.All]: true }
        }

        return throatField(list[form], country)
    }
}

/** Return an array of string which used to adjust all of form inputs arrangement, depend on country and type.
 *  by country and shipping method. */
export const AddressArrangement = (country, type) => {
    /* How to use Dynamic Addressbook:
    ** [1] use `_` to add sub ID such as `name_jp` <- this `name` form will be checked with FormUsage() function (use or not use)
    ** you also need to added `name_jp` in formItems form too.
    ** [2] add `#s` to make this form small (in half-size), you need to add these forms in pair (e.g. 2, 4 or 6) 
    ** or it will not display when without its pair. */
    const template = {
        [K.AddressBook]: {
            // Primary Country
            [K.Thailand]: ['name', 'address1', 'address2', 'province_th', 'country#s', 'zip#s'],
            [K.Singapore]: ['name', 'address1', 'address2', 'country#s', 'zip#s'],
            [K.Indonesia]: ['name', 'address1', 'address2_id', 'district_id', 'province_id', 'country#s', 'zip#s'],
            [K.Philippines]: ['name', 'address1', 'address2', 'province', 'country#s', 'zip#s'],
            [K.Japan]: ['name', 'country#s', 'zip#s', 'address1', 'address2'],
            // Oceania Country
            [K.Australia]: ['name', 'address1', 'address2', 'city', 'state', 'country#s', 'zip#s'],
            [K.Newzealand]: ['name', 'address1', 'address2', 'city', 'state', 'country#s', 'zip#s'],
            // New Phase Country
            [K.Hongkong]: ['name', 'address1', 'address2_hk', 'district_hk', 'country'],
        },
        [K.Delivery]: {
            [K.Thailand]: ['name', 'country', 'zip', 'province_th', 'address2', 'address1', 'email', 'mobile'],
            [K.Australia]: ['name', 'address1', 'address2', 'city', 'country#s', 'zip#s', 'mobile#s', 'email#s'],
            [K.Newzealand]: ['name', 'address1', 'address', 'city', 'state', 'country#s', 'zip#s', 'mobile#s', 'email#s'],
            [K.Malaysia]: ['name', 'address1', 'address2', 'city', 'country#s', 'zip#s', 'mobile#s', 'email#s'],
            [K.Indonesia]: ['name', 'address1', 'address2_id', 'district_id', 'province_id', 'country#s', 'zip#s', 'mobile#s', 'email#s'],
            [K.Philippines]: ['name', 'address1', 'address2', 'city', 'province', 'country#s', 'zip#s', 'mobile#s', 'email#s'],
            [K.Japan]: ['name', 'country#s', 'zip#s', 'address1', 'address2', 'mobile#s', 'email#s'],
            [K.Hongkong]: ['name', 'address1', 'address2_hk', 'district_hk', 'country', 'mobile#s', 'email#s']
        },
        [K.PickUp]: {
            // use fallback value
        },
        [K.Aramex]: {
            [K.Singapore]: ['name', 'sns1', 'sns2', 'zip', 'address1', 'email', 'mobile']
        },
        [K.MotorDelivery]: {
            [K.Indonesia]: ['name', 'country', 'zip', 'province_id', 'city_id', 'district_id', 'state_id', 'address1', 'email', 'mobile']
        }
    }

    const fallbackTemplate = {
        [K.AddressBook]: [
            'name', 'address1', 'address2', 'district', 'city',
            'province', 'state', 'country#s', 'zip#s'
        ],
        [K.Delivery]: [
            'name', 'country', 'zip', 'province', 'city', 'district', 'state', 'address1', 'address2', 'email', 'mobile'
        ],
        [K.PickUp]: [
            'name', 'email', 'mobile' , 'referralId'
        ],
        [K.Aramex]: [
            'name', 'sns1', 'sns2', 'zip', 'province', 'city', 'district', 'state', 'address1', 'address2', 'email', 'mobile',
        ],
        [K.MotorDelivery]: [
            'name', 'country', 'zip', 'province', 'city', 'district', 'state', 'address1', 'email', 'mobile'
        ]
    }

    // return result of arrangement if existed, return fallback template if null, undefined or empty.
    return get(template, `${type}.${country}`, fallbackTemplate[type])
}

export const DefaultCity = {
    [K.Thailand]: 'Bangkok',
    [K.Malaysia]: 'Kuala Lumpur',
    [K.Singapore]: '',
    [K.Philippines]: 'Manila',
    [K.Indonesia]: '',
    [K.Australia]: 'Canberra',
    [K.Newzealand]: 'Wellington',
    [K.Japan]: '',
    [K.Vietnam]: 'Hojimin',
    [K.Hongkong]: 'Central & Western District',
    [K.Korea]: 'Seoul',
}

export const MotorDelivery = {
    [K.Indonesia]: {
        'jakarta': {
            'Jakarta Pusat': {
                'Gambir': ['Gambir', 'Kebon Kalapa', 'Petojo Utara', 'Duri Pulo', 'Cideng', 'Petojo Selatan'],
                'Tanah Abang': ['Bendungan Hilir', 'Karet Tengsin', 'Kebon Melati', 'Kebon Kacang', 'Kampung Bali', 'Petamburan', 'Gelora'],
                'Menteng': ['Menteng', 'Pegangsaan', 'Cikini', 'Kebon Sirih', 'Gondangdia'],
                'Senen': ['Senen', 'Kwitang', 'Kenari', 'paseban', 'Kramat', 'Bungur'],
                'Cempaka Putih': ['Cempaka Putih Timur', 'Cempaka Putih Barat', 'Rawa Sari'],
                'Johar Baru': ['Galur', 'tanah Tinggi', 'Kampung Rawa', 'Johar Baru'],
                'Kemayoran': ['Gunung Sahari Selatan', 'kemayoran', 'Kebon Kosong', 'Cempaka Baru', 'Harapan Mulia', 'Sumur Batu', 'Serdang', 'Utan Panjang'],
                'Sawah Besar': ['Pasar Baru', 'Gunung Sahari Utara', 'Mangga Dua Selatan', 'Karang Anyar', 'Kartini'],
            },
            'Jakarta Barat': {
                'Taman Sari': ['Pinangsia', 'Glodok', 'Keagungan', 'Krukut', 'Taman Sari', 'Mahpar', 'Tangki', 'Mangga Besar'],
                'Tambora': ['Tanah Sereal', 'Tambora', 'Roa Malaka', 'Pekojan', 'Jembatan Lima', 'Kerendang', 'Duri Selatan', 'Duri Utara', 'Kali Anyar', 'Jembatan Besi', 'Angke'],
                'Pal Merah': ['Slipi', 'Kota Bambu Selatan', 'Kota Bambu Utara', 'Jati Pulo', 'Kemanggisan', 'Pal Merah'],
                'Grogol Petamburan': ['Tomang', 'Grogol', 'Jelambar', 'Jelambar Baru', 'Wijaya Kusuma', 'Tanjung Duren Selatan', 'Tanjung Duren Utara'],
                'Kebon Jeruk': ['Duri Kelapa', 'Kedoya Selatan', 'Kedoya Utara', 'Kebon Jeruk', 'Sukabumi Utara', 'Kelapa Dua', 'Sukabumi Selatan'],
                'Kembangan': ['Kembangan Selatan', 'Kembangan Utara', 'Meruya Utara', 'Srengseng', 'Joglo', 'Meruya Selatan'],
                'Cengkareng': ['Kedaung Kali Angke', 'Kapuk', 'Cengkareng Barat', 'Cengkareng Timur', 'Rawa Buaya', 'Duri Kosambi'],
                'Kali Deres': ['Kamal', 'Tegal Alur', 'Pegadungan', 'Kali Deres', 'Semanan'],
            },
            'Jakarta Selatan': {
                'Kebayoran Baru': ['Selong', 'Gunung', 'Kramat Pela', 'Gandaria Utara', 'Cipete Utara', 'Melawai', 'Pulo', 'Petogogan', 'Rawa Barat', 'Senayan'],
                'Kebayoran Lama': ['Grogol Utara', 'Grogol Selatan', 'Cipulir', 'Kebayoran Lama Selatan', 'Kebayoran Lama Utara', 'Pondok Pinang'],
                'Kec. Pesanggrahan': ['Ulujami', 'Petukangan Utara', 'Petukangan Selatan', 'Pesanggrahan', 'Bintaro'],
                'Cilandak': ['Cipete Selatan', 'Gandaria Selatan', 'Cilandak Barat', 'Lebak Bulus', 'Pondok Labu'],
                'Pasar Minggu': ['Pejaten Barat', 'Pejaten Selatan', 'Kebagusan', 'Pasar Minggu', 'Jati Padang', 'Ragunan', 'Cilandak Timur'],
                'Jagakarsa': ['Tanjung Barat', 'Lenteng Agung', 'Jagakarsa', 'Ciganjur', 'Cipendak', 'Srengseng Sawah'],
                'Mampang Prapatan': ['Kuningan Barat', 'Kuningan Timur', 'Bangka', 'Pancoran', 'Mampang Prapatan', 'Tegal Parang'],
                'Pancoran': ['KaliBata', 'Rawa jati', 'Duren Tiga', 'Cikoko', 'Pengadegan'],
                'Tebet': ['Tebet Barat', 'Tebet Timur', 'Kebon Baru', 'Manggarai', 'Manggarai selatan', 'Menteng Dalam'],
                'Setia Budi': ['Setia Budi', 'Karet', 'Karet semanggi', 'Karet Kuningan', 'Kuningan Timur', 'Menteng Atas', 'pasar Manggis', 'Guntur']
            },
            'Jakarta Utara': {
                'Cilincing': ['Cilincing', 'Semper Barat', 'Semper Timur', 'Rorotan', 'Sukapura'],
                'Koja': ['Koja Utara', 'Koja Selatan', 'Rawa Badak', 'Tugu Selatan', 'Tugu Utara'],
                'Kelapa Gading': ['Kelapa Gading Timur', 'Pegangsaan Dua'],
                'Tanjung Priok': ['Tanjung Priok', 'Kebon Bawang', 'Sungai Bambu', 'Papanggo', 'Warakas', 'Sunter Agung', 'Sunter jaya'],
                'Pademangan': ['Pademangan Timur', 'Pademangan Barat', 'Ancol'],
                'Penjaringan': ['Penjaringan', 'Penjagalan', 'Pluit', 'Kapuk Muara', 'Kamal Muara']
            },
            'Jakarta Timur': {
                'Matraman': ['Utan Kayu Selatan', 'Utan Kayu Utara', 'Kayu Manis', 'Pal Meriam', 'Kebon Manggis'],
                'Pulo Gadung': ['Kayu Putih', 'Jati', 'Rawa Mangun', 'Pisangan Timur'],
                'Jatinaegara': ['Kampung Melayu', 'Bidara Cina', 'Cipinang Cempedak', 'Rawa Bunga', 'Cipinang Besar Selatan', 'Cipinang Besar Utara'],
                'Duren Sawit': ['Pondok Bambu', 'Duren Sawit', 'Pondok Kelapa', 'Malak Jaya', 'Malaka sari', 'Pondok Kopi', 'Klender', 'Kramat Jati', 'Batu Ampar', 'Bale Kambang', 'Kampung Tengah', 'Dukuh', 'Cawang', 'Cililitan'],
                'Makasar': ['Pinang Ranti', 'Makasar', 'Halim Perdana Kusuma', 'Cipinang Melayu', 'Kebon Pala'],
                'Pasar Rebo': ['Gendong', 'Cijantung', 'Baru', 'Kalisari'],
                'Ciracas': ['Cibubur', 'Kelapa Dua Wetan', 'Ciracas', 'Susukan', 'Rambutan'],
                'Cipayung': ['Lubang Buaya', 'Ceger', 'Cipayung', 'Pondok rangon', 'Cilangkap', 'Setu', 'Bambu Apus'],
                'Kec. Cakung': ['Cakung Barat', 'Cakung Timur', 'Rawa Terate', 'Jatinegara', 'Penggilingan', 'Pulo Gebang', 'Ujung Menteng'],
            },
            'Depok': {
                'Sukma Jaya': ['Mekar Jaya', 'Kali Mulya', 'Cilodong', 'Kali Baru', 'Cisalak', 'Abadi Jaya', 'Bhakti Jaya'],
                'Beji': ['Beji Barat', 'Beji Timur', 'Kemiri Muka', 'Pondok Cina', 'Kukusan', 'Tanah Baru', 'Citayam (depok)'],
                'Pancoran Mas': ['Depok', 'Depok Jaya', 'Mampang', 'Pancoran Mas'],
                'Limo': ['Desa Krukut', 'Desa Gandul', 'Desa Pangkalan Jati', 'Desa pangkalan Jati Baru', 'Desa Cinere'],
            },
            'Tangerang': {
                'Tangerang': ['Sukaasih', 'Sukarasa', 'Cikokol', 'Kelapa Indah', 'Sukasari', 'Babakan', 'Buaran Indah', 'Tanah Tinggi'],
                'Karawaci': ['Koang Jaya', 'Nambo Jaya', 'Pabuaran Tumpeng', 'Pasar Baru', 'Bugel', 'Gerendeng', 'MargaSari', 'Suka Jadi', 'Cimone', 'Cimone Baru', 'Pabuaran', 'Sumur Pancing', 'Bojong Jaya', 'Karawaci', 'Karawaci Baru', 'Nusa Jaya'],
                'Batu Ceper': ['Batu Sari', 'Poris Gaga', 'Poris Gaga Baru', 'Batu Ceper', 'Kebon Besar', 'Poris Jaya'],
                'Negla Sari': ['Karang Anyar', 'Selapang Jaya', 'Kedaung', 'Kedaung Wetan', 'Mekar Sari', 'Negla Sari'],
                'Benda': ['Belendung', 'Jurumudi Baru', 'Jurumudi', 'Benda', 'Panjang'],
                'Jatiuwung': ['roncong (k.permai saja)'],
                'Cipondoh': ['Poris Plawad', 'Poris Plawad Indah', 'Poris Plawad Utara', 'Kenanga', 'Gondrong', 'Petir', 'Ketapang', 'Cipondoh', 'Cipondoh Indah', 'Cipondoh Makmur'],
                'Pinang': ['Cipete', 'Pakojan', 'Panungganan Selatan', 'Panungganan Timur', 'Panungganan Utara', 'Kunciran', 'Kunciran Indah', 'Kunciran Jaya', 'Narogtog', 'Pinang', 'Sudimara Pinang'],
                'Ciledug': ['Sudimara Barat', 'Sudimara Jaya', 'Sudimara Selatan', 'Sudimara Timur', 'Tajur', 'Paninggilan', 'Paninggilan Utara', 'Parung Serab'],
                'Larangan': ['Gaga', 'Larangan Indah', 'Larangan Utara', 'Cipadu', 'Cipadu jaya', 'Larangan Selatan', 'Kreo', 'Kreo Selatan'],
                'Karang Tengah': ['Karang Mulya', 'Karang Tengah', 'Karang Timur', 'Pendurenan', 'Pondok Pucung', 'Parung Jaya', 'Pondok Bahar'],
                'Pondok Aren': ['Pondok Betung', 'Jurangmangu Timur', 'Jurangmangu Barat', 'Pondok Aren', 'Pondok Karya Kacang', 'Pondok Karya Barat', 'Pondok Karya Timur', 'Parigi', 'Parigi Baru', 'Pondok Pucung (pdk aren)'],
                'BSD': ['Serpong', 'Muncul (hanya puspitek saja)', 'Setu', 'Babakan', 'Rawa Buntu (Rawa Mekar Jaya)', 'Lengkong Gudang', 'Lengkong Wetan', 'Jelupang', 'Paku jaya', 'Pakulonan', 'Pondok Jagung'],
                'Ciputat': ['Cipayung', 'Ciputat', 'Cempaka Putih', 'Pondok Ranji', 'Rempoa', 'Rengas', 'Sawah Baru', 'Sawah Lama'],
                'Pamulang': ['Jombang', 'Sarua', 'Sarua Indah', 'Bambu Apus', 'Kedaung', 'Pondok Benda Baru', 'Pondok Benda Lama', 'Pamulang Barat', 'Pamulang Timur', 'Pondok Cabe Ilir', 'Pondok Cabe Udik', 'Cirendeu', 'Pisangan'],
                'Curug': ['Bencongan( Lippo Karawaci)'],
            },
            'Bekasi': {
                'Bekasi Timur': ['Duren Jaya', 'Aren jaya', 'Bekasi Jaya', 'Margahayu', 'Rawa lumbu', 'Jati Mulya'],
                'Bekasi Utara': ['Teluk Pucung', 'Perwira', 'Harapan Baru', 'Harapan Jaya', 'Kaliabang Tengah'],
                'Bekasi Barat': ['Kota Baru', 'Bintara', 'Kranji', 'Bintara Jaya', 'Jaka Sampurna'],
                'Bekasi Selatan': ['Kayu Ringin Jaya', 'Jaka Mulya', 'Jaka Setia', 'Pekayon Jaya'],
                'Medan Satria': ['Pejuang', 'Medan Satria', 'Kali Baru'],
                'Pondok Gede': ['Jatiwaringin', 'Jati Bening', 'Jati Makmur', 'Jati Rahayu', 'Jati Warna', 'Jati Kramat', 'Jati Asih'],
            }
        },
        'surabaya': {
            'Kota Surabaya': [
                'Surabaya', 'Asemrowo', 'Benowo', 'Bubutan', 'Bulak', 'Dukuh Pakis', 'Gayungan', 'Genteng (Surabaya)', 'Gubeng', 'Gununganyar',
                'Jambangan', 'Karangpilang', 'Kenjeran', 'Krembangan', 'Lakar Santri', 'Mulyorejo', 'Pabean Cantikan', 'Pakal', 'Rungkut',
                'Sambikerep', 'Sawahan (Surabaya)', 'Semampir', 'Simokerto', 'Sukolilo (Surabaya)', 'Sukomanunggal', 'Tambaksari (Surabaya)',
                'Tandes', 'Tegalsari (Surabaya)', 'Tenggilis Mejoyo', 'Wiyung', 'Wonocolo', 'Wonokromo'
            ],
            'Kab. Sidoarjo': [
                'Sidoarjo', 'Balongbendo', 'Buduran', 'Candi', 'Gedangan (Sidoarjo)', 'Jabon', 'Krembung', 'Krian', 'Porong', 'Prambon (Sidoarjo)',
                'Sedati', 'Sukodono (Sidoarjo)', 'Taman (Sidoarjo)', 'Tanggulangin', 'Tarik', 'Tulangan', 'Waru (Sidoarjo)', 'Wonoayu'
            ]
        }
    }
}