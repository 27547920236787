import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import mobiscroll from '@mobiscroll/react'
import { DateTime } from 'luxon'
import { staticFooter } from '@Stores/MainStore'
import './mobi.scss'
import styled, { css } from 'styled-components'
import { toBool } from '@Components/utils/Utils'

mobiscroll.settings = {
    theme: 'ios',
    themeVariant: 'light'
}

const StyledWrapperSelect = styled.div`
    //
`

const Mobi = props => observer(() => <div>tets</div>)

const Select = observer(
    (props, ref) => {
        const { filterPlaceholderText, value, data, buttons, onSet, maxWidth, filter, rows, height, placeholder, className, display, touchUi, disabled, dataText, dataHtml, onFilter, hideSelection, wrapperStyle } = props

        const extraProps = {}

        if (onFilter) {
            extraProps.onFilter = onFilter
        }

        return (
            <StyledWrapperSelect className={`wrapper-mobi ${disabled ? 'disabled-handmake' : ''}`} style={wrapperStyle || {}}>
                <mobiscroll.Select
                    ref={ref}
                    // display="bubble"
                    display={display ? display : 'center'}
                    filter={filter == false ? false : true}
                    touchUi={touchUi == true ? true : false}
                    filterPlaceholderText={filterPlaceholderText}
                    value={value}
                    data={data}
                    height={height ? height : 35}
                    maxWidth={maxWidth ? maxWidth : 350}
                    buttons={buttons}
                    // buttons={false}
                    className={className ? className : ''}
                    onSet={onSet}
                    rows={rows ? rows : 7}
                    focusOnClose={false}
                    placeholder={placeholder ? placeholder : ''}
                    disabled={disabled ? disabled : false}
                    dataText={dataText || 'text'}
                    dataHtml={dataHtml || 'html'}
                    cssClass={`wrapper-top-mobi-select ${hideSelection ? 'hide-selection' : ''}`}
                    {...props}
                    {...extraProps}

                    // onSelected={(e) => this.onSelected(e)}
                >
                    <mobiscroll.Input dropdown={true} />
                </mobiscroll.Select>
            </StyledWrapperSelect>
        )
    },
    { forwardRef: true }
)

const SelectMoblie = observer(props => {
    const { data, buttons, onSet, value, disabled, multiline, height, maxWidth, cssClass } = props

    return (
        <div className={`wrapper-mobi`}>
            <mobiscroll.Select buttons={buttons} display="center" value={value} data={data} onSet={onSet} disabled={disabled} multiline={multiline} height={height} maxWidth={maxWidth} cssClass={cssClass}>
                <mobiscroll.Input dropdown={true} />
            </mobiscroll.Select>
        </div>
    )
})

const Input = observer(props => {
    const { value, placeholder, onChange, onClick, type, disabled, className, onKeyUp, dataIcon, iconAlign, id, autoComplete, onBlur, maxLength, icon, onIconClicked } = props
    return (
        <div className={`wrapper-mobi ${icon ? 'has-input-icon' : ''}`}>
            <mobiscroll.Input
                onClick={onClick}
                className={className ? className : ''}
                type={type ? type : 'text'}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                onKeyUp={onKeyUp}
                disabled={disabled}
                data-icon={dataIcon}
                iconAlign={iconAlign}
                autoComplete={autoComplete ? autoComplete : 'new-password'}
                id={id}
                onBlur={onBlur}
                maxLength={maxLength}></mobiscroll.Input>
            {icon && (
                <div className="mobi-icon" onClick={() => onIconClicked?.()}>
                    {icon}
                </div>
            )}
        </div>
    )
})

const Calendar = observer(props => {
    const { value, type, className, dateFormat, buttons, onSet, display, lang } = props
    const validAge = (staticFooter.footerMisc.ushopEnrollBirthday) ? staticFooter.footerMisc.ushopEnrollBirthday : 20

    const minimum = DateTime.local().minus({ year: 120 }).toISO()
    const maximum = DateTime.local().minus({ year: validAge }).toISO()

    return (
        <div className="wrapper-mobi">
            <mobiscroll.Calendar
                display={display ? display : 'center'}
                className={className ? className : ''}
                type={type ? type : 'text'}
                value={value}
                onSet={onSet}
                dateFormat={dateFormat}
                buttons={buttons}
                lang={lang ? lang : 'en'}
                max={maximum}
                min={minimum}
                cssClass="wrapper-mobi-calendar">
                <mobiscroll.Input dropdown={true} />
            </mobiscroll.Calendar>
        </div>
    )
})

const Password = observer(props => {
    const { placeholder, value, autoComplete, onChange, onFocus, onBlur } = props
    return (
        <div className="wrapper-mobi">
            <mobiscroll.Input type="password" placeholder={placeholder} value={value} onChange={onChange} autoComplete={autoComplete ? autoComplete : 'new-password'} passwordToggle={true} onBlur={onBlur} onFocus={onFocus}></mobiscroll.Input>
        </div>
    )
})

const Checkbox = observer(props => {
    const { onChange, checked } = props
    return (
        <div className="wrapper-mobi">
            <mobiscroll.Checkbox checked={checked} onChange={onChange}>
                {props.children}
            </mobiscroll.Checkbox>
        </div>
    )
})

const InputFile = observer(props => {
    const { onChange } = props
    return (
        <div className="wrapper-mobi">
            <mobiscroll.Input inputStyle="outline" type="file" placeholder="Select file..." onChange={onChange}></mobiscroll.Input>
        </div>
    )
})
Mobi.Select = Select
Mobi.SelectMoblie = SelectMoblie
Mobi.Input = Input
Mobi.Calendar = Calendar
Mobi.Checkbox = Checkbox
Mobi.Password = Password
Mobi.InputFile = InputFile

export default Mobi
