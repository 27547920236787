import * as K from "./Keywords";

/** The URL of delivery note for **ShippingDetails**. */
export const DeliveryNote = {
    [K.Thailand]: {
        [K.Delivery]: 'u_th_os_shipping_note_delivery',
    },
    [K.Singapore]: {
        [K.Delivery]: 'u_sg_os_shipping_note_delivery',
        [K.Aramex]: 'u_sg_os_shipping_note_aramex'
    },
    [K.Malaysia]: {
        [K.Delivery]: 'u_my_os_shipping_note_delivery',
        [K.Aramex]: 'u_my_os_shipping_note_aramex'
    },
    [K.Vietnam]: {
        [K.Delivery]: 'u_vn_os_shipping_note_delivery',
    },
    [K.Indonesia]: {
        [K.Delivery]: 'u_id_os_shipping_note_delivery',
        [K.DeliverySBY]: 'u_id_os_shipping_note_delivery',
        [K.MotorDelivery]: 'u_id_os_shipping_note_motor_delivery',
        [K.MotorDeliverySBY]: 'u_id_os_shipping_note_motor_delivery',
    },
    [K.Japan]: {
        [K.Delivery]: 'u_jp_os_shipping_note_delivery',
    },
    [K.Hongkong]: {
        [K.Delivery]: 'u_hk_os_shipping_note_delivery',
    },
    [K.Taiwan]: {
        [K.Delivery]: 'u_tw_os_shipping_note_delivery',
    },
    [K.Morocco]: {
        [K.Delivery]: 'u_ma_os_shipping_note_delivery',
    },
    [K.Thailand]: {
        [K.Delivery]: 'u_th_os_shipping_note_delivery',
    },
    [K.Philippines]: {
        [K.Delivery]: 'u_ph_os_shipping_note_delivery',
        [K.PickUpTaguig]: 'u_ph_os_shipping_note_delivery'
    }
}

const ConfirmEmail = {
    [K.All]: true
}

const PrintedInvoice = {
    [K.All]: false,
    [K.Thailand]: true
}

const showTCList = {
    [K.All]: false,
    [K.Thailand]: true
}
