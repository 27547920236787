import { isNothing, isNullOrUndefined } from './Utils';
import { isProduction, dictionary, isWordExist, replaceHashTagsWith } from '@GlobalHelpers';
import { loge } from './PikaLog';
import get from 'lodash/get';
import { upperFirst } from 'lodash';

// ** Simulate Errors from Hydra
// Payment Required
export const errorHydraPaymentRequired = {
    responseJSON: {
        code: '3009',
        error_code: 402,
        error_messages: ['Payment Required'],
        message: 'Could not authorize and capture credit card payment. Error#A6Y4E'
    }
}

class CustomError extends Error {
    constructor(name, ...params) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params)
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CustomError)
        }
        this.name = name
    }
}

const ErrorObject = (name, message) => {
    const e = new CustomError(name, message)
    return e
}

/**
 * Assert any value with `isNothing()` then return output of error message by `loge()`. 
 * If application run on development stage use `throw` instead.
 * @param {*} value any value that need to be assert.
 * @param {string} message any noted message.
 * @return {*} the same of input value.
 */
export const assertValue = (value, message = '') => {
    if (isNothing(value)) {
        let format = `Error: Value assertion was fail, the result is = ${value}${isNothing(message) ? `` : `\nMessage: ${message}`}`
        format += `\nThis error only appear on development stage to prevent any unattended error on production stage.`
        if (isProduction()) {
            loge(`AssertValue - ${format}`)
        } else {
            throw ErrorObject('AssertValue', format)
        }
    } else {
        return value
    }
}

/**
 * 
 * @return {array} The result error messages.
 */
export const translateHydraErrors = (response) => {
    try {
        const error = get(response, 'responseJSON', response)
        // Warning: please change these conditions with carefully
        if (isWordExist(error.error_messages[0], 'Payment Required')) {
            error.error_messages[0] = dictionary('error_payment_required')
        } else {
            if (isNullOrUndefined(error.error_code) === false) {
                switch (error.error_code.toString()) {
                    case '500':
                        if (isWordExist(error.message, 'Upstream service returned unexpected response.')) {
                            error.error_messages = [dictionary('error_internal_server')]
                        }
                        break
                    case '402':
                        if (isWordExist(error.message, 'Could not authorize and capture credit card payment.')) {
                            error.error_messages = [dictionary('error_could_not_authorize_card')]
                        }
                        break
                    case '3449':
                        if (isWordExist(error.message, 'Retry With')) {
                            error.error_messages = [dictionary('error_retry_with')]
                        }
                        break
                }
            } else {
                if (isNullOrUndefined(error.code) === false) {
                    switch (error.code.toString()) {
                        case '3011':
                            let sku = String(error.message).replace('This SKU is not supported: `', '')
                            sku = sku.split('`')[0]
                            let e = dictionary('error_sku_not_supported')
                            e = replaceHashTagsWith(e, 'SKU', sku)
                            error.error_messages = [e]
                            break
                        case '3009':
                            error.error_messages = [dictionary('error_could_not_authorize_card')]
                            break
                        case '449':
                            error.error_messages = [dictionary('error_retry_with')]
                            break
                    }

                } else {
                    if (isNullOrUndefined(error.error_messages) === false) {
                        const msg2 = 'Payment Required'
                        if (isWordExist(error.error_messages[0], msg2)) {
                            error.error_messages[0] = dictionary('error_payment_required')
                        }
                    }
                    if (isNullOrUndefined(error.message) === false) {
                        const msg = 'Could not authorize and capture credit card payment.'
                        if (isWordExist(error.message, msg)) {
                            error.message = dictionary('error_could_not_authorize_card') // removed error code
                        }
                        error.error_messages.push(error.message)
                    }
                }
            }
        }

        error.error_messages = error.error_messages.map(v => {
            if (isWordExist(v, 'creditCardNumber')) {
                v = v.replace('creditCardNumber', 'Credit card number')
            } else if (isWordExist(v, 'payer')) {
                v = upperFirst(v)
            } else if (isWordExist(v, 'creditCardSecurityCode')) {
                v = v.replace('creditCardSecurityCode', 'Credit card security code')
            } else if (isWordExist(v, 'creditCardExpires')) {
                v = v.replace('creditCardExpires', 'Credit expires')
            }

            return v
        })
        return error.error_messages
    } catch (e) {
        console.error(e)
        const error = response.responseJSON
        return error.error_messages
    }
}



export const translateHydraErrorsAutoship = (response) => {
    try {
        const error = get(response, 'responseJSON', response)
        // Warning: please change these conditions with carefully
        if (isNullOrUndefined(error.error_code) === false) {
            switch (error.error_code.toString()) {
                case '500':
                    if (isWordExist(error.message, 'Upstream service returned unexpected response.')) {
                        error.error_messages = [dictionary('error_internal_server')]
                    }
                    break
                case '402':
                    if (isWordExist(error.message, 'Could not authorize and capture credit card payment.')) {
                        error.error_messages = [dictionary('error_could_not_authorize_card')]
                    }
                    break
                case '3449':
                    if (isWordExist(error.message, 'Retry With')) {
                        error.error_messages = [dictionary('error_retry_with')]
                    }
                    break
            }
        } else {
            if (isNullOrUndefined(error.error.code) === false) {
                switch (error.error.code.toString()) {
                    case '3011':
                        let sku = String(error.error.error_message).replace('This SKU is not supported: ', '')
                        // sku = sku.split('`')[0]
                        let e = dictionary('u_all_error_sku_not_supported')
                        e = replaceHashTagsWith(e, 'SKU', sku)
                        error.error_messages = [e]
                        break
                    case '3009':
                        error.error_messages = [dictionary('error_could_not_authorize_card')]
                        break
                    case '449':
                        error.error_messages = [dictionary('error_retry_with')]
                        break
                    case '500':
                        console.log('error', error)
                        error.error_messages = [error.error.error_message]
                }
            } else {
                if (isNullOrUndefined(error.error.error_messages) === false) {
                    const msg2 = 'Payment Required'
                    if (isWordExist(error.error_messages[0], msg2)) {
                        error.error_messages[0] = dictionary('error_payment_required')
                    }
                }
                if (isNullOrUndefined(error.message) === false) {
                    const msg = 'Could not authorize and capture credit card payment.'
                    if (isWordExist(error.message, msg)) {
                        error.message = dictionary('error_could_not_authorize_card') // removed error code
                    }
                    error.error_messages.push(error.message)
                }
            }
        }
        return error.error_messages
    } catch (e) {
        console.error(e)
        const error = response.responseJSON
        return error.error_messages
    }
}