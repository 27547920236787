import React, { useLayoutEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { numeralFormat, getParameterByName } from '@GlobalHelpers'
import { inArray } from '@Components/config'
import { enrollStore, store } from '@Stores/MainStore'
import { storeEnroll } from '@Stores/StoreEnroll'
import { language as l } from '@Language'
import { CountryConfigs } from '@Components/configs/Configs'
import { generateEnrollID, updateEnroll } from '@Services/Network'
import StoreCountry from '@Stores/StoreCountry'
import EnrollFactory from '../lib/EnrollFactory'
import { Country } from '@Stores/StoreConfigCountry'
import { Button } from 'antd'
import { isNothing } from '@Components/utils/Utils'
import { LoadingOutlined } from '@ant-design/icons'
import { storeBowser } from '@Stores/StoreBowser'
import { IconPlus, IconPlusMobile } from './SVGIcon'
import { toJS } from 'mobx'

const ActionFooter = observer(props => {
    const continueBtnRef = useRef()

    useLayoutEffect(() => {
        if (Country.isThailand() && continueBtnRef.current) {
            continueBtnRef.current.click()
        }
    }, [continueBtnRef.current])

    const history = useHistory()

    const addItem = () => {
        storeEnroll.openSelectProductModal()
    }

    const renderTotal = () => {
        let totalPv = 0
        let totalPrice = 0

        let showPV = true
        let priceType = 'wholesale_price'
        let starterKitItems = Country.isPhilippines() ? storeEnroll.StarterKitItemCodeOnlyPH() : storeEnroll.starterKitItems
        // console.log('storeEnroll.productSource in render total==>', toJS(storeEnroll.productSource))
        storeEnroll.productSource.map((v, k) => {
            if (inArray(v.item_code, starterKitItems) && v.item_code !== storeEnroll.starterKitSelected) {
                return false
            }
            if (v.qty > 0) {
                totalPrice += (v.price === '' ? 0 : parseFloat(v.price)) * v.qty

                totalPv += parseInt(v.pv) * v.qty
            }
        })
        return (
            <div className="autoship-total col-lg-12 col-md-12 col-sm-12 col-xs-12 pull-right row-group-total">
                {totalPv > 0 && (
                    <p className="text-right text-lg text-medium group-total">
                        <span className="lg31 text-total">{l.listen('total', { autoPrefix: false })}:</span>
                        <span className="total-price">{totalPv}</span>
                        <span className="total-currency text-muted">{l.listen('pv', { autoPrefix: false })}</span>
                    </p>
                )}
                <p className="text-right text-lg text-medium group-total group-total-price">
                    {totalPv <= 0 && <span className="lg31 text-total">{l.listen('total', { autoPrefix: false })}:</span>}
                    <span className="total-price">{numeralFormat(totalPrice)}</span>
                    <span className="total-currency text-muted">{store.currency}</span>
                </p>
            </div>
        )
    }

    const continueCheckout = () => {
        //storeRenew.loading = true
        storeEnroll.loading = true
        const removed = storeEnroll.productSource.filter((item, key) => item.item_code !== '')
        const removed2 = removed.filter((item, key) => !inArray(item.item_code, storeEnroll.starterKitItems) || item.item_code === storeEnroll.starterKitSelected)
        storeEnroll.productSource = removed
        storeEnroll.checkoutCart = removed2
        enrollStore.productSource = storeEnroll.checkoutCart

        const stepOrderDetails = {}
        Object.keys(EnrollFactory.EnrollData).map(v => {
            stepOrderDetails[v] = EnrollFactory.EnrollData[v]
        })
        stepOrderDetails.language = l.current

        let data = {
            country_code: CountryConfigs.CountryCode3(),
            step_select_products: JSON.stringify(enrollStore.productSource),
            step_order_details: JSON.stringify(stepOrderDetails)
        }
        if (storeEnroll.starterKitSelected === '') {
            storeEnroll.loading = false
            storeEnroll.errorMessage = 'renewal_please_select_starter_kit'
            storeEnroll.orderError = true
        } else if (!checkHasDiscontinuedProduct()) {
            storeEnroll.orderError = false
            storeEnroll.proceedCheckout = true
            // window.scrollTo(0, 0)

            // [taiwan change data flow don't use enroll state]
            if (Country.isTaiwan()) {
                let id = getParameterByName('id')
                if (!id) {
                    enrollStore.currentPage = 2
                    // enrollStore.reloadData = false
                    history.push(`/${StoreCountry.CountryLowerCase()}/enroll/detail`)
                    storeEnroll.loading = false
                } else {
                    enrollStore.currentPage = 2
                    enrollStore.reloadData = true
                    history.push(`/${StoreCountry.CountryLowerCase()}/enroll/detail?id=${id}`)
                    storeEnroll.loading = false
                }
            } else {
                let ref_id = getParameterByName('ref_id')
                if (!ref_id) {
                    GenerateEnrollId(data)
                } else {
                    UpdateEnroll(ref_id, data)
                }
            }
        } else {
            storeEnroll.loading = false
            storeEnroll.errorMessage = 'customlink_error_discontinue'
            storeEnroll.orderError = true
        }
    }

    const GenerateEnrollId = data => {
        generateEnrollID((res, status) => {
            if (status) {
                enrollStore.currentPage = 2
                enrollStore.reloadData = false
                history.push(`/${StoreCountry.CountryLowerCase()}/enroll/detail?ref_id=${res.data.reference_id}`)
                storeEnroll.loading = false
            }
        }, data)
    }

    const UpdateEnroll = (ref_id, data) => {
        updateEnroll(
            (res, status) => {
                if (status) {
                    enrollStore.currentPage = 2
                    enrollStore.reloadData = false
                    history.push(`/${StoreCountry.CountryLowerCase()}/enroll/detail?ref_id=${res.data.reference_id}`)
                    storeEnroll.loading = false
                }
            },
            ref_id,
            data
        )
    }

    const checkHasDiscontinuedProduct = () => {
        let hasDiscontinuedProduct = false
        let hasBackorder = false
        let hasOutofstock = false
        storeEnroll.checkoutCart.map((v, k) => {
            if (v.status == 'out_of_stock' || v.qty_sellable == 0) {
                hasDiscontinuedProduct = true
            }
        })

        return hasDiscontinuedProduct
    }

    return (
        <div className="enrollment--footer-wrapper">
            {storeBowser.isMobile() && !Country.isJapan() && (
                <>
                    {(() => {
                        if (storeEnroll.isLoadingProfileID) {
                            return <LoadingOutlined spin />
                        } else {
                            return (
                                <>
                                    {storeEnroll.starterKitSelected && (
                                        <div className={`block_addmore block_addmore_mobile`} onClick={() => addItem()}>
                                            <IconPlusMobile />
                                            &nbsp;&nbsp; <span>{l.listen('add_more_products', { autoPrefix: false })}</span>
                                        </div>
                                    )}
                                </>
                            )
                        }
                    })()}
                </>
            )}

            <div className={`${!storeBowser.isMobile() ? 'desktop' : 'mobile'}`}>
                {!storeBowser.isMobile() && !Country.isJapan() && (
                    <div className={`block_addmore ${storeEnroll.isLoadingProfileID ? 'border-0' : ''}`} onClick={() => (isNothing(storeEnroll.starterKitSelected) || storeEnroll.isLoadingProfileID ? {} : addItem())}>
                        {(() => {
                            if (storeEnroll.isLoadingProfileID) {
                                return <LoadingOutlined spin />
                            } else {
                                return (
                                    <>
                                        {storeEnroll.starterKitSelected && (
                                            <>
                                                <IconPlus />
                                                &nbsp;&nbsp; <span>{l.listen('add_more_products', { autoPrefix: false })}</span>
                                            </>
                                        )}
                                    </>
                                )
                            }
                        })()}
                    </div>
                )}

                {!storeBowser.isMobile() && Country.isJapan() && <div className="block_addmore "></div>}
                <div className={`enroll-select-product ${storeEnroll.isLoadingProfileID ? 'border-0' : ''}`} id="rsTotal">
                    {storeEnroll.isLoadingProfileID === false && (
                        <>
                            {renderTotal()}
                            {storeEnroll.orderError && (
                                <div className="block-error row ">
                                    <div className="col-12">
                                        <div className="alert alert-danger">
                                            <div className="text-center">
                                                <ul style={{ listStyle: 'none' }} id="rsErrors">
                                                    <li>{l.listen(storeEnroll.errorMessage)}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {storeBowser.isMobile() && (
                                <div className="block_button_continue">
                                    <Button type="primary" block loading={storeEnroll.loading} onClick={continueCheckout} ref={continueBtnRef}>
                                        {l.listen('continue', { autoPrefix: false })}
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            {!storeBowser.isMobile() && storeEnroll.isLoadingProfileID === false && storeEnroll.starterKitSelected && (
                <div className={`${!storeBowser.isMobile() ? 'buttons-desktop' : 'buttons-mobile'}`}>
                    <div className="block_button_continue">
                        <Button type="primary" block loading={storeEnroll.loading} onClick={continueCheckout} ref={continueBtnRef}>
                            {l.listen('continue', { autoPrefix: false })}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
})

export default ActionFooter
