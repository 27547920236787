import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { reaction } from 'mobx'
import PropTypes from 'prop-types'
import { defaults, isNothing, isNullOrUndefined, isSomething } from '@Utils/Utils'
import { findString, lowerCase, removeSpaces } from '@Utils/String'
import { storeBowser } from '@Stores/StoreBowser'
import { language as l } from '@Language'
import Tooltip from 'antd/es/tooltip'
import Mustache from 'mustache'
import set from 'lodash/set'

// NOTE: should change to svg later
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

/** Wrap component with delivery note feature. Support both hover tooltip and open new tab for an external link. 
 * The result of feature function depend on dictionary value.
 * @param {object} props
 * @param {React.ReactNode} props.children A component which need to wrap with delivery note feature.
 * @param {boolean} props.noIcon Hide question mark icon while tooltip still work.
 * @param {string} props.status Short status of customer (e.g. `A`, `C`, `E`), 
 * lower-case and upper-case are not matter. If no sepcified status, fallback to status `C`.
 * @param {'delivery'|'pickup'|'aramex'} props.shipping Specified a shipping method, 
 * lower-case and upper-case are not matter. If no sepcified shipping method, fallback to `delivery`.
 * @return {React.ReactNode} Return a wrapped component.
 */
const DisplayDeliveryNote = observer(props => {

    const [legitValue, setLegitValue] = useState('')
    const [noteType, setNoteType] = useState('')
    const view = { status: {} }

    useEffect(() => {

        const disposerLanguage = reaction(
            () => l.current,
            () => {
                processLegitValue()
            }, { fireImmediately: true }
        )


        return () => {
            disposerLanguage()
        }

    }, [])

    const processLegitValue = () => {

        // set shipping method type
        let type = lowerCase(defaults(props.shipping, 'delivery'))

        if (props.autoship) {
            type = 'autoship'
        }

        if (props.splash) {
            type = 'splash'
        }

        let legitKey = `os_shipping_note_${type}`

        const status = lowerCase(defaults(props.status, 'c')) // for Enroll use C, any other different conditions please add here
        const dictValue = l.listen(legitKey, { keyOnMissing: true })
        const isNullUndefined = isNullOrUndefined(dictValue)
        const isKeyMissing = findString(dictValue, /\[u_/)
        const isExisting = (isNullUndefined === false) && (isKeyMissing === false)

        if (isExisting) {
            if (isKeyMissing === false) {
                if (status) {
                    if (findString(removeSpaces(dictValue), `status.${status}}}`)) {
                        set(view, `status.${status}`, true)
                        const existingStatus = Mustache.render(dictValue, view)
                        if (isNothing(existingStatus)) {
                            legitKey = ''
                        }
                    } else if (findString(removeSpaces(dictValue), 'status.all}}')) {
                        set(view, 'status.all', true)
                        const existingAll = Mustache.render(dictValue, view)
                        if (isNothing(existingAll)) {
                            legitKey = ''
                        }
                    } else {
                        if (isNothing(dictValue)) {
                            legitKey = ''
                        }
                    }
                }
            } else {
                legitKey = ''
            }
        } else {
            legitKey = ''
        }

        const value = legitKey ? Mustache.render(l.listen(legitKey), view) : ''

        if (isSomething(legitKey)) {
            if (findString(value, 'http')) {
                setNoteType('redirect')
            } else {
                setNoteType('tooltip')
            }
            setLegitValue(value)
        } else {
            setNoteType('')
        }
    }

    /*  useEffect(() => {
        console.log('noteType',noteType)
    }, [noteType]) */

    return (
        <>
            {(() => {
                if (isSomething(noteType)) {
                    if (isSomething(legitValue)) {
                        if (noteType === 'redirect') {
                            return (
                                <span 
                                    onClick={() => { window.open(legitValue, '_blank') }} 
                                    role="button" className="cursor-pointer">
                                    {props.children}
                                    <FontAwesomeIcon icon={faQuestionCircle} fixedWidth className="ml-2" style={{ color: '#384250' }} />
                                </span>
                            )
                        } else if (noteType === 'tooltip') {
                            return (
                                <Tooltip 
                                    title={legitValue} 
                                    trigger={storeBowser.isDesktop() ? 'hover' : 'click'}>
                                    <span className="cursor-pointer">
                                        {props.children}
                                        {!props.noIcon &&
                                        <FontAwesomeIcon icon={faQuestionCircle} fixedWidth className="ml-2" style={{ color: '#384250' }} />
                                        }
                                    </span>
                                </Tooltip>
                            )
                        }
                    }
                } else {
                    return props.children
                }
            })()}
        </>
    )
})

DisplayDeliveryNote.propTypes = {
    noIcon: PropTypes.bool,
    status: PropTypes.string,
    shipping: PropTypes.string
}

export default DisplayDeliveryNote