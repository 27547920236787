import ImageUpload from '@Components/ImageUpload/ImageUpload'
import { storeIdVerification } from '@Stores/StoreIDVerification'
import { useCallback, useEffect, useState } from 'react'
import { ReactComponent as IDCard } from '@Components/enroll/IDVerification/assets/idcard.svg'

const TestNewWatermark = props => {
    const [img1, setImg1] = useState(false)
    const [img5, setImg5] = useState(false)
    console.log(img5)

    const [watermark, setWatermark] = useState('https://watermarkassets.s3.ap-southeast-1.amazonaws.com/watermark-cropped-fit.png')

    const [resizeWatermark, setResizeWatermark] = useState(0)
    const [position, setPosition] = useState('topLeft')

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="text-center" style={{ marginBottom: 20, marginTop: 20, maxWidth: 500 }}>
                <h3> Watermark 1:</h3>
                <div className="form">
                    <strong>Resize watermark to:</strong>
                    <select className="form form-control" onChange={e => setResizeWatermark(e.target.value)}>
                        <option value="3">25%</option>
                        <option value="2">50% </option>
                        <option value="1">75%</option>
                        <option value="0" selected>
                            Default watermark size
                        </option>
                    </select>
                    <br />
                    <select className="form form-control" onChange={e => setPosition(e.target.value)} disabled={resizeWatermark === '0'}>
                        <option value="topLeft" selected>
                            Top Left
                        </option>
                        <option value="bottomRight">Bottom Right</option>
                    </select>
                    <br />
                    <input type="text" onChange={e => setWatermark(e.target.value)} defaultValue={watermark} className="form form-control" placeholder="Watermark Url" />
                </div>
                <br />
                <ImageUpload coverPhoto={<IDCard />} hasImage={img1 || false} govid={'test1'} setImageCallback={setImg1} name="test1" reUpload={true} watermarkUrl={watermark} watermarkResizeTo={resizeWatermark} position={position} />
            </div>
            <hr />
            <div className="text-center" style={{ marginBottom: 20, marginTop: 20 }}>
                <h3> Watermark 1 + Resize</h3>
                <ImageUpload coverPhoto={<IDCard />} hasImage={img5 || false} govid={'test1'} setImageCallback={setImg5} name="test1" reUpload={true} resize={true} watermarkUrl={watermark} watermarkResizeTo={resizeWatermark} position={position} />
            </div>
        </div>
    )
}

export default TestNewWatermark
