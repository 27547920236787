/* eslint-disable no-dupe-class-members */
import { enrollStore, staticFooter } from '@Stores/MainStore'
import {
    validateGovId,
    validateDuplicateGovId,
    validateDuplicateMobilePhone,
    checkTerminatedAccountByGovId,
    checkSponsorAndEnroller,
    makeCustomersInternational_Enroll,
    uplineSponsoringV2,
    createMenaAccountAPI,
    updateEnroll,
    getEnrollPaymentResume,
    getProduct,
    getEnroll,
    validateEnrollerSponsorV2
} from '@Services/Network'
import { loge, logi } from '@Components/utils/PikaLog'
import { getNativeName, isCountryThailand } from '@Components/GlobalHelpers'
import { getParameterByName } from '@Components/configs/ConfigsHeader'
import { getCountryCode } from '@GlobalHelpers'
import { language } from '@Language'
import moment from 'moment'
import { storeProduct } from '@Stores/StoreProduct'
import { storeEnroll } from '@Stores/StoreEnroll'
import EnrollSubmitPayment from './EnrollSubmitPayment'
import { appConfig, shippingAddress } from '@Components/config'
import StoreCountry from '@Stores/StoreCountry'
import { capitalize, lowerCase, upperCase } from 'lodash'
import { isString } from '@Components/utils/Utils'

import { makeAutoObservable, toJS } from 'mobx'
import { Country } from '@Stores/StoreConfigCountry'

class EnrollFactory {
    isMockup = false
    sponsorHref = ''
    /** param {*} {enrollStore.enrollData} */
    EnrollData = enrollStore.enrollData
    Payment = enrollStore.payment
    subscribeType = enrollStore.subscribeType

    get OrderTerms() {
        return enrollStore.orderTerms
    }

    get OrderSummary() {
        return enrollStore.orderSummary
    }

    SetSubscribe(type = false) {
        this.subscribeType = type ? 'Associate' : 'PreferredCustomer'
    }

    SetOrderSummary(value) {
        enrollStore.orderSummary = value
    }

    SetOrderTerms(value) {
        enrollStore.orderTerms = value
    }

    SetGovId(value) {
        this.EnrollData.govId = value
    }

    SetGovId2(value) {
        this.EnrollData.govId2 = value
    }

    SetPassport(value) {
        this.EnrollData.govIdPassport = value
    }

    SetWorkPermit(value) {
        this.EnrollData.govIdWorkPermit = value
    }

    SetEnroller(value) {
        this.EnrollData.enroller = value
    }

    SetBirSep(value) {
        enrollStore.stepBIR = value
    }

    SetProductSource(value) {
        enrollStore.productSource = value
    }

    SetAllThailandEnrollData(resultData) {
        this.EnrollData = resultData
        this.EnrollData.enroller = resultData.enroller_id
        this.EnrollData.sponsor = resultData.sponsor_id
        this.EnrollData.enrollerName = resultData.enroller_name
        this.EnrollData.sponsorName = resultData.sponsor_name
        this.EnrollData.enrollerNativeName = resultData.enroller_native_name
        this.EnrollData.sponsorNativeName = resultData.sponsor_native_name
        this.EnrollData.govId = resultData.hydra.customer.taxTerms.taxId
        this.EnrollData.gender = resultData.hydra.customer.gender
        this.EnrollData.maritalStatus = resultData.hydra.customer.maritalStatus
        this.EnrollData.ethnicity = resultData.hydra.customer.ethnicity
        this.EnrollData.birthday = resultData.hydra.customer.birthDate
        this.EnrollData.mainAddress = resultData.hydra.customer.mainAddress.address1
        this.EnrollData.mainAddress2 = resultData.hydra.customer.mainAddress.address2
        this.EnrollData.country = resultData.hydra.customer.mainAddress.country
        this.EnrollData.city = resultData.hydra.customer.mainAddress.city
        this.EnrollData.zip = resultData.hydra.customer.mainAddress.zip
        this.EnrollData.email = resultData.hydra.customer.email
        this.EnrollData.password = resultData.hydra.customer.password.value
        this.EnrollData.mobile = resultData.hydra.customer.mobilePhone
        this.EnrollData.firstNameEn = resultData.hydra.customer.humanName['firstName']
        this.EnrollData.lastNameEn = resultData.hydra.customer.humanName['lastName']
        this.EnrollData.firstNameTh = resultData.hydra.customer.humanName['firstName@th']
        this.EnrollData.lastNameTh = resultData.hydra.customer.humanName['lastName@th']
        this.EnrollData.fullNameEn = `${resultData.hydra.customer.humanName['firstName']} ${resultData.hydra.customer.humanName['lastName']}`
        this.EnrollData.fullNameTh = `${resultData.hydra.customer.humanName['firstName@th']} ${resultData.hydra.customer.humanName['lastName@th']}`
        this.EnrollData.fullName = `${resultData.hydra.customer.humanName['firstName@th']} ${resultData.hydra.customer.humanName['lastName@th']}`
        this.EnrollData.shippingmethod = resultData.shippingMethod

        if (resultData.orderTermsJson.order.language) {
            language.current = resultData.orderTermsJson.order.language
        }
    }

    CheckGovId(govId) {
        return new Promise((resolve, reject) => {
            validateGovId(
                (res, status) => {
                    if (res.Success === 'Yes') {
                        resolve({
                            icfileStatus: 2,
                            msgNRIC: ''
                        })
                    } else {
                        logi('else')
                        if (res.Message) {
                            reject({
                                msgNRIC: Object.keys(res.Message),
                                icNumberStatus: 2,
                                loading: false
                            })
                        }
                    }
                },
                govId,
                ''
            )
        })
    }

    CheckDuplicateGovId(govId, countryCode = null) {
        return new Promise((resolve, reject) => {
            validateDuplicateGovId(
                (resCheckDuplicate, statusCheckDuplicate) => {
                    if (statusCheckDuplicate === true) {
                        let numberStatus = 1
                        if (isCountryThailand()) {
                            if (!resCheckDuplicate.data.error) {
                                if (resCheckDuplicate.data.error.error_code === '2006' && resCheckDuplicate.data.error.message === 'Not Found') {
                                    logi('Government ID not found')
                                    numberStatus = 2
                                }
                            }
                        }

                        resolve({
                            msgNRIC: '',
                            icNumberStatus: numberStatus
                        })
                    } else {
                        loge('ID CARD DUPLICATE')
                        reject({
                            msgNRIC: ['err_idcard_duplicate'],
                            icNumberStatus: 2
                        })
                    }
                },
                govId,
                countryCode
            )
        })
    }

    CheckDuplicateMobilePhone(mobilePhone, chk) {
        return new Promise((resolve, reject) => {
            validateDuplicateMobilePhone((resCheckDuplicate, statusCheckDuplicate) => {
                if (statusCheckDuplicate === true) {
                    resolve({
                        Message: ''
                    })
                } else {
                    loge('MOBILE PHONE DUPLICATE')
                    reject({
                        Message: chk == 2 ? ['u_jp_err_coapp_mobilephone_duplicate'] : ['u_jp_err_mobilephone_duplicate']
                    })
                }
            }, mobilePhone)
        })
    }

    CheckEnroller() {
        this.EnrollData.enrollerName = ''
        this.EnrollData.enrollerNativeName = ''

        return new Promise((resolve, reject) => {
            checkSponsorAndEnroller(
                (res, status) => {
                    if (status && res.success === 'yes') {
                        this.EnrollData.enrollerName = getNativeName(res.data.humanName, 'fullName')
                        this.EnrollData.enrollerNativeName = getNativeName(res.data.humanName, 'nativeName')
                        let baStatus = res.data.status
                        let baType = res.data.type
                        if (baType === 'Associate' && (baStatus === 'Active' || baStatus === 'Pending')) {
                            enrollStore.isBAStatusActive = true
                            makeCustomersInternational_Enroll(this.EnrollData.enroller)
                            resolve('enrollerStatus')
                        } else {
                            reject('enrollerStatus')
                        }
                    } else {
                        reject('enrollerStatus')
                    }
                },
                sessionStorage.getItem('customerToken'),
                this.EnrollData.enroller
            )
        })
    }

    CheckSponsor() {
        this.EnrollData.sponsorName = ''
        this.EnrollData.sponsorNativeName = ''

        return new Promise((resolve, reject) => {
            checkSponsorAndEnroller(
                (res, status) => {
                    if (status) {
                        this.EnrollData.sponsorName = getNativeName(res.data.humanName, 'fullName')
                        this.EnrollData.sponsorNativeName = getNativeName(res.data.humanName, 'nativeName')
                        if (res.data) {
                            this.asponsorHref = res.data.href
                        }
                        let baStatus = res.data.status
                        let baType = res.data.type

                        if (baType === 'Associate' && (baStatus === 'Active' || baStatus === 'Pending')) {
                            enrollStore.isBAStatusActive = true
                            makeCustomersInternational_Enroll(this.EnrollData.sponsor)
                            resolve('sponsorStatus')
                        } else {
                            reject('sponsorStatus')
                        }
                    } else {
                        reject('sponsorStatus')
                    }
                },
                sessionStorage.getItem('customerToken'),
                this.EnrollData.sponsor
            )
        })
    }

    CheckUplineSponsor(sponsorHref) {
        return new Promise((resolve, reject) => {
            uplineSponsoringV2(
                (res, status) => {
                    if (res['$.customer.sponsor'] !== undefined) {
                        reject(false)
                    } else {
                        resolve(true)
                    }
                },
                this.EnrollData.sponsor,
                this.EnrollData.enroller
            )
        })
    }

    checkEnrollerAndSponsorV2(Code2 = null) {
        return new Promise((resolve, reject) => {
            const data = {
                market: Code2 !== null ? Code2 : Country.getCode2(),
                enrollerId: this.EnrollData.enroller,
                sponsorId: this.EnrollData.sponsor
            }
            validateEnrollerSponsorV2((res, status) => {
                if (status) {
                    this.EnrollData.enrollerName = getNativeName(res.enroller.humanName, 'fullName')
                    this.EnrollData.enrollerNativeName = getNativeName(res.enroller.humanName, 'nativeName')
                    this.EnrollData.sponsorName = getNativeName(res.sponsor.humanName, 'fullName')
                    this.EnrollData.sponsorNativeName = getNativeName(res.sponsor.humanName, 'nativeName')
                    this.sponsorHref = res.sponsor.href
                    this.EnrollData.displayEnrollerName = res.enroller.display.fullName
                    this.EnrollData.displaySponsorName = res.sponsor.display.fullName
                    resolve(true)
                } else {
                    reject(res.responseJSON)
                }
            }, data)
        })
    }

    UpdateEnrollData() {
        return new Promise((resolve, reject) => {
            let ref_id = getParameterByName('ref_id')
            let data = {
                step_order_details: this.EnrollData
            }

            // data.step_order_details.language = language.current;

            data.step_order_details = JSON.stringify(data.step_order_details)

            updateEnroll(
                (res, status) => {
                    if (status) {
                        this.SetReloadData(false)
                        resolve(res.data.reference_id)
                    } else {
                        reject(false)
                    }
                },
                ref_id,
                data
            )
        })
    }

    UpdateEnrollDataPH() {
        return new Promise((resolve, reject) => {
            let ref_id = getParameterByName('ref_id')

            var combined = {
                enrollData: this.EnrollData,
                birData: this.BirData
            }

            let data = {
                step_order_details: JSON.stringify(combined)
            }
            updateEnroll(
                (res, status) => {
                    if (status) {
                        this.SetReloadData(false)
                        resolve(res.data.reference_id)
                    } else {
                        reject(false)
                    }
                },
                ref_id,
                data
            )
        })
    }

    EnrollPaymentResume(Ref_resume) {
        return new Promise((resolve, reject) => {
            EnrollSubmitPayment.ProceedToPaymentResume(Ref_resume)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    Enroll(ref_id) {
        return new Promise((resolve, reject) => {
            getEnroll(response => {
                if (response.statusText === 'error') {
                    reject(false)
                } else {
                    if (response.data.step_order_details) {
                        let orderDetail = isString(response.data.step_order_details) ? JSON.parse(response.data.step_order_details) : response.data.step_order_details
                        if (orderDetail) {
                            Object.keys(orderDetail).map((v, k) => {
                                this.EnrollData[v] = orderDetail[v]
                            })
                        }

                        enrollStore.dataReady = true

                        if (this.EnrollData.language) {
                            language.current = this.EnrollData.language
                        }
                        if (Country.isPhilippines()) {
                            console.log('response.data==>', response.data)
                            resolve(response.data)
                        } else {
                            enrollStore.productSource = JSON.parse(response.data.step_select_products)
                            storeEnroll.checkoutCart = JSON.parse(response.data.step_select_products)
                            resolve(true)
                        }
                        // processcalculateOrder()
                    }
                }
            }, ref_id)
        })
    }

    SetReloadData(value) {
        enrollStore.reloadData = value
    }

    SetCurrentStep(value) {
        enrollStore.enrollStep = value
    }
    SetVerifiedGovIDStep(value) {
        enrollStore.verifiedGovID = value
    }
    SetVerifiedPassportIDStep(value) {
        enrollStore.verifiedPassportID = value
    }
    SetVerifiedWorkPermitIDStep(value) {
        enrollStore.verifiedWorkPermitID = value
    }

    SetVerifyEnroller(value) {
        enrollStore.verifiedEnroller = value
    }

    SetVerifySponsor(value) {
        enrollStore.verifiedSponsor = value
    }

    SetPassVerifiedAll() {
        this.SetVerifyEnroller(true)
        this.SetVerifySponsor(true)
        this.SetCurrentStep(3)
    }

    SetReloadData(value) {
        enrollStore.reloadData = value
    }

    get ReloadData() {
        return enrollStore.reloadData
    }

    get CurrentStep() {
        return enrollStore.enrollStep
    }

    get GovIDStep() {
        return enrollStore.verifiedGovID
    }

    get PassportIDStep() {
        return enrollStore.verifiedPassportID
    }

    get WorkPermitStep() {
        return enrollStore.verifiedWorkPermitID
    }

    get CurrentBirStep() {
        return enrollStore.stepBIR
    }

    get BirData() {
        return enrollStore.birData
    }

    get VerifiedEnroller() {
        return enrollStore.verifiedEnroller
    }

    get VerifiedSponsor() {
        return enrollStore.verifiedSponsor
    }

    get ErrorValidate() {
        return enrollStore.errorValidate
    }

    get Shipping() {
        return this.EnrollData.shippingmethod
    }

    get AgreeTCPass() {
        return enrollStore.agreeTCPass
    }

    get FullNameEn() {
        return `${this.EnrollData.firstNameEn} ${this.EnrollData.lastNameEn}`
    }

    get FullNameNative() {
        return `${this.EnrollData[`firstName${capitalize(StoreCountry.country.shorter)}`]} ${this.EnrollData[`lastName${capitalize(StoreCountry.country.shorter)}`]}`
    }

    get Source() {
        return `ushop-link-${lowerCase(StoreCountry.country.short)}`
    }

    get DataForThailandValidateAddress() {
        return {
            language: language.now(),
            source: this.Source,
            firstName: this.EnrollData.firstNameEn,
            lastName: this.EnrollData.lastNameEn,
            'firstName@th': this.EnrollData.firstNameTh,
            'lastName@th': this.EnrollData.lastNameTh,
            gender: this.EnrollData.gender,
            maritalStatus: this.EnrollData.maritalStatus,
            shippingMethod: this.EnrollData.shippingmethod,
            ethnicity: this.EnrollData.ethnicity,
            birthDate: this.EnrollData.birthday,
            address1: this.EnrollData.mainAddress,
            address2: this.EnrollData.mainAddress2,
            city: this.EnrollData.city,
            zip: this.EnrollData.zip,
            country: upperCase(StoreCountry.country.shorter),
            email: this.EnrollData.email,
            mobilePhone: this.EnrollData.mobile,
            homePhone: '',
            password1: this.EnrollData.password,
            password2: this.EnrollData.password2,
            period: this.EnrollData.period
        }
    }

    get DataForJapanValidateAddress() {
        return {
            language: language.now(),
            applicationNumber: this.EnrollData.govId,
            period: this.EnrollData.period,
            firstName: this.EnrollData.firstNameEn,
            lastName: this.EnrollData.lastNameEn,
            'firstName@ja': this.EnrollData.fullNameJp,
            'lastName@ja': '',
            gender: this.EnrollData.gender,
            maritalStatus: this.EnrollData.maritalStatus,
            birthDate: this.EnrollData.birthday,
            address1: this.EnrollData.mainAddress,
            address2: this.EnrollData.mainAddress2,
            city: '',
            country: upperCase(StoreCountry.country.shorter),
            zip: this.EnrollData.zip,
            email: this.EnrollData.email,
            mobilePhone: this.EnrollData.mobile,
            homePhone: '',
            password1: this.EnrollData.password,
            password2: this.EnrollData.password2,
            accountHolder: this.EnrollData.bankAccountName,
            accountType: this.EnrollData.bankAccountType,
            accountNumber: this.EnrollData.uploadBookBank === 'now' ? (this.EnrollData.bankNo === '' ? ' ' : this.EnrollData.bankNo) : '',
            bankName: this.EnrollData.bankName,
            routingNumber: this.EnrollData.bankNameCode,
            branchName: this.EnrollData.branchName,
            branchNumber: this.EnrollData.branchNumber,
            spouseFirstName: this.EnrollData.uploadCoapp === 'now' ? (this.EnrollData.spouseFirstName === '' ? ' ' : this.EnrollData.spouseFirstName) : '',
            spouseLastName: this.EnrollData.spouseLastName,
            'spouseFirstName@ja': this.EnrollData.spouseFirstNameJp,
            'spouseLastName@ja': '',
            spouseBirthDate: this.EnrollData.spouseBirthday,
            spouseAddress1: this.EnrollData.spouseMainAddress,
            spouseAddress2: this.EnrollData.spouseMainAddress2,
            spouseCity: '',
            spouseState: '',
            spouseCountry: upperCase(StoreCountry.country.shorter),
            spouseZip: this.EnrollData.spouseZip,
            spouseMobilePhone: this.EnrollData.spouseMobilePhone,
            companyName: ''
        }
    }

    get DataForPhilippinesValidateAddress() {
        return {
            language: language.now(),
            source: 'ushop-link-phl',
            firstName: this.EnrollData.firstNameEn + ' ' + this.EnrollData.middleName,
            lastName: this.EnrollData.lastNameEn,
            gender: this.EnrollData.gender,
            maritalStatus: this.EnrollData.maritalStatus,
            nationality: this.EnrollData.nationality,
            birthDate: this.EnrollData.birthday,
            address1: this.EnrollData.mainAddress,
            address2: this.EnrollData.mainAddress2,
            city: this.EnrollData.city,
            zip: this.EnrollData.zip,
            country: upperCase(StoreCountry.country.shorter),
            email: this.EnrollData.email,
            mobilePhone: this.EnrollData.mobile,
            homePhone: '',
            password1: this.EnrollData.password,
            password2: this.EnrollData.password2,
            period: this.EnrollData.period
        }
    }

    get DataForHongkongValidateAddress() {
        return {
            language: language.now(),
            source: 'ushop-link-hkg',
            firstName: this.EnrollData.firstNameEn,
            lastName: this.EnrollData.lastNameEn,
            'fullName@zh': this.EnrollData.fullNameTh,
            gender: this.EnrollData.gender,
            birthDate: this.EnrollData.birthday,
            address1: this.EnrollData.address1,
            address2: this.EnrollData.address2,
            city: this.EnrollData.city,
            country: upperCase(StoreCountry.country.shorter),
            email: this.EnrollData.email,
            mobilePhone: this.EnrollData.mobile,
            homePhone: '',
            password1: this.EnrollData.password,
            password2: this.EnrollData.password2,
            period: this.EnrollData.period
        }
    }

    get DataForTaiwanValidateAddress() {
        return {
            language: language.now(),
            source: this.Source,
            firstName: this.EnrollData.firstNameEn,
            lastName: this.EnrollData.lastNameEn,
            'fullName@zh': this.EnrollData['fullName@zh'],
            gender: this.EnrollData.gender,
            birthDate: this.EnrollData.birthday,

            zip: this.EnrollData.zip,
            city: this.EnrollData.city,
            // city:
            //   this.EnrollData.city +
            //   (this.EnrollData.zone ? this.EnrollData.zone : ""),
            zone: this.EnrollData.zone ? this.EnrollData.zone : '',
            address: this.EnrollData.address1,
            // address2: this.EnrollData.address2,
            country: upperCase(StoreCountry.country.shorter),
            email: this.EnrollData.email,
            mobilePhone: this.EnrollData.mobile,
            password1: this.EnrollData.password,
            password2: this.EnrollData.password2,

            period: this.EnrollData.period
        }
    }

    get DataForIndonesiaValidateAddress() {
        return {
            language: language.now(),
            source: this.Source,
            firstName: this.EnrollData.firstNameEn,
            lastName: this.EnrollData.lastNameEn,
            fullName: this.EnrollData.fullName,
            gender: this.EnrollData.gender,
            maritalStatus: this.EnrollData.maritalStatus,
            birthDate: this.EnrollData.birthday,

            zip: this.EnrollData.zip,
            city: this.EnrollData.city + ', ' + this.EnrollData.province,
            // district: this.EnrollData.district,
            // village: this.EnrollData.village,
            // province: this.EnrollData.province,
            address1: this.EnrollData.address1,
            // address2: this.EnrollData.district,
            address2: (this.EnrollData.village ? this.EnrollData.village : '') + (this.EnrollData.village ? ', ' : '') + this.EnrollData.district,

            country: upperCase(StoreCountry.country.shorter),
            email: this.EnrollData.email,
            mobilePhone: this.EnrollData.mobile,
            homePhone: '',
            password1: this.EnrollData.password,
            password2: this.EnrollData.password2,

            period: this.EnrollData.period,
            accountNumber: this.EnrollData.uploadBookBank == 'now' ? (this.EnrollData.bankNo ? this.EnrollData.bankNo : '1') : '',
            accountHolder: this.EnrollData.bankAccountName,
            accountType: 'SavingsPersonal',
            bankName: this.EnrollData.bankName
        }
    }

    get DataForSingaporeValidateAddress() {
        if (this.EnrollData.uploadBookBank === 'now') {
            return {
                language: language.now(),
                source: 'ushop-link-sgp',
                firstName: this.EnrollData.firstNameEn,
                ethnicity: this.EnrollData.ethnicity,
                lastName: '',
                gender: this.EnrollData.gender,
                birthDate: this.EnrollData.birthday,
                address1: this.EnrollData.address1,
                address2: this.EnrollData.address2,
                zip: this.EnrollData.zip,
                city: this.EnrollData.city,
                country: upperCase(StoreCountry.country.shorter),
                email: this.EnrollData.email,
                mobilePhone: this.EnrollData.mobile,
                homePhone: '',
                password1: this.EnrollData.password,
                password2: this.EnrollData.password2,
                period: this.EnrollData.period,
                accountNumber: this.EnrollData.bankNo,
                accountHolder: this.EnrollData.bankAccountName,
                accountType: 'SavingsPersonal',
                bankName: this.EnrollData.bankName,
                frontID: this.EnrollData.icFile,
                backID: this.EnrollData.icFileBC,
                bankBook: this.EnrollData.icFileBB
            }
        } else {
            return {
                language: language.now(),
                source: 'ushop-link-sgp',
                firstName: this.EnrollData.firstNameEn,
                ethnicity: this.EnrollData.ethnicity,
                lastName: '',
                gender: this.EnrollData.gender,
                birthDate: this.EnrollData.birthday,
                address1: this.EnrollData.address1,
                address2: this.EnrollData.address2,
                zip: this.EnrollData.zip,
                city: this.EnrollData.city,
                country: upperCase(StoreCountry.country.shorter),
                email: this.EnrollData.email,
                mobilePhone: this.EnrollData.mobile,
                homePhone: '',
                password1: this.EnrollData.password,
                password2: this.EnrollData.password2,
                period: this.EnrollData.period,
                frontID: this.EnrollData.icFile,
                backID: this.EnrollData.icFileBC
            }
        }
    }

    get DataForMalaysiaValidateAddress() {
        return {
            language: language.now(),
            source: 'ushop-link-mys',
            firstName: this.EnrollData.fullname,
            lastName: '',
            gender: this.EnrollData.gender,
            maritalStatus: this.EnrollData.maritalStatus,
            ethnicity: this.EnrollData.ethnicity,
            birthDate: this.EnrollData.birthday,
            address1: this.EnrollData.address1,
            address2: this.EnrollData.address2,
            city: this.EnrollData.city,
            zip: this.EnrollData.zip,
            country: getCountryCode(),
            email: this.EnrollData.email,
            mobilePhone: this.EnrollData.mobile,
            homePhone: '',
            password1: this.EnrollData.password,
            password2: this.EnrollData.password2,
            period: moment(new Date()).format('YYYY-MM'),
            accountNumber: this.EnrollData.bankNo,
            accountHolder: this.EnrollData.bankAccountName,
            accountType: 'CheckingPersonal',
            bankName: this.EnrollData.bankName,
            branchName: this.EnrollData.branchName,
            backID: this.EnrollData.icFileBC,
            frontID: this.EnrollData.icFile
        }
    }

    get DataForCambodiaValidateAddress() {
        return {
            language: language.now(),
            source: 'ushop-link-mys',
            firstName: this.EnrollData.fullname,
            lastName: '',
            gender: this.EnrollData.gender,
            maritalStatus: this.EnrollData.maritalStatus,
            ethnicity: this.EnrollData.ethnicity,
            birthDate: this.EnrollData.birthday,
            address1: this.EnrollData.address1,
            address2: this.EnrollData.address2,
            city: this.EnrollData.city,
            zip: this.EnrollData.zip,
            country: getCountryCode(),
            email: this.EnrollData.email,
            mobilePhone: this.EnrollData.mobile,
            homePhone: '',
            password1: this.EnrollData.password,
            password2: this.EnrollData.password2,
            period: moment(new Date()).format('YYYY-MM'),
            accountNumber: this.EnrollData.bankNo,
            accountHolder: this.EnrollData.bankAccountName,
            accountType: 'CheckingPersonal',
            bankName: this.EnrollData.bankName,
            branchName: this.EnrollData.branchName,
            backID: this.EnrollData.icFileBC,
            frontID: this.EnrollData.icFile
        }
    }

    get PickUpAddressTH() {
        return shippingAddress.pickUpAddressTHA[language.current]
    }

    get PickUpAddressPH() {
        return shippingAddress.taguigPickUpAddress
    }

    get PickUpAddressSG() {
        return shippingAddress.pickUpAddress
    }

    get PickUpAddressTW() {
        return shippingAddress.pickUpAddressTWN.TW
    }

    isDelivery(method) {
        let result = false
        const deliveryID = appConfig.shippingMethodID.delivery
        if (method === appConfig.shippingMethodIDToName[deliveryID]) {
            // check if it is name
            result = true
        } else if (method === deliveryID) {
            // check if it is id
            result = true
        }
        return result
    }

    prepareProductItems(items) {
        const handler = (carry, item) => {
            if (parseInt(item['qty']) >= 1 && item.qty !== '0' && item.isFreeItem !== true) {
                const product = {
                    item: {
                        href: 'https://hydra.unicity.net/v5a/items?id.unicity=' + item['item_code']
                    },
                    quantity: item['qty'],
                    item_code: item['item_code'],
                    href_params: ['id.unicity=' + item['item_code']],
                    item_total_pv: item['pv'],
                    price_per_unit: item['price'],
                    item_subtotal: parseFloat(item['price']) * parseInt(item['qty'])
                }
                carry.push(product)
            }
            return carry
        }
        return items.reduce(handler, [])
    }

    setAgreeTCPass(value) {
        enrollStore.agreeTCPass = value
    }

    constructor() {
        this.EnrollData = enrollStore.enrollData

        makeAutoObservable(this)
    }

    createMenaAccount(url,data) {
        return new Promise((resolve, reject) => {
            createMenaAccountAPI(
                (res, status) => {
                    if (status){
                        resolve({
                            res: res,
                        })
                    } else {
                        reject({
                            res: res,
                        })
                    }
                },
                url,
                data
            )
        })
    }
}

export default new EnrollFactory()
