import React from 'react';
import { dictionary, getFooterLanguageControl, getCountry, getLocalizedCurrency, getDecimalPointLength, dictionaryNative, getStoreLanguage, getCountryCode } from '@GlobalHelpers';
import { StoreConfigs } from './Configs';
import { getFooterByKey } from '../utils/StaticFooter';
import { store, shopStore } from '../../stores/MainStore';
import numeral from 'numeral';
import history from '../History';
import { storeProfile } from '../../stores/StoreProfile';
import { logout } from '../Authorization';

export const SideBarMenu = (country) => {
    const menu = sortingMenu(country)
    const returnMenu = []

    menu.map((v, k) => {
        sideBarConfigs.map((b, i) => {
            if (v === b.key) {
                returnMenu.push(b)
            }
        })
    })

    return returnMenu
}

export const sideBarConfigs = [
    {
        key: 'home',
        title: dictionary('home').toUpperCase(),
        path: `/`,
        children: [],
        auth: false,
        countries: true,
        userCountry: true
    },
    {
        key: 'dashboard',
        title: dictionary('dashboard').toUpperCase(),
        path: `/dashboard`,
        children: [
            // {
            //     key: 'pc_referral_link',
            //     title: dictionary('pc_referral_link').toUpperCase(),
            //     path: `/dashboard/pc-referral-link`,
            //     children: [],
            //     auth: true,
            //     countries: ["PH"],
            //     userCountry: ["PH"]
            // }
            {
                key: 'profile',
                title: dictionary('profile'),
                path: `/profile`,
                auth: true,
                countries: ['PH'],
                userCountry: ['PH']
            }
        ],
        auth: true,
        countries: true,
        userCountry: true,
        onClick: storeProfile.resetData
    },
    {
        key: 'shopping',
        title: dictionary('shop').toUpperCase(),
        path: `/catproduct/all`,
        onClick: () => {
            if (store.cartView === 'list') {
                history.push(`/${getCountry()}/catproduct/all`)
            } else if (store.cartView === 'card') {
                history.push(`/${getCountry()}/shopping/card/all`)
            } else if (store.cartView === 'a-to-z') {
                history.push(`/${getCountry()}/shopping/a-to-z/all`)
            }
        },
        children: [
            {
                key: 'shopping',
                title: dictionary('shop').toUpperCase(),
                path: `/catproduct/all`,
                children: [],
                auth: false,
                countries: true,
                userCountry: true,
                parent: 'shopping'
            },
            {
                key: 'order_history',
                title: dictionary('order_history').toUpperCase(),
                path: `/orderhistory`,
                children: [],
                auth: true,
                countries: true,
                userCountry: true,
                parent: 'shopping'
            }
        ],
        auth: false,
        countries: ['SG', 'PH', 'TH', 'VN', 'ID', 'AE', 'JP', 'IN', 'US', 'BH', 'TW', 'HK', 'CN', 'AU', 'NZ', 'KH'],
        userCountry: ['SG', 'PH', 'TH', 'VN', 'ID', 'AE', 'JP', 'IN', 'US', 'BH', 'TW', 'HK', 'CN', 'AU', 'NZ', 'KH']
    },
    {
        key: 'products',
        title: dictionary('products').toUpperCase(),
        path: `/catproduct/all`,
        children: [],
        auth: false,
        countries: ['KH', 'LA', 'MM'],
        userCountry: ['KH', 'LA', 'MM']
    },
    {
        key: 'enroll',
        title: dictionary('enroll').toUpperCase(),
        path: `/enroll`,
        children: [
            {
                key: 'enroll',
                title: dictionary('enroll').toUpperCase(),
                path: `/enroll`,
                children: [],
                auth: false,
                countries: ['SG', 'PH', 'TH', 'VN', 'ID', 'AE', 'JP', 'IN', 'US', 'BH', 'HK', 'CN', 'AU', 'NZ', 'KH'],
                userCountry: ['SG', 'PH', 'TH', 'VN', 'ID', 'AE', 'JP', 'IN', 'US', 'BH', 'HK', 'CN', 'AU', 'NZ', 'KH'],
                parent: 'enroll',
            },
            {
                key: 'idverification',
                title: dictionary('u_all_sidebar_enroll_idverification'),
                path: `/enroll/id-verification`,
                children: [],
                auth: false,
                countries: ['TH'],
                userCountry: ['TH'],
                parent: 'enroll',
            }
        ],
        auth: false,
        countries: ['SG', 'PH', 'TH', 'VN', 'ID', 'AE', 'JP', 'IN', 'US', 'BH', 'HK', 'CN', 'AU', 'NZ', 'KH'],
        userCountry: ['SG', 'PH', 'TH', 'VN', 'ID', 'AE', 'JP', 'IN', 'US', 'BH', 'HK', 'CN', 'AU', 'NZ', 'KH']
    },
    {
        key: 'genealogy',
        title: dictionary('genealogy').toUpperCase(),
        path: `/genealogy`,
        children: [
            // {
            //     key: 'genealogy_card_view',
            //     title: dictionary('card').toUpperCase(),
            //     path: `/genealogy`,
            //     children: [],
            //     auth: true,
            //     countries: true,
            //     userCountry: true,
            //     onClick: () => store.genealogy.show_card = true
            // },
            // {
            //     key: 'table',
            //     title: dictionary('table').toUpperCase(),
            //     path: `/genealogy`,
            //     children: [],
            //     auth: true,
            //     countries: true,
            //     userCountry: true,
            //     onClick: () => store.genealogy.show_card = false
            // }
            // ,
            // {
            //     key: 'success',
            //     title: dictionary('success').toUpperCase(), 
            //     path: `/success`,
            //     children: [],
            //     auth: true,
            //     countries: ['SG', 'PH', 'TH', 'VN', 'ID', 'AE', 'JP', 'IN', 'US', 'BH', 'HK', 'AU', 'NZ', 'KH', 'AT', 'BN', 'CH', 'CO', 'DE', 'IE', 'KR', 'MN', 'MY', 'TW', 'BE', 'LA', 'CA', 'LU', 'DK', 'DZ', 'EG', 'ES', 'FR', 'GB', 'GC', 'JO', 'KW', 'NO', 'OM', 'PL', 'QA', 'SA', 'SE', 'TN', 'YE'],
            //     userCountry: ['SG', 'PH', 'TH', 'VN', 'ID', 'AE', 'JP', 'IN', 'US', 'BH', 'HK', 'AU', 'NZ', 'KH', 'AT', 'BN', 'CH', 'CO', 'DE', 'IE', 'KR', 'MN', 'MY', 'TW', 'BE', 'LA', 'CA', 'LU', 'DK', 'DZ', 'EG', 'ES', 'FR', 'GB', 'GC', 'JO', 'KW', 'NO', 'OM', 'PL', 'QA', 'SA', 'SE', 'TN', 'YE']
            // }
        ],
        auth: true,
        countries: true,
        userCountry: true
    },
    {
        key: 'nfr',
        title: dictionary('nfr').toUpperCase(),
        path: `/nfr`,
        children: [],
        auth: true,
        countries: ['JP'],
        userCountry: ['JP']
    },
    {
        key: 'resources',
        title: dictionary('resources').toUpperCase(),
        path: null,
        showSubmenu: true,
        children: [
            {
                key: 'product_catalogue',
                title: dictionary('product_catalogue'),
                path: null,
                externalLink: {
                    link: getCountryCode() === 'SG' ? 'https://d351kq7iyhnk3y.cloudfront.net/flip/SGP_Catalogue' : dictionaryNative('product_catalogue_link_en'),
                    target: 'blank'
                },
                children: [],
                auth: false,
                countries: ['SG'],
                userCountry: true,
                parent: 'resources'
            },
            {
                key: 'magazine',
                title: dictionary('magazine'),
                path: null,
                externalLink: {
                    link: 'https://d351kq7iyhnk3y.cloudfront.net/flip/GLIC2019_Mobile',
                    target: 'blank'
                },
                children: [],
                auth: false,
                countries: ['SG'],
                userCountry: true,
                parent: 'resources'
            },
            // {
            //     key: 'umag',
            //     title: dictionary('umag').toUpperCase(),
            //     path: null,
            //     externalLink: {
            //         link: 'http://umag.unicity.com/books/gnge/',
            //         target: 'blank'
            //     },
            //     children: [],
            //     auth: false,
            //     countries: ['SG'],
            //     userCountry: true,
            //     parent: 'resources'
            // },

        ],
        auth: true,
        countries: ['SG'],
        userCountry: true
    },
    {
        key: 'commission',
        title: dictionary('commission').toUpperCase(),
        path: `/commission`,
        children: [
            // {
            //     key: 'statement',
            //     title: dictionary('statement').toUpperCase(),
            //     path: `/commission/statement`,
            //     children: [],
            //     auth: false,
            //     countries: true,
            //     userCountry: true
            // },
            // {
            //     key: 'lsb',
            //     title: dictionary('lsb').toUpperCase(),
            //     path: `/commission/lsb`,
            //     children: [],
            //     auth: false,
            //     countries: true,
            //     userCountry: true
            // }
        ],
        auth: true,
        countries: ['SG', 'PH', 'TH', 'VN', 'ID', 'AE', 'TW', 'HK', 'AU', 'NZ', 'KH', 'US'],
        userCountry: ['SG', 'PH', 'TH', 'VN', 'ID', 'AE', 'TW', 'HK', 'AU', 'NZ', 'KH', 'US']
    },
    {
        key: 'award_plan_summary',
        title: 'award_plan_summary',
        path: `/award-plan-summary`,
        children: [],
        auth: true,
        countries: ['ID'],
        userCountry: ['ID']
    },
    {
        key: 'program_and_promotion_summary',
        title: 'program_and_promotion_summary',
        path: `/program-and-promotion-summary`,
        children: [],
        auth: true,
        countries: ['ID'],
        userCountry: ['ID']
    },
    {
        key: 'news',
        title: dictionary('news').toUpperCase(),
        path: `/news`,
        children: [],
        auth: true,
        countries: ['JP', 'ID'],
        userCountry: ['JP', 'ID']
    },
    {
        key: 'reports',
        title: dictionary('reports').toUpperCase(),
        path: `/reports`,
        children: [],
        auth: true,
        countries: ['SG', 'PH', 'TH', 'VN', 'ID', 'AE', 'JP', 'IN', 'US', 'BH', 'HK', 'AU', 'NZ', 'KH'],
        userCountry: ['SG', 'PH', 'TH', 'VN', 'ID', 'AE', 'JP', 'IN', 'US', 'BH', 'HK', 'AU', 'NZ', 'KH']
    },
    // {
    //     key: 'seminar',
    //     title: dictionary('seminar').toUpperCase(),
    //     path: `/seminar`,
    //     children: [],
    //     auth: true,
    //     countries: ['SG', 'PH', 'TH', 'VN', 'AE', 'JP', 'KH', 'LA', 'MM', 'AU', 'NZ'],
    //     userCountry: ['SG', 'PH', 'TH', 'VN', 'AE', 'JP', 'KH', 'LA', 'MM', 'AU', 'NZ']
    // },

    {
        key: 'autoship',
        title: dictionary('autoship'),
        path: `/autoship`,
        children: [],
        auth: true,
        countries: ['JP'],
        userCountry: ['JP']
    },

    {
        key: 'marketing_artworks',
        title: dictionary('u_all_sidebar_marketing_artworks'),
        path: '/marketing_artworks',
        children: [],
        auth: true,
        countries: ['TH'],
        userCountry: ['TH']

    },
    {
        key: 'contact_us',
        title: dictionary('contact_us').toUpperCase(),
        path: null,
        render: 'FeedbackForm',
        children: [
            {
                key: 'chat_with_us',
                title: dictionary('u_all_sidebar_contact_chat_with_us'),
                path: null,
                externalLink: {
                    link: StoreConfigs.FacebookMessenger(),
                    target: 'blank'
                },
                children: [],
                auth: false,
                countries: true,
                userCountry: true,
                parent: 'contact_us'
            },
            {
                key: 'feedback',
                title: dictionary('u_all_sidebar_contact_send_feedback'),
                path: null,
                render: 'FeedbackForm',
                auth: false,
                countries: true,
                userCountry: true,
                parent: 'contact_us'
            }

        ],
        auth: false,
        countries: true,
        userCountry: true
    },
    {
        key: 'logout',
        title: dictionary('logout').toUpperCase(),
        children: [],
        auth: true,
        countries: true,
        userCountry: true,
        onClick: () => {
            logout(true)
        }
    }
]

export const sortingMenu = (country) => {

    const list = {
        default: [
            'credit_balance_default_data',
            'home',
            'dashboard',
            'shopping',
            'products',
            'enroll',
            'genealogy',
            'success',
            'nfr',
            'commission',
            'award_plan_summary',
            'program_and_promotion_summary',
            'news',
            'reports',
            'resources',
            'seminar',
            'autoship',
            'product_catalogue',
            'marketing_artworks',
            'contact_us',
            'logout'
        ],
        SG: [
            'credit_balance_default_data',
            'home',
            'dashboard',
            'product_catalogue',
            'shopping',
            'products',
            'enroll',
            'genealogy',
            'success',
            'nfr',
            'commission',
            'award_plan_summary',
            'program_and_promotion_summary',
            'news',
            'reports',
            'resources',
            'magazine',
            'seminar',
            'autoship',
            'contact_us',
            'logout'
        ]
    }

    return list[country] !== undefined ? list[country] : list.default

}
