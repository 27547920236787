import React, { useEffect, useState } from 'react'
import { storeEnroll } from '@Stores/StoreEnroll'
import { storeProduct } from '@Stores/StoreProduct'
import { observer } from 'mobx-react-lite'
import { getParameterByName } from '@GlobalHelpers'
import { appConfig } from '@Components/config'
import { getQueryString } from '@Components/configs/ConfigsHeader'
import { Country } from '@Stores/StoreConfigCountry'
import { getFormatEnrollSummary, getFormatEnrollSummaryForTWOnly } from '@Components/V2/APIs'
import { isSomething } from '@Components/utils/Utils'
import { storeBowser } from '@Stores/StoreBowser'
import { enrollStore } from '@Stores/MainStore'
import { language } from '@Language'

import EnrollFactory from './lib/EnrollFactory'
import Products, { StarterKitSetBox } from './Products'
import Redirect from '@Components/Redirect'
import ActionFooter from './Common/ActionFooter'

import styled from 'styled-components'
import first from 'lodash/first'

import '../Layouts/2020/Components/enroll.scss'
import './enrollment.scss'

const Enrollment = observer(props => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            if (storeEnroll.productSource.length === 0) {
                if (Country.isTaiwan()) {
                    setEnrollStarterKitV2()
                } else if (Country.isCambodia()) {
                    setEnrollStarterKitV2()
                } else {
                    setEnrollStarterKit()
                }
            }
        }, 1500)

        if (storeEnroll.proceedCheckout) {
            setLoading(false)
        }
    }, [])

    const setEnrollStarterKit = () => {
        if (storeProduct.starterKits) {
            const refID = getQueryString().ref_id
            if (refID) {
                EnrollFactory.Enroll(refID)
            }
            storeEnroll.InitStarterKit(storeProduct.starterKits, refID).then(r => {
                setLoading(false)
            })
            if (Country.isPhilippines()) {
                storeProduct.starterKits.map(v => {
                    storeEnroll.starterKitItemsPH.push(v)
                })
            }
        }
    }

    const setEnrollStarterKitV2 = async () => {
        setLoading(true)
        if (storeProduct.starterKits) {
            let id = getParameterByName('id')
            if (id) {
                getProductUUID(atob(id))
            } else {
                setLoading(false)
            }
            storeEnroll.InitStarterKit(storeProduct.starterKits).then(r => {
                // setLoading(false)
            })
        }
    }

    const getProductUUID = async id => {
        try {
            let res
            if (Country.isTaiwan()) {
                res = await getFormatEnrollSummaryForTWOnly(id)
            } else {
                res = await getFormatEnrollSummary(id)

            }
            let product = []
            let starter_kit_item_code = first(res.data.ushop.items)

            product = res.data.ushop.items.map(element => {
                return element.item_code
            })
            let orderedProducts = res.data.ushop.items
            // let orderedProducts = [];
            // orderedProducts = res.data.ushop.items.map((element) => {
            //     let product2 = storeProduct.products.get(element.item_code)
            //     if (!product2) {
            //         product2 = storeProduct.starterKits.find(x => x.item_code === element.item_code)
            //     }

            //     return {
            //         ...product2,
            //         qty: element.qty,
            //         item: {
            //             href: `https://hydra.unicity.net/v5a/items?id.unicity=${element.item_code}`
            //         },
            //         quantity: element.qty,
            //         item_code: element.item_code,
            //         href_params: [`id.unicity=${element.item_code}`]
            //     }
            // })
            storeEnroll.starterKitSelected = starter_kit_item_code.item_code
            storeEnroll.selectedItems = product
            storeEnroll.productSource = orderedProducts
            storeEnroll.checkoutCart = orderedProducts

            enrollStore.productSource = orderedProducts

            EnrollFactory.EnrollData = res.data.ushop.customer
            EnrollFactory.EnrollData.verifyEnroller = true
            EnrollFactory.EnrollData.verifyGovId = true

            setLoading(false)
        } catch (err) {
            if (err.response.data.message === 'uuid expired') {
                window.location.replace(`/${appConfig.country}/enroll`)
                // history.push(`/${appConfig.country}/enroll`);
            }
        }
    }

    return (
        <StyledEnrollmentWrapper className="enrollment--wrapper">
            {(() => {
                if (loading) {
                    return (
                        <div className="enroll-loading-spinner">
                            <Redirect style={{ marginTop: -75 }} />
                        </div>
                    )
                } else {
                    if (Country.isThailand()) {
                        return (
                            <div className="enroll-loading-spinner">
                                <Redirect style={{ marginTop: -75 }} />
                                <div className="d-none">
                                    <ActionFooter />
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <>
                                <StarterKitSetBox />
                                <ProductListBlockWrapper>
                                    <Products isStarterKit={false} />
                                    <ActionFooter />
                                </ProductListBlockWrapper>
                            </>
                        )
                    }
                }
            })()}
        </StyledEnrollmentWrapper>
    )
})

const ProductListBlockWrapper = observer(props => {
    const isProductEmpty = storeEnroll.productSource.filter(o => o.item_code !== storeEnroll.starterKitSelected).length === 0

    return (
        <div
            className={`enrollment--product-list-wrapper ${storeBowser.isMobile() ? 'list-mobile' : 'list-desktop'}`}
            style={storeEnroll.isLoadingProfileID === false && isProductEmpty === false && isSomething(storeEnroll.starterKitSelected) ? { background: 'rgb(227 232 236)' } : {}}>
            {(() => {
                if (storeEnroll.isLoadingProfileID === false && isProductEmpty === false && isSomething(storeEnroll.starterKitSelected)) {
                    return (
                        <div className="text-center">
                            <h1>{language.listen('renewal_title_initial_order')}</h1>
                        </div>
                    )
                }
            })()}
            {props.children}
        </div>
    )
})

const StyledEnrollmentWrapper = styled.div`
    min-height: 50vh;
`

export default Enrollment
