import { useState, useEffect } from 'react'
import { Observer, observer } from 'mobx-react-lite'
import { isSomething, defaults, trim, isString, isObject } from '@Utils/Utils'
import { language as l } from '@Language'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Alert from 'antd/es/alert'
import PropTypes from 'prop-types'
import { has, uniqueId } from 'lodash'

/**
 * @param {*} props
 * @param {object|string} props.alertClassName
 * @param {object} props.alertStyle
 * @param {object|string} props.className
 * @param {object} props.style
 * @param {array|array[string|object]} props.list
 * @param {string} props.name
 * @param {'error'|'warning'|'success'|'info'} props.type
 * @param {number|text} props.span
 * @param {number|text} props.xs
 * @param {number|text} props.sm
 * @param {number|text} props.lg
 * @param {number|text} props.xl
 * @param {number|text} props.xxl
 * @param {boolean} props.noFrame
 */
const SuperAlert = observer(props => {
    const { className, style, alertClassName, alertStyle, list, type, description, title, name, span, ...customProps } = props

    const isNoFrame = props.noFrame !== undefined || props.noFrame === true
    let additionalClassName = ''
    if (isNoFrame) additionalClassName += 'no-frame'

    const [responsiveGrid, setResponsiveGrid] = useState({ span: 24 })

    useEffect(() => {
        setResponsiveGrid({
            span: defaults(props.span, 24),
            xs: props.xs,
            sm: props.sm,
            md: props.md,
            lg: props.lg,
            xl: props.xl,
            xxl: props.xxl
        })
    }, [props.span, props.xs, props.sm, props.md, props.lg, props.xl, props.xxl])

    return (
        <>
            {(() => {
                if (isSomething(list)) {
                    return (
                        <Row justify="center" 
                            className={trim(`super-alert ${additionalClassName} ${defaults(className, '')}`)} 
                            style={style}
                        >
                            <Col {...responsiveGrid}>
                                <Alert {...customProps}
                                    className={alertClassName}
                                    style={alertStyle}
                                    type={defaults(type, 'error')}
                                    description={<AlertList list={list} />}
                                />
                            </Col>
                        </Row>
                    )
                } else {
                    return null
                }
            })()}
        </>
    )
})

const AlertList = observer(props => {
    const { list } = props

    const processSuspectedKey = value => {
        if (isString(value)) {
            // test suspected dictionary key
            let output = value
            if ((/^[a-z0-9_-]+$/g).test(value)) {
                const d = l.listen(value, { autoPrefix: false, keyWithBrackets: true })
                if (d.indexOf(']') > -1) {
                    // dictionary not contained this key
                    output = value
                } else {
                    // dictionary key
                    output = d
                }
            }
            return output
        } else {
            return value
        }
    }

    return (
        <>
            {(() => {
                if (isSomething(list)) {
                    return (
                        <ul>
                            {list.map((item, index) => {
                                const currentKey = uniqueId(`alert-item-${index}`)
                                if (isString(item)) {
                                    return (
                                        <Observer key={currentKey}>
                                            {() => 
                                                <li>{processSuspectedKey(item)}</li>
                                            }
                                        </Observer>
                                    )
                                } else {
                                    // verify primary key of object
                                    if (isObject(item) && has(item, 'title')) {
                                        const resultOutput = [] 
                                        resultOutput.push(
                                            <Observer key={`${currentKey}-title`}>
                                                {() => 
                                                    <li className="super-alert-title">
                                                        {processSuspectedKey(item.title)}
                                                    </li>
                                                }
                                            </Observer>
                                        )
                                        if (has(item, 'subtitle')) {
                                            resultOutput.push(
                                                <Observer key={`${currentKey}-subtitle`}>
                                                    {() =>
                                                        <li className="super-alert-subtitle">
                                                            {processSuspectedKey(item.subtitle)}
                                                        </li>
                                                    }
                                                </Observer>
                                            )
                                        }
                                        return resultOutput
                                    } else {
                                        return null
                                    }
                                }
                            })}
                        </ul>
                    )
                } else {
                    return null
                }
            })()}
        </>
    )
})

SuperAlert.propTypes = {
    alertClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    alertStyle: PropTypes.object,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    style: PropTypes.object,
    name: PropTypes.string,
    type: PropTypes.string
}

SuperAlert.defaultProps = {
    list: [],
    name: '',
    type: 'error'
}

export default SuperAlert
