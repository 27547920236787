import React, { Component, useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { getParameterByName, dictionary } from '@GlobalHelpers'
import success from '@Assets/customlink/payment_success.svg'
import regisSuccess from '@Assets/customlink/regis_success.svg'
import regisSuccessV2 from '@Assets/customlink/regis_successV2.svg'
import error from '@Assets/customlink/payment_failed_v2.svg'
import Spinner from '../Spinner'
import { appConfig } from '../config'
import { apiURL, getCurrentDomain } from '../ShoppingHelper'
import { Button } from 'antd'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import $ from 'jquery'
import last from 'lodash/last'
import { Else, If, Then, When } from 'react-if'
import Lightbox from 'react-image-lightbox'
import './enroll-postback.scss'
import { language } from '@Language'
import StoreCountry from '@Stores/StoreCountry'
import { Country } from '@Stores/StoreConfigCountry'
import { QRCode } from 'react-qr-svg'

const PostBack = observer(props => {
    const [ajaxLoading, setAjaxLoading] = useState(true)
    const [status, setStatus] = useState(props.match.params.paymentstatus)
    const [checkOrderIdError, setCheckOrderIdError] = useState('')
    const [message, setMessage] = useState('')
    const [message2, setMessage2] = useState('')
    const [title, setTitle] = useState('')
    const [messageOrderId, setMessageOrderId] = useState('')
    const [new_id, setNew_id] = useState('')
    const [count, setCount] = useState(0)
    const [referenceId, setReferenceId] = useState(getParameterByName('Ref'))
    const maxCountRequestCheckOrder = 5

    const [referenceId3, setReferenceId3] = useState(null)
    const toClipboard = getParameterByName('Ref')
    const [linkDownloadPdf, setLinkDownloadPdf] = useState('')
    const [textCopyLoading, settextCopyLoading] = useState(false)
    const textAreaRef = useRef(null)

    const copyToClipboard = e => {
        textAreaRef.current.select()
        document.execCommand('copy')
        e.target.focus()
        settextCopyLoading(true)
        setTimeout(() => {
            settextCopyLoading(false)
        }, 5000)
    }

    const processRefID = () => {
        if (Country.isCambodia()) {
            setAjaxLoading(false)
            setMessageOrderId(referenceId)
        } else if (Country.isMena()) {
            setAjaxLoading(false)
            setMessageOrderId(referenceId)
        } else {
            if (count <= maxCountRequestCheckOrder) {
                checkOrderId()
            } else {
                setAjaxLoading(false)
                setMessageOrderId(referenceId)
            }
        }
    }

    const getPDFDownload = () => {
        let postData = {
            ref_id: getParameterByName('Ref'),
            language: 'EN',
            regenerate: false
        }
        $.post('https://member-calls.unicity.com/ALL/PDF/kh_enrollV2.php', JSON.stringify(postData))
            .done(function (resultPDF) {
                setLinkDownloadPdf(resultPDF.urlpdf)
                setAjaxLoading(false)
                setMessageOrderId(referenceId)
            })
            .fail(function (errorPDF) {
                setAjaxLoading(false)
            })
    }

    const checkOrderId = () => {
        if (status === 'success') {
            setAjaxLoading(true)
            setCheckOrderIdError(false)
            let postData = { reference_id: referenceId }
            $.post(apiURL.shopping.checkOrderId, JSON.stringify(postData))
                .done(function (result) {
                    if (result.language) {
                        language.current = result.language
                    }

                    if (result.order_id) {
                        setMessageOrderId(last(result.order_id.split('-')))
                        setNew_id(result.new_id)
                        setReferenceId3(result.reference_id_3)
                        setAjaxLoading(false)
                    } else {
                        setTimeout(() => {
                            setCount(count + 1)
                        }, 5000)
                    }
                })
                .fail(function (error) {
                    setAjaxLoading(false)
                })
        } else {
            setAjaxLoading(false)
            if (StoreCountry.Country2() === 'TH') {
                checkOrderIdWithInquery(referenceId)
            }
        }
    }

    const checkOrderIdWithInquery = reference_id => {
        let postData = { reference_id: reference_id }

        $.post(apiURL.shopping.checkOrderIdWithInquery, JSON.stringify(postData)).done(successHandler).fail(failHandler)

        function successHandler(result) {
            if (result.language) {
                language.current = result.language
            }

            if (result.order_id) {
                setMessageOrderId(result.order_id)
                setNew_id(result.new_id)
                setAjaxLoading(false)
            } else {
                failHandler()
            }
        }
        function failHandler(err) {
            setAjaxLoading(false)
            setMessageOrderId(getParameterByName('Ref'))
        }
    }

    useEffect(() => {
        if (!getParameterByName('order_id')) {
            processRefID()
        } else {
            if (Country.isThailand()) {
                // checkOrderId()
            }
        }
    }, [count])

    useEffect(() => {
        setLanguage()

        if (status === 'success') {
            let reference_id = getParameterByName('Ref')
            let order_id = getParameterByName('order_id')

            // console.log("reference_id",reference_id)
            // console.log("order_id",order_id)

            if (order_id) {
                // let new_order_id = order_id.replace(/65-/gi, '')
                // new_order_id = new_order_id.replace(/63-/gi, '')
                // new_order_id = new_order_id.replace(/85-/gi, '')
                // new_order_id = new_order_id.replace(/66-/gi, '')
                let new_order_id = last(order_id.split('-'))
                setMessageOrderId(new_order_id)
                setAjaxLoading(false)
            } else {
                if (reference_id) {
                    if (Country.isThailand() || Country.isHongKong()) {
                        processRefID()
                    } else {
                        setMessageOrderId('')
                    }
                } else {
                    setAjaxLoading(false)
                }
            }
        } else {
            setAjaxLoading(false)
        }

        // setTimeout(()=>{
        //     setAjaxLoading(false)
        // },2000)
    }, [])

    useEffect(() => {
        if (Country.isThailand()) {
            setNew_id(getParameterByName('new_id'))
        }
        if (Country.isMena()) {
            setNew_id(getParameterByName('new_id'))
        }
        if (Country.isSingapore()) {
            setNew_id(getParameterByName('new_id'))
        }
        if (Country.isJapan()) {
            setNew_id(getParameterByName('new_id'))
        }
        if (Country.isPhilippines()) {
            setNew_id(getParameterByName('new_id'))
        }
        if (Country.isAustralia()) {
            setNew_id(getParameterByName('new_id'))
        }
    }, [])

    let alert = status === 'failed' ? `payment-fail` : 'payment-success'

    const openDialPad = number => {
        if (props.store.isWebview) {
            if (props.store.latestWebview) {
                window.ReactNativeWebView.postMessage(JSON.stringify({ data: `tel: + ${number}`, targetFunc: 'redirect' }))
            } else {
                window.WebViewBridge.send(JSON.stringify({ data: `tel: + ${number}`, targetFunc: 'redirect' }))
            }
        } else {
            window.location.href = `tel: + ${number}`
        }
    }

    const setLanguage = () => {
        let langCurrent = localStorage.getItem('language')
        if (!langCurrent) {
            localStorage.setItem('lanuage', language.current)
        } else {
            language.current = langCurrent
        }

        if (status === 'success') {
            setTitle(dictionary('payment_thank_you'))
            setMessage(dictionary('payment_order_placed'))
            setMessage2(dictionary(''))
        } else {
            setTitle(dictionary('payment_something_wrong'))
            setMessage(dictionary('payment_try_again_th'))
            setMessage2(dictionary('payment_if_error_persists'))
        }
    }

    const backToShop = () => {
        let refID = getParameterByName('ref_id')

        let url = getCurrentDomain() + '/' + appConfig.country + '/enroll/payment-resume?ref_id=' + refID + '&status=resume'

        window.location = url
    }

    return (
        <div className={`offcanvas-wrapper padding-bottom-3x customlink-result enroll-result ${alert}`}>
            {ajaxLoading && <Spinner />}

            {!ajaxLoading && (
                <div className="container">
                    <div className="card text-center card-payment-success">
                        {status === 'success' && Country.isCambodia() ? (
                            <img className="postbackenroll-img" src={regisSuccess} />
                        ) : Country.isMena() ? (
                            <img className="postbackenroll-mena-img" src={regisSuccessV2} />
                        ) : (
                            <img className="postbackenroll-img" src={success} />
                        )}
                        {status === 'failed' && <img className="postbackenroll-img" src={error} />}

                        <div className="card-body" style={Country.isCambodia() ? { paddingBottom: 20 } : Country.isMena() ? { paddingBottom: 20 } : {}}>
                            <h3 className={`card-title`}>
                                {status === 'success' && dictionary('u_all_enroll_payment_thank_you')}
                                {status === 'failed' && dictionary('u_all_enroll_payment_failed')}
                            </h3>

                            <div className="text">
                                {status === 'success' && (Country.isCambodia() || Country.isMena()) && <span style={{ fontSize: 14, color: '#9ea9b9' }}>{dictionary('u_all_enroll_success_register_massage')}</span>}

                                {status === 'success' && !Country.isCambodia() && !Country.isMena() && dictionary('u_all_enroll_success_massage')}

                                {status === 'failed' && dictionary('u_all_enroll_failed_massage')}
                            </div>

                            <When condition={status === 'success'}>
                                <div className="mt-4" style={Country.isCambodia() ? { padding: '0px 10px' } : { padding: '0px 20px' }}>
                                    {new_id && (
                                        <div className="messageOrderId messageNewId">
                                            <span className="">{dictionary('u_all_enroll_distributor_number')} : </span>
                                            {new_id}
                                        </div>
                                    )}
                                    <If condition={Country.isCambodia()}>
                                        <Then>
                                            <div className="messageOrderId mt-3">
                                                <div className="boxCopy">
                                                    <span className="" style={{ fontWeight: 'bold' }}>
                                                        {dictionary('u_all_reference_id')} :{' '}
                                                    </span>
                                                    <input className="inputCopy" ref={textAreaRef} value={toClipboard} />
                                                    <button
                                                        // onClick={() => copyToClipboard(toClipboard, handleClipboardWriteSuccess, handleClipboardWriteError)}
                                                        onClick={copyToClipboard}
                                                        className={textCopyLoading ? 'btCopyToClipboardActive' : 'btCopyToClipboard'}
                                                        disabled={textCopyLoading}>
                                                        {textCopyLoading ? dictionary('u_all_copide') : dictionary('u_all_copy')}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="messageOrderId mt-3">
                                                <div className="boxCopy">
                                                    <span className="" style={{ fontWeight: 'bold', verticalAlign: 'sub' }}>
                                                        {dictionary('u_kh_application_form')} :{' '}
                                                    </span>
                                                    {/* <input className="inputCopy" ref={textAreaRef} value={toClipboard} /> */}
                                                    <a target="_blank" href={linkDownloadPdf}>
                                                        <button className={'btCopyToDownload'}>{dictionary('u_all_download')}</button>
                                                    </a>
                                                </div>
                                            </div>
                                        </Then>
                                        <Else>
                                            <If condition={Country.isMena()}>
                                                <Then></Then>
                                                <Else>
                                                    <div className="messageOrderId mt-3">
                                                        <span className="">{dictionary('u_all_enroll_order_number')} : </span>
                                                        {messageOrderId.replace(/66-/gi, '')}
                                                    </div>
                                                </Else>
                                            </If>
                                        </Else>
                                    </If>

                                    <If condition={Country.isThailand()}>
                                        <Then>
                                            {/* <VerifyId referenceId={referenceId3} /> */}
                                            <DigitalStarterKitButton />
                                        </Then>
                                        <Else>
                                            <If condition={Country.isCambodia()}>
                                                <Then>
                                                    <QRCode bgColor="#FFFFFF" fgColor="#000000" level="H" style={{ width: 150, marginTop: 30 }} value={referenceId} />
                                                </Then>
                                                <Else>
                                                    <BackToMenu />
                                                </Else>
                                            </If>
                                        </Else>
                                    </If>
                                </div>
                            </When>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
})

const BackToMenu = observer(props => {
    const [loading, setLoading] = useState(false)
    const backToMenu = () => {
        setLoading(true)
        setTimeout(() => {
            let project = 'ushop'
            let path = '/menu'
            if (/splash/.test(window.location.href)) {
                project = 'express'
                path = ''
            }
            let url = ''
            if (StoreCountry.CountryLowerCase() === 'mena') {
                url = `https://${project}.unicity.com/office#${path}`
            } else {
                url = `https://${project}.unicity.com/${StoreCountry.CountryLowerCase()}${path}`
            }

            window.location = url
        }, 2000)
    }

    return (
        <div className="block-button">
            <Button
                block={true}
                loading={loading}
                onClick={() => {
                    backToMenu()
                }}>
                <If condition={Country.isMena()}>
                    <Then> {dictionary('u_all_enroll_login_now')}</Then>
                    <Else> {dictionary('u_all_enroll_back_to_menu')}</Else>
                </If>
                {/* <Row type="flex" justify="center" className="ml-1">
                    <Col span={24}>
                        <span>
                            <If condition={Country.isMena()}>
                                <Then>{dictionary('u_all_enroll_login_now')}</Then>
                                <Else>{dictionary('u_all_enroll_back_to_menu')}</Else>
                            </If>
                        </span>
                    </Col>
                </Row> */}
            </Button>
        </div>
    )
})

const DigitalStarterKitButton = observer(props => {
    return (
        <>
            <div style={{ margin: '20px 0px' }}>
                <p className="sub-title-2">{language.listen('enrollpostback_digital_staterkit_description')}</p>
            </div>
            <div>
                <a href={`https://ushop.unicity.com/thailand/digital-starter-kit-v1?lang=${language.current}&through`} className="btn btn-primary no-margin" target="_blank">
                    {language.listen('digital_starter_kit')}
                </a>
            </div>
            <div className="text-center" style={{ marginTop: 20 }}>
                <p style={{ fontFamily: 'Maven Pro', marginBottom: 10, color: '#707783' }}>
                    LINE ID:{' '}
                    <a href="https://page.line.me/unicity?openQrModal=true" target="_blank">
                        @uniticy
                    </a>
                </p>
                <img src="https://ushop-media.unicity.com/images/footer/LineTH.svg" />
            </div>
        </>
    )
})

const VerifyId = observer(props => {
    const history = useHistory()
    const [isShowLightBox, setIsShowLightBox] = useState(false)

    const openLightBox = () => {
        setIsShowLightBox(!isShowLightBox)
    }

    const img = ['https://d351kq7iyhnk3y.cloudfront.net/THA/ID_Verification_Offfline_TH_202006.jpg']

    return (
        <>
            <div style={{ margin: '30px 0px' }}>
                <p className="sub-title-2">{dictionary('u_all_enrollpostback_thankyou_subtitle_2')}</p>
            </div>
            <div className="buttons-for-verify-id">
                <Button type="primary" onClick={() => history.push(`/thailand/enroll/id-verification/${props.referenceId}`)}>
                    {dictionary('u_all_enrollpostback_selfie_by_myself')}
                </Button>
                <p>{dictionary('u_all_enrollpostback_or')}</p>
                <Button type="ghost" onClick={() => openLightBox()}>
                    {dictionary('u_all_enrollpostback_send_mail')}
                </Button>
                <When condition={isShowLightBox}>
                    <Lightbox mainSrc={img[0]} onCloseRequest={openLightBox} />
                </When>
            </div>
        </>
    )
})

export default PostBack
