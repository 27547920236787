import { makeAutoObservable, get as mget } from 'mobx';
import { randomNumber, randomString } from '../../../../components/GlobalHelpers';

class StoreOrderDetails {
    isLoadingAPI = false
    hasError = false
    errorMessage = []
    isCheckedTC = false
    memoPath = null
    passOrdersummary = false

    constructor() {
        makeAutoObservable(this)
    }
}

class StoreSleepy {
    enabled = false
    thailand = {
        zipLength: 5,
        mobileLength: 10
    }
    singapore = {
        zipLength: 6,
        mobileLength: 8
    }

    mockupMobile(country) {
        return randomNumber(mget(this, country).mobileLength)
    }

    mockupEmail() {
        return `${randomString(8, true, true, true)}@unicity.com`
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export const storeOrderDetails = new StoreOrderDetails()
export const storeSleepy = new StoreSleepy()