/* eslint-disable no-empty */
import React, { useEffect } from 'react'
import { Router, Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { observer, useLocalObservable } from 'mobx-react-lite'

import { store, storeManager, shopStore } from '../../stores/MainStore'
import history from '../History'
import Spinner from '../Spinner'
import Header from '../Header'
import { getCountry, getCountryCode } from '../GlobalHelpers'
import { language } from '../../stores/StoreLanguage'
import CustomlinkPostBack from '../customlink/CustomlinkPostBack'
import CustomlinkPostBackSocial from '../shopsocial/CustomlinkPostBackSocial'
import RenewalPostBack from '../renewal/RenewalPostBack'
import EnrollPostBack from '../enroll/EnrollPostBack'

import Loadable from 'react-loadable'
import Renewal from '../renewal/Renewal'
import Enrollment from '../enroll/Enrollment'
import { StoreSessionTimeout } from '@Stores/StoreSessionTimeout'
import StoreCountry from '@Stores/StoreCountry'
import Splash from '@Components/Splash/Splash'
import { Country } from '@Stores/StoreConfigCountry'
import { isArray, isString } from '@Components/utils/Utils'
import loadable from '@loadable/component'
import { loge, logn } from '@Utils/PikaLog'
import TrackingEvents from '@Services/Tracker/TrackingEvents'
import TestNewWatermark from '@Components/test/TestNewWatermark'
import { exceptCountryWithoutProduct } from '@Components/config'
import { SectionSkeletonRegister } from '@Components/enroll/SectionSkeletonRegister'

let country = localStorage.getItem('country')
if (country === null) {
    country = getCountry()
}

const Loading = props => {
    return (
        <div className="page-spin">
            <Spinner />
        </div>
    )
}

const LoadingEnroll = props => {
    if (exceptCountryWithoutProduct(getCountryCode())) {
        return (
            <div className="container" style={{ paddingTop: 30 }}>
                <SectionSkeletonRegister />
            </div>
        )
    } else {
        return (
            <div className="page-spin enroll-loading-spinner">
                <Spinner />
            </div>
        )
    }
}

// Old Loadable
const ShopsocialDsc = Loadable({
    loader: () => import(`../shopsocial/DSC/${country}/Ordersummary`),
    loading: Loading
})

const RenewOrderSummary = Loadable({
    loader: () => import(`../renewal/${country}/OrderSummary`),
    loading: Loading
})

const Autoship = Loadable({
    loader: () => import(`../autoship/${country}/Autoship`),
    loading: Loading
})

const AutoshipJPV2 = Loadable({
    loader: () => import('../autoship/japanV2/Autoship'),
    loading: Loading
})

const AutoshipOrderSummary = Loadable({
    loader: () => import(`../autoship/${country}/OrderSummary`),
    loading: Loading
})

const AutoshipOrderSummaryV2 = Loadable({
    loader: () => import(`../autoship/japanV2/OrderSummary`),
    loading: Loading
})

const EnrollmentWithoutProduct = Loadable({
    loader: () => import(`../enroll/${country}/EnrollStep`),
    loading: LoadingEnroll
})

const EnrollmentSummary = Loadable({
    loader: () => import(`../enroll/${country}/OrderSummary`),
    loading: LoadingEnroll
})

const EnrollmentDetail = Loadable({
    loader: () => import(`../enroll/${country}/EnrollStep`),
    loading: LoadingEnroll
})
const IDVerification = Loadable({
    loader: () => import(`../enroll/IDVerification/IDVerification`),
    loading: Loading
})

const HydraProcess = Loadable({
    loader: () => import(`../hydraprocess/hydraProcess`),
    loading: Loading
})
const PageNotFound = Loadable({
    loader: () => import(`../notFound/PageNotFound`),
    loading: Loading
})

/* const TestComponent = Loadable({
    loader: () => import('../test/TestComponent'),
    loading: Loading
}) */

const Customlink = loadable(() => import(`../customlink/Customlink`))

const ShopSocial = loadable(dynamicProps => import(`../shopsocial/${dynamicProps.dynamicCountry}/Ordersummary`), {
    cacheKey: cacheProps => cacheProps.dynamicCountry,
    fallback: Loading
})

const OrderSummaryCustomlink = loadable(dynamicProps => import(`../customlink/${dynamicProps.dynamicCountry}/OrderSummary`), {
    cacheKey: cacheProps => cacheProps.dynamicCountry,
    fallback: Loading
})

/** ProtectedRoute help with conditional route which could not be done with normal JSX condition.
 *
 * @param {*} props
 * @param {string} props.path Route path.
 * @param {boolean} props.exact Route path should be exactly matched.
 * @param {React.ReactNode} props.component Route component. If render function is exist, this props decided to skip.
 * @param {function} props.render Route render function. Always overrided component props.
 * @param {string|string[]} props.country Available country code (alpha-2) for this route. Could be string or array of string.
 * @param {boolean} props.visible Show or hide route, this one always overrided country props.
 */
const ProtectedRoute = observer(({ component: Component, render, country, visible = true, ...restProps }) => {
    const localStore = useLocalObservable(() => ({ shouldDisplay: false }))

    useEffect(() => {
        if (country) {
            localStore.shouldDisplay = Country.isMatchedCountry(isArray(country) ? country : isString(country) ? [country] : '')
        }
        localStore.shouldDisplay = visible
    }, [])

    return (
        localStore.shouldDisplay && (
            <Route
                {...restProps}
                render={props => {
                    if (render) {
                        return render()
                    } else if (Component) {
                        return <Component {...props} />
                    } else {
                        return null
                    }
                }}
            />
        )
    )
})

const AclCore = observer(props => {
    const location = useLocation()

    const changeLocationHandler = () => {
        if (StoreSessionTimeout.isSessionTimeout()) {
            StoreSessionTimeout.clearTimestampAndRefresh()
        } else {
            StoreSessionTimeout.initTimestamp()
        }
    }

    useEffect(changeLocationHandler, [location])

    return (
        <div className={`${language.current} site-wrap wrap-layout`} style={{ minHeight: /ordersummary/.test(window.location.href) ? window.innerHeight : 'auto' }}>
            <Switch>
                <ProtectedRoute exact country={['AU', 'NZ']} path={`/${StoreCountry.CountryLowerCase()}/autoship/:id/add`} component={Autoship} />
                <ProtectedRoute exact country={['AU', 'NZ']} path={`/${StoreCountry.CountryLowerCase()}/autoship/:id/edit/:autoshipid`} render={() => <Autoship edit />} />
                <ProtectedRoute exact country={['AU', 'NZ']} path={`/${StoreCountry.CountryLowerCase()}/autoship/ordersummary/:id`} component={AutoshipOrderSummary} />

                <ProtectedRoute
                    exact
                    country="JP"
                    path={`/${StoreCountry.CountryLowerCase()}/autoship/:id/add`}
                    render={props => {
                        return <Autoship {...props} history={history} store={store} />
                    }}
                />

                <ProtectedRoute
                    exact
                    country="JP"
                    path={`/${StoreCountry.CountryLowerCase()}/autoship/:id/edit/:autoshipid`}
                    render={props => {
                        return <Autoship {...props} history={history} store={store} edit={true} />
                    }}
                />

                <ProtectedRoute exact country="JP" path={`/${StoreCountry.CountryLowerCase()}/autoshipV2/:id/add`} component={AutoshipJPV2} />
                <ProtectedRoute exact country="JP" path={`/${StoreCountry.CountryLowerCase()}/autoshipV2/:id/edit/:autoshipid`} render={() => <AutoshipJPV2 edit />} />

                {/* <Route exact path={`/${StoreCountry.CountryLowerCase()}/splash/payment/:state_ref_id`} component={Splash} />
                <Route exact path={`/${StoreCountry.CountryLowerCase()}/splash/shipping-detail/:state_ref_id`} component={Splash} />
                <Route exact path={`/${StoreCountry.CountryLowerCase()}/splash/:state_ref_id`} component={Splash} />
                <Route exact path={`/${StoreCountry.CountryLowerCase()}/splash/`} component={Splash} /> */}
                <Route exact path={`/${StoreCountry.CountryLowerCase()}/wait-payment`} component={HydraProcess} />
                <Route exact path={`/${StoreCountry.CountryLowerCase()}/not-found`} component={PageNotFound} />
                <Route
                    exact
                    path={`/${StoreCountry.CountryLowerCase()}/splash/:paymentType/payment/`}
                    render={() => {
                        if (StoreCountry.CountryLowerCase() === 'australia') {
                            window.location.replace = `https://ushop-feelgreat.unicity.com/australia${window.location.search}`
                        } else {
                            return <Splash />
                        }
                    }}
                />
                <Route
                    exact
                    path={`/${StoreCountry.CountryLowerCase()}/splash/:paymentType/shipping-detail/`}
                    render={() => {
                        if (StoreCountry.CountryLowerCase() === 'australia') {
                            window.location.replace = `https://ushop-feelgreat.unicity.com/australia${window.location.search}`
                        } else {
                            return <Splash />
                        }
                    }}
                />
                <Route exact path={`/${StoreCountry.CountryLowerCase()}/splash/:paymentType/`} component={Splash} />
                <Route
                    exact
                    path={`/${StoreCountry.CountryLowerCase()}/splash/`}
                    render={() => {
                        if (StoreCountry.CountryLowerCase() === 'australia') {
                            window.location.replace = `https://ushop-feelgreat.unicity.com/australia${window.location.search}`
                        } else {
                            return <Splash />
                        }
                    }}
                />

                <Route
                    exact
                    path={`/${StoreCountry.CountryLowerCase()}/enroll/id-verification`}
                    render={() => {
                        return <Redirect to={`/${StoreCountry.CountryLowerCase()}/enroll`} />
                    }}
                />
                <Route exact path={`/${StoreCountry.CountryLowerCase()}/enroll/id-verification/:ref_id`} component={IDVerification} />
                <Route
                    exact
                    path={`/${StoreCountry.CountryLowerCase()}/enroll/payment-response/:paymentstatus`}
                    render={props => {
                        store.match = props
                        return (
                            <EnrollPostBack
                                {...props}
                                history={history}
                                store={store}
                                setInitialLanguage={lang => props.setInitialLanguage(lang)}
                                changeCurrentPage={(page, menuForce) => props.changeCurrentPage(page, menuForce)}
                                login={(username, password, callback) => props.login(username, password, callback)}
                            />
                        )
                    }}
                />

                <Route
                    exact
                    path={`/${StoreCountry.CountryLowerCase()}/enroll`}
                    render={props => {
                        if (exceptCountryWithoutProduct(getCountryCode())) {
                            return <EnrollmentWithoutProduct />
                        } else {
                            return <Enrollment />
                        }
                    }}
                />

                <Route exact path={`/${StoreCountry.CountryLowerCase()}/social/:id`}>
                    <ShopSocial dynamicCountry={country} />
                </Route>

                <Route
                    exact
                    path={`/${StoreCountry.CountryLowerCase()}/social-dsc/:id`}
                    render={props => {
                        return (
                            <ShopsocialDsc
                                {...props}
                                isEnrol={false}
                                history={history}
                                store={props.store}
                                quickNavNext={shopStore.quickNavNext}
                                currentPage={props.currentPage}
                                changeCurrentPage={(page, menuForce) => props.changeCurrentPage(page, menuForce)}
                            />
                        )
                    }}
                />

                <Route
                    exact
                    path={`/${StoreCountry.CountryLowerCase()}/renewal/:id`}
                    render={props => {
                        return <Renewal {...props} />
                    }}
                />

                <Route
                    exact
                    path={`/${StoreCountry.CountryLowerCase()}/enroll/:id`}
                    render={props => {
                        if (props.match.params.id === 'ordersummary' || props.match.params.id === 'payment-resume') {
                            return (
                                <EnrollmentSummary
                                    {...props}
                                    history={history}
                                    store={store}
                                    setInitialLanguage={lang => props.setInitialLanguage(lang)}
                                    changeCurrentPage={(page, menuForce) => props.changeCurrentPage(page, menuForce)}
                                    login={(username, password, callback) => props.login(username, password, callback)}
                                />
                            )
                        } else {
                            return (
                                <EnrollmentDetail
                                    {...props}
                                    history={history}
                                    store={store}
                                    setInitialLanguage={lang => props.setInitialLanguage(lang)}
                                    changeCurrentPage={(page, menuForce) => props.changeCurrentPage(page, menuForce)}
                                    login={(username, password, callback) => props.login(username, password, callback)}
                                />
                            )
                        }
                    }}
                />

                <Route exact path={`/${StoreCountry.CountryLowerCase()}/:status/:id`} component={Customlink} />

                <Route
                    exact
                    path={`/${StoreCountry.CountryLowerCase()}/:status/ordersummary/:id`}
                    render={props => {
                        store.match = props
                        if (props.match.params.status === 'renewal') {
                            return (
                                <RenewOrderSummary
                                    {...props}
                                    isEnrol={false}
                                    history={history}
                                    store={store}
                                    quickNavNext={shopStore.quickNavNext}
                                    currentPage={props.currentPage}
                                    changeCurrentPage={(page, menuForce) => props.changeCurrentPage(page, menuForce)}
                                />
                            )
                        } else if (props.match.params.status === 'autoship') {
                            return <AutoshipOrderSummary {...props} />
                        } else if (props.match.params.status === 'autoshipV2') {
                            return <AutoshipOrderSummaryV2 {...props} />
                        } else {
                            return (
                                <OrderSummaryCustomlink
                                    {...props}
                                    dynamicCountry={country}
                                    isEnrol={false}
                                    history={history}
                                    store={store}
                                    quickNavNext={shopStore.quickNavNext}
                                    currentPage={props.currentPage}
                                    changeCurrentPage={(page, menuForce) => props.changeCurrentPage(page, menuForce)}
                                />
                            )
                        }
                        // return (
                        //     props.match.params.status === 'renewal' ?
                        //         <RenewOrderSummary
                        //             {...props}
                        //             isEnrol={false}
                        //             history={history}
                        //             store={store}
                        //             quickNavNext={shopStore.quickNavNext}
                        //             currentPage={props.currentPage}
                        //             changeCurrentPage={(page, menuForce) => props.changeCurrentPage(page, menuForce)}
                        //         />
                        //         :
                        //         <SPOrderSummary
                        //             {...props}
                        //             isEnrol={false}
                        //             history={history}
                        //             store={store}
                        //             quickNavNext={shopStore.quickNavNext}
                        //             currentPage={props.currentPage}
                        //             changeCurrentPage={(page, menuForce) => props.changeCurrentPage(page, menuForce)}
                        //         />

                        // )
                    }}
                />

                <Route
                    exact
                    path={'/' + StoreCountry.CountryLowerCase() + '/:status/:id/payment-response/:paymentstatus'}
                    render={props => {
                        store.match = props
                        return (
                            <CustomlinkPostBack
                                {...props}
                                history={history}
                                store={store}
                                setInitialLanguage={lang => props.setInitialLanguage(lang)}
                                changeCurrentPage={(page, menuForce) => props.changeCurrentPage(page, menuForce)}
                                login={(username, password, callback) => props.login(username, password, callback)}
                            />
                        )
                    }}
                />

                <Route
                    exact
                    path={'/' + StoreCountry.CountryLowerCase() + '/:status/:id/payment-resume'}
                    render={props => {
                        store.match = props
                        return (
                            <OrderSummaryCustomlink
                                {...props}
                                dynamicCountry={country}
                                history={history}
                                store={store}
                                setInitialLanguage={lang => props.setInitialLanguage(lang)}
                                changeCurrentPage={(page, menuForce) => props.changeCurrentPage(page, menuForce)}
                                login={(username, password, callback) => props.login(username, password, callback)}
                            />
                            // <CustomlinkPostBack
                            //     {...props}
                            //     history={history}
                            //     store={store}
                            //     setInitialLanguage={(lang) => props.setInitialLanguage(lang)}
                            //     changeCurrentPage={(page, menuForce) => props.changeCurrentPage(page, menuForce)}
                            //     login={(username, password, callback) => props.login(username, password, callback)} />
                        )
                    }}
                />
                {/splash/.test(window.location.pathname) ? (
                    <Route exact path={`/${StoreCountry.CountryLowerCase()}/splash/:paymentType/payment-response/:paymentstatus`} component={EnrollPostBack} />
                ) : (
                    <Route
                        exact
                        path={'/' + StoreCountry.CountryLowerCase() + '/social/payment-response/:paymentstatus'}
                        render={props => {
                            store.match = props
                            return (
                                <CustomlinkPostBackSocial
                                    {...props}
                                    history={history}
                                    store={store}
                                    setInitialLanguage={lang => props.setInitialLanguage(lang)}
                                    changeCurrentPage={(page, menuForce) => props.changeCurrentPage(page, menuForce)}
                                    login={(username, password, callback) => props.login(username, password, callback)}
                                />
                            )
                        }}
                    />
                )}
                <Route
                    exact
                    path={'/' + StoreCountry.CountryLowerCase() + '/social/payment-response/:paymentstatus'}
                    render={props => {
                        store.match = props
                        return <CustomlinkPostBackSocial />
                    }}
                />

                <Route
                    exact
                    path={'/' + StoreCountry.CountryLowerCase() + '/renewal/payment-response/:paymentstatus'}
                    render={props => {
                        store.match = props
                        return (
                            <RenewalPostBack
                                {...props}
                                history={history}
                                store={store}
                                setInitialLanguage={lang => props.setInitialLanguage(lang)}
                                changeCurrentPage={(page, menuForce) => props.changeCurrentPage(page, menuForce)}
                                login={(username, password, callback) => props.login(username, password, callback)}
                            />
                        )
                    }}
                />
                {/* <Route exact path={`/${StoreCountry.CountryLowerCase()}/test-page`} component={TestComponent} /> */}
                <Route exact path={`/${StoreCountry.CountryLowerCase()}/enroll/test/test-watermark`} component={TestNewWatermark} />
                <Route
                    exact
                    path={`/${StoreCountry.CountryLowerCase()}`}
                    render={() => {
                        window.location.href = `//ushop.unicity.com/${StoreCountry.CountryLowerCase()}`
                        return false
                    }}
                />

                <Route
                    path="/*"
                    render={() => {
                        window.location.href = `//ushop.unicity.com/${StoreCountry.CountryLowerCase()}`
                        return false
                    }}
                />
            </Switch>
        </div>
    )
})

const AclWrapper = observer(props => {
    useEffect(() => {
        storeManager.isReadyToDisplayHome = true
    }, [])

    return (
        <Router history={history} basename={`/${StoreCountry.CountryLowerCase()}`}>
            <Header />
            <AclCore />
            {(() => {
                if (StoreCountry.isJapan() === false) {
                    return <DynamicFooter />
                } else {
                    return <DynamicFooterJP store={store} history={history} changeCurrentPage={(page, menuForce) => props.changeCurrentPage(page, menuForce)} />
                }
            })()}
        </Router>
    )
})

const DynamicFooter = loadable(() => import('../SPFooter'), { fallback: Loading })

const DynamicFooterJP = loadable(() => import('../SPFooterJP'), { fallback: Loading })

export default AclWrapper
