import React, { Component, useState, useEffect, useCallback, useRef } from 'react'
import { Layout, Row, Col, Select, Checkbox, Button } from 'antd'
import { observer } from 'mobx-react-lite';
import { toJS, reaction } from 'mobx'
import { inArray, simpleShipAddress, appConfig } from '../config';
import { shopStore, store, checkOutStore, checkOutEnrollStore } from "../../stores/MainStore";
import { validateShoppinCheckout, calculateOrder, getUserDetail2, getUserRenew } from '../../services/Network';
import { apiURL, getCurrentDomain, getShippingKey } from '../ShoppingHelper'
import { When, Unless, If, Then, Else } from "react-if";
import history from '../History';
import { dictionary, getCountryCode, getStoreLanguage, isWordExist, numeralFormat, isCountryPhilippines, isCountryJapan } from '@GlobalHelpers';
import { storeRenew } from '../../stores/StoreRenew';
import Redirect from "../Redirect";
import Loadable from 'react-loadable'
import { storeProduct } from '../../stores/StoreProduct';
import last from 'lodash/last';
import CheckoutProcess from "./checkout/CheckoutProcess";
import { language } from '../../stores/StoreLanguage';
import { storeOrderDetails } from './OrderDetails/Store/StoreOrderDetails';
import { getShoppingBillingAddress } from "../ShoppingHelper";
import { logi } from "../utils/PikaLog";
import Skeleton from 'antd/es/skeleton';
import styled from 'styled-components';
import Mobi from '@Components/mobiscroll/Mobi';
import { storeElement } from '@Stores/StoreElement';

const Loading = props => {
    return <span></span>
}
const ProductItem = Loadable({
    loader: () => import('./ProductItem'),
    loading: Loading
})

const Renewal = observer(props => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        setTimeout(() => {
            if (storeRenew.productSource.length === 0) {
                setRenewSet()
            }
        }, 1500)


        // localStorage.setItem('customerHref', 'https://hydra.unicity.net/v5a/customers/dfc3bac5b517ad5da0a6b235f8b7ec605b041410cf1e86b3d73cafa99d6565b7')
        // localStorage.setItem('customerToken','5965b837-9fcf-4cf2-b82f-69abb8f13d42')
        // localStorage.setItem('baId','101901681')
        // localStorage.setItem('setIntLanguage','en')


        getUserRenew((result) => {

            // console.log('result', result)

            if (result.statusText === "error") {

                window.location.href = `${getCurrentDomain()}/${appConfig.country}/not-found`;
            } else {

                localStorage.setItem('customerHref', result.payload.customerHref)
                localStorage.setItem('customerToken', result.payload.token)
                localStorage.setItem('baId', result.payload.baId)
                localStorage.setItem('setIntLanguage', result.payload.language)

                if (!storeRenew.proceedCheckout) {
                    language.current = result.payload.language
                }

                getUserDetail2((result) => {

                    if (result.statusText === "error") {
                        // setErrors([dictionary('u_all_customlink_orderdetail_ba_id_not_found')])
                    } else {
                        if (result.items[0]) {

                            storeRenew.baDetails = result.items[0]
                            localStorage.setItem('baDetails', JSON.stringify(result.items[0]))


                        } else {
                            // setErrors([dictionary('u_all_customlink_orderdetail_ba_id_not_found')])
                        }
                    }


                }, result.payload.baId);

            }


        }, props.match.params.id);

        storeRenew.renewID = props.match.params.id

        // if (storeRenew.proceedCheckout) {
        setLoading(false)
        // }


    }, [])

    const setRenewSet = () => {
        if (storeProduct.starterKits) {

            //let newRenewal = res.renewal.filter((item, key) => item.wholesale_price != 0)
            let newRenewal = storeProduct.renewSet
            storeRenew.productSource = newRenewal
            storeRenew.productRenewSource = newRenewal
            //setLoading(false)
            let tempRenewItems = []
            let tempRenewItems2 = []
            // setCartItems(productSource)

            newRenewal.map((v, k) => {
                //if (v.qty > 0) {
                tempRenewItems.push(v.item_code)
                storeRenew.productSource[k].qty = 1
                // }
            })

            tempRenewItems.map((v, k) => {
                tempRenewItems2.push(v)
            })

            storeRenew.renewItems = tempRenewItems2

            if (storeRenew.renewItems.length === 1) {
                storeRenew.renewItemSelected = last(tempRenewItems2)
            }

        }
    }

    useEffect(() => {
        const storeProductChange = reaction(
            () => storeProduct.isInitialized,
            () => {
                setLoading(false)

            }
        )

        return () => {
            storeProductChange()

        }
    }, [])



    return (
        <>
            <div className="shopprofile renewal">

                <When condition={loading}>
                    <Redirect />
                </When>
                <When condition={!loading}>
                    <div className={`customlink-shop-profile ${storeRenew.productSource.length - storeRenew.renewItems.length > 0 ? 'have-bg' : ''}`} style={{ paddingTop: 0 }}>
                        <div className="container-fluid" >
                            <div className="checkout offcanvas-wrapper row" style={{ background: 'none' }}>
                                <div className="data-list" style={{ width: '100%' }}>
                                    <div className="div-grid-body customlink-shop-profile-shopcart" >

                                        <React.Fragment>
                                            <CheckoutBlock />
                                            <RenewList />
                                        </React.Fragment>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </When>
            </div>

        </>


    )
})

export default Renewal;

const IconPlus = () => {
    return (
        <svg width="19" height="17" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5714 5.85704C12.5714 5.38382 12.1875 4.9999 11.7143 4.9999H8V1.28561C8 0.812395 7.61607 0.428467 7.14286 0.428467H5.42857C4.95536 0.428467 4.57143 0.812395 4.57143 1.28561V4.9999H0.857143C0.383929 4.9999 0 5.38382 0 5.85704V7.57132C0 8.04454 0.383929 8.42847 0.857143 8.42847H4.57143V12.1428C4.57143 12.616 4.95536 12.9999 5.42857 12.9999H7.14286C7.61607 12.9999 8 12.616 8 12.1428V8.42847H11.7143C12.1875 8.42847 12.5714 8.04454 12.5714 7.57132V5.85704Z" fill="#333" />
        </svg>
    )

}

const IconPlusMobile = () => {
    return (
        <svg width="22" height="25" viewBox="0 0 20 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5714 5.85704C12.5714 5.38382 12.1875 4.9999 11.7143 4.9999H8V1.28561C8 0.812395 7.61607 0.428467 7.14286 0.428467H5.42857C4.95536 0.428467 4.57143 0.812395 4.57143 1.28561V4.9999H0.857143C0.383929 4.9999 0 5.38382 0 5.85704V7.57132C0 8.04454 0.383929 8.42847 0.857143 8.42847H4.57143V12.1428C4.57143 12.616 4.95536 12.9999 5.42857 12.9999H7.14286C7.61607 12.9999 8 12.616 8 12.1428V8.42847H11.7143C12.1875 8.42847 12.5714 8.04454 12.5714 7.57132V5.85704Z" fill="#333" />
        </svg>
    )

}


const RenewList = observer(props => {
    return (
        <RenewListWrapper>
            {!storeRenew.proceedCheckout &&
                <RenewListLayout />
            }
        </RenewListWrapper>
    )
})

const RenewListWrapper = observer(props => {
    return (
        <div className="container mb-1">
            <div className="row block-row-renew">
                <div className="container">
                    {props.children}
                </div>
            </div>
        </div>
    )
})

const RenewListLayout = observer(props => {
    const [renderList, setRenderList] = useState(null)
    const [renderListRenew, setRenderListRenew] = useState(null)
    const [filteredOptions, setFilteredOptions] = useState()

    const productProps = {
        avatar: {
            shape: 'square',
            width: '100%'
        },
        paragraph: {
            rows: 0
        },
        title: false,
        active: true,
        round: true
    }
    const Skel = styled.div`
        .ant-skeleton-header {
            width: 100% !important;
            padding: 0px;
        }
        
        .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
            width: 100% !important;
            height: 378px !important;
            border-radius: 7px;
            background: linear-gradient(90deg, #d8dde3 30%, #f6f9fd 50%, #d8dde3 60%);
            background-size: 400% 100%;
        }
        .Skeleton-row{
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 25px 25px;
            gap: 25px 25px;
            justify-content: center;
            text-align: center;
            padding: 0 25px;
            display: grid;
        }
    `

    useEffect(() => {
        initData()
        setFilteredOptions(selectItems())
    }, [])
    useEffect(() => {


        const productSourceChange = reaction(
            () => storeRenew.productSource,
            () => {
                initData()
                setFilteredOptions(selectItems())
            }
        )

        const langDispose = reaction(
            () => language.current,
            () => {
                initData()
                setFilteredOptions(selectItems())
            }
        )


        return () => {
            productSourceChange()
            langDispose()

        }
    }, [])

    const renderTotal = () => {

        let totalPv = 0;
        let totalPrice = 0;

        let showPV = true
        let priceType = 'wholesale_price'
        storeRenew.productSource.map((v, k) => {
            if (inArray(v.item_code, storeRenew.renewItems) && v.item_code !== storeRenew.renewItemSelected) {
                return false;
            }
            if (v.qty > 0) {

                totalPrice += (v.price === "" ? 0 : parseFloat(v.price)) * v.qty;

                totalPv += parseInt(v.pv) * v.qty;

            }
        });

        return (
            <div className="autoship-total col-lg-12 col-md-12 col-sm-12 col-xs-12 pull-right row-group-total">

                {totalPv > 0 && <p className="text-right text-lg text-medium group-total">
                    <span className="lg31 text-total">{dictionary("total")}:</span>
                    <span className="total-price">{numeralFormat(totalPv)}</span>
                    <span className="total-currency text-muted">{dictionary("pv")}</span>
                </p>}

                <p className="text-right text-lg text-medium group-total group-total-price" style={{ marginTop: `${(storeElement.isMobile() && totalPv <= 0) ? '0px' : '0px'}` }}>
                    {
                        (totalPv <= 0) && <span className="lg31 text-total">{dictionary("total")}:</span>
                    }
                    {/* <span className="lg31 text-total">{dictionary("u_all_customlink_total_pv")}:</span> */}
                    <span className="total-price">{numeralFormat(totalPrice)}</span>
                    <span className="total-currency text-muted">{store.currency}</span>
                </p>



            </div>
        );
    };

    const AddItem = (bypassCheckDiscontinuedProduct) => {

        if (storeRenew.renewItemSelected === '') {
            storeRenew.loading = false
            storeRenew.errorMessage = dictionary('u_all_renewal_please_select_renewal_set')
            storeRenew.orderError = true
            return false;
        }
        storeRenew.openSelectProductModal()
    }

    const checkStockQty = (propVal, stock) => {
        if (propVal.buffer_qty !== "" && propVal.buffer_qty !== null) {
            if (parseInt(stock) <= parseInt(propVal.buffer_qty)) {
                return true
            } else {
                return false
            }
        } else {
            if (parseInt(stock) <= 0) {
                return true
            } else {
                return false
            }
        }
    }

    const backOrderTag = (propVal, stock) => {
        if (propVal.buffer_qty !== "" && propVal.buffer_qty !== null) {
            if (stock <= parseInt(propVal.buffer_qty)) {
                return true
            } else {
                return false
            }
        } else {
            if (stock <= 0) {

                return true
            } else {
                return false
            }
        }
    }

    const checkHasDiscontinuedProduct = () => {
        let hasDiscontinuedProduct = false
        let hasBackorder = false
        let hasOutofstock = false
        storeRenew.checkoutCart.map((v, k) => {
            // if (v.item_code !== '') {

            //     let p = storeProduct.getProduct(v.item_code)
            //     if (!p) {
            //         hasDiscontinuedProduct = true
            //     }
            // }

            // let stock = parseInt(v.stock)
            // stock = stock === '' || stock === null || stock === undefined || parseInt(stock) === NaN ? 0 : parseInt(stock)


            // let isBackOrder = v.allow_backorder === '1' ? true : false


            // if (isBackOrder === true) {
            //     if (backOrderTag(v, stock)) {
            //         hasBackorder = true
            //     }

            // } else if (checkStockQty(v, stock)) {

            //     hasOutofstock = true
            // } else {
            //     hasOutofstock = false
            // }

            // if (hasOutofstock) {
            //     hasDiscontinuedProduct = true
            // }
            if (v.status == 'out_of_stock' || v.qty_sellable == 0) {
                hasDiscontinuedProduct = true
            }



        })



        return hasDiscontinuedProduct
    }

    const continueCheckout = () => {
        //storeRenew.loading = true
        const removed = storeRenew.productSource.filter((item, key) => item.item_code !== "")
        const removed2 = removed.filter((item, key) => !inArray(item.item_code, storeRenew.renewItems) || item.item_code === storeRenew.renewItemSelected)

        storeRenew.productSource = removed
        storeRenew.checkoutCart = removed2

        // console.log('storeRenew.checkoutCart', storeRenew.checkoutCart)

        if (storeRenew.renewItemSelected == '') {
            storeRenew.loading = false
            storeRenew.errorMessage = dictionary('u_all_renewal_please_select_renewal_set')
            storeRenew.orderError = true

            // console.log('storeRenew.errorMessage', storeRenew.errorMessage)

        } else if (!checkHasDiscontinuedProduct()) {
            if (storeRenew.renewItemSelected === '0100' && isCountryJapan()) {
                storeRenew.loading = true
                const keyDelivery = getShippingKey()
                const baDetail_json = localStorage.getItem('baDetails')
                const baDetail = JSON.parse(baDetail_json)

                let phone
                if (baDetail.mobilePhone) {
                    phone = baDetail.mobilePhone
                } else if (baDetail.homePhone) {
                    phone = baDetail.homePhone
                } else {
                    phone = undefined
                }
                checkOutStore.billingAddress[keyDelivery].firstNameEn = baDetail.humanName.firstName
                checkOutStore.billingAddress[keyDelivery].lastNameEn = baDetail.humanName.lastName

                checkOutStore.billingAddress[keyDelivery].address1 = baDetail.mainAddress.address1
                checkOutStore.billingAddress[keyDelivery].address2 = baDetail.mainAddress.address2
                checkOutStore.billingAddress[keyDelivery].city = baDetail.mainAddress.city
                checkOutStore.billingAddress[keyDelivery].state = baDetail.mainAddress.state
                checkOutStore.billingAddress[keyDelivery].zip = baDetail.mainAddress.zip

                checkOutStore.billingAddress[keyDelivery].mobilePhone = phone
                checkOutStore.billingAddress[keyDelivery].email = baDetail.email

                // checkOutStore.billingAddress[keyDelivery].zip = '123456'
                proceedCheckoutFinal()

            } else {
                storeRenew.orderError = false
                storeRenew.proceedCheckout = true
                storeRenew.productLoad = true
                window.scrollTo(0, 0)
                //proceedCheckout()
            }

        } else {
            storeRenew.loading = false
            storeRenew.errorMessage = dictionary('u_all_customlink_error_discontinue')
            storeRenew.orderError = true
        }


    }

    const initData = () => {
        if (storeRenew.productLoad) {

            setRenderListRenew(<ProductItem isRenewItems={true} />)
            setRenderList(<ProductItem isRenewItems={false} />)

        } else {
            setTimeout(() => {
                setRenderListRenew(<ProductItem isRenewItems={true} />)
                setRenderList(<ProductItem isRenewItems={false} />)
            }, 2000)
        }


    }

    const proceedCheckout = () => {


        checkOutStore.items = []
        storeRenew.checkoutCart.map((v, k) => {
            if (v.qty > 0) {
                checkOutStore.items.push({
                    quantity: v.qty,
                    item_code: v.product_id
                })
            }
        })

        let data = {
            language: getStoreLanguage(),
            orderData: {
                items: checkOutStore.items,
                minStockQty: store.minStockQty,
                country: store.country_code
            }
        }


        validateShoppinCheckout((res, succ) => {

            if (succ === false) {
                if (res.responseJSON.errors !== undefined) {
                    res.responseJSON.errors.map((v, k) => {
                        const text = String(v).toLowerCase()
                        if (isWordExist(text, 'not enough inventory')) {
                            const product = res.responseJSON.errors[k].split(' - ')[1]
                            res.responseJSON.errors[k] = dictionary('error_not_enough_inventory') + (product ? ' - ' + product : '')

                        }
                    })

                    storeRenew.orderError = true
                    storeRenew.errorMessage = res.responseJSON.errors


                    store.stockError = true

                    if (res.responseJSON.itemsError) {
                        res.responseJSON.itemsError.map((v, k) => {
                            let product = storeProduct.products.find(item => item.item_code === v.item_code)

                            if (product) {
                                product.stock = 0
                            }
                        })
                    }

                } else {
                    if (res.responseJSON.error_code !== undefined) {
                        storeRenew.orderError = true
                        storeRenew.errorMessage = [dictionary(res.responseJSON.error_code)]

                        store.stockError = true
                    }
                }
                storeRenew.loading = false


            } else {
                storeRenew.loading = false
                const referral = checkOutEnrollStore.referralParam
                // if (store.customerData !== null && store.customerData.id === undefined) {
                if (referral) {
                    let storageCart = [];
                    storeProduct.products.map((b, i) => {
                        if (b.qty > 0) {
                            storageCart.push({
                                id: b.product_id,
                                qty: b.qty
                            });
                        }
                    })
                    localStorage.setItem('storageEnrollCart', JSON.stringify(storageCart))

                } else {
                    if (checkOutStore.pcshopping === true) {
                        shopStore.passCheckOut = true
                        history.push(`/cart`)
                    }
                    else {
                        history.push(`/checkout`)
                    }

                }
            }

        }, data, apiURL.shopping.checkstock_api)
    }

    const selectItems = () => {

        const temp = []


        storeProduct.products.forEach((itemValue, itemKey) => {



            if (inArray(itemValue.item_code, storeRenew.selectedItems)) {
                return false
            }

            if (inArray(itemValue.item_code, storeRenew.renewItems)) {
                return false
            }

            if (isCountryPhilippines()) {
                if ((itemValue.price == 0 || itemValue.price == '') && itemValue.item_code !== '31325') {
                    return false;
                }

            }

            let price = itemValue.price
            if (price == 0) {
                return false;
            }
            if (itemValue.qty_sellable == 0) {
                return false;
            }

            let title = itemValue.item_name.english
            if (store.languageCurrent !== 'EN') {
                if (itemValue.item_name.native !== '') {
                    title = itemValue.item_name.native
                }
            }

            let textSearch = itemValue.item_code + itemValue.item_name.native + itemValue.item_name.english;

            title = padZerosToLength(itemValue.item_code, 5, 0) + ' ' + title
            temp.push({

                value: itemValue.item_code,
                text: textSearch,
                html: '' + `${title}`

            })

        })


        temp.sort((a, b) => {
            const aName = a['text'].toLowerCase()
            const bName = b['text'].toLowerCase()
            return aName.localeCompare(bName)
        })

        return temp

    }

    const padZerosToLength = (value, minLength, padChar) => {
        var iValLength = value.toString().length;
        return ((new Array((minLength + 1) - iValLength).join(padChar)) + value);
    }

    const onSelected = (value, index) => {

        let product = storeProduct.getProduct(value)
        /**** check out of stock || back order */

        let price = product.price

        if (price == 0) {
            return false;
        }


        let stock = product.stock
        stock = stock === '' || stock === null || stock === undefined || isNaN(parseInt(stock)) ? 0 : parseInt(stock)


        let disableInput = false

        if (product.status == 'out_of_stock' || product.qty_sellable == 0) {
            disableInput = true
        }
        // if (isBackOrder) {
        //   if (product.buffer_qty !== "" && product.buffer_qty !== null) {
        //     if (stock <= parseInt(product.buffer_qty)) {
        //       disableInput = false
        //     }
        //   } else {
        //     if (stock <= 0) {
        //       disableInput = false
        //     }
        //   }
        // } else {
        //   if (product.buffer_qty !== "" && product.buffer_qty !== null) {
        //     if (parseInt(stock) <= parseInt(product.buffer_qty)) {
        //       disableInput = true
        //     }
        //   } else {
        //     if (parseInt(stock) <= 0) {
        //       disableInput = true
        //     }
        //   }
        // }


        if (product) {
            product.qty = 1

            if (disableInput) {
                product.qty = 0

            }


            if (storeRenew.productSource[index]) {
                storeRenew.productSource[index] = product
                storeRenew.productSource[index].qty = 1
                if (disableInput) {
                    storeRenew.productSource[index].qty = 0
                }

            } else {

                storeRenew.productSource.push(product)
            }

            let tempSelectedItems = []
            let tempSelectedItems2 = []
            // setCartItems(productSource)

            storeRenew.productSource.map((v, k) => {
                //if (v.qty > 0) {

                tempSelectedItems.push(v.item_code)
                // }
            })

            tempSelectedItems.map((v, k) => {
                tempSelectedItems2.push(v)
            })

            storeRenew.selectedItems = tempSelectedItems2

        }


        setFilteredOptions(selectItems())
    }

    return (
        <RenewListLayoutWrapper>
            <div className="block-renew-set">
                <div className="extra-page-title mobile">
                    <div className="block-underline">
                        <div>{dictionary("u_all_renewal_title_select_renewal")}</div>
                    </div>
                </div>
                <If condition={renderListRenew || storeRenew.productLoad} >
                    <Then>
                        <div className={`block-grid-shopping-cart shopping-list ${(storeRenew.renewItemSelected) !== '' ? 'block-grid-shopping-cart-1' : (storeRenew.renewItems.length) == 2 ? 'block-grid-shopping-cart-2' : (storeRenew.renewItems.length) == 3 ? 'block-grid-shopping-cart-3' : ''}`}  >

                            {renderListRenew}

                        </div>

                    </Then>
                    <Else>
                        <div className={`block-grid-shopping-cart shopping-list block-grid-shopping-cart-1`} >
                            <Skel><Skeleton {...productProps} /></Skel>
                        </div>
                    </Else>
                </If>

            </div>
            <div className="block-not-renew-set">
                {(storeRenew.productSource.length - storeRenew.renewItems.length) > 0 &&

                    <div className="extra-page-title extra-page-title-not-renewal-set">
                        <div className="block-underline">
                            <h1>{dictionary("u_all_renewal_title_initial_order")}</h1>
                        </div>
                    </div>
                }

                <div className={`block-grid-shopping-cart shopping-list shopping-list-not-renew-set ${(storeRenew.productSource.length - storeRenew.renewItems.length) == 1 ? 'block-grid-shopping-cart-1' : (storeRenew.productSource.length - storeRenew.renewItems.length) == 2 ? 'block-grid-shopping-cart-2' : (storeRenew.productSource.length - storeRenew.renewItems.length) == 3 ? 'block-grid-shopping-cart-3' : ''}`}>

                    {renderList}

                </div>
            </div>






            <div className="row block-bottom-renew">
                {storeElement.isMobile() &&
                    <div className="block_addmore block_addmore_mobile" onClick={() => AddItem()}>
                        {storeRenew.renewItemSelected !== '0100' && <>
                            <IconPlusMobile />&nbsp;&nbsp; <span>{dictionary('add_more_products')}</span>
                        </>}
                    </div>
                }
                <div className={`block-bottom-customlink-cart ${storeElement.isMobile() ? "block-bottom-customlink-cart-mobile" : ""}`}>
                    {!storeElement.isMobile() &&
                        <div className="block_addmore" onClick={() => AddItem()}>
                            {storeRenew.renewItemSelected !== '0100' && <>
                                <IconPlus />&nbsp;&nbsp; <span>{dictionary('add_more_products')}</span>
                            </>}
                        </div>
                    }
                    <div className="container" id="rsTotal">
                        {renderTotal()}
                        {storeRenew.orderError && (
                            <div className="block-error row ">
                                <div className="col-12">
                                    <div className="alert alert-danger">
                                        <div className="text-center">
                                            <ul style={{ listStyle: "none" }} id="rsErrors">
                                                <li>{storeRenew.errorMessage}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="block_button_continue mobile-btn-center">
                            <Button className="btn btn-primary col-xs-2 pull-right"
                                type="primary"
                                loading={storeRenew.loading}
                                onClick={() => {

                                    continueCheckout()

                                }} >
                                <span> {dictionary("continue")} </span>
                            </Button>
                            {/* <button className="btn btn-primary col-xs-2 pull-right" disabled={storeRenew.loading} onClick={() => {

                                continueCheckout()

                            }} >
                                <span> {dictionary("continue")} </span>
                                <span><i className={storeRenew.loading ? "fa fa-spin fa-spinner" : ""}></i></span>
                            </button> */}
                        </div>
                    </div>
                </div>

            </div>

            <SelectingProductLayout 
                onSelected={onSelected}
                selectOptions={filteredOptions}
            />
        </RenewListLayoutWrapper>
    )
})

const SelectingProductLayout = observer(({ onSelected, selectOptions, index }) => {

    // const filteredOptions = selectItems()
    const refSelectBox = useRef()
    let noItem = true
    let removeIcon = noItem ? 'show-icon' : ''
    useEffect(() => {
        const disposerSelectProduct = reaction(
            () => storeRenew.isOpenSelectProductModal,
            (open, pOpen) => {
                if (open) {
                    storeRenew.resetSelectProductModal()
                    refSelectBox.current.instance.show()
                }
            }
        )

        return () => {
            disposerSelectProduct()
        }

    }, [])

    return <Mobi.Select
        filterPlaceholderText={dictionary('type_to_search')}
        ref={refSelectBox}
        onSet={(selectedValue, inst) => {
            var selected = inst.getVal();
            if (selected) {
                onSelected(selected, index)
            }
        }}
        hideSelection
        wrapperStyle={{ display: 'none' }}
        data={selectOptions}
    />
})

const RenewListLayoutWrapper = observer(props => {
    return props.children
})

const CheckoutBlock = observer(props => {
    return (
        <CheckoutBlockWrapper>
            {storeRenew.proceedCheckout &&
                <CheckoutProcess proceedCheckoutFinal={(referral_id, easyChoice) => { proceedCheckoutFinal(referral_id, easyChoice) }} store={store} />
            }
        </CheckoutBlockWrapper>
    )
})

const CheckoutBlockWrapper = observer(props => {
    return (
        <div className="checkout-process">
            {props.children}
        </div>
    )
})

const proceedCheckoutFinal = (referral_id, easyChoice) => {

    storeOrderDetails.isLoadingAPI = true
    let orderedProducts = [];

    let priceType = 'wholesale_price'
    let priceLevel = '2'
    let customerType = 'Associate'
    let customerHref = `https://hydra.unicity.net/v5a/customers?type=${customerType}`

    storeRenew.checkoutAddress = getShoppingBillingAddress();



    // store.shareCart.shipping = checkOutStore.shipping
    // store.shareCart.email_confirmation = checkOutStore.isReceivedEmail
    // store.shareCart.invoice = checkOutStore.isReceivedInvoice


    // store.shareCart.errorMessage = []
    // store.shareCart.loading = true

    //logi('checkoutData', toJS(checkoutData))
    logi('checkoutData', toJS(getShoppingBillingAddress()))

    if (checkOutStore.email === "") {
        checkOutStore.email = 'no@unicity.com';
    }

    storeRenew.checkoutCart.map((item, index) => {
        if (item.proQTY !== 0 && item.qty !== "0") {
            var product = {
                item: {
                    href: `https://hydra.unicity.net/v5a/items?id.unicity=${item.item_code}`
                },
                quantity: item.qty,
                item_code: item.item_code,
                href_params: [`id.unicity=${item.item_code}`]
            };
            orderedProducts.push(product);
        }
    });



    let shippingHref = `https://hydra.unicity.net/v5a/shippingmethods?type=${getCountryCode() === 'AU' ? 'Expedited' : getCountryCode() === 'NZ' ? 'Expedited' : 'Economy'}`;

    if (checkOutStore.shipping == '3') {
        shippingHref = `https://hydra.unicity.net/v5a/shippingmethods?type=WillCall&location=${getCountryCode() === 'AU' ? 'Bangkok, Main Office' : getCountryCode() === 'NZ' ? 'Bangkok, Main Office' : getCountryCode() === 'HK' ? 'Hong Kong, Main Office' : 'Bangkok, Main Office'}`;
    }



    if (isCountryPhilippines()) {
        var data = {
            order: {
                market: getCountryCode(),
                customer: {
                    href: customerHref
                },
                lines: {
                    items: orderedProducts
                },
                notes: "sharecart",
                // passing deafult address
                shipToAddress: {
                    country: getCountryCode(),
                    zip: storeRenew.checkoutAddress.zip || '11111'
                },
                shippingMethod: {
                    href: checkOutStore.shipping == '3' || checkOutStore.shipping == '32' ? 'https://hydra.unicity.net/v5a/shippingmethods?type=WillCall&location=Taguig City, Main Office' : "https://hydra.unicity.net/v5a/shippingmethods?type=Economy"
                }
            }
        };

    } else {
        var data = {
            order: {
                market: getCountryCode(),
                customer: {
                    href: customerHref
                },
                lines: {
                    items: orderedProducts

                },
                notes: "renewal",
                // passing deafult address
                shipToAddress: {
                    country: getCountryCode(),
                    zip: storeRenew.checkoutAddress.zip || '11111'
                },
                shippingMethod: {
                    href: getCountryCode() === 'SG' ? `${shippingHref}&location=${simpleShipAddress(getCountryCode())}` : shippingHref
                }
            }
        };
    }

    storeRenew.calculateOrderData = data
    data = JSON.stringify(data);

    calculateOrder((result, status) => {
        if (status) {
            storeRenew.proceedCheckout = true;
            storeRenew.calculateOrder = JSON.stringify(result);

            localStorage.setItem("calculateOrder", JSON.stringify(result));
            localStorage.setItem("proceedCheckout", true);
            localStorage.setItem("checkoutAddress", JSON.stringify(storeRenew.checkoutAddress));
            localStorage.setItem("checkoutCart", JSON.stringify(storeRenew.checkoutCart));


            storeOrderDetails.isLoadingAPI = false
            storeOrderDetails.passOrdersummary = true

            history.push(`/${appConfig.country}/renewal/ordersummary/${storeRenew.renewID}`);

        } else {
            if (result.responseJSON.error.error_code == 3001) {
                storeRenew.orderError = true
                storeRenew.errorMessage = ["No item exists."]
                storeRenew.loading = false

                storeOrderDetails.errorMessage = storeRenew.errorMessage
                storeOrderDetails.isLoadingAPI = false
            } else {
                const regex = /\`(.*?)`/;
                const itemCodeText = result.responseJSON.error.error_message.match(regex);
                if (itemCodeText) {
                    const itemCode = itemCodeText[1];
                    let itemName = "";
                    if (itemCode) {
                        itemName = storeRenew.checkoutCart.find(item => item.item_code === itemCode).item_name_1;
                    }
                    storeRenew.orderError = true
                    storeRenew.errorMessage = [result.responseJSON.error.error_message.replace(/This SKU is not supported:/g, `No items: ${itemName}`)]
                    storeRenew.loading = false

                } else {

                    storeRenew.orderError = true
                    storeRenew.errorMessage = [result.responseJSON.error.error_message.replace(/This SKU is not supported:/g, `No items`)]
                    storeRenew.loading = false

                }

                storeOrderDetails.errorMessage = storeRenew.errorMessage
                storeOrderDetails.isLoadingAPI = false
            }
        }
    }, data);
}



