import React, { useEffect } from 'react'
import { useObserver } from 'mobx-react-lite'
import { reaction } from 'mobx'

import { checkOutStore, store } from '../../../../stores/MainStore'
import { storeOrderDetails } from '../Store/StoreOrderDetails'
import { storeResponsive } from '../Store/StoreResponsive'
import { mAramexValue } from '../Store/StoreFormConfigs'

import { dictionary, randomNumber, randomString } from '@GlobalHelpers'
import { CountryConfigs as CC } from '../../../configs/Configs'
import { trim } from '../../../utils/Utils'
import { ShippingAddress } from '../../../configs/Address'
import { CountryCode } from '../../../configs/Country'
import { hasQueryString } from '../../../configs/ConfigsHeader'

import TemplatePortal from './Template/TemplatePortal'

import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Layout from 'antd/es/layout'
import Typography from 'antd/es/typography'

import get from 'lodash/get'
import capitalize from 'lodash/capitalize'

import * as K from '../../../configs/Keywords'
import ShippingMethods from '@Configs/ShippingMethods'

const { Title } = Typography

const FormAramex = props => {

    const processSpecialCase = () => {
        // * Any special case for each country
        storeOrderDetails.errorMessage = []
    }

    // * Create default address for Aramex
    let shipTo = ShippingAddress.aramex[CC.Country()]
    if (ShippingMethods.getWarehouseEnabled()) {
        shipTo = shipTo[checkOutStore.warehouse]
    }
    
    let isShipToHasLanguage = false
    Object.keys(shipTo).map(key => {
        if (key === 'EN') isShipToHasLanguage = true
    })
    if (isShipToHasLanguage) {
        shipTo = shipTo[store.languageCurrent]
    }

    // const keySNS1 = `sns_city_label`
    // const keySNS2 = `sns_mailbox_label`

    /* const formItems = {
        name:
            <Form.Item {...formItemConfig} label={dictionary(keyFullname)}>
                <SuperInput 
                    defaultValue={mState.fullName}
                    value={mState.fullName}
                    onChange={(input) => mState.fullName = input.target.value}
                    placeholder={dictionary(keyPlaceholderFullname)}
                    />
            </Form.Item>
        ,
        address1: 
            <Form.Item {...formItemConfig} label={dictionary(keyAddressL1)}>
                <SuperInput readOnly disabled 
                    defaultValue={shipTo.address1}
                />
            </Form.Item>
        ,
        address2:
            <Form.Item {...formItemConfig} label={dictionary(keyAddressL2)}>
                <SuperInput readOnly disabled 
                    defaultValue={shipTo.address2}
                />
            </Form.Item>
        ,
        zip:
            <Form.Item {...formItemConfig} label={dictionary(keyZip)}>
                <SuperInput readOnly disabled 
                    defaultValue={shipTo.zip}
                />
            </Form.Item>
        ,
        country:
            <Form.Item {...formItemConfig} label={dictionary(keyCountry)}>
                <SuperInput readOnly disabled 
                    defaultValue={CountryCodeToCountryName[shipTo.country]}
                />
            </Form.Item>
        ,
        mobile:
            <Form.Item {...formItemConfig} label={dictionary(keyMobilePhone)}>
                <SuperInput readOnly disabled 
                    defaultValue={shipTo.mobilePhone}
                />
            </Form.Item>
        ,
        email:
            <Form.Item {...formItemConfig} label={dictionary(keyEmail)}>
                <SuperInput 
                    defaultValue={mState.email}
                    value={mState.email}
                    onChange={(input) => mState.email = input.target.value}
                    placeholder={dictionary(keyPlaceholderEmail)}
                />
            </Form.Item>
        ,
        sns1:
            <Form.Item {...formItemConfig} label={dictionary(keySNS1)} style={{marginBottom: '0.2rem'}}>
                <SuperInput 
                    defaultValue={mState.sns1}
                    value={mState.sns1}
                    onChange={(input) => mState.sns1 = input.target.value}
                    placeholder={dictionary(keyPlaceholderSNS1)}
                />
            </Form.Item>
        ,
        sns2: 
            <Form.Item {...formItemConfig} label={dictionary(keySNS2)} style={{marginBottom: '0.2rem'}}>
                <SuperInput 
                    defaultValue={mState.sns2}
                    value={mState.sns2}
                    onChange={(input) => mState.sns2 = input.target.value}
                    placeholder={dictionary(keyPlaceholderSNS2)}
                />
            </Form.Item>
    } */

    useEffect(
        () => {

            const billingKey = 'aramex'

            // Aramex Address
            const address1 = get(shipTo, 'address1', '')
            const address2 = get(shipTo, 'address2', '')
            const city = get(shipTo, 'city', '')
            const state = get(shipTo, 'state', '')
            const zip = get(shipTo, 'zip', '')
            const mobile = get(shipTo, 'mobilePhone', '')

            checkOutStore.billingAddress[billingKey].address1 = address1
            checkOutStore.billingAddress[billingKey].address2 = address2
            checkOutStore.billingAddress[billingKey].city = city
            checkOutStore.billingAddress[billingKey].state = state
            checkOutStore.billingAddress[billingKey].zip = zip
            checkOutStore.billingAddress[billingKey].mobilePhone = mobile

            mAramexValue.address1 = address1
            mAramexValue.address2 = address2
            mAramexValue.city = city
            mAramexValue.state = state
            mAramexValue.zip = zip
            mAramexValue.mobile = mobile

            const reactionName = reaction(
                () => mAramexValue.fullName,
                () => {
                    checkOutStore.billingAddress[billingKey].firstNameEn = trim(`${mAramexValue.fullName}`)
                    checkOutStore.billingAddress[billingKey].lastNameEn = ''
                    processSpecialCase()
                }
            )

            const reactionEmail = reaction(
                () => mAramexValue.email,
                () => {
                    checkOutStore.billingAddress[billingKey].email = trim(`${mAramexValue.email}`)
                    processSpecialCase()
                }
            )

            const reactionSNS1 = reaction(
                () => mAramexValue.sns1,
                () => {
                    checkOutStore.billingAddress[billingKey].sns1 = trim(`${mAramexValue.sns1}`)
                    processSpecialCase()
                }
            )

            const reactionSNS2 = reaction(
                () => mAramexValue.sns2,
                () => {
                    checkOutStore.billingAddress[billingKey].sns2 = trim(`${mAramexValue.sns2}`)
                    processSpecialCase()
                }
            )

            if (hasQueryString('sleepy')) {
                switch (CC.CountryCode()) {
                    case CountryCode.singapore:
                        mAramexValue.fullName = 'Leroy Irvin'
                        mAramexValue.email = `${randomString(8, true, true, true)}@unicity.com`
                        mAramexValue.sns1 = `${randomString(3, true, false, false)}`
                        mAramexValue.sns2 = `${randomNumber(6, true)}`
                        break
                    default: break
                }
            }

            return () => {
                // dispose watcher
                reactionName()
                reactionEmail()
                reactionSNS1()
                reactionSNS2()
            }
        }, []
    )
    
    return useObserver(() =>
        <Layout className="section-form aramex">
            <Row {...storeResponsive.form.rowResponsive}>
                <Col>
                    <Title level={1}>{dictionary('u_all_od_header_pickup_form')}</Title>
                </Col>
            </Row>
            <Row {...storeResponsive.form.rowResponsive}>
                <Col {...storeResponsive.form.colResponsive}>
                    <TemplatePortal type={capitalize(K.Aramex)} country={CC.CountryFullDisplay()}/>
                </Col>
            </Row>
        </Layout>
    )
}

export default FormAramex