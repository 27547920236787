import * as K from './Keywords'
import { isProduction } from './ConfigsHeader';

export const TermsAndConditions = {
    [K.Singapore]: {
        [K.English]: 'https://s3-ap-southeast-1.amazonaws.com/com.ushop.uploads/terms%26conditions/UShop+Terms+%26+Conditions.pdf'
    },
    [K.Australia]: {
        [K.English]: ''
    },
    [K.Newzealand]: {
        [K.English]: ''
    },
    [K.Hongkong]: {
        [K.English]: 'https://member-hk.unicity.com/cpanel/en/terms-and-conditions'
    }
}

export const GatewaysHydra = {
    [K.Australia]: true,
    [K.Newzealand]: true
}

export const MerchantID = {
    [K.OCBC]: {
        [K.Singapore]: {
            [K.Production]: '9722587149000980',
            [K.Development]: '9722587149000980',
            [K.Localhost]: '9722587149000980'
        }
    },
    [K.OCBCExpress]: {
        [K.Australia]: {
            [K.Production]: '8408400227349002280',
            [K.Development]: '0360360227149002440',
            [K.Localhost]: '0360360227149002440'
        },
        [K.Newzealand]: {
            [K.Production]: '8408400227349002280',
            [K.Development]: '8408400227349002280',
            [K.Localhost]: '8408400227349002280'
        },
    },
    test: {
        [K.All]: {
            [K.Production]: 'all_pro',
            [K.Development]: 'all_dev',
            [K.Localhost]: 'all_loc'
        }
    }
}

export const FormSubmitUrl = {
    [K.ECPay]: {
        [K.Taiwan]: {
            [K.Production]: 'https://payment.ecpay.com.tw/Cashier/AioCheckOut/V5',
            [K.Development]: 'https://payment.ecpay.com.tw/Cashier/AioCheckOut/V5',
            [K.Localhost]: 'https://payment-stage.ecpay.com.tw/Cashier/AioCheckOut/V5'
        }
    }
}