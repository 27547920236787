import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { reaction } from 'mobx';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Layout, Typography } from 'antd';
import { randomNumber, randomString } from '@GlobalHelpers';
import { trim } from '../../../utils/Utils'
import { hasQueryString } from '../../../configs/ConfigsHeader'
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import TemplatePortal from './Template/TemplatePortal';
import { mDeliveryValue } from '../Store/StoreFormConfigs';
import { checkOutStore } from '../../../../stores/MainStore';
import { storeOrderDetails, storeSleepy } from '../Store/StoreOrderDetails';
import { isCustomerLogin } from '../../../utils/Customer';
import { storeResponsive } from '../Store/StoreResponsive';
import * as K from '../../../configs/Keywords';
import { Country } from '@Stores/StoreConfigCountry'
import { language as l } from '@Language'

const { Title } = Typography

const FormDelivery = observer(props => {

    const getShippingKey = () => {
        switch (get(checkOutStore, 'shipping', '').toString()) {
            case '7': return 'deliverySBY'
            default: return 'delivery'
        }
    }

    const keyDelivery = getShippingKey()

    const processSpecialCase = () => {
        if (Country.getCode2() != 'ID' || Country.getCode2() != 'SG') {
            return
        }
        // * Any special case for each country
        switch (Country.getCode2()) {
            case Country.Code2.singapore:
                // SG always use city equal Singapore
                checkOutStore.billingAddress[keyDelivery].city = 'Singapore'
                break
            case Country.Code2.indonesia:
                checkOutStore.billingAddress[keyDelivery].address2 = `${trim(mDeliveryValue.address2)}, ${trim(mDeliveryValue.state)}`
                checkOutStore.billingAddress[keyDelivery].state = ''
                break
        }
        // changeAddressLabel()
        // copy changed to later restored it
        storeOrderDetails.errorMessage = []
    }

    // const updateAddressHistory = () => {
    //     Raven.getAddressHistory()
    //     .then(response => {


    //         console.log('response getAddressHistory',response)
    //         mDeliveryAddressHistory.list = response.map(item => {
    //             const data = {
    //                 addressHref: get(item, 'addressHref'),
    //                 fullName: get(item, 'shipToName.fullName'),
    //                 address1: get(item, 'shipToAddress.address1'),
    //                 address2: get(item, 'shipToAddress.address2'),
    //                 city: get(item, 'shipToAddress.city'),
    //                 state: get(item, 'shipToAddress.state'),
    //                 zip: get(item, 'shipToAddress.zip')
    //             }
    //             return data
    //         })
    //     })
    //     .finally(() => {

    //         if (isSomething(mDeliveryValue.fullName)) {
    //             if (mDeliveryAddressHistory.list.length > 1) {
    //                 mDeliveryValue.selectedAddressHistory = 1
    //             }
    //         }

    //         if (isSomething(mDeliveryValue.fullName)) {
    //             if(storeAutoShip.action==='edit') {
    //                 mDeliveryValue.selectedAddressHistory = mDeliveryValue.fullName
    //             }
    //         }


    //     })
    // }

    useEffect(
        () => {
            // * Get Address History from API
            // setLoading(true)

            const reactionName = reaction(
                () => mDeliveryValue.fullName,
                () => {
                    checkOutStore.billingAddress[keyDelivery].firstNameEn = trim(`${mDeliveryValue.fullName}`)
                    checkOutStore.billingAddress[keyDelivery].lastNameEn = ''
                    processSpecialCase()
                }
            )

            const reactionAddress1 = reaction(
                () => mDeliveryValue.address1,
                () => {
                    checkOutStore.billingAddress[keyDelivery].address1 = trim(`${mDeliveryValue.address1}`)
                    processSpecialCase()
                }
            )

            const reactionAddress2 = reaction(
                () => mDeliveryValue.address2,
                () => {
                    checkOutStore.billingAddress[keyDelivery].address2 = trim(`${mDeliveryValue.address2}`)
                    processSpecialCase()
                }
            )

            const reactionCity = reaction(
                () => mDeliveryValue.city,
                () => {
                    checkOutStore.billingAddress[keyDelivery].city = trim(`${mDeliveryValue.city}`)
                    processSpecialCase()
                }
            )

            const reactionState = reaction(
                () => mDeliveryValue.state,
                () => {
                    checkOutStore.billingAddress[keyDelivery].state = trim(`${mDeliveryValue.state}`)
                    processSpecialCase()
                }
            )

            const reactionZip = reaction(
                () => mDeliveryValue.zip,
                () => {
                    checkOutStore.billingAddress[keyDelivery].zip = trim(`${mDeliveryValue.zip}`)
                    processSpecialCase()
                }
            )

            const reactionEmail = reaction(
                () => mDeliveryValue.email,
                () => {
                    checkOutStore.billingAddress[keyDelivery].email = trim(`${mDeliveryValue.email}`)
                    processSpecialCase()
                }
            )

            const reactionMobile = reaction(
                () => mDeliveryValue.mobile,
                () => {
                    checkOutStore.billingAddress[keyDelivery].mobilePhone = trim(`${mDeliveryValue.mobile}`)
                    processSpecialCase()
                }
            )

            const reactionReferralId = reaction(
                () => mDeliveryValue.referral_id,
                () => {
                    checkOutStore.billingAddress[keyDelivery].referral_id = trim(`${mDeliveryValue.referral_id}`)
                    processSpecialCase()
                }
            )


            const reactionLadmark = reaction(
                () => mDeliveryValue.comment,
                () => {
                    checkOutStore.billingAddress[keyDelivery].comment = trim(`${mDeliveryValue.comment}`)
                    processSpecialCase()
                }
            )

            if (hasQueryString('sleepy')) {//|| isLocalhost()) {
                switch (Country.getCode2()) {
                    case Country.Code2.thailand:
                        mDeliveryValue.selected = 'Bangkok'
                        mDeliveryValue.fullName = 'มัฒธณิฏาศ์ พรรษญธรธนัชส์'
                        mDeliveryValue.address1 = '500/12 หมู่บ้านสุขศิริเลอไอเฟล'
                        mDeliveryValue.address2 = 'แขวงคลองกุ่ม เขตคันนายาว'
                        mDeliveryValue.city = 'Bangkok'
                        mDeliveryValue.zip = randomNumber(storeSleepy.thailand.zipLength, false)
                        mDeliveryValue.mobilePhone = randomNumber(storeSleepy.thailand.mobileLength)
                        mDeliveryValue.email = `${randomString(8, true, true, true)}@unicity.com`
                        break
                }
            }

            return () => {
                // dispose watcher
                reactionName()
                reactionAddress1()
                reactionAddress2()
                reactionCity()
                reactionState()
                reactionZip()
                reactionMobile()
                reactionEmail()
                reactionReferralId()
                reactionLadmark()
            }
        }, []
    )

    return (
        <Layout className="section-form delivery">
            <Row {...storeResponsive.form.rowResponsive}>
                <Col>
                    <Title level={1}>{l.listen('od_header_delivery_form')}</Title>
                </Col>
            </Row>
            <Row {...storeResponsive.form.rowResponsive}>
                <Col {...storeResponsive.form.colResponsive}>
                    <TemplatePortal type={capitalize(K.Delivery)} country={Country.getPascalCase()} />
                </Col>
            </Row>
        </Layout>
    )
})

export default FormDelivery