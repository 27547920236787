import { reaction } from 'mobx'
import moment from 'moment'
import get from 'lodash/get'
import { language as l } from '@Language'
import { appConfig, momentLocale } from '../config'
import { isNothing, isSomething, testArray } from './Utils'
import { lowerCase } from './String'
import { store, devTools } from '../../stores/MainStore'
import { getCountryCode, getStoreLanguageName } from '@GlobalHelpers'
import { isProduction } from '../configs/ConfigsHeader'
import { CountryConfigs as CC } from '../configs/Configs'
import { loge, logn } from './PikaLog'

const missingList = []

export const plainTranslate = (key) => {
    return get(l.dictionary, key, undefined) || get(l.dictionary, lowerCase(key), '')
}

/** Display word from dictionary with specified logic. If word does not existed, development error log show.
     * @param {string} key any key for a word related with dictionary.
     * @param {boolean} showLabelWhenMissing show label instead when this word is missing.
     * @param {boolean} showBracketAlongLabel use with missing word, show brackets along a label.
     * @example
     * translate('profile') // output => Profile | error => [profile]
     * translate('Simply show this.', true, false) // output => Simply show this.
    */
export const translate = (key, showLabelWhenMissing = true, showBracketAlongLabel = true) => {
    if (isNothing(get(l, 'dictionary', undefined))) return key

    // show missing word from English instead of its label
    const isShowMissingWordInEnglish = isProduction()

    let word = get(l.dictionary, key, undefined) || get(l.dictionary, lowerCase(key), '')

    // how to display brackets
    const bracketLeft = '[', bracketRight = ']'
    const tagBracket = (_string, brl, lb, brr) => `${showBracketAlongLabel ? brl : ''}${lb}${showBracketAlongLabel ? brr : ''}`
    const missingWord = tagBracket`${bracketLeft}${key}${bracketRight}`

    if (isNothing(word)) {
        // * Missing word case
        // check if this label alrady added in missing list
        const isSkipErrorMissingWord = testArray(key, missingList)
        if (isSkipErrorMissingWord === false) {
            loge(`Label [${key}] was not exists in dictionary (${l.current})`)
            missingList.push(key)
        }

        if (isShowMissingWordInEnglish) {
            // check English word if it exists
            if (get(l, 'dictionaries', undefined) !== undefined) {
                if (get(l, 'dictionaries.EN', undefined) !== undefined) {
                    word = get(l, `dictionaries.EN.${key}`, undefined)
                    if (isSomething(word)) {
                        return word
                    }
                }
            }
        }
        // this word does not exists
        return showLabelWhenMissing ? missingWord : ''
    } else {
        // * Word exists case
        return devTools.isShowDictionaryLabel ? missingWord : word
    }
}

/** @param {string} lang specified language. */
export const updateTranslation = (lang) => {
    // update local storage
    // localStorage.setItem(`language`, lang) // fall back language
    // localStorage.setItem(`language-${CC.CountryCode3(true)}`, lang)
    l.memoFooter()
    // update flag, dictionary, currency
    l.dictionary = l.dictionaries[lang]
    // temp
    store.language = l.dictionaries[lang]
    store.flagCurrent = appConfig.flags[lang]
    store.currency = l.listenObject(store.currencyObject)
    // update moment
    const momentLocaleCode = momentLocale(CC.Country(), lang)
    if (testArray(momentLocaleCode, moment.locales()) === false) {
        if (momentLocaleCode !== 'en') {
            moment.locale(momentLocaleCode, require(`moment/locale/${momentLocaleCode}`).default)
        }
    } else {
        moment.locale(momentLocaleCode)
    }
    //logi('Moment Update (', momentLocaleCode, ') >>', moment("2019-06-10").format('MMM YYYY'), moment.locales())
    logn(`✅ 🌐 Language setting for [${lang}] has been updated.`)
}

// NOTE: Optimized this later
/** Display a NATIVE word from dictionary with specified key in native language
 * 
 * @param {string} key any key for a word related with dictionary.
 * @param {boolean} showLabelWhenMissing show label instead when this word is missing.
 * @param {boolean} showBracketAlongLabel use with missing word, show brackets along a label.
 * Use same as dictionary() but use dictionaryNative() instead of dictionary()
*/
export const translateNative = (key, showLabelWhenMissing = true, showBracketAlongLabel = true) => {

    const country = getCountryCode()

    if (store === undefined) return key
    if (store.dictionaryAll === undefined) return key
    if (store.dictionaryAll[country] === undefined) return key
    if (store.dictionaryAll[country][key] === undefined) return key

    let word = store.dictionaryAll[country][key]
    // follow language object when it's changed
    reaction(() => store.languageCurrent, () => word = store.dictionaryAll[country][key])
    // how to display brackets
    const bracketLeft = '[', bracketRight = ']'
    const tagBracket = (_string, brl, lb, brr) => `${showBracketAlongLabel ? brl : ''}${lb}${showBracketAlongLabel ? brr : ''}`
    const missingWord = tagBracket`${bracketLeft}${key}${bracketRight}`

    if (isNothing(word)) {
        // * Missing word case
        // check if this label alrady added in missing list
        const isSkipErrorMissingWord = testArray(key, missingList)
        if (isSkipErrorMissingWord === false) {
            loge(`Label [${key}] was not exists in dictionary (${getStoreLanguageName()})`)
            missingList.push(key)
        }

        // this word does not exists
        return showLabelWhenMissing ? missingWord : ''
    } else {
        // * Word exists case
        return devTools.isShowDictionaryLabel ? missingWord : word
    }
}