import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { LoadingOutlined } from '@ant-design/icons'
import { clearEasyShop } from '../../../ShoppingHelper';
import { dictionary, isCountryPhilippines } from '@GlobalHelpers';
import { isIPad } from '../../../utils/Bowser';
import { Row, Col, Button, Card, Layout, Spin } from 'antd';
import { storeOrderDetails } from '../Store/StoreOrderDetails';
import { store } from "../../../../stores/MainStore";
import FormCheckBox from '../Form/FormCheckBox';
import SuperAlert from '../../../fragment/SuperAlert';
import { If, Then, Else } from 'react-if';
import { mAddressHistory } from '../Store/StoreFormConfigs';
import SuperAlertHR from '@Components/fragment/SuperAlertHR';

// * Responsive - Grid 24 rule
const colResponsive = {
    wrapper: {
        xs: 22,
        sm: 22,
        md: 20,
        lg: isIPad() ? 20 : 12,
        xl: 12,
        xxl: 12
    },
    wrapperBody: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
        xxl: 24
    },
    wrapperFooter: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
        xxl: 24
    }
}

const alertResponsiveConfig = {
    xs: 24,
    sm: 24,
    md: 20,
    lg: 12,
    xl: 12,
    xxl: 12
}

const { Content, Footer } = Layout

const ODFooter = observer(props => {

    const [isCheckedTC, setCheckedTC] = useState(false)

    const onCheckedTC = (checked) => {
        // setCheckedTC(checked)

        // if(store.shareCart.sharedStatus !== 'A'){
        //     storeOrderDetails.isCheckTc = checked
        //     storeOrderDetails.errorMessage = []
        // }
    }

    return (
        <>
            <Content className="section-shipto-content-bottom">
                <Card>
                    <FormCheckBox onCheckedTC={onCheckedTC} />
                    {
                        /autoshipV2/.test(window.location.href) ? <SuperAlertHR
                            list={storeOrderDetails.errorMessage}
                            {...alertResponsiveConfig}
                        /> : <SuperAlert
                            list={storeOrderDetails.errorMessage}
                            {...alertResponsiveConfig}
                        />
                    }

                </Card>
            </Content>
            <Footer className="section-shipto-footer">
                <Row>
                    <Col {...colResponsive.wrapperFooter}>
                        <Card>
                            <Row type="flex" justify="center" gutter={[20, 10]} style={{ margin: 'auto' }}>
                                <Col>
                                    <Button onClick={props.onClickBack} disabled={storeOrderDetails.isLoadingAPI}>
                                        {dictionary('back')}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button type="primary"
                                        onClick={props.onClickCheckout}
                                        disabled={storeOrderDetails.isLoadingAPI || (props.autoship && mAddressHistory.selected === null)}>
                                        <Spin spinning={storeOrderDetails.isLoadingAPI}
                                            indicator={
                                                <LoadingOutlined
                                                    style={{ fontSize: '16px', color: 'white', marginRight: '10px' }} spin />} />
                                        {dictionary('continue')}
                                    </Button>
                                    {/* <If condition={isCountryPhilippines()}>
                                    <Then>
                                        <Button type="primary"
                                            onClick={props.onClickCheckout} 
                                            disabled={!store.shareCart.termAndCondition || storeOrderDetails.isLoadingAPI}>
                                            <Spin spinning={storeOrderDetails.isLoadingAPI} 
                                                indicator={
                                                    <LoadingOutlined 
                                                    style={{ fontSize: '16px', color:'white', marginRight: '10px' }} spin/>}/>
                                                {dictionary('continue')}
                                        </Button>
                                    </Then>
                                    <Else>
                                        <Button type="primary"
                                            onClick={props.onClickCheckout} 
                                            disabled={storeOrderDetails.isLoadingAPI}>
                                            <Spin spinning={storeOrderDetails.isLoadingAPI} 
                                                indicator={
                                                    <LoadingOutlined 
                                                    style={{ fontSize: '16px', color:'white', marginRight: '10px' }} spin/>}/>
                                                {dictionary('continue')}
                                        </Button>

                                    </Else>
                                </If> */}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Footer>
        </>
    )
})

export default ODFooter