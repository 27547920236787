import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { reaction } from 'mobx';

import { checkOutStore } from '../../../../stores/MainStore';
import { storeOrderDetails, storeSleepy } from '../Store/StoreOrderDetails';
import { storeResponsive } from '../Store/StoreResponsive';
import { mPickUpValue } from '../Store/StoreFormConfigs';
import { language } from '../../../../stores/StoreLanguage';

import { dictionary, randomNumber, randomString } from '@GlobalHelpers';
import { trim, isNothing } from '../../../utils/Utils';
import { ShippingAddress } from '../../../configs/Address';
import { CountryConfigs as CC } from '../../../configs/Configs';
import { hasQueryString } from '../../../configs/ConfigsHeader';

import TemplatePortal from './Template/TemplatePortal';

import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Layout from 'antd/es/layout';
import Typography from 'antd/es/typography';

import get from 'lodash/get';
import capitalize from 'lodash/capitalize';

import * as K from '../../../configs/Keywords';
import { CountryCode } from '../../../configs/Country';
import ShippingMethods from '@Configs/ShippingMethods';

const { Title } = Typography

const FormPickUp = props => {

    const getShippingKey = () => {
        switch (get(checkOutStore, 'shipping', '').toString()) {
            case '8': return 'pickUpSBY'
            default: return 'pickUp'
        }
    }

    const processSpecialCase = () => {
        // * Any special case for each country
        storeOrderDetails.errorMessage = []
    }

    // * Create default address for PickUp
    let shipTo = ShippingAddress.pickUp[CC.Country()]
    if (isNothing(shipTo)) shipTo = {}

    if (ShippingMethods.getWarehouseEnabled()) {
        shipTo = shipTo[checkOutStore.warehouse]
    }
    
    let isShipToHasLanguage = false
    Object.keys(shipTo).map(key => {
        if (key === 'EN') isShipToHasLanguage = true
    })
    if (isShipToHasLanguage) {
        shipTo = shipTo[language.current]
    }

    useEffect(
        () => {

            const billingKey = getShippingKey()

            checkOutStore.billingAddress[billingKey].address1 = get(shipTo, 'address1', '')
            checkOutStore.billingAddress[billingKey].address2 = get(shipTo, 'address2', '')
            checkOutStore.billingAddress[billingKey].city = get(shipTo, 'city', '')
            checkOutStore.billingAddress[billingKey].state = get(shipTo, 'state', '')
            checkOutStore.billingAddress[billingKey].zip = get(shipTo, 'zip', '')

            const reactionName = reaction(
                () => mPickUpValue.fullName,
                () => {
                    checkOutStore.billingAddress[billingKey].firstNameEn = trim(`${mPickUpValue.fullName}`)
                    checkOutStore.billingAddress[billingKey].lastNameEn = ''
                    processSpecialCase()
                }
            )

            const reactionEmail = reaction(
                () => mPickUpValue.email,
                () => {
                    checkOutStore.billingAddress[billingKey].email = trim(`${mPickUpValue.email}`)
                    processSpecialCase()
                }
            )

            const reactionMobile= reaction(
                () => mPickUpValue.mobile,
                () => {
                    checkOutStore.billingAddress[billingKey].mobilePhone = trim(`${mPickUpValue.mobile}`)
                    processSpecialCase()
                }
            )

            if (hasQueryString('sleepy')) {
                switch (CC.CountryCode()) {
                    case CountryCode.thailand: 
                        mPickUpValue.fullName = 'มัฒธณิฏาศ์ พรรษญธรธนัชส์'
                        mPickUpValue.mobile = `${randomNumber(storeSleepy.thailand.mobileLength)}`
                        mPickUpValue.email = `${randomString(8, true, true, true)}@unicity.com`
                        break
                    /* default: 
                        mPickUpValue.fullName = 'Leroy Irvin'
                        mPickUpValue.mobile = `${randomNumber(storeSleepy.singapore.mobileLength)}`
                        mPickUpValue.email = `${randomString(8, true, true, true)}@unicity.com` */
                }
            }

            return () => {
                // dispose watcher
                reactionName()
                reactionMobile()
                reactionEmail()
            }
        }, []
    )
    
    return useObserver(() =>
        <Layout className="section-form pickup">
            <Row {...storeResponsive.form.rowResponsive}>
                <Col>
                    <Title level={1}>{dictionary('u_all_od_header_pickup_form')}</Title>
                </Col>
            </Row>
            <Row {...storeResponsive.form.rowResponsive}>
                <Col {...storeResponsive.form.colResponsive}>
                    <TemplatePortal type={capitalize(K.PickUp)} country={CC.CountryFullDisplay()}/>
                </Col>
            </Row>
        </Layout>
    )
}

export default FormPickUp