import React, { useState, useEffect } from 'react';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { store } from '@Stores/MainStore';
import { defaults, sortByValue, isSomething } from '@Utils/Utils';
import { findString } from '@Utils/String';
import { language as l } from '@Language';
import { Input, Select } from '@mobiscroll/react';
import { CountryConfigs } from '@Components/configs/Configs';
import { isCountryHongkong } from '@Components/GlobalHelpers';

const getAlwaysTopValue = () => CountryConfigs.CountryCapital

const ProvincesPills = observer(props => {
    const [listForRender, setListForRender] = useState([])
    const [alwaysTop, setAlwaysTop] = useState(getAlwaysTopValue())

    const renderProvinces = () => {
        let list = []
        let topOption = {}
        const keyEnglish = isCountryHongkong() ? 'PROVINCE_NAME' : 'city_roman' // new api still not support hongkong // hongkong always show native
        const keyNative = isCountryHongkong() ? 'PROVINCE_NAME' : 'city_native' // new api still not support hongkong

        store.listProvinces.map((province, index) => {
            const englishTitle = defaults(province[keyEnglish], '').trim()
            const nativeTitle = defaults(province[keyNative], '').trim()
            const title = l.isEnglish() ? englishTitle : nativeTitle
            // move special option to top
            if (findString(alwaysTop, englishTitle)) {
                topOption = {
                    text: title,
                    value: title
                }
            } else {
                list.push({
                    text: title,
                    value: title
                })
            }
            return null
        })
        list.sort(sortByValue)
        if (isSomething(CountryConfigs.CountryCapital())) {
            list.unshift(topOption)
        } 
        setListForRender(list)
    }

    // ** Whenever `selected` changed, also update `listForRender` of provinces.
    useEffect(() => {

        const disposerLanguage = reaction(
            () => l.current,
            (lang, prevLang) => {
                if (lang !== prevLang) {
                    renderProvinces() // then push options to list again and re-render will happen
                }
            }, { fireImmediately: true }
        )

        return () => {
            disposerLanguage()
        }
        
    }, [])

    // ** An always top province was depend on country.
    useEffect(() => {
        setAlwaysTop(getAlwaysTopValue())
    }, [props.country])
    
    // ** Triggered this function when manual selected is happen.
    const onSelected = (event, inst) => {
        props.onSelected(inst.getVal())
    }

    return (
        <div className="select-provinces">
            <Select 
                display="center"
                touchUi={false}
                filter={true}
                filterPlaceholderText=""
                height={40} 
                maxWidth={400} 
                data={listForRender} 
                onSet={onSelected}
                value={props.value}
                group={{
                    groupWheel: false,
                    header: false
                }}
            >
                <Input dropdown inputStyle="box"/>
            </Select>
        </div>
    )
})
            
export default ProvincesPills