import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { checkOutStore } from '../../../stores/MainStore'
import { dictionary, isWordExist } from '@GlobalHelpers'
import { isNothing, defaults } from '@Utils/Utils';
import { SpinnerInline } from '@Fragment/Spinner';
import { When, If, Then, Else } from 'react-if';
import { Icon } from '@Fragment/FontAwesome';
import ShoppingClass from '../../shopping/ShoppingClass';

const LoginForm = observer(props => {
    const [errorMessage, setErrorMessage] = useState([])
    const [errors, setErrors] = useState([])
    const [showLoggingError, setShowLoggingError] = useState(false)
    const [loggingIn, setLoggingIn] = useState(false)
    // login value
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [formPwdType, setFormPwdType] = useState('password')
    const stylePwdReveal = {
        on: 'fas fa-eye fa-fw showPass text-primary', 
        off: 'fas fa-eye-slash fa-fw showPass'
    }

    // componentDidMount()
    useEffect(() => {
        const shoppingClass = new ShoppingClass()
        if(shoppingClass.isFamilyCustomer() === true) {
            checkOutStore.referralValue = '2'
            checkOutStore.referralBy = 'by_status_f' 
        }        
        if(shoppingClass.isPreferredCustomer() === true) {
            checkOutStore.referralValue = '2'
            checkOutStore.referralBy = 'by_status_p'   
        }
        checkOutStore.addressBook.showconfirmation = true
    }, [])

    const onClickLoginHandler = (e) => {
        e.preventDefault()
        clearErrors()
        let username = document.getElementById('username').value
        let password = document.getElementById('password').value
        if (isNothing(username) || isNothing(password)) {
            setErrorMessage(dictionary('error_login_incorrect'))
            setShowLoggingError(true)
            return
        }
        
        setLoggingIn(true)
        setShowLoggingError(false)
        setErrors([])
        
        props.login(username, password, (pass) => {
            if (pass) {
                if (pass.error !== undefined) {
                    errorConcatenate(pass.error)
                    setLoggingIn(false)
                    setShowLoggingError(true)
                } else {
                    if (pass.pass === false) {
                        setErrorMessage(dictionary('error_user_type_denied'))
                        setLoggingIn(false)
                        setShowLoggingError(true)
                    } else {
                        window.location.href = './checkout'
                    }
                }
            } else {
                setLoggingIn(false)
                setShowLoggingError(true)
            }
        })
    }

    const togglePasswordReveal = () => {
        if (formPwdType === 'password') {
            setFormPwdType('text')
        } else {
            setFormPwdType('password')
        }
    }

    const clearErrors = () => {
        setErrorMessage([])
    }

    const errorConcatenate = (error) => {
        const msg = error['error_message']
        let result = ''
        if (!isNothing(msg)) {
            if (isWordExist(msg, 'suspended') || isWordExist(msg, 'suspense')) {
                result = dictionary('error_account_suspense')
            } else if (isWordExist(msg, 'lock')) {
                result = dictionary('error_account_lock')
            } else if (isWordExist(msg, 'terminate')) {
                result = dictionary('error_account_terminate')
            } else if (isWordExist(msg, 'only vietnam')) {
                result = dictionary('error_account_vietnam')
            }
        }
        if (result === '') result = dictionary('error_login_incorrect')
        setErrorMessage(result)
    }

    return (
        <div className={`row ${defaults(props.className, '')}`} >
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="shopping-cart-footer checkout-footer not-login pb-3 mb-0">
                    <h4>{dictionary('existing_customer')}</h4>
                    <div className="row">
                        <div className="col-12">
                            <form onSubmit={(e) => onClickLoginHandler(e)} target="_parent" method="POST">
                                <div className="form-group mt-3">
                                    <label className="form-label">{dictionary('username')}</label>
                                    <input type="text" id="username" className="form-control no-autofill-style" 
                                    defaultValue={username} onChange={(event) => setUsername(event.target.value)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{dictionary('password')}</label>
                                    <div style={{ height: "45px" }}>
                                        <input type={formPwdType} id="password" className="form-control no-autofill-style" defaultValue={password} onChange={(event) => setPassword(event.target.value)} />
                                        <Icon icon={stylePwdReveal.on} icon2={stylePwdReveal.off}
                                            title="Show/Hide Password" cursor="pointer"
                                            onClick={togglePasswordReveal}
                                            toggle={formPwdType === 'text'}/>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" onClick={(e) => onClickLoginHandler(e)} 
                                    className={`btn btn-primary col-lg-4 col-md-6 col-sm-12 
                                    ${loggingIn ? 'disabled' : ''}`}>
                                    <SpinnerInline spin={loggingIn} className={"mr-3"}/>
                                    {dictionary('login')} 
                                    </button>
                                </div>
                            </form>
                            <div className="text-center">
                                <div className={`alert alert-danger transition-100ms margin-top-1x ${showLoggingError ? 
                                    `opacity-1 h-auto` : 
                                    `opacity-0 h-0 m-0 p-0`}`}>
                                    <p className="no-margin text-danger">{showLoggingError ? errorMessage : null}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix" />
            </div>
        </div>
    )
})

export default LoginForm