import { isString, defaults, trim, caseInsensitiveCompare } from './Utils';
import { upperCase as _upperCase, lowerCase as _lowerCase, replace } from 'lodash';

export const isContainSpecialCase = (text) => {
	const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/
	return format.test(text)
}

/** Removed **Zero-width** space characters (`U+200B`, `&#8203;`, `\u200B`) from **String** value then return. 
 * 
 * **Note:** If it's not **String** return the same value.
 * @return {String} the new result without zero-width space characters.
 */
export const eliminateGhostSpace = (value) => { return isString(value) ? value.replace(/\u200B/g,'') : value }

export const eliminatesSingleQuote = (text) => {
	const format = /'/g
	return text.replace(format, '')
}

export const testNumber = (value) => {
	const format = /^[0-9]+$/
	return format.test(value)
}

/** Remove spaces and zero-width spaces from string value. 
 * @param {string} value
 * @return {string}
*/
export const removeSpaces = value => defaults(value, '').replace(/[\s\u200B]/g, '')

export const removeSpecial = value => defaults(value, '').replace(/[\s\u200B!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '')

/** Converts **String** to `upper case` without harm (prevent an undefined error). */
export const upperCase = value => defaults(value, '').toString().toUpperCase()

/** Converts **String** to `lower case` without harm (prevent an undefined error). */
export const lowerCase = value => defaults(value, '').toString().toLowerCase()

export const findString = (text, find) => defaults(text, '').search(find) !== -1

/** An alias version of `caseInsensitiveCompare()`.
 *
 * Make a case-insensitive compare betweenn two string.
 * Only accept `String` type. Any other type of value the result always return`false`.
 * @param {string} stringA - first value of string.
 * @param {string} stringB - value of string need to compare with.
 * @return {boolean}
 * @example
 * isEqualText('CreditCard', 'creditcard') // true
 * isEqualText('Actived', 'Terminated') // false */
 export const isEqualText = (stringA, stringB) => caseInsensitiveCompare(stringA, stringB)