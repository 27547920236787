import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { checkOutStore } from '../../stores/MainStore';

export default @observer class ShoppingClass extends Component {

    constructor(props) {
        super(props)
    }

    isPreferredCustomer = () => {
        let isPreferredCustomer = false

        try {
            let cType = checkOutStore.dToken.acl.api.type
            let cStatus = checkOutStore.dToken.acl.api.status

            isPreferredCustomer = (cType === 'PreferredCustomer' && cStatus === 'Active')

        } catch (e) {
            // ...
        }

        return isPreferredCustomer
    }

    isFamilyCustomer = () => {
        let isFamilyCustomer = false

        try {
            let cType = checkOutStore.dToken.acl.api.type
            let cStatus = checkOutStore.dToken.acl.api.status

            isFamilyCustomer = (cType === 'Family' && cStatus === 'Active')

        } catch (e) {
            // ...
        }

        return isFamilyCustomer
    }

    render() {
        return (<view></view>)
    }

}