import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import { aramexValidator, checkOutPeriod } from '@Services/Network';
import { uniqueEmail, referralAddressValidator, updateAddress } from '@Services/Network';
import { checkOutStore, shopStore, store } from '../../../stores/MainStore';
import { resetReferralStore, getReferralFormData } from '@Components/EnrollHelper';
import { getShippingKey, getShoppingFormData, getShoppingPickUpAddress, getShoppingAramexAddress, getShoppingDeliveryAddress, getHydraNotes, clearEasyShop } from '@Components/ShoppingHelper'
import Login from '../login/Login';
import OrderDetails from '../OrderDetails/OrderDetails';
import { storeOrderDetails } from '../OrderDetails/Store/StoreOrderDetails';
// import OrderDetails from '../../orderdetails/OrderDetails';
import { dictionary, getStoreLanguageName, getLanguageName, isCountryJapan, isCountryPhilippines } from "@GlobalHelpers";
import { isNothing, isSomething, justOneOf, toBool } from '@Utils/Utils';
import { isCustomerLogin, clearCartStorage, getCustomerObject } from '../../utils/Customer';
import { If, Then, Else } from 'react-if';
import { storeEasyShop } from '../../../stores/StoreEasyShop';
import { CountryConfigs as C } from '../../configs/Configs';
import { Layout, Row, Col } from 'antd';
import PageTitle from '../../fragment/PageTitle';
import { CountryCode } from '../../configs/Country';
import Raven from '@Services/Raven';
import { ShippingMethods } from '../../configs/ShippingMethods';
import get from 'lodash/get';
import extend from 'lodash/extend';
import set from 'lodash/set';
import unset from 'lodash/unset';
import * as K from '../../configs/Keywords';
import { toJS } from 'mobx';
import { mDeliveryValue } from '../OrderDetails/Store/StoreFormConfigs';
import OrderDetailProcess from './OrderDetailProcess';
// Avaiable Country: TH, SG
// In-progress Country: NZ

// Destructuring for antd components 
const { Header, Content } = Layout

// * Core Component
const CheckoutProcess = props => {
    const history = useHistory()

    const [isLoadingAPI, setLoadingAPI] = useState(false)
    const [commissionMonth, setCommissionMonth] = useState([])
    const [errorMessage, setErrorMessage] = useState([])
    const [hasError, setHasError] = useState(false)

    const clearErrors = () => {
        setErrorMessage([])
        setLoadingAPI(false)
    }

    const setErrors = (messages = []) => {
        setErrorMessage(messages)
        setLoadingAPI(false)
    }

    useEffect(() => {
        if (isSomething(errorMessage)) {
            if (hasError === false) {
                shopStore.passCheckOut = false
                setHasError(true)
            }
        } else {
            if (hasError === true) setHasError(false)
        }
    }, [errorMessage])

    const passedValidate = () => {
        shopStore.passCheckOut = true
        history.push(`/${C.Country()}/checkout/summary`)
    }

    useEffect(() => {
        if (isNothing(checkOutStore.shipping)) {
            checkOutStore.shipping = '2'
        }

        if (isNothing(checkOutStore.warehouse)) {
            checkOutStore.warehouse = get(ShippingMethods.getWarehouseAcceptance(), `${C.Country()}[0]`, '')
        }


        if (isCustomerLogin()) {
            onSelectShippingChange(checkOutStore.shipping)
        }

        return () => {
            let path = window.location.pathname.split('/');
            if (path[2] !== 'cart' && path[2] !== 'checkout') {
                shopStore.passCheckOut = false
                storeEasyShop.isEnabled = false
                clearEasyShop()
            }
        }
    }, [])

    const processWarehouse = () => {
        if (ShippingMethods.getWarehouseEnabled()) {
            console.log('Warehouse =', checkOutStore.warehouse)
            if (isSomething(checkOutStore.warehouse)) {
                const overridedShippingID = get(
                    ShippingMethods.WarehouseDeclaration,
                    `${checkOutStore.warehouse}.shippingValue.${ShippingMethods.getShippingName(checkOutStore.shipping)}`,
                    checkOutStore.shipping
                )

                checkOutStore.shipping = overridedShippingID
                console.log('overridedShippingID', overridedShippingID)
            }
        }
    }

    const onSelectShippingChange = () => {
        processWarehouse()
        clearErrors()
    }

    const onInputChanged = (key, value) => {
        const shippingKey = ShippingMethods.getShippingName(checkOutStore.shipping)
        console.log('change', shippingKey, key, value)
        if (isNothing(checkOutStore.billingAddress[shippingKey])) {
            checkOutStore.billingAddress[shippingKey] = {}
        }

        checkOutStore.billingAddress[shippingKey][key] = value
        checkOutStore[key] = value

        if (document.getElementById('checkout-' + key) !== null) {
            document.getElementById('checkout-' + key).value = value
        }
    }

    const onValidatedDeliverySuccess = () => {
        checkOutStore.checkout = true
        passedValidate()
    }

    const onConfirmedShipping = () => {
        setLoadingAPI(true)
        checkOutStore.checkout = true
        const shippingKey = ShippingMethods.getShippingName(checkOutStore.shipping)
        const billingAddress = checkOutStore.billingAddress[shippingKey]

        console.log('>> Shipping', checkOutStore.shipping, '>>', shippingKey)
        console.log('>> CHECK', toJS(checkOutStore.billingAddress[shippingKey]))

        let data = {
            language: getLanguageName(store.languageCurrent),
            source: getHydraNotes(''),
            period: checkOutStore.commissionMonth,
            lastName: '',
            lastNameEn: '',
            firstName: billingAddress.firstNameEn,
            firstNameEn: billingAddress.firstNameEn,
            address1: billingAddress.address1,
            address2: billingAddress.address2,
            zip: billingAddress.zip,
            email: billingAddress.email,
            mobilePhone: billingAddress.mobilePhone,
            city: billingAddress.city,
            state: billingAddress.state,
            country: billingAddress.country || C.CountryCode(),
            comment: billingAddress.comment || ''
        }

        if (isCountryJapan()) {
            set(data, 'firstName@ja', billingAddress.firstNameEn)
            set(data, 'lastName@ja', billingAddress.lastNameEn)
            set(data, 'city', '')

            unset(data, 'firstName')
            unset(data, 'lastName')
            unset(data, 'firstNameEn')
            unset(data, 'lastNameEn')
            unset(data, 'state')
            unset(data, 'comment')
        }



        // * Aramex -> Attached the SNS
        if (checkOutStore.shipping === ShippingMethods.ID.aramex) {
            set(data, 'sns', `${billingAddress.sns1}${billingAddress.sns2}`)
            Raven.validateAramex(data)
                .then(response => {
                    if (toBool(response.success)) {
                        setLoadingAPI(false)
                        passedValidate()
                    } else {
                        setErrors(response.Message)
                    }
                })
                .catch(() => {
                    setErrors(['Error, the validation is failed.'])
                })
        } else {
            // validate address
            if (isNothing(checkOutStore.referralValue)) {
                Raven.validateAddress(data)
                    .then(response => {
                        if (toBool(response.success)) {
                            setLoadingAPI(false)
                            passedValidate()
                        } else {
                            setErrors(response.Message)
                        }
                    })
                    .catch(() => {
                        setLoadingAPI(false)
                    })
            } else {
                // validate for referral, delivery, pickup
                let dataRefferal = getReferralFormData()
                clearErrors()
                uniqueEmail((resUniqeEmail, succUniqeEmail) => {
                    if (succUniqeEmail === false && resUniqeEmail.status == 404) {
                        validateReferralAddress(dataRefferal, '')
                    } else {
                        validateReferralAddress(dataRefferal, 'Email already exists')
                    }
                }, dataRefferal.email)

            }
        }
    }

    const validateReferralAddress = (dataRefferal, errorEmailMessage) => {
        referralAddressValidator((response, success) => {
            setLoadingAPI(false)
            if (success === true) {
                if (toBool(response.success)) {
                    if (errorEmailMessage !== '') {
                        setErrors([errorEmailMessage])
                    } else {
                        // console.log("validate referral ", checkOutStore.shipping)
                        passedValidate()
                    }
                } else {
                    if (errorEmailMessage !== null) {

                        let combineErrors = response.Message
                        combineErrors.push(errorEmailMessage)
                        setErrors(combineErrors)
                    } else {
                        setErrors(response.Message)
                    }
                }
            }
        }, dataRefferal)
    }




    const rowConfig = {
        type: 'flex',
        justify: 'center'
    }

    const colConfig = {
        OrderDetails: {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 24,
            xl: 24,
            xxl: 24
        },
        EasyShop: {
            xs: 22,
            sm: 22,
            md: 22,
            lg: 20,
            xl: 18,
            xxl: 18
        }
    }

    return useObserver(() =>
        <Layout className="offcanvas-wrapper">
            {/* <Header>
                <PageTitle>
                    {dictionary('u_all_welcome')}
                    
                </PageTitle>
            </Header> */}
            <Content>
                <Row {...rowConfig}>
                    {/* <Col className="block-order-detail">
                        <Login {...props} login={(username, password, callback) => props.login(username, password, callback)} />
                    </Col> */}
                    <OrderDetailProcess 
                        {...props}
                        store={props.store}
                        history={props.history}
                        shopStore={props.shopStore}
                    />

                    {/* <If condition={!isCountryPhilippines()}>
                        <Then>
                            <Col className="block-order-detail">
                                <Login {...props} login={(username, password, callback) => props.login(username, password, callback)}/>
                            </Col>
                        </Then>
                        <Else>
                            <OrderDetailProcess 
                                {...props}
                                store={props.store}
                                history={props.history}
                                shopStore={props.shopStore}
                            />
                        </Else>
                    </If> */}


                </Row>



            </Content>
        </Layout>
    )
}

CheckoutProcess.displayName = 'CheckoutProcess'
export default CheckoutProcess