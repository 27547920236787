import * as K from './Keywords';

export const SectionsDeclaration = {
    OrderDetails: () => {
        return {
            title: {
                [K.All]: true,
                [K.Thailand]: {
                    status: {
                        except: ['E']
                    }
                }
            },
            commission: {
                [K.All]: true,
                [K.Thailand]: {
                    status: {
                        except: ['E']
                    }
                },
                [K.Japan]: false
            },
            shippingMethods: {
                [K.All]: true,
                [K.Thailand]: {
                    status: {
                        except: ['E']
                    }
                },
                [K.Japan]: false
            },
            warehouse: {
                [K.All]: false,
                [K.Indonesia]: true
            },
            time: {
                [K.All]: false,
                [K.Japan]: true
            },
            subscribe: {
                [K.All]: true,
                [K.Thailand]: {
                    status: {
                        except: ['E']
                    }
                }
            }
        }
    }
}