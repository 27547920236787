import React, { useState, useEffect, Fragment } from 'react';
import { useObserver } from 'mobx-react-lite';
import { isSomething, defaults, trim } from '../utils/Utils';
import { dictionary } from '@GlobalHelpers';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Alert from 'antd/es/alert';
import PropTypes from 'prop-types';
import { language } from '@Language';

/**
 * @param {object} props 
 * @param {object|string} props.alertClassName
 * @param {object} props.alertStyle
 * @param {object|string} props.className
 * @param {object} props.style
 * @param {array|object} props.list
 * @param {string} props.name
 * @param {'error'|'warning'|'success'|'info'} props.type
 * @param {number|text} props.span
 * @param {number|text} props.xs
 * @param {number|text} props.sm
 * @param {number|text} props.lg
 * @param {number|text} props.xl
 * @param {number|text} props.xxl
 */
const SuperAlertHR = props => {
    SuperAlertHR.propTypes = {
        alertClassName: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        alertStyle: PropTypes.object,
        className: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        style: PropTypes.object,
        name: PropTypes.string,
        type: PropTypes.string
    }

    SuperAlertHR.defaultProps = {
        list: [],
        name: '',
        type: 'error'
    }

    const {
        className, style, alertClassName, alertStyle,
        list, type, description, title, name, span,
        ...customProps } = props

    SuperAlertHR.displayName = `SuperAlertHR${isSomething(name) ? `-${name}` : ``}`

    const renderList = () => {
        if (isSomething(list)) {
            return list.map((message, index) => {
                // test suspected dictionary key 
                let output = message[language.footer]
                if ((/^[a-z0-9_-]+$/g).test(message)) {
                    const d = dictionary(message, true, true)
                    if (d.indexOf(']') > -1) {
                        // dictionary not contained this key
                        output = message
                    } else {
                        // dictionary key
                        output = d
                    }
                }
                return (<Fragment key={index}>
                    <div className="hr-alert-error" />
                    <li style={{ padding: 5 }}>{(output)}</li>
                </Fragment>
                )
            })
        } else {
            return []
        }
    }

    const isNoFrame = props.noFrame !== undefined || props.noFrame === true

    const [responsiveGrid, setResponsiveGrid] = useState({ span: 24 })

    useEffect(() => {
        setResponsiveGrid({
            span: defaults(props.span, 24),
            xs: props.xs,
            sm: props.sm,
            md: props.md,
            lg: props.lg,
            xl: props.xl,
            xxl: props.xxl
        })
    }, [props.span, props.xs, props.sm, props.md, props.lg, props.xl, props.xxl])

    return useObserver(() => {
        if (isSomething(list)) {
            return (
                <Row
                    type="flex" justify="center"
                    className={trim(`super-alert ${isNoFrame ? 'no-frame' : ''} ${defaults(className, '')}`)} style={style}>
                    <Col {...responsiveGrid}>
                        <Alert {...customProps}
                            className={alertClassName}
                            style={alertStyle}
                            type={defaults(type, 'error')}
                            description={<ul>{renderList()}</ul>}
                        />
                    </Col>
                </Row>
            )
        } else {
            return null
        }
    })
}

export default SuperAlertHR