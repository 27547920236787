import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Mobx
import { useObserver } from 'mobx-react';
import { checkOutStore } from '../../../../stores/MainStore';
// Library
import get from 'lodash/get';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Divider from 'antd/es/divider';
import Typography from 'antd/es/typography';
// Helpers
import { useWhen } from '../../../utils/Hooks';
import { SectionsDeclaration } from '../../../configs/Sections';
import { CountryConfigs as CC } from '../../../configs/Configs';
import { ShippingMethods } from '../../../configs/ShippingMethods'
import { dictionary } from '@GlobalHelpers';
import { throatBoolean } from '../../../configs/ConfigsHeader';
import { isNothing } from '../../../utils/Utils';

const { Title } = Typography
const { Option } = Select

/**
 * @param {object} props
 * @param {boolean} props.loading 
 */
const SectionWarehouse = props => {

    const when = useWhen()

    const [warehouse, setWarehouse] = useState(() => {
        if (isNothing(checkOutStore.warehouse)) {
            return get(ShippingMethods.WarehouseAcceptance, `${CC.Country()}[0]`, '')
        } else {
            return checkOutStore.warehouse
        }
    })

    const optionsFactory = () => {
        const result = []
        ShippingMethods.getWarehouseAcceptance().map((method, index) => {
            let key = '', value = ''
            key = get(ShippingMethods.WarehouseDeclaration, `${method}.dictionary`)
            value = get(ShippingMethods.WarehouseDeclaration, `${method}.value`)
            result.push((
                <Option value={value} key={index}>
                    {dictionary(key)}
                </Option>
            ))
        })

        return result
    }

    // always update mobx warehouse whenever state changed
    useEffect(() => {
        checkOutStore.warehouse = warehouse
    }, [warehouse])

    return useObserver(() => 
        when(throatBoolean(SectionsDeclaration.OrderDetails().warehouse),
            when(
                ShippingMethods.getWarehouseEnabled(),
                <Row type="flex" justify="center" className="text-center mt-5">
                    <Col span={24}>
                        <Title level={4}>{dictionary('shipping_warehouse')}</Title>
                        <Divider/>
                        <Row id="delivery" type="flex" justify="center">
                            <Col span={16} className="shipping-method">
                                <Select  
                                    defaultValue={warehouse}
                                    value={warehouse}
                                    onChange={value => setWarehouse(value)}
                                    disabled={props.loading}>
                                    {optionsFactory()}
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        )
    )
}

SectionWarehouse.propTypes = {
    loading: PropTypes.bool
}

SectionWarehouse.defaultProps = {
    loading: false
}

SectionWarehouse.displayName = 'SectionWarehouse'
export default SectionWarehouse