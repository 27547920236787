import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import { reaction } from 'mobx';
import moment from 'moment';
import Scroll from 'react-scroll';
import { Switch, Case } from 'react-if';

import { checkOutStore, store, shopStore } from '../../../stores/MainStore';
import { storeEasyShop } from '../../../stores/StoreEasyShop';
import { checkOutPeriod, expandCustomer } from '@Services/Network';
import Raven from '@Services/Raven';

import { getShippingKey, getHydraNotes, clearEasyShop } from '@Components/ShoppingHelper';
import { getRefferalDeliveryAddress, getRefferalPickUpAddress, getReferralFormDataTHA, getReferralFormData, getReferralAramexAddress } from '@Components/EnrollHelper';
import { dictionary, getStoreLanguageName, isCountryThailand, isCountrySingapore, isCountryAustralia, randomNumber, isCountryHongkong, localizeProvinceOutput, isLanguageNative, isLocalhost } from '@GlobalHelpers';
import { hasQueryString } from '@Configs/ConfigsHeader'
import { isSomething, eliminateJSONEnemy, isString, trim, toBool, someOf } from '@Utils/Utils';
import { isMobile, isDesktop } from '@Bowser'
import { logi } from '../../utils/PikaLog';
import { useWhen } from '../../utils/Hooks';

import { CountryConfigs as C } from '../../configs/Configs';

import ProvincesPills from '../../fragment/dropdown/ProvincesPills';
import SuperInput from '../../fragment/SuperInput';
import SuperAlert from '../../fragment/SuperAlert';
import SubscribeCheckBox from '../../fragment/common/SubscribeCheckBox';

import Layout from 'antd/es/layout';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Card from 'antd/es/card';
import Typography from 'antd/es/typography';
import Divider from 'antd/es/divider';
import Button from 'antd/es/button';

import get from 'lodash/get';
import reject from 'lodash/reject';
import ShippingMethods from '@Configs/ShippingMethods';

const { Content, Footer } = Layout
const { Title } = Typography
const { Option } = Select

const Element = Scroll.Element
const scroller = Scroll.scroller

/** Selection component for Shipping Methods & Warehouses. */
const RoundPills = props => {
    return useObserver(() => 
        <Select size="large" {...props}>
            {props.children}
        </Select>
    )
}

// ** Core Component
const EasyForm = props => {
    const history = useHistory()
    const when = useWhen()

    const [hasError, setHasError] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    const [selected, setSelected] = useState()
    const [continueButtonDisable, setContinueButtonDisable] = useState(false)
    const [commissionMonth, setCommissionMonth] = useState([])
    const [additionalAddressLabel, setAdditionalAddressLabel] = useState('')

    useEffect(() => {
        const disposerLanguage = reaction(
            () => store.languageCurrent, 
            () => clearErrors()
        )

        logi('Referral by:', storeEasyShop.referralBy, '| Detail:', storeEasyShop.referenceDetail)
        if (storeEasyShop.referralBy === 'ba_id' && storeEasyShop.referenceDetail !== '') {
            checkOutPeriod((response, success) => {
                if (success === true) {
                    checkOutStore.commissionMonth = response.entryPeriod
                    const customerData = storeEasyShop.referenceDetail
                    const checkEnrollTopup = moment(customerData.joinDate).format('YYYY-MM')

                    if (checkEnrollTopup === response.message[0].period && response.topup === 'Yes') {
                        setCommissionMonth([response.message[0]])
                    } else {
                        setCommissionMonth(response.message)
                    }
                }
            }, {
                country: store.country_code,
                system: 'AO'
            })
        }

        storeEasyShop.isEasyShopForm = true
        
        setScroll()
        changeAddressLabel()

        // if (hasQueryString('sleepy') || isLocalhost()) {
        //     if (isCountryThailand()) {
        //         storeEasyShop.referralAddress.delivery.firstNameEn = 'Happylife Jaidee'
        //         storeEasyShop.referralAddress.delivery.lastNameEn = ''
        //         storeEasyShop.referralAddress.delivery.address1 = '1150 Sleepy Condominium'
        //         storeEasyShop.referralAddress.delivery.address2 = 'Giant Street, Empty District'
        //         storeEasyShop.referralAddress.delivery.zip = '10150'
        //         storeEasyShop.referralAddress.delivery.email = 'test@unicity.com'
        //         storeEasyShop.referralAddress.delivery.mobilePhone = '0972290022'
        //         storeEasyShop.referralAddress.delivery.city = localizeProvinceOutput('Bangkok')
        //         storeEasyShop.referralAddress.delivery.state = ''
        //         storeEasyShop.referralAddress.delivery.comment = ''
        //         setSelected(localizeProvinceOutput('Bangkok'))
        //     } else if (isCountrySingapore()) {
        //         storeEasyShop.referralAddress.delivery.firstNameEn = 'Happylife Jaidee'
        //         storeEasyShop.referralAddress.delivery.lastNameEn = ''
        //         storeEasyShop.referralAddress.delivery.address1 = '1150 Sleepy Condominium'
        //         storeEasyShop.referralAddress.delivery.address2 = 'Giant Street, Empty District'
        //         storeEasyShop.referralAddress.delivery.zip = '201500'
        //         storeEasyShop.referralAddress.delivery.email = 'test@unicity.com'
        //         storeEasyShop.referralAddress.delivery.mobilePhone = '09722900'
        //         storeEasyShop.referralAddress.delivery.city = 'Singapore'
        //         storeEasyShop.referralAddress.delivery.state = ''
        //         storeEasyShop.referralAddress.delivery.comment = ''
        //         setSelected(undefined)
        //     } else if (isCountryHongkong()) {
        //         storeEasyShop.referralAddress.delivery.firstNameEn = 'aaaa'
        //         storeEasyShop.referralAddress.delivery.lastNameEn = 'bbbb'
        //         storeEasyShop.referralAddress.delivery.address1 = 'cccc'
        //         storeEasyShop.referralAddress.delivery.address2 = 'dddd'
        //         storeEasyShop.referralAddress.delivery.zip = 'hhhh'
        //         storeEasyShop.referralAddress.delivery.email = 'ffff@unicity.com'
        //         storeEasyShop.referralAddress.delivery.mobilePhone = '49722900'
        //         storeEasyShop.referralAddress.delivery.city = 'mmmm'
        //         storeEasyShop.referralAddress.delivery.state = ''
        //         storeEasyShop.referralAddress.delivery.comment = ''
        //         setSelected(undefined)
        //     } else {
        //         storeEasyShop.referralAddress.delivery.firstNameEn = 'Gandalf Graymage'
        //         storeEasyShop.referralAddress.delivery.lastNameEn = ''
        //         storeEasyShop.referralAddress.delivery.address1 = '445 Hobbit Village'
        //         storeEasyShop.referralAddress.delivery.address2 = 'Mount Eden Road, Mount Eden'
        //         storeEasyShop.referralAddress.delivery.zip = '2010'
        //         storeEasyShop.referralAddress.delivery.email = 'test@unicity.com'
        //         storeEasyShop.referralAddress.delivery.mobilePhone = randomNumber(10, false)
        //         storeEasyShop.referralAddress.delivery.city = 'Wellington'
        //         storeEasyShop.referralAddress.delivery.state = ''
        //         storeEasyShop.referralAddress.delivery.comment = ''
        //         setSelected(undefined)
        //     }
        // }

        return () => {
            disposerLanguage()

            let path = window.location.pathname.split('/');
            if (path[2] !== 'cart' && path[2] !== 'checkout') {
                shopStore.passCheckOut = false
                clearEasyShop()
            }
        }
    }, [])

    useEffect(() => {
        changeAddressLabel(selected)
        checkOutStore.city = selected
        const shipping = getShippingKey()
        storeEasyShop.referralAddress[shipping]['city'] = selected
        storeEasyShop.referralAddress[shipping].lastNameEn = ''
    }, [selected])

    const FormFooter = (props) => {
        return (
            <Footer className="shopping-footer">
                <Row>
                    <Col {...colResponsive.wrapperFooter}>
                        <Card>
                            <Row type="flex" justify="space-between">
                                <Col>
                                    <Button onClick={onClickBack} disabled={continueButtonDisable}>
                                        <i className="icon-arrow-left"/>
                                        <span className="d-none d-sm-block ml-2">{dictionary('back')}</span>
                                    </Button>
                                </Col>
                                <Col>
                                    <Button type="primary"
                                        onClick={onClickCheckout} 
                                        loading={continueButtonDisable}>
                                        <span className="d-none d-sm-block mr-2">
                                            {dictionary('continue')}
                                        </span>
                                        <i className="icon-arrow-right "></i>
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Footer>
        )
    }

    const setScroll = () => {
        if (isMobile()) {
            scroller.scrollTo('dummyScrollToElement', {
                duration: 750,
                delay: 50,
                smooth: true,
                offset: -100, // Scrolls to element + 50 pixels down the page                    
            })
        } else {
            window.scroll(0, 0)
        }
    }

    const changeAddressLabel = value => {
        const city = value
        if (isCountryThailand()) {
            if ((city !== 'กรุงเทพมหานคร') && (city !== 'Bangkok') && (city !== 'none')) {
                setAdditionalAddressLabel('2')
            } else {
                setAdditionalAddressLabel('1')
            }
        }
    }

    const setShippingAddress = (key, value) => {
        if (key === 'referral_id') {
            storeEasyShop.referralValue = value
        } else {
            checkOutStore[key] = value
            const shipping = getShippingKey()
            storeEasyShop.referralAddress[shipping][key] = value
            storeEasyShop.referralAddress[shipping].lastNameEn = ''
    
            if (key === 'city') {
                setSelected(storeEasyShop.referralAddress[shipping].city)
            }
        }

        clearErrors()
    }

    const onCommissionMonthSelected = (name, value) => {
        checkOutStore.commissionMonth = value
    }

    const onClickBack = () => {
        clearEasyShop()
        props.backToLogin()
    }

    const onClickCheckout = () => {
        clearErrors()
        setContinueButtonDisable(true)

        const referralID = trim(storeEasyShop.referralValue)
        if (isSomething(referralID)) {
            const statuses = ['Active', 'Pending']
            expandCustomer((res, success) => {
                if (success) {
                    if (res.items[0] !== undefined) {
                        if (res.items[0].type === 'Associate' && statuses.indexOf(res.items[0].status) >= 0) {
                            checkOutStore.referenceDetail = res.items[0]
                            executeValidateAddress()
                        } else {
                            logi(`Referral ID has invalid status! (${res.items[0].type}, ${res.items[0].status})`)
                            setCheckoutErrors([dictionary('invalid_referral_id_status')])
                        }
                    } else {
                        setCheckoutErrors([dictionary('invalid_referral_id')])
                    }
                } else {
                    setCheckoutErrors([dictionary('invalid_referral_id')])
                }
            }, '', referralID)
        } else {
            executeValidateAddress()
        }
    }

    const onSelectShipping = (value) => {
        clearErrors()
        setShippingAddress('shipping', value)
    }

    const getShippingMethodOptions = () => {
        const result = []

        let list = ShippingMethods.getShippingAcceptance()

        if (isCountrySingapore()) {
            if (storeEasyShop.easyChoice === 2) {
                list = reject(list, item => item === 'aramex')
            }
        }

        list.map((method, index) => {
            let key = '', value = ''
            if (method.indexOf('.') === -1) {
                key = get(ShippingMethods.ShippingDeclaration, `${method}.default.dictionary`)
                value = get(ShippingMethods.ShippingDeclaration, `${method}.default.value`)
            } else {
                key = get(ShippingMethods.ShippingDeclaration, `${method}.dictionary`)
                value = get(ShippingMethods.ShippingDeclaration, `${method}.value`)
            }

            result.push((
                <Option value={value} key={`${index}`}>
                    {dictionary(key)}
                </Option>
            ))
        })

        return result
    }

    const clearErrors = () => {
        setHasError(false)
        setErrorMessage([])
    }

    const executeValidateAddress = () => {
        let shipping = getShippingKey()
        let dataValidate = isCountryThailand() ? getReferralFormDataTHA() : getReferralFormData()
        // eliminate anti-JSON characters
        Object.keys(dataValidate).map((key) => {
            const value = dataValidate[key]
            if (isString(value)) {
                dataValidate[key] = eliminateJSONEnemy(value)
            }
        })

        dataValidate.language = getStoreLanguageName()
        dataValidate.source = getHydraNotes('')
        dataValidate.period = checkOutStore.commissionMonth
        dataValidate.comment = storeEasyShop.referralAddress[shipping].comment || ''

        if (checkOutStore.shipping === ShippingMethods.ID.aramex) {
            Raven.validateAramex(dataValidate)
            .then(response => {
                if (toBool(response.success)) {
                    passedValidate()
                } else {
                    setCheckoutErrors(response.Message)
                }
            })
        } else {
            Raven.validateAddress(dataValidate)
            .then(response => {
                if (toBool(response.success)) {
                    onValidatedDeliverySuccess()
                } else {
                    setCheckoutErrors(response.Message)
                }
            })
        }
    }

    const setCheckoutErrors = (message) => {
        shopStore.passCheckOut = false
        setHasError(true)
        setErrorMessage(message)
        setContinueButtonDisable(false)
    }

    const onValidatedDeliverySuccess = () => {
        checkOutStore.checkout = true
        clearErrors()
        // reset to 'show' so that continue button work correctly
        passedValidate()
    }

    const passedValidate = () => {
        // going to exit from EasyForm page
        shopStore.passCheckOut = true
        storeEasyShop.isEasyShopForm = false

        console.log('checkOutStore')
        console.log(checkOutStore)
        
        props.proceedCheckoutFinal();
       // history.push(`/${C.Country()}/checkout/summary`)
        
    }

    const onCheckEmail = () => {
        checkOutStore.isReceivedEmail = !checkOutStore.isReceivedEmail
    }

    const onCheckReceipt = () => {
        checkOutStore.isReceivedInvoice = !checkOutStore.isReceivedInvoice
    }


    const commission = []
    commissionMonth.map((v) => {
        return commission.push({
            value: v.period,
            name: v.name,
            native: v.native
        })
    })

    const c = C.CountryCode(true)
    const a = additionalAddressLabel
    const keyLabelAddressL1 = `address_line1_${c}`
    const keyLabelAddressL2 = `address_line2_${c}${a}`
    const keyFullname = `placeholder_full_name_${c}`
    const keyAddressL1 = `placeholder_address_line1_${c}`
    const keyAddressL2 = `placeholder_address_line2_${c}`
    const keyCity = `placeholder_city_${c}`
    const keyComment = `placeholder_comment_${c}`
    const keyZip = `placeholder_zip_${c}`
    const keyMobile = `placeholder_mobile_phone_${c}`
    const keyEmail = `placeholder_email_${c}`

    // placeholder keys
    const keyPlaceholderFullname = `placeholder_full_name_${c}`
    const keyPlaceholderAddressL1 = `placeholder_address_line1_${c}`
    const keyPlaceholderAddressL2 = `placeholder_address_line2_${c}`
    const keyPlaceholderCity = `placeholder_city_${c}`
    const keyPlaceholderDistrict = `placeholder_district_${c}`
    const keyPlaceholderState = `placeholder_state_${c}`
    const keyPlaceholderZip = `placeholder_zip_${c}`
    const keyPlaceholderMobile = `placeholder_mobile_phone_${c}`
    const keyPlaceholderEmail = `placeholder_email_${c}`
    const keyPlaceholderComment = `placeholder_comment_${c}`

    // * Row - flex with justify-content-center
    const rowResponsive = {
        type: 'flex',
        justify: 'center'
    }
    // * Responsive - Grid 24 rule
    const colResponsive = {
        wrapper: {
            xs: 22,
            sm: 22,
            md: 12,
            lg: 10,
            xl: 10,
            xxl: 8
        },
        wrapperAddress: {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 24,
            xl: 24,
            xxl: 24
        },
        wrapperFooter: {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 24,
            xl: 24,
            xxl: 24
        }
    }

    const formItemConfig = {
        colon: false
    }

    return (<>
        <Layout className="order-details">
            <Content>
                <Row {...rowResponsive}>
                    <Col {...colResponsive.wrapper}>
                        <Element name="dummyScrollToElement"/>
                        <Row className="text-center">
                            {/* --- Start -> Commission Months --- */}
                            {when(
                                storeEasyShop.easyChoice === 1,
                                when(
                                    isSomething(commission),
                                    <Col span={24}>
                                        <Title level={4}>{dictionary('commission_month')}</Title>
                                        <Divider/>
                                        <div className="dropdown-button scrollable">
                                            {commission.map((v, k) => {
                                                let btnClass = (v.value == checkOutStore.commissionMonth) ? " btn-primary" : " btn-outline-secondary"
                                                let btnSelected = (v.value == checkOutStore.commissionMonth) ? "step-indicator icon-circle-check" : ""
                                                return (<button key={k} className={"btn th-commission-dropdown" + btnClass} 
                                                    name="commission-dropdown"
                                                    value={v.value}
                                                    onClick={(name, value) => onCommissionMonthSelected(v.name, v.value)}>
                                                    <span className={btnSelected}></span>
                                                    <span className="">{isLanguageNative() ? v.native : v.name}</span>
                                                </button>)
                                            })}
                                        </div>
                                    </Col>
                                )
                            )}
                            {/* --- End <- Commission Months --- */}

                            {/* --- Start -> Shipping Methods --- */}
                            <Col span={24} className={storeEasyShop.easyChoice === 1 ? 'mt-6' : ''}>
                                <Title level={4}>{dictionary('shipping_method')}</Title>
                                <Divider/>
                                <Row id="delivery" type="flex" justify="center">
                                    <Col span={16} className="shipping-method">
                                        <RoundPills 
                                            defaultValue={checkOutStore.shipping}
                                            value={checkOutStore.shipping}
                                            onChange={(value) => onSelectShipping(value)}>
                                            {getShippingMethodOptions()}
                                        </RoundPills>
                                    </Col>
                                </Row>
                            </Col>
                            {/* --- End <- Shipping Methods --- */}
                        </Row>
                        
                        <Row {...rowResponsive} className="mt-5">
                            <Col {...colResponsive.wrapperAddress}>
                                <Switch>
                                    <Case condition={checkOutStore.shipping === ShippingMethods.ID.delivery}>
                                        <Layout>
                                            <Content>
                                                <Card>
                                                    <Layout>
                                                        <Row>
                                                            <Col>
                                                                <Form layout="vertical">
                                                                    <Row>
                                                                        <Col span={24}>
                                                                            <Form.Item {...formItemConfig} label={dictionary('full_name')}>
                                                                                <SuperInput 
                                                                                    defaultValue={getRefferalDeliveryAddress().firstNameEn}
                                                                                    onChange={(input) => setShippingAddress('firstNameEn', input.target.value)}
                                                                                    placeholder={dictionary(keyPlaceholderFullname)}
                                                                                    />
                                                                            </Form.Item>
                                                                            <Form.Item {...formItemConfig} label={dictionary(keyLabelAddressL1)}>
                                                                                <SuperInput 
                                                                                    defaultValue={getRefferalDeliveryAddress().address1}
                                                                                    onChange={(input) => setShippingAddress('address1', input.target.value)}
                                                                                    placeholder={dictionary(keyPlaceholderAddressL1)}
                                                                                    />
                                                                            </Form.Item>
                                                                            <Form.Item {...formItemConfig} label={dictionary(keyLabelAddressL2)}>
                                                                                <SuperInput 
                                                                                    defaultValue={getRefferalDeliveryAddress().address2}
                                                                                    onChange={(input) => setShippingAddress('address2', input.target.value)}
                                                                                    placeholder={dictionary(keyPlaceholderAddressL2)}
                                                                                    />
                                                                            </Form.Item>
                                                                            {when(
                                                                                isCountryThailand(),
                                                                                <Form.Item {...formItemConfig} label={dictionary('province')}>
                                                                                    <ProvincesPills
                                                                                        country={C.Country()}
                                                                                        value={localizeProvinceOutput(selected)}
                                                                                        onSelected={(value) => {
                                                                                            setSelected(localizeProvinceOutput(value))
                                                                                        }}/>
                                                                                </Form.Item>
                                                                            )}
                                                                            <Row type="flex" justify="space-between">
                                                                                <Col span={11}>
                                                                                    <Form.Item {...formItemConfig} label={dictionary('country')}>
                                                                                        <SuperInput 
                                                                                            disabled={true}
                                                                                            value={C.CountryFullDisplay(true)}
                                                                                            />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col span={11}>
                                                                                    <Form.Item {...formItemConfig} label={dictionary('zip')}>
                                                                                        <SuperInput 
                                                                                            defaultValue={getRefferalDeliveryAddress().zip}
                                                                                            onChange={(input) => setShippingAddress('zip', input.target.value)}
                                                                                            placeholder={dictionary(keyPlaceholderZip)}
                                                                                            />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row type="flex" justify="space-between">
                                                                                <Col span={11}>
                                                                                    <Form.Item {...formItemConfig} label={dictionary('mobilePhone')}>
                                                                                        <SuperInput 
                                                                                            defaultValue={getRefferalDeliveryAddress().mobilePhone}
                                                                                            onChange={(input) => setShippingAddress('mobilePhone', input.target.value)}
                                                                                            placeholder={dictionary(keyPlaceholderMobile)}
                                                                                            />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col span={11}>
                                                                                    <Form.Item {...formItemConfig} label={dictionary('email')}>
                                                                                        <SuperInput 
                                                                                            defaultValue={getRefferalDeliveryAddress().email}
                                                                                            onChange={(input) => setShippingAddress('email', input.target.value)}
                                                                                            placeholder={dictionary(keyPlaceholderEmail)}
                                                                                            />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                            </Row>

                                                                            {when(
                                                                                storeEasyShop.easyChoice === 2 && isCountryAustralia() === false,
                                                                                <Form.Item {...formItemConfig} label={dictionary('easy_referral_id')}>
                                                                                    <SuperInput 
                                                                                        defaultValue={storeEasyShop.referralValue === '2' ? '' : storeEasyShop.referralValue}
                                                                                        onKeyDown={(e) => someOf(e.key, ['e', '+', '-']) && e.preventDefault()}
                                                                                        onChange={(input) => setShippingAddress('referral_id', input.target.value)}
                                                                                        value={storeEasyShop.referralValue}
                                                                                        placeholder={dictionary('placeholder_easy_referral_id')}
                                                                                        type={isDesktop() ? 'number' : 'tel'}
                                                                                        />
                                                                                </Form.Item>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            </Col>
                                                        </Row>
                                                        <SubscribeCheckBox className="mt-3"/>
                                                    </Layout>
                                                    <SuperAlert list={errorMessage} className="mt-5"/>
                                                </Card>
                                            </Content>
                                            <FormFooter/>
                                        </Layout>
                                    </Case>

                                    <Case condition={checkOutStore.shipping === ShippingMethods.ID.pickUp}>
                                        <Layout>
                                            <Content>
                                                <Card>
                                                    <Layout>
                                                        <Row>
                                                            <Col>
                                                                <Form layout="vertical">
                                                                    <Row>
                                                                        <Col span={24}>
                                                                            <Form.Item {...formItemConfig} label={dictionary('full_name')}>
                                                                                <SuperInput 
                                                                                    defaultValue={getRefferalPickUpAddress().firstNameEn}
                                                                                    onChange={(input) => setShippingAddress('firstNameEn', input.target.value)}
                                                                                    placeholder={dictionary(keyPlaceholderFullname)}
                                                                                    />
                                                                            </Form.Item>
                                                                            <Row type="flex" justify="space-between">
                                                                                <Col span={11}>
                                                                                    <Form.Item {...formItemConfig} label={dictionary('mobilePhone')}>
                                                                                        <SuperInput 
                                                                                            defaultValue={getRefferalPickUpAddress().mobilePhone}
                                                                                            onChange={(input) => setShippingAddress('mobilePhone', input.target.value)}
                                                                                            placeholder={dictionary(keyPlaceholderMobile)}
                                                                                            />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col span={11}>
                                                                                    <Form.Item {...formItemConfig} label={dictionary('email')}>
                                                                                        <SuperInput 
                                                                                            defaultValue={getRefferalPickUpAddress().email}
                                                                                            onChange={(input) => setShippingAddress('email', input.target.value)}
                                                                                            placeholder={dictionary(keyPlaceholderEmail)}
                                                                                            />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                            </Row>

                                                                            {when(
                                                                                storeEasyShop.easyChoice === 2 && isCountryAustralia() === false,
                                                                                <Form.Item {...formItemConfig} label={dictionary('easy_referral_id')}>
                                                                                    <SuperInput 
                                                                                        defaultValue={storeEasyShop.referralValue === '2' ? '' : storeEasyShop.referralValue}
                                                                                        onKeyDown={(e) => someOf(e.key, ['e', '+', '-']) && e.preventDefault()}
                                                                                        onChange={(input) => setShippingAddress('referral_id', input.target.value)}
                                                                                        value={storeEasyShop.referralValue}
                                                                                        placeholder={dictionary('placeholder_easy_referral_id')}
                                                                                        type={isDesktop() ? 'number' : 'tel'}
                                                                                        />
                                                                                </Form.Item>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            </Col>
                                                        </Row>
                                                        <SubscribeCheckBox className="mt-3"/>
                                                    </Layout>
                                                    <SuperAlert list={errorMessage} className="mt-5"/>
                                                </Card>
                                            </Content>
                                            <FormFooter/>
                                        </Layout>
                                    </Case>

                                    <Case condition={checkOutStore.shipping === ShippingMethods.ID.aramex}>
                                        <Layout>
                                            <Content>
                                                <Card>
                                                    <Layout>
                                                        <Row>
                                                            <Col>
                                                                <Form layout="vertical">
                                                                    <Row>
                                                                        <Col span={24}>
                                                                            <Form.Item {...formItemConfig} label={dictionary('full_name')}>
                                                                                <SuperInput 
                                                                                    defaultValue={getReferralAramexAddress().firstNameEn}
                                                                                    onChange={(input) => setShippingAddress('firstNameEn', input.target.value)}
                                                                                    placeholder={dictionary(keyPlaceholderFullname)}
                                                                                    />
                                                                            </Form.Item>
                                                                            <Form.Item {...formItemConfig} label={dictionary(keyLabelAddressL1)}>
                                                                                <SuperInput 
                                                                                    disabled={true} 
                                                                                    value={getReferralAramexAddress().address1}
                                                                                    />
                                                                            </Form.Item>
                                                                            <Form.Item {...formItemConfig} label={dictionary(keyLabelAddressL2)}>
                                                                                <SuperInput 
                                                                                    disabled={true}
                                                                                    value={getReferralAramexAddress().address2}
                                                                                    />
                                                                            </Form.Item>

                                                                            <Row type="flex" justify="space-between">
                                                                                <Col span={11}>
                                                                                    <Form.Item {...formItemConfig} label={dictionary('country')}>
                                                                                        <SuperInput 
                                                                                            disabled={true}
                                                                                            value={C.CountryFullDisplay(true)}
                                                                                            />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col span={11}>
                                                                                    <Form.Item {...formItemConfig} label={dictionary('zip')}>
                                                                                        <SuperInput 
                                                                                            disabled={true}
                                                                                            value={getReferralAramexAddress().zip}
                                                                                            />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row type="flex" justify="space-between">
                                                                                <Col span={11}>
                                                                                    <Form.Item {...formItemConfig} label={dictionary('mobilePhone')}>
                                                                                        <SuperInput 
                                                                                            disabled={true}
                                                                                            value={getReferralAramexAddress().mobilePhone}
                                                                                            />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col span={11}>
                                                                                    <Form.Item {...formItemConfig} label={dictionary('email')}>
                                                                                        <SuperInput 
                                                                                            defaultValue={getReferralAramexAddress().email}
                                                                                            onChange={(input) => setShippingAddress('email', input.target.value)}
                                                                                            placeholder={dictionary(keyPlaceholderEmail)}
                                                                                            />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                            </Row>

                                                                            <Row type="flex" justify="space-between">
                                                                                <Col span={11}>
                                                                                    <Form.Item {...formItemConfig} label={dictionary('sns_city_label')}>
                                                                                        <SuperInput 
                                                                                            defaultValue={getReferralAramexAddress().sns1}
                                                                                            onChange={(input) => setShippingAddress('sns1', input.target.value)}
                                                                                            placeholder={dictionary('sns_city_sg')}
                                                                                            />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col span={11}>
                                                                                    <Form.Item {...formItemConfig} label={dictionary('sns_mailbox_label')}>
                                                                                        <SuperInput 
                                                                                            defaultValue={getReferralAramexAddress().sns2}
                                                                                            onChange={(input) => setShippingAddress('sns2', input.target.value)}
                                                                                            placeholder={dictionary('sns_mailbox_sg')}
                                                                                            />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            </Col>
                                                        </Row>
                                                        <SubscribeCheckBox className="mt-3"/>
                                                    </Layout>
                                                    <SuperAlert list={errorMessage} className="mt-5"/>
                                                </Card>
                                            </Content>
                                            <FormFooter/>
                                        </Layout>
                                    </Case>
                                </Switch>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout>
    </>)
}

export default EasyForm