import './Precompiled';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
// import { supportedMarkets, VERSION_DEV } from './components/config';
// import {  getCountryCode } from './components/GlobalHelpers';
import { checkCountry } from './CheckCountry';
import styled from 'styled-components';
import StoreCountry from '@Stores/StoreCountry';
import App from 'App';
import { hasExtension } from '@GlobalHelpers';

sessionStorage.removeItem('menu')
sessionStorage.removeItem('menuGroupA-desktop')
sessionStorage.removeItem('menuGroupA-mobile')
sessionStorage.removeItem('menuGroupB-desktop')
sessionStorage.removeItem('menuGroupB-mobile')

const WebSkeleton = styled.div`
    width: ${window.innerWidth}px;
    height: ${window.innerHeight}px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Root = (props) => {

    const [webReady, setWebReady] = useState(false)
    const [showCountryPortal, setShowCountryPortal] = useState(null)

    function checkEnvironmentHost() {
        const isDev = process.env.NODE_ENV === 'development'
        console.log(`%cEnvironment: ${process.env.NODE_ENV}`, `font-weight: bold; color: ${isDev ? 'red' : 'green'};`)
    }
 
    function clearStorage() {
        try {
            // -- Versioning for Developers --
            const clearStorageThenSetVersion = () => {
                localStorage.clear()
                sessionStorage.clear()
                // localStorage.setItem('version-dev', VERSION_DEV)
                window.location.reload()
                throw 'reload'
            }

            // const preVersionDev = localStorage.getItem('version-dev')
            // if (preVersionDev) {
            //     if (preVersionDev !== VERSION_DEV) {
            //         clearStorageThenSetVersion()
            //     }
            // } else {
            //     clearStorageThenSetVersion()
            // }
            // clearStorageThenSetVersion()
            // const versionStr = ''.concat('[Version] ', VERSION_DEV)
            // console.log('%c' + versionStr, 'font-weight: bold; color: blue;')
        } catch (error) {
            if (error === 'string') {
                throw '- Found new development version, reload is required.'
            }
        }
    }

    function setWebTitle() {
        let uriPath = window.location.pathname.split('/');
        // supportedMarkets.map((b, i) => {
        //     if (uriPath[1] !== 'undefined' && uriPath[1] === b.name) {
        //         const host = window.location.hostname;
        //         const split_name = host.split('-');
        //         const country = getCountryCode();
        //         if (split_name[1] !== undefined) {
        //             const dev_name = split_name[1].substr(0, 3);
        //             if (dev_name === 'dev') {
        //                 const dev_number = split_name[1].substr(3, 1);
        //                 document.title = `UNICITY (${country + dev_number})`;
        //             } else {
        //                 document.title = b.title
        //             }
        //         } else {
        //             document.title = b.title
        //         }
        //     }
        // })
    }

    useEffect(() => {
        checkEnvironmentHost()
        clearStorage()
        setWebTitle()
    
        if (/\?autoLogin=/.test(window.location.search)) {
            setWebReady(true)
            setShowCountryPortal(false)
        } else {
            const responseCountry = checkCountry()
            responseCountry.then(response => {

                if (response.success === true) {
                    if (response.needReload === true) {
                        if (response.path === '') {
                            setShowCountryPortal(true)
                            setWebReady(true)
                        } else {
                            window.location.href = `${window.location.origin}/${response.path.replace('/', '')}${window.location.search}`
                        }
                    } else {

                        if (!response.country.hasOwnProperty('country')){
                            localStorage.setItem('country',response.country)
                            StoreCountry.country.country = response.country
                            setWebReady(true)
                        } else {
                            StoreCountry.country = response.country
                            localStorage.setItem('country', response.country.country.replace(/\s/g,'').toLowerCase())
                            setWebReady(true)
                            // ReactDOM.render(<Root />, document.getElementById('root'))
                        }
                        
                    }
                } else {
                    window.location.href = `${window.location.origin}`
                }
            })
        }

    }, [])

    return webReady ? <App {...props} showCountryPortal={showCountryPortal} /> : <WebSkeleton></WebSkeleton>
    // return <WebSkeleton></WebSkeleton>
}

ReactDOM.render(<Root />, document.getElementById('root'))