import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import { aramexValidator, checkOutPeriod, APIReferralCode } from '@Services/Network';
import { uniqueEmail, referralAddressValidator, updateAddress, getUserDetail2 } from '@Services/Network';
import { checkOutStore, shopStore, store } from '../../../stores/MainStore';
import { resetReferralStore, getReferralFormData } from '@Components/EnrollHelper';
import { getShippingKey, getShoppingFormData, getShoppingPickUpAddress, getShoppingAramexAddress, getShoppingDeliveryAddress, getHydraNotes, clearEasyShop } from '@Components/ShoppingHelper'
import OrderDetails from '../OrderDetails/OrderDetails';
import OrderDetailsPH from '../OrderDetails/OrderDetailsPH';
import { storeOrderDetails } from '../OrderDetails/Store/StoreOrderDetails';
import { dictionary, getStoreLanguageName, getLanguageName, isCountryJapan, isCountrySingapore, isCountryHongkong, isCountryPhilippines } from "@GlobalHelpers";
import { isNothing, isSomething, justOneOf, toBool } from '@Utils/Utils';
import { isCustomerLogin, clearCartStorage, getCustomerObject } from '../../utils/Customer';
import { If, Then, Else } from 'react-if';
import { storeEasyShop } from '../../../stores/StoreEasyShop';
import { CountryConfigs as C } from '../../configs/Configs';
import { Layout, Row, Col } from 'antd';
import PageTitle from '../../fragment/PageTitle';
import { CountryCode } from '../../configs/Country';
import Raven from '@Services/Raven';
import { ShippingMethods } from '../../configs/ShippingMethods';
import get from 'lodash/get';
import extend from 'lodash/extend';
import set from 'lodash/set';
import unset from 'lodash/unset';
import * as K from '../../configs/Keywords';
import { toJS, reaction } from 'mobx';
import $ from "jquery";
import { mDeliveryValue, mAddressHistory } from '../OrderDetails/Store/StoreFormConfigs';
import { mPickUpValue } from '../OrderDetails/Store/StoreFormConfigs';
import { ACL } from '@Config';

// Avaiable Country: TH, SG
// In-progress Country: NZ

// Destructuring for antd components 
const { Header, Content } = Layout

// * Core Component
const OrderDetailProcess = props => {

    const history = useHistory()

    const [commissionMonth, setCommissionMonth] = useState([])
    const [commission, setCommission] = useState()
    const [checkValidateAddress, setCheckValidateAddress] = useState(false)

    const clearErrors = () => {
        storeOrderDetails.errorMessage = []
        storeOrderDetails.isLoadingAPI = false
    }

    const setErrors = (messages = []) => {
        storeOrderDetails.errorMessage = messages
        storeOrderDetails.isLoadingAPI = false
    }

    const passedValidate = () => {
        // makeup lastest address history data then add it to api
        const addressHistoryData = {
            shipToAddress: {
                address1: mDeliveryValue.address1,
                address2: mDeliveryValue.address2,
                // city: mDeliveryValue.city,
                // state: mDeliveryValue.state,
                zip: mDeliveryValue.zip,
                country: C.CountryCode()
            },
            shipToName: {
                fullName: mDeliveryValue.fullName,
                firstName: mDeliveryValue.fullName,
                lastName: '',
            }
        }

        console.log('sending address:', addressHistoryData)
        if (mAddressHistory.selected === 'new') {
            Raven.addAddressHistory(addressHistoryData)
                .then(response => {
                    console.log('add address history complete', response)
                })
                .catch(error => {
                    console.log('add address history fail', error)
                })
        }

        // don't wait address history
        shopStore.passCheckOut = true
        //history.push(`/${C.Country()}/checkout/summary`)


        if (checkOutStore.shipping == 3 || checkOutStore.shipping == 32) {
            props.proceedCheckoutFinal(mPickUpValue.referral_id, storeEasyShop.easyChoice)
        } else if (checkOutStore.shipping == 2) {
            props.proceedCheckoutFinal(mDeliveryValue.referral_id, storeEasyShop.easyChoice)
        }

        // history.push(`/${C.Country()}/shopprofile/ordersummary/${store.shareCart.sharedCardID}`);
    }

    useEffect(() => {

        storeOrderDetails.isLoadingAPI = false

        if (isNothing(checkOutStore.shipping)) {
            checkOutStore.shipping = '2'
        }

        if (isNothing(checkOutStore.warehouse)) {
            checkOutStore.warehouse = get(ShippingMethods.getWarehouseAcceptance(), `${C.Country()}[0]`, '')
        }

        const getPeriodCountryCode = () => {
            switch (C.CountryCode()) {
                case CountryCode.australia: return 'SG'
                case CountryCode.newzealand: return 'SG'
                default: return C.CountryCode()
            }
        }


        if (store.shareCart.sharedStatus !== 'A') {
            checkOutPeriod((response, success) => {
                if (success === true) {
                    if (response.success.toLowerCase() !== 'no') {
                        checkOutStore.commissionMonth = response.entryPeriod
                        if (isCustomerLogin() === false) {
                            setCommissionMonth(response.message)
                        } else {
                            const customerData = getCustomerObject()
                            const cusJoinDate = customerData.joinDate.split('-')
                            const checkEnrollTopup = `${cusJoinDate[0]}-${cusJoinDate[1]}`
                            if (checkEnrollTopup === response.message[0].period && response.topup === 'Yes') {
                                setCommissionMonth([response.message[0]])
                            } else {
                                setCommissionMonth(response.message)
                            }
                        }
                    }
                }
            }, { country: getPeriodCountryCode(), system: 'AO' })
        }

        if (isCustomerLogin()) {
            onSelectShippingChange(checkOutStore.shipping)
        }

        const disposerErrorMessage = reaction(
            () => storeOrderDetails.errorMessage,
            (emsgs) => {
                if (isSomething(emsgs)) {
                    if (emsgs.length > 0) {
                        storeOrderDetails.hasError = true
                        shopStore.passCheckOut = false
                    } else {
                        storeOrderDetails.hasError = false
                    }
                } else {
                    storeOrderDetails.hasError = false
                }
            }
        )

        return () => {

            disposerErrorMessage()

            let path = window.location.pathname.split('/');
            if (path[2] !== 'cart' && path[2] !== 'checkout') {
                shopStore.passCheckOut = false
                storeEasyShop.isEnabled = false
                clearEasyShop()
            }
        }
    }, [])

    const processWarehouse = () => {
        if (ShippingMethods.getWarehouseEnabled()) {
            console.log('Warehouse =', checkOutStore.warehouse)
            if (isSomething(checkOutStore.warehouse)) {
                const overridedShippingID = get(
                    ShippingMethods.WarehouseDeclaration,
                    `${checkOutStore.warehouse}.shippingValue.${ShippingMethods.getShippingName(checkOutStore.shipping)}`,
                    checkOutStore.shipping
                )

                checkOutStore.shipping = overridedShippingID
                console.log('overridedShippingID', overridedShippingID)
            }
        }
    }

    const onSelectShippingChange = () => {
        processWarehouse()
        clearErrors()
    }

    const onInputChanged = (key, value) => {
        const shippingKey = ShippingMethods.getShippingName(checkOutStore.shipping)
        console.log('change', shippingKey, key, value)
        if (isNothing(checkOutStore.billingAddress[shippingKey])) {
            checkOutStore.billingAddress[shippingKey] = {}
        }

        checkOutStore.billingAddress[shippingKey][key] = value
        checkOutStore[key] = value

        if (document.getElementById('checkout-' + key) !== null) {
            document.getElementById('checkout-' + key).value = value
        }
    }

    const onValidatedDeliverySuccess = () => {
        checkOutStore.checkout = true
        passedValidate()
    }

    const checkReferralCode = (referral_id) => {

        storeOrderDetails.errorMessage = []

        getUserDetail2((result) => {

            if (result.statusText === "error") {
                setErrors([dictionary('u_all_customlink_orderdetail_ba_id_not_found')])
            } else {
                if (result.items[0]) {
                    let humanName = result.items[0].humanName

                    let type = result.items[0].type
                    let status = result.items[0].status

                    store.shareCart.distributorDetails.humanName = humanName
                    store.shareCart.distributorId = result.items[0].unicity

                    if (type === 'Associate') {
                        if (status === 'Active' || status === 'Pending') {
                            passedValidate()

                        } else {
                            setErrors([dictionary('only_active_distributor')])
                        }
                    } else {
                        setErrors([dictionary('u_all_customlink_orderdetail_ba_id_not_found')])
                    }
                } else {
                    setErrors([dictionary('u_all_customlink_orderdetail_ba_id_not_found')])
                }
            }


        }, referral_id);



    }


    const onConfirmedShipping = () => {
        storeOrderDetails.isLoadingAPI = true
        checkOutStore.checkout = true

        let shippingKey = ShippingMethods.getShippingName(checkOutStore.shipping)

        if (isCountryPhilippines()) {
            if (checkOutStore.shipping == 32) {
                shippingKey = ShippingMethods.IDToName[3]
            }
        }
        const billingAddress = checkOutStore.billingAddress[shippingKey]

        // console.log('>> Shipping', checkOutStore.shipping, '>>', shippingKey)
        // console.log('>> CHECK', toJS(checkOutStore.billingAddress[shippingKey]))
        if (isCountryHongkong()) {
            if (checkOutStore.shipping == 3) {
                //checkReferralCode(mPickUpValue.referral_id)
                billingAddress.city = "none"
                billingAddress.address1 = "none"
            }
            if (!billingAddress.address2) {
                billingAddress.address2 = ""
            }

        }

        if (isCountryPhilippines()) {

            if (checkOutStore.shipping == 3 || checkOutStore.shipping == 32) {
                billingAddress.comment = "TAGUIG Office"
                billingAddress.address1 = "Ground Floor 8/10 Upper McKinley Building"
                billingAddress.address2 = ""
                billingAddress.city = "Taguig"
                billingAddress.zip = 1201


            }
        }
        let data = {
            language: getLanguageName(store.languageCurrent),
            source: getHydraNotes(''),
            period: checkOutStore.commissionMonth,
            lastName: '',
            lastNameEn: '',
            firstName: billingAddress.firstNameEn,
            firstNameEn: billingAddress.firstNameEn,
            address1: billingAddress.address1,
            address2: billingAddress.address2,
            zip: billingAddress.zip,
            email: billingAddress.email,
            mobilePhone: billingAddress.mobilePhone,
            city: billingAddress.city,
            state: billingAddress.state,
            country: billingAddress.country || C.CountryCode(),
            comment: billingAddress.comment || ''
        }

        if (isCountryJapan()) {
            set(data, 'firstName@ja', billingAddress.firstNameEn)
            set(data, 'lastName@ja', billingAddress.lastNameEn)
            set(data, 'city', '')
            set(data, 'address2', `${billingAddress.address2}${billingAddress.city ? billingAddress.city + '' : ''}${billingAddress.state ? billingAddress.state : ''}`)

            unset(data, 'firstName')
            unset(data, 'lastName')
            unset(data, 'firstNameEn')
            unset(data, 'lastNameEn')
            unset(data, 'state')
            unset(data, 'comment')
        }

        if (isCountrySingapore()) {
            setCheckValidateAddress(false)
            let data_sg = {
                address1: mDeliveryValue.address
            }


            if (mDeliveryValue.address && checkOutStore.shipping == 2) {
                billingAddress.address1 = mDeliveryValue.address
                data = {
                    language: getLanguageName(store.languageCurrent),
                    source: getHydraNotes(''),
                    period: checkOutStore.commissionMonth,
                    lastName: '',
                    lastNameEn: '',
                    firstName: billingAddress.firstNameEn,
                    firstNameEn: billingAddress.firstNameEn,
                    address1: mDeliveryValue.address,
                    address2: '',
                    zip: billingAddress.zip,
                    email: billingAddress.email,
                    mobilePhone: billingAddress.mobilePhone,
                    city: billingAddress.city,
                    state: billingAddress.state,
                    country: billingAddress.country || C.CountryCode(),
                    comment: billingAddress.comment || ''
                }

                // validate address
                if (isNothing(checkOutStore.referralValue)) {
                    Raven.validateAddress(data)
                        .then(response => {
                            if (toBool(response.success)) {
                                if (store.shareCart.sharedStatus === 'A') {

                                    checkReferralCode(store.shareCart.distributorId)
                                } else {


                                    passedValidate()
                                }
                            } else {
                                setErrors(response.Message)
                            }
                        })
                        .catch(() => {
                            storeOrderDetails.isLoadingAPI = false
                        })

                } else {
                    // validate for referral, delivery, pickup
                    let dataRefferal = getReferralFormData()
                    clearErrors()
                    uniqueEmail((resUniqeEmail, succUniqeEmail) => {
                        if (succUniqeEmail === false && resUniqeEmail.status == 404) {
                            validateReferralAddress(dataRefferal, '')
                        } else {
                            validateReferralAddress(dataRefferal, 'Email already exists')
                        }
                    }, dataRefferal.email)

                }


            } else {

                data = {
                    language: getLanguageName(store.languageCurrent),
                    source: getHydraNotes(''),
                    period: checkOutStore.commissionMonth,
                    lastName: '',
                    lastNameEn: '',
                    firstName: billingAddress.firstNameEn,
                    firstNameEn: billingAddress.firstNameEn,
                    address1: billingAddress.address1,
                    address2: "",
                    zip: billingAddress.zip,
                    email: billingAddress.email,
                    mobilePhone: billingAddress.mobilePhone,
                    city: billingAddress.city,
                    state: billingAddress.state,
                    country: billingAddress.country || C.CountryCode(),
                    comment: billingAddress.comment || ''
                }

                // validate address
                if (isNothing(checkOutStore.referralValue)) {
                    Raven.validateAddress(data)
                        .then(response => {
                            if (toBool(response.success)) {
                                if (store.shareCart.sharedStatus === 'A') {

                                    checkReferralCode(store.shareCart.distributorId)
                                } else {

                                    passedValidate()
                                }
                            } else {

                                setErrors(response.Message)
                            }
                        })
                        .catch(() => {
                            storeOrderDetails.isLoadingAPI = false
                        })

                } else {
                    // validate for referral, delivery, pickup
                    let dataRefferal = getReferralFormData()
                    clearErrors()
                    uniqueEmail((resUniqeEmail, succUniqeEmail) => {
                        if (succUniqeEmail === false && resUniqeEmail.status == 404) {
                            validateReferralAddress(dataRefferal, '')
                        } else {
                            validateReferralAddress(dataRefferal, 'Email already exists')
                        }
                    }, dataRefferal.email)

                }
            }

            //https://member-calls2.unicity.com/fn-split-address
        }


        if (!isCountrySingapore()) {
            // * Aramex -> Attached the SNS
            if (checkOutStore.shipping === ShippingMethods.ID.aramex) {
                set(data, 'sns', `${billingAddress.sns1}${billingAddress.sns2}`)
                Raven.validateAramex(data)
                    .then(response => {
                        if (toBool(response.success)) {
                            //storeOrderDetails.isLoadingAPI = false
                            passedValidate()
                        } else {
                            setErrors(response.Message)
                        }
                    })
                    .catch(() => {
                        setErrors(['Error, the validation is failed.'])
                    })
            } else {
                // validate address
                console.log('data submit', data)
                if (isNothing(checkOutStore.referralValue)) {
                    Raven.validateAddress(data)
                        .then(response => {
                            if (toBool(response.success)) {
                                //storeOrderDetails.isLoadingAPI = false
                                //passedValidate()

                                //checkReferralCode(store.shareCart.distributorId)

                                passedValidate()

                            } else {


                                setErrors(response.Message)
                            }
                        })
                        .catch(() => {
                            storeOrderDetails.isLoadingAPI = false
                        })

                } else {
                    // validate for referral, delivery, pickup
                    let dataRefferal = getReferralFormData()
                    clearErrors()
                    uniqueEmail((resUniqeEmail, succUniqeEmail) => {
                        if (succUniqeEmail === false && resUniqeEmail.status == 404) {
                            validateReferralAddress(dataRefferal, '')
                        } else {
                            validateReferralAddress(dataRefferal, 'Email already exists')
                        }
                    }, dataRefferal.email)

                }
            }
        }



    }

    const validateReferralAddress = (dataRefferal, errorEmailMessage) => {
        referralAddressValidator((response, success) => {
            storeOrderDetails.isLoadingAPI = false
            if (success === true) {
                if (toBool(response.success)) {
                    if (errorEmailMessage !== '') {
                        setErrors([errorEmailMessage])
                    } else {
                        // console.log("validate referral ", checkOutStore.shipping)
                        passedValidate()
                    }
                } else {
                    if (errorEmailMessage !== null) {

                        let combineErrors = response.Message
                        combineErrors.push(errorEmailMessage)
                        setErrors(combineErrors)
                    } else {
                        setErrors(response.Message)
                    }
                }
            }
        }, dataRefferal)
    }

    const setContinueButton = (e) => {
        /* setState({
            continueButtonDisable: e
        }) */
    }

    const setReferenceDetail = (reference, referralValue) => {
        storeEasyShop.referenceDetail = reference
        storeEasyShop.referralValue = referralValue
        setContinueButton(false)
    }

    const selectLogin = (e) => {
        /* setState({
            selectLogin: e,
        }) */
    }

    const backToHomePage = () => {
        shopStore.productDataSource.map((v, k) => {
            v.qty = 0
            return v
        })
        clearCartStorage()
        history.push(`./`)
    }

    const setSentEmail = (e) => {
        /* setState({
            emailSent: e
        }) */
        window.scrollTo(0, 0)
    }

    const changeReferral = () => {
        // setState({hasError: false});
        resetReferralStore()
        window.scrollTo(0, 0)
    }

    const setCheckoutErrors = (messages) => {
        shopStore.passCheckOut = false
        storeOrderDetails.errorMessage = messages
        storeOrderDetails.isLoadingAPI = false
        setContinueButton(false)
    }



    /* const updateAddressAndContinue = () => {
        clearErrors()
        checkOutStore.billingAddress.delivery.fullName = getShoppingDeliveryAddress().firstNameEn
        let customerToken = localStorage.getItem('customerToken')
        let customerHref = localStorage.getItem('customerHref')
        let dataHydraUpdate = {
            "shipToName": {
                "firstName": getShoppingDeliveryAddress().firstNameEn,
                "lastName": getShoppingDeliveryAddress().lastNameEn,
                "fullName": getShoppingDeliveryAddress().fullName
            },
            "shipToAddress": {
                "address1": getShoppingDeliveryAddress().address1,
                "address2": getShoppingDeliveryAddress().address2,
                "city": getShoppingDeliveryAddress().city,
                "state": getShoppingDeliveryAddress().state,
                "zip": getShoppingDeliveryAddress().zip,
                "country": getShoppingDeliveryAddress().country
            }
        }

        let dataValidate = {
            language: getStoreLanguageName(),
            source: getHydraNotes(''),
            firstName: getShoppingDeliveryAddress().firstNameEn,
            lastName: getShoppingDeliveryAddress().lastNameEn,
            address1: getShoppingDeliveryAddress().address1,
            address2: getShoppingDeliveryAddress().address2,
            city: getShoppingDeliveryAddress().city,
            state: getShoppingDeliveryAddress().state,
            zip: getShoppingDeliveryAddress().zip,
            country:getShoppingDeliveryAddress().country,
            email: getShoppingDeliveryAddress().email,
            mobilePhone: getShoppingDeliveryAddress().mobilePhone,
            comment: getShoppingDeliveryAddress().comment,
            period: checkOutStore.commissionMonth
        }
        checkOutStore.addressBook.phone = getShoppingDeliveryAddress().mobilePhone
        checkOutStore.addressBook.email = getShoppingDeliveryAddress().email
        validateAddress((r, s) => {
            const response = JSON.parse(r)
            if (response.success === 'yes') {

                if (checkOutStore.billingAddress.delivery.isMainAddress === 'false') {
                    updateAddress((res, status) => {
                        if (status) {
                            checkOutStore.addressBook.firstLoaded = false
                            onValidatedDeliverySuccess()

                        } else {
                            return false
                        }
                    }
                    , checkOutStore.addressBook.href
                    , customerToken
                    , checkOutStore.billingAddress.delivery.tempHref
                    , dataHydraUpdate)

                } else {
                    onValidatedDeliverySuccess()
                }
            } else {
                setCheckoutErrors(response.Message)
            }
        }, dataValidate)
    } */

    const rowConfig = {
        type: 'flex',
        justify: 'center'
    }

    const colConfig = {
        OrderDetails: {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 24,
            xl: 24,
            xxl: 24
        },
        EasyShop: {
            xs: 22,
            sm: 22,
            md: 22,
            lg: 20,
            xl: 18,
            xxl: 18
        }
    }


    return useObserver(() =>
        <Layout className="offcanvas-wrapper">
            {/*  <Header>
                <PageTitle>
                    <If condition={isCustomerLogin()}>
                        <Then>
                            {dictionary('order_details')}
                        </Then>
                        <Else>
                            <If condition={storeEasyShop.isEasyShopForm}>
                                <Then>{dictionary('order_details')}</Then>
                                <Else>{dictionary('login')}</Else>
                            </If>
                        </Else>
                    </If>
                </PageTitle>
            </Header> */}
            <Content>
                <Row {...rowConfig}>
                    {/* Regular Checkout Form */}
                    <Col {...colConfig.OrderDetails}>
                        <If condition={isCountryPhilippines()}>
                            <Then>
                                <OrderDetailsPH
                                    {...props}
                                    commissionMonth={commissionMonth}
                                    commission={commission}
                                    onConfirmedShipping={() => onConfirmedShipping()}
                                    processWarehouse={() => processWarehouse()}

                                />
                            </Then>
                            <Else>
                                <OrderDetails
                                    {...props}
                                    commissionMonth={commissionMonth}
                                    commission={commission}
                                    onConfirmedShipping={() => onConfirmedShipping()}
                                    processWarehouse={() => processWarehouse()}

                                />
                            </Else>
                        </If>


                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

OrderDetailProcess.displayName = 'OrderDetailProcess'
export default OrderDetailProcess