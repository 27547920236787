import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { expandCustomer, APIReferralCode,getUserDetail2 } from '@Services/Network'
import { store } from '../../../stores/MainStore'
import { storeEasyShop } from '../../../stores/StoreEasyShop'
import EasyForm from './EasyForm'
import { clearEasyShop } from '@Components/ShoppingHelper'
import { dictionary, isCountryAustralia, isProduction } from '@GlobalHelpers'
import { isNothing, defaults } from '@Utils/Utils';
import { SpinnerInline } from '@Fragment/Spinner';
import { shopStore } from '../../../stores/MainStore';
import { When, If, Then, Else, Switch, Default, Case } from 'react-if';
import { logi } from '../../utils/PikaLog'
import { Layout, Row, Col } from 'antd';
import OrderDetailProcess from '../checkout/OrderDetailProcess';
import PageTitle from '../../fragment/PageTitle';
const { Header, Content } = Layout
const EasyChoice = observer(props => {
    const [haveBaId, setHaveBaId] = useState(false)
    const [haveReferralCode, setHaveReferralCode] = useState(false)
    const [errorInvalidID, setErrorInvalidID] = useState(false)
    const [errorInvalidReferralCode, setErrorInvalidReferralCode] = useState('')
    const [showLoader, setShowLoader] = useState(false)
    const [orderDetailForm, setOrderDetailForm] = useState(false)
    const [notActive, setNotActive] = useState(false)
    const [radioChoice, setRadioChoice] = useState(2)
    // form input
    const [id, setId] = useState('')
    const [referralCode, setReferralCode] = useState('')

    // componentDidMount()
    useEffect(() => {
        if (isCountryAustralia()) {
            storeEasyShop.easyChoice = 1
        }

        if (storeEasyShop.easyChoice === 1) {
            setRadioChoice(1)
            setHaveBaId(true)
            setHaveReferralCode(false)
        } else if (storeEasyShop.easyChoice === 2) {
            setRadioChoice(2)
            setHaveBaId(false)
            setHaveReferralCode(false)
            setId('')
        } else if (storeEasyShop.easyChoice === 3) {
            setRadioChoice(3)
            setHaveBaId(false)
            setHaveReferralCode(true)
            setId('')
        } else {
            setRadioChoice(1)
            setHaveBaId(true)
            setHaveReferralCode(false)
        }

        setErrorInvalidID(false)

        if (storeEasyShop.isEnabled) {
            setOrderDetailForm(true)
        }

        // componentWillUnmount()
        return () => {
            if (!shopStore.passCheckOut) {
                clearEasyShop()
            }
            if (store.openCheckoutForm === true) {
                store.openCheckoutForm = false
            }
        }
    }, [])

    useEffect(() => {
        store.openCheckoutForm = orderDetailForm 
    }, [orderDetailForm])

    useEffect(() => {
        storeEasyShop.easyChoice = radioChoice
    }, [radioChoice])

    const handlerChoiceYes = () => {
        setHaveBaId(true)
        setHaveReferralCode(false)

        setErrorInvalidID(false)
        setErrorInvalidReferralCode('')
        setRadioChoice(1)
    }

    const handlerChoiceNo = () => {
        setHaveBaId(false)
        setHaveReferralCode(false)

        setErrorInvalidID(false)
        setErrorInvalidReferralCode('')
        setRadioChoice(2)
        setId('')
    }

    const handlerChoiceReferralCode = () => {
        setHaveBaId(false)
        setHaveReferralCode(true)

        setErrorInvalidID(false)
        setErrorInvalidReferralCode('')
        setRadioChoice(3)
        setId('')
    }

    const _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onClickConfirm()
        }
    }
    
    const backToLogin = () => {
        window.scrollTo(0, 0)
        if (storeEasyShop.easyChoice === 1) {
            setRadioChoice(1)
            setHaveBaId(true)
            setHaveReferralCode(false)
            setOrderDetailForm(false)
            setShowLoader(false)
            props.store.shareCart.OrderDetailForm = false
            store.shareCart.OrderDetailForm = false
        } else if (storeEasyShop.easyChoice === 2) {
            setRadioChoice(2)
            setHaveBaId(false)
            setHaveReferralCode(false)
            setOrderDetailForm(false)
            setShowLoader(false)
            setId('')
            props.store.shareCart.OrderDetailForm = false
            store.shareCart.OrderDetailForm = false
        } else if (storeEasyShop.easyChoice === 3) {
            setRadioChoice(3)
            setHaveBaId(false)
            setHaveReferralCode(true)
            setOrderDetailForm(false)
            setShowLoader(false)
            setId('')
            props.store.shareCart.OrderDetailForm = false
            store.shareCart.OrderDetailForm = false
        } else {
            setRadioChoice(1)
            setHaveBaId(true)
            setHaveReferralCode(false)
            setOrderDetailForm(false)
            setShowLoader(false)
            props.store.shareCart.OrderDetailForm = false
            store.shareCart.OrderDetailForm = false
        }
        setErrorInvalidID(false)
        setErrorInvalidReferralCode('')
        storeEasyShop.isEasyShopForm = false
    }

    const onClickConfirm = () => {
        setShowLoader(true)
        clearEasyShop()
        storeEasyShop.isEnabled = true
        if (radioChoice === 3) {
            if (isNothing(referralCode)) {
                setErrorInvalidID(false)
                setErrorInvalidReferralCode('rc_ba_id_not_found')
                setShowLoader(false)
                setNotActive(false)
            } else {
                APIReferralCode.Validate((response, status) => {
                    if (status) {
                        logi('Validate success! =>', response.data.ba_id)
                        storeEasyShop.baID = id
                        storeEasyShop.referralCode = response.data.ba_id
                        storeEasyShop.referralBy = ''
                        storeEasyShop.referenceDetail = ''
                        shopStore.productDataSource.map((v, k) => {
                            v.member_price = v.wholesale_price
                            return v
                        })
                        setOrderDetailForm(true)
                        props.store.shareCart.OrderDetailForm = true
                        store.shareCart.OrderDetailForm = true
                    } else {
                        setErrorInvalidReferralCode('rc_ba_id_not_found')
                        setShowLoader(false)
                    }
                    
                }, referralCode)
            }
        } else {
            if (haveBaId) {
                if (isNothing(id)) {
                    setErrorInvalidID(true)
                    setErrorInvalidReferralCode('')
                    setShowLoader(false)
                    setNotActive(false)
                } else {

                    getUserDetail2((result) => {
                       
                
                        if(result.error){
                            setErrorInvalidID(true)
                            setShowLoader(false)
                            setNotActive(false)
                            return
                        }
                        const baType = result.items[0].type
                        const baStatus = result.items[0].status
                        // for option > Yes, I have a distributor number.
                        if (baType === 'Associate') {
                            if (baStatus === 'Active' || baStatus === 'Pending') {
                                storeEasyShop.baID = id
                                storeEasyShop.referralBy = 'ba_id'
                                storeEasyShop.referenceDetail = result.items[0]
                                shopStore.productDataSource.map((v, k) => {
                                    v.member_price = v.wholesale_price
                                    return v
                                })
                                setOrderDetailForm(true)
                                props.store.shareCart.OrderDetailForm = true
                                store.shareCart.OrderDetailForm = true


                            } else {
                                setErrorInvalidID(true)
                                setShowLoader(false)
                                setNotActive(true)
                            }
                        } else {
                            setErrorInvalidID(true)
                            setShowLoader(false)
                            setNotActive(true)
                        }

                         


                    
                    }, id);

                    // expandCustomer((res, status) => {
                    //     if (status) {
                    //         if (res.error) {
                    //             setErrorInvalidID(true)
                    //             setShowLoader(false)
                    //             setNotActive(false)
                    //             return
                    //         }
                    //         const baType = res.items[0].type
                    //         const baStatus = res.items[0].status
                    //         // for option > Yes, I have a distributor number.
                    //         if (baType === 'Associate') {
                    //             if (baStatus === 'Active' || baStatus === 'Pending') {
                    //                 storeEasyShop.baID = id
                    //                 storeEasyShop.referralBy = 'ba_id'
                    //                 storeEasyShop.referenceDetail = res.items[0]
                    //                 shopStore.productDataSource.map((v, k) => {
                    //                     v.member_price = v.wholesale_price
                    //                     return v
                    //                 })
                    //                 setOrderDetailForm(true)
                    //                 props.store.shareCart.OrderDetailForm = true
                    //                 store.shareCart.OrderDetailForm = true


                    //             } else {
                    //                 setErrorInvalidID(true)
                    //                 setShowLoader(false)
                    //                 setNotActive(true)
                    //             }
                    //         } else {
                    //             setErrorInvalidID(true)
                    //             setShowLoader(false)
                    //             setNotActive(true)
                    //         }
                    //     } else {
                    //         setErrorInvalidID(true)
                    //         setShowLoader(false)
                    //     }
                    // }, null, id)
                }
            } else {
                // for option > No, I would like to buy with the normal price.
                storeEasyShop.referralBy = 'by_referral_name'
                setOrderDetailForm(true)
                props.store.shareCart.OrderDetailForm = true
                store.shareCart.OrderDetailForm = true
            }
        }
    }

    const getBaIdFromInput = (e) => {
        setErrorInvalidID(false)
        setId(e.target.value)
        setNotActive(false)
    }

    const getReferralCodeFromInput = (e) => {
        setErrorInvalidReferralCode('')
        setReferralCode(e.target.value)
        setNotActive(false)
    }

    return (
        <If condition={orderDetailForm || props.store.shareCart.OrderDetailForm}>
            <Then>
                <OrderDetailProcess 
                    {...props}
                    store={props.store}
                    history={props.history}
                    shopStore={props.shopStore}
                    backToLogin={() => { backToLogin() }}
                />
                {/* <EasyForm
                    {...props}
                    store={props.store}
                    history={props.history}
                    shopStore={props.shopStore}
                    backToLogin={() => { backToLogin() }}
                /> */}
            </Then>
            <Else>
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-9 col-sm-12 col-xs-12  margin-bottom-2x">
                    <Header>
                        <PageTitle>
                            {dictionary('u_all_welcome')}
                            
                        </PageTitle>
                    </Header>
                        <Switch>
                            <Case condition={isCountryAustralia()}>
                                <div className="shopping-cart-footer checkout-footer th-form-login">
                                    <h4>{dictionary('existing_customer')}</h4>
                                    <div className="">
                                        <div className="mt-4 custom-control custom-radio custom-control-inline" >
                                            <div className="block-input">
                                                <input className="custom-control-input" type="radio" id="loginBaID" name="loginOption" 
                                                    checked={radioChoice === 1}
                                                    onChange={handlerChoiceYes} />
                                                <label className="custom-control-label" htmlFor="loginBaID">
                                                    I am registered with Unicity.
                                                </label>
                                            </div>
                                            
                                        </div>
                                        <br />
                                        <div className="mt-4 custom-control custom-radio custom-control-inline" >
                                            <div className="block-input">
                                                <input className="custom-control-input" type="radio" id="referralText" name="loginOption"         
                                                    checked={radioChoice === 2}
                                                    onChange={handlerChoiceNo} />
                                                <label className="custom-control-label" htmlFor="referralText">
                                                    Check out as a guest.
                                                </label>
                                            </div>
                                            
                                        </div>
                                        <br />
                                        <div className="mt-4 custom-control custom-radio custom-control-inline" >
                                            <div className="block-input">
                                                <input className="custom-control-input" type="radio" id="referralCode" name="loginOption"    
                                                    checked={radioChoice === 3}
                                                    onChange={handlerChoiceReferralCode} />
                                                <label className="custom-control-label" htmlFor="referralCode">
                                                    I have a referral code.
                                                </label>
                                            </div>
                                        </div>

                                        <div className="mt-5 border-top pt-5">
                                            <div className="row justify-content-center">
                                                <When condition={haveBaId}>
                                                    <div className="col-12 mb-2 col-sm-8">
                                                        <input autoComplete="new-password" autoCorrect="off" autoCapitalize="off" spellCheck="false" type="text" className="form-control text-center" id="referral_value"
                                                            value={defaults(id, '')} disabled={showLoader}
                                                            onChange={(e) => getBaIdFromInput(e)}
                                                            onKeyPress={(e) => _handleKeyPress(e)} placeholder={dictionary('distributor_id')}
                                                        />
                                                    </div>
                                                </When>

                                                <When condition={haveReferralCode}>
                                                    <div className="col-12 mb-2 col-sm-8">
                                                        <input autoComplete="new-password" autoCorrect="off" autoCapitalize="off" spellCheck="false" type="text" className="form-control text-center" id="referral_code"
                                                            value={defaults(referralCode, '')} 
                                                            disabled={showLoader}
                                                            onChange={(e) => getReferralCodeFromInput(e)}
                                                            onKeyPress={(e) => _handleKeyPress(e)} placeholder={'Referral Code'}
                                                        />
                                                    </div>
                                                </When>

                                                <div className="col-12 col-sm-4">
                                                    <button type="submit" 
                                                        className="col-12 btn primary-btn py-0" 
                                                        disabled={showLoader} onClick={onClickConfirm}>
                                                        <SpinnerInline spin={showLoader} className="mr-2"/>
                                                        {dictionary('confirm')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <When condition={errorInvalidID}>
                                            <div className="alert alert-danger margin-top-1x text-center">
                                                <If condition={notActive}>
                                                    <Then>{dictionary('only_active_distributor')}</Then>
                                                    <Else>{dictionary('invalid_distributor_number')}</Else>
                                                </If>
                                            </div>
                                        </When>

                                        <When condition={isNothing(errorInvalidReferralCode) === false}>
                                            <div className="alert alert-danger margin-top-1x text-center">
                                                {dictionary(errorInvalidReferralCode)}
                                            </div>
                                        </When>
                                    </div>
                                </div>
                                <div className="clearfix" />
                            </Case>
                            <Default>
                                <div className="shopping-cart-footer checkout-footer th-form-login">
                                    <h4>{dictionary('existing_customer')}</h4>
                                    <div className="">
                                        <div className="mt-4 custom-control custom-radio custom-control-inline" >
                                            <div className="block-input">
                                                <input className="custom-control-input" type="radio" id="loginBaID" name="loginOption" 
                                                    checked={radioChoice === 1}
                                                    onChange={handlerChoiceYes} />
                                                <label className="custom-control-label" htmlFor="loginBaID">
                                                    {dictionary('login_yes')}
                                                </label>
                                            </div>
                                            <When condition={haveBaId}>
                                                <div className="col-12 referral_value_block">
                                                    <div className="title">
                                                        {dictionary('distributor_id')}
                                                    </div>
                                                    <input autoComplete="new-password" autoCorrect="off" autoCapitalize="off" spellCheck="false" type="text" className="form-control text-center" id="referral_value"
                                                        value={defaults(id, '')} disabled={showLoader}
                                                        onChange={(e) => getBaIdFromInput(e)}
                                                        onKeyPress={(e) => _handleKeyPress(e)} placeholder=""
                                                    />
                                                </div>
                                            </When>
                                        </div>
                                        <div className="mt-4 custom-control custom-radio custom-control-inline" >
                                            <div className="block-input">
                                                <input className="custom-control-input" type="radio" id="referralText" name="loginOption"         checked={radioChoice === 2}
                                                    onChange={handlerChoiceNo} />
                                                <label className="custom-control-label" htmlFor="referralText">
                                                    {dictionary('login_no')}</label>
                                            </div>
                                        </div>
                                        <When condition={errorInvalidID}>
                                            <div className="alert alert-danger margin-top-1x text-center">
                                                <If condition={notActive}>
                                                    <Then>{dictionary('only_active_distributor')}</Then>
                                                    <Else>{dictionary('invalid_distributor_number')}</Else>
                                                </If>
                                            </div>
                                        </When>

                                        <div className="block-button">
                                            <div className="row justify-content-center">
                                                

                                                <div className="col-12 ">
                                                    <button type="submit" 
                                                        className="col-12 btn primary-btn py-0" 
                                                        disabled={showLoader} onClick={onClickConfirm}>
                                                        <SpinnerInline spin={showLoader} className="mr-2"/>
                                                        {dictionary('confirm')}
                                                    </button>
                                                </div>
                                                <div className="col-12 ">
                                                    <button 
                                                        className="col-12 btn btn-back py-0" 
                                                        disabled={showLoader} onClick={()=>{ store.shareCart.proceedCheckout = false
                                                        
                                                        }}>
                                                        
                                                        {dictionary('back')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                                <div className="clearfix" />
                            </Default>
                        </Switch>
                    </div>
                </div>
            </Else>
        </If>
    )
})

export default EasyChoice