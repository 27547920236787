import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import LoginForm from './LoginForm';
import EasyShop from '../easyshop/EasyShop';
import { StoreConfigs } from '../../configs/Configs';
import { When } from 'react-if';
import once from 'lodash/once';

const Login = observer(props => {
    
    return (
        <div className="easy-choice">
            <EasyShop {...props} className={"justify-content-center"}/>
           
        </div>
    )
})

export default Login