import * as K from './Keywords'

export const ListPriceLevel = {
    /* [K.Thailand]: [
        {
            name: dictionary('price_retail_profit', 'P', '25'), 
            value: '25'
        },
        {
            name: dictionary('price_retail_profit', 'P', '20'), 
            value: '20'
        },
        {
            name: dictionary('price_retail_profit', 'P', '10'), 
            value: '10'
        }
    ] */
    [K.Thailand]: [
        {name: '25%', value: '25'},
        {name: '20%', value: '20'},
        {name: '10%', value: '10'}
    ]
}

export const ShippingMethods = {
    delivery: '1',
    pickup: '2'
}
export const ShippingMethodsToString = {
    1: 'delivery',
    2: 'pickup'
}
export const PriceLevelToDictionaryKey = {
    1: 'retail_price',
    2: 'wholesale_price',
    3: 'preferred_customer',
    4: 'employee_price'
}
export const PriceLevelToPriceKey = {
    1: 'retail_price',
    2: 'wholesale_price',
    3: 'preferred_customer',
    4: 'employee_price'
}
export const PriceLevelToStringNumber = {
    retail: '1',
    wholesale: '2',
    preferred: '3',
    employee: '4',
    family: '4'
}
export const PriceLevelToUserType = {
    1: 'Customer',
    2: 'Associate',
    3: 'PreferredCustomer'
}
export const PriceLevelToKey = {
    1: 'retail',
    2: 'wholesale',
    3: 'preferred'
}
export const ShippingMethodList = {
    [K.All]: [
        { name: 'select_shpping_method', value: '' },
        { name: 'delivery', value: ShippingMethods.delivery },
        { name: 'pickup', value: ShippingMethods.pickup }
    ],
    [K.Thailand]: [
        { name: 'select_shpping_method', value: '' },
        { name: 'delivery', value: ShippingMethods.delivery },
        { name: 'pickup', value: ShippingMethods.pickup }
    ],
    
    [K.Philippines]: [
        { name: 'select_shpping_method', value: '' },
        { name: 'delivery', value: ShippingMethods.delivery },
        { name: 'pickup', value: ShippingMethods.pickup }
    ],
    [K.Singapore]: [
        { name: 'select_shpping_method', value: '' },
        { name: 'delivery', value: ShippingMethods.delivery },
        { name: 'pickup', value: ShippingMethods.pickup }
    ],
    [K.Japan]: [
        { name: 'select_shpping_method', value: '' },
        { name: 'delivery', value: ShippingMethods.delivery },
        { name: 'pickup', value: ShippingMethods.pickup }
    ]
}

export const CustomerLevelList = {
    [K.All]: [
        { name: 'sp_select_customer_lv', value: '' },
        { name: 'sp_retail_customer', value: 'retail' },
    ],    
    [K.Thailand]: [
        { name: 'sp_select_customer_lv', value: '' },
        { name: 'sp_retail_customer', value: 'retail' },
        { name: 'sp_downline_customer', value: 'wholesale' } 
    ],
    [K.Philippines]: [
        { name: 'sp_select_customer_lv', value: '' },
        { name: 'sp_retail_customer', value: 'retail' },
        { name: 'sp_preferred_customer', value: 'preferred' },
        { name: 'sp_downline_customer', value: 'wholesale' } 
    ],
    [K.Singapore]: [
        { name: 'sp_select_customer_lv', value: '' },
        { name: 'sp_retail_customer', value: 'retail' },
        { name: 'sp_preferred_customer', value: 'preferred' },
        { name: 'sp_downline_customer', value: 'wholesale' }        
    ]
}
export const DownlineAddressFields = {

    [K.All]: [
        {
            id: 'name',
            label: 'name',
            field: 'name',
            className: 'col-md-12',
            placeholder: 'share_this_cart_name_placeholder',
            component: 'InputText'
        },
        {
            id: 'address1',
            label: 'address1',
            field: 'address1',
            className: 'col-md-6',
            placeholder: 'share_this_cart_address1_placeholder',
            component: 'InputText'
        },
        {
            id: 'address2',
            label: 'address2',
            field: 'address2',
            className: 'col-md-6',
            placeholder: 'share_this_cart_address2_placeholder',
            component: 'InputText'
        },
        {
            id: 'zip',
            label: 'zip',
            field: 'zip',
            className: 'col-md-6',
            placeholder: 'share_this_cart_zip_code_placeholder',
            component: 'InputText'
        },        
    ],
    [K.Thailand]: [
        {
            id: 'name',
            label: 'name',
            field: 'name',
            className: 'col-md-12',
            placeholder: 'share_this_cart_name_placeholder',
            component: 'InputText'
        },
        {
            id: 'address1',
            label: 'address1',
            field: 'address1',
            className: 'col-md-6',
            placeholder: 'share_this_cart_address1_placeholder',
            component: 'InputText'
        },
        {
            id: 'address2',
            label: 'address2',
            field: 'address2',
            className: 'col-md-6',
            placeholder: 'share_this_cart_address2_placeholder',
            component: 'InputText'
        },
        {
            id: 'city',
            label: 'city',
            field: 'city',
            className: 'col-md-6',
            placeholder: 'city',
            component: 'CityDropdown'
        },
        {
            id: 'zip',
            label: 'zip',
            field: 'zip',
            className: 'col-md-6',
            placeholder: 'share_this_cart_zip_code_placeholder',
            component: 'InputText'
        },        
    ],
    [K.Singapore]: [
        {
            id: 'name',
            label: 'name',
            field: 'name',
            className: 'col-md-12',
            placeholder: 'name',
            component: 'InputText'
        },
        {
            id: 'address1',
            label: 'address1',
            field: 'address1',
            className: 'col-md-6',
            placeholder: 'address1',
            component: 'InputText'
        },
        {
            id: 'address2',
            label: 'address2',
            field: 'address2',
            className: 'col-md-6',
            placeholder: 'address2',
            component: 'InputText'
        },
        {
            id: 'zip',
            label: 'zip',
            field: 'zip',
            className: 'col-md-6',
            placeholder: 'zip',
            component: 'InputText'
        },        
    ]
}
export const ShippingMethodsDeliveryType = {
    [K.All]: {
        type: 'Economy',
        location: ''
    },
    [K.Singapore]: {
        type: 'Economy',
        location: 'Singapore, Main Office'
    }
}
export const ShippingMethodsPickUpType = {
    [K.All]: {
        type: 'WillCall',
        location: ''
    },
    [K.Singapore]: {
        type: 'WillCall',
        location: 'Singapore, Main Office'
    },
    [K.Thailand]: {
        type: 'WillCall',
        location: 'Bangkok, Main Office'
    }
}