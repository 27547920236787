import { observable, makeAutoObservable, get as mget, set as mset } from 'mobx';



class StoreFormConfigs {
    formItem = {
        colon: false
    }
    selectableCity = {
        thailand: true,
        indonesia: true
    }
    constructor() {
        makeAutoObservable(this)
    }
}
class StoreDeliveryValue {
    selectedAddressHistory = undefined
    selected = undefined
    fullName = ''
    address = ''
    address1 = ''
    address2 = ''
    city = ''
    state = ''
    zip = ''
    mobile = ''
    email = ''
    referral_id = ''
    comment = ''
    distributorId = ''
    constructor() {
        makeAutoObservable(this)
    }
}
class StorePickUpValue {
    fullName = ''
    mobile = ''
    email = ''
    referral_id = ''
    distributorId = ''
    constructor() {
        makeAutoObservable(this)
    }
}

class StoreAramexValue {
    fullName = ''
    address1 = ''
    address2 = ''
    city = ''
    state = ''
    zip = ''
    mobile = ''
    email = ''
    sns1 = ''
    sns2 = ''
    distributorId = ''
    constructor() {
        makeAutoObservable(this)
    }
}

class StoreDeliveryAddressHistory {
    list = []
    open = false
    focus = false
    constructor() {
        makeAutoObservable(this)
    }
}

class StoreAddressHistory {
    isLoading = true
    isListEmpty = false
    isLegitMainAddress = false
    list = []
    options = []
    open = false
    focus = false
    selected = null // could be number
    selectedValue = '' // could be string

    userDetails = null

    getUserDetails() {
        return this.userDetails
    }

    setUserDetails(value) {
        this.userDetails = value
    }

    constructor() {
        makeAutoObservable(this)
    }

    hasOptions() {
        return this.options.length > 0
    }

    reset() {
        this.open = false
        this.selected = null
        this.selectedValue = ''
        this.options = []
    }
}

export const mAddressHistory = new StoreAddressHistory()
export const mDeliveryAddressHistory = new StoreDeliveryAddressHistory()
export const mAramexValue = new StoreAramexValue()
export const mPickUpValue = new StorePickUpValue()
export const mDeliveryValue = new StoreDeliveryValue()
export const mFormConfigs = new StoreFormConfigs()