import React, { useState, useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { checkOutStore, staticFooter, store } from '../../../../stores/MainStore';
import { dictionary, isCountryPhilippines } from '@GlobalHelpers';
import { language } from '../../../../stores/StoreLanguage';
import { storeEasyShop } from '../../../../stores/StoreEasyShop';
import { Row, Col, Checkbox } from 'antd';
import { throatBoolean } from '../../../configs/ConfigsHeader';
import { isMobile, isIPad } from '../../../utils/Bowser';
import { getCustomerStatus} from "../../../utils/Customer";
import { storeOrderDetails } from '../Store/StoreOrderDetails';
import { toJS,reaction } from "mobx";
import { If, Then, When, Else, Unless, Switch, Default, Case  } from 'react-if';
import { isSomething } from "../../../utils/Utils";
import * as K from '../../../configs/Keywords';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import Modal from 'antd/es/modal';
import ReactMarkdown from 'react-markdown';

const colResponsiveConfig = {
  xs: 24,
  sm: 24,
  md: 20,
  lg: isIPad() ? 12 : 16,
  xl: 12,
  xxl: 12
}

const StyledCheckbox = styled(Checkbox)`
  margin-left: 15px;
  display: flex;
​
  > span:first-child {
      display: inline-table;
      margin-top: 5px;
  }
​
  > span:last-child {
      display: inline-table;
      padding-right: 0;
  }

  .ant-checkbox {
      height: min-content;
      margin-top: 5px;
  }
`

const confirmEmailList = {
  [K.All]: true
}

const printedInvoiceList = {
  [K.All]: false,
  [K.Thailand]: true
}

const showTCList = {
  [K.All]: false,
  [K.Thailand]: true,
  [K.Philippines]: true,
}

const FormCheckBox = props => {
  const [isConfirmEmail, setConfirmEmail] = useState(false)
  const [isPrintedInvoice, setPrintedInvoice] = useState(false)
  const [isShowTC, setShowTC] = useState(false)
  const [isAgreeTC, setAgreeTC] = useState(false)
  const [isReadTC, setReadTC] = useState(false)

  const [tcContent, setTCContent] = useState()
  const [tcType, setTCType] = useState()

  useEffect(()=>{
      
        // setAgreeTC(true)
        // storeOrderDetails.isCheckTc = true
    
  },[])

  const processTCContent = () => {
 

      let status = 'A';
    //   if(storeEasyShop.easyChoice == 1){
    //     status = 'A'
    //   }else{
    //     status = 'C'
    //   }

    //   if(isCountryPhilippines()){
    //     status = 'A'
    //   }
      setShowTC(false)
      props.onCheckedTC(true)
      
      staticFooter.footerTCOrder.map((item) => {

          console.log('item.status[language.footer]',item.status[language.footer])
          console.log('status',status)
  
          if (item.status[language.footer] == status) {
              if (isSomething(item.html_content)) {
                  setTCContent(item.html_content)
                  setTCType('text')
                  setShowTC(true)
                  props.onCheckedTC(false)
              }
          }
      })
  }

  useEffect(() => {
      setConfirmEmail(throatBoolean(confirmEmailList))
      setPrintedInvoice(throatBoolean(printedInvoiceList))
      setShowTC(throatBoolean(showTCList))

      if (throatBoolean(showTCList) === false) {
          props.onCheckedTC(true)
      }

      processTCContent()

      const disposerTCContent = reaction(
          () => language.current,
          () => {
              processTCContent()
          }
      )

      return () => {
          disposerTCContent()
      }

  }, [])

  const onCheckEmailConfirmation = (e) => {
      checkOutStore.isReceivedEmail = e.target.checked
  }

  const onCheckReceipt = (e) => {
      checkOutStore.isReceivedInvoice = e.target.checked
  }

  const onCheckTC = (e) => {
      //props.onCheckedTC(e.target.checked)
      setAgreeTC(e.target.checked)

      console.log('e.target.checked',e.target.checked)

      storeOrderDetails.isCheckTc = e.target.checked
      storeOrderDetails.errorMessage = []

  }

  const onClickReadTC = () => {
      setReadTC(!isReadTC)
  }

  return useObserver(() =>
      <Row type="flex" justify="center" gutter={[0, 12]}>
          <Col {...colResponsiveConfig}>
              <When condition={isConfirmEmail}>
                  <Col span={24}>
                      <StyledCheckbox
                          onChange={onCheckEmailConfirmation}
                          checked={checkOutStore.isReceivedEmail}>
                          {dictionary('confirmation_email_checkbox')}
                      </StyledCheckbox>
                  </Col>
              </When>
              <When condition={isPrintedInvoice}>
                  <Col span={24}>
                      <StyledCheckbox
                          onChange={onCheckReceipt}
                          checked={checkOutStore.isReceivedInvoice}>
                          {dictionary('printed_invoice')}
                      </StyledCheckbox>
                  </Col>
              </When>

              <When condition={isShowTC}>
                  <Col span={24}>
                      <StyledCheckbox
                          onChange={onCheckTC}
                          checked={storeOrderDetails.isCheckTc}
                          defaultChecked={false}>
                          <span>{dictionary('i_agree_company')}{' '}</span>
                          
                      </StyledCheckbox>
                      <span onClick={onClickReadTC} className="order-details-read-tc">
                            {dictionary('terms_and_conditions')}
                        </span>
                  </Col>
              </When>

              <When condition={isReadTC}>
                  <Switch>
                      {/* <Case condition={tcType === 'image'}>
                          {() => 
                              <Lightbox 
                                  mainSrc={tcContent[language.footer]}
                                  onCloseRequest={onClickReadTC}
                              />
                          }
                      </Case> */}
                      <Case condition={tcType === 'text'}>
                          {() =>
                              <Modal
                                  centered
                                  wrapClassName="order-details-modal"
                                  visible={isReadTC}
                                  onCancel={onClickReadTC}>
                                  <ReactMarkdown source={tcContent[language.footer]} escapeHtml={false} />
                              </Modal>
                          }
                      </Case>
                  </Switch>
              </When>
          </Col>
      </Row>
  )
}

export default FormCheckBox